import { useEffect, useState } from "react";
import {
  API_URL,
  API_ROUTES,
  API_ROUTES_NTD,
  API_ROUTES_ZALO,
} from "../../../api";
import axios, { all } from "axios";
import { useHeaders } from "../../../const/headers";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { locale } from "primereact/api";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { Checkbox } from "primereact/checkbox";
export const TableAccountGr = ({
  getDataAllAddGr,
  dataZalo,
  selectedItems,
  setSelectedItems,
  refresh,
  setAllAddFriend,
  getScanAddFriend,
  setAllGroup,
  allGroup,
  setListFr,
  listFr,
}) => {
  locale("vi");
  const apiAllGroup = API_URL + API_ROUTES_ZALO.GET_ALL_GROUP;
  const headers = useHeaders();

  const filterAccountCheckpoit = dataZalo?.filter(
    (item) => item?.checkpoint === false && item?.proxy?.status === true
  );
  useEffect(() => {
    if (selectedItems?.length > 0) {
      getDataAllAddGr(selectedItems[0]);
    }
  }, [refresh, selectedItems]);
  const nameAndAvt = (rowData) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <img
          className="rounded-circle"
          style={{ width: "20px", height: "20px" }}
          src={rowData?.avatar}
        />
        <p>{rowData?.name}</p>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  const bodyCheckbox = (
    rowData,
    selectedItems = [],
    onSelectionChange,
    getDataAllAddFr
  ) => {
    const isSelected =
      Array.isArray(selectedItems) &&
      selectedItems.some((item) => item.uid === rowData.uid);

    return (
      <Checkbox
        checked={isSelected}
        onChange={(e) => {
          e.stopPropagation();
          if (isSelected) {
            onSelectionChange(
              selectedItems.filter((item) => item.uid !== rowData.uid)
            );
          } else {
            onSelectionChange(rowData);
          }
        }}
      />
    );
  };
  return (
    <div style={{ border: "1px solid", width: "100%", height: "400px" }}>
      <DataTable
        size="small"
        value={filterAccountCheckpoit || []}
        className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ minWidth: "100%" }}
        rowClassName={(rowData) => rowClassName(rowData, selectedItems)}
        onRowClick={(e) => {
          const isSelected = selectedItems.some(
            (item) => item.uid === e.data.uid
          );
          if (isSelected) {
            setSelectedItems([]);
          } else {
            setSelectedItems([e.data]);
          }
          setListFr([]);
        }}
      >
        <Column
          body={(rowData) =>
            bodyCheckbox(
              rowData,
              selectedItems,
              (newSelection) => setSelectedItems([newSelection]),
              getDataAllAddGr
            )
          }
        />
        <Column body={indexTemplate} field="uid" header="STT"></Column>
        <Column
          body={(rowData) => nameAndAvt(rowData)}
          field="name"
          header="Tên tài khoản"
          sortable
        ></Column>
      </DataTable>
    </div>
  );
};
