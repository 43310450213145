import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES, API_ROUTES_ZALO } from "../../../api";
import "primeicons/primeicons.css";
import { RiExpandDiagonal2Line } from "react-icons/ri";
import { useAuth } from "../../../AuthContext";
import "./Friend.css";
import { ListAccount } from "./ListAccount";
import { ListFrOfAccount } from "./ListFrOfAccount";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
export const ModalScanFr = ({
  setRefresh,
  refresh,
  dataZalo,
  setDataZalo,
  listFr,
  setListFr,
  toast,
  setManagerScript,
  renderKey,
  setOpenFriend,
  openFriend,
}) => {
  const headers = useHeaders();
  const apiAccountZalo = API_URL + API_ROUTES.GET_ACC_FB;
  const apiScanFriend = API_URL + API_ROUTES_ZALO.GET_LIST_FRIEND;
  const apiAllFriend = API_URL + API_ROUTES_ZALO.GET_ALL_FRIEND;
  const apiUnfriend = API_URL + API_ROUTES_ZALO.UNFRIEND;
  const apiUnfriendResult = API_URL + API_ROUTES_ZALO.UNFRIEND_RESULT;
  const scanFriendRequest = API_URL + API_ROUTES_ZALO.SCAN_LIST_FRIEND_REQUEST;
  const apiResultsListFriend = API_URL + API_ROUTES_ZALO.GET_LIST_FRIEND_RESULT;
  const { setUserLoggedIn } = useAuth();
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [rows, setRows] = useState(100);
  const [taskId, setTaskId] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [statusScan, setStatusScan] = useState(false);
  const [allGroup, setAllGroup] = useState([]);
  const [taskIdDelete, setTaskIdDelete] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [statusQuit, setStatusQuit] = useState(false);
  const [resultQuit, setResultQuit] = useState("");
  const [typeDelete, setTypeDelete] = useState("");
  const [rowDataDelete, setRowDataDelete] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [resultScan, setResultScan] = useState("");
  const [download, setDownload] = useState([]);
  const intervalRef = useRef();
  const getDataAllAddFr = async (item) => {
    if (item?.id !== undefined) {
      try {
        const res = await axios.get(
          `${apiAllFriend}?number_per_page=${rows}&page=${
            totalPage + 1
          }&id_account=${selectedItems[0]?.id}`,
          headers
        );
        setAllGroup(res.data.results);
        setGetTotal(res.data);
      } catch (err) {}
    } else {
      setAllGroup([]);
    }
  };
  const scanListFriends = async () => {
    if (selectedItems?.length === 0) {
      toast.error("Vui lòng chọn tài khoản");
      return;
    }
    const body = { id_account: selectedItems[0]?.id };
    try {
      const res = await axios.post(apiScanFriend, body, headers);
      setTaskId(res.data.id_task);
      setStatusScan(true);
    } catch (err) {}
  };
  const checkResultScan = async () => {
    if (taskId?.length === 0) {
      return;
    }
    const body = { id_task: taskId };
    try {
      const res = await axios.post(apiResultsListFriend, body, headers);
      setResultScan(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      checkResultScan();
    }, 3000);
    return () => clearInterval(intervalRef.current);
  }, [taskId]);
  useEffect(() => {
    if (resultScan?.status === "SUCCESS" || resultScan?.status === "FAILURE") {
      if (resultScan?.data[0]?.status === true) {
        toast.success("Quét danh sách bạn bè thành công");
        getDataAllAddFr(selectedItems[0]);
      }
      if (resultScan?.data[0]?.status === false) {
        toast.error("Quét danh sách bạn bè thất bại");
      }
      setStatusScan(false);
      setTaskId([]);
      setResultScan("");
      clearInterval(intervalRef.current);
      setRefresh(!refresh);
    }
  }, [resultScan]);
  useEffect(() => {
    axios
      .get(apiAccountZalo, headers)
      .then(function (response) {
        setDataZalo(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          setUserLoggedIn(false);
        }
      });
  }, []);
  // Xóa bạn
  const deleteFriend = async (rowData, type) => {
    const fids =
      type === "one" ? [rowData.uid] : selectedRows?.map((item) => item.uid);
    setTypeDelete(type);
    setRowDataDelete(rowData);
    const body = {
      id_account: selectedItems[0]?.id,
      fids: fids,
    };
    try {
      const res = await axios.post(apiUnfriend, body, headers);
      setTaskIdDelete(res.data);
      checkResultQuit(res.data.id_task, rowData, type);
      toast.info("Đang xóa bạn");
      setStatusQuit(true);
    } catch (err) {}
  };
  const checkResultQuit = async (taskId, rowData, type) => {
    const body = { id_task: taskId };
    try {
      const res = await axios.post(apiUnfriendResult, body, headers);
      if (res.data.status === "SUCCESS") {
        toast.success("Xóa bạn thành công");
        if (type === "one") {
          const spliceGr = allGroup.filter((item) => item.uid !== rowData.uid);
          setAllGroup(spliceGr);
          setTaskIdDelete([]);
          setRowDataDelete([]);
        } else if (type === "all") {
          const selectedUserIds = res.data.data.map((item) => item.uid);
          const filteredData = allGroup.filter(
            (item) => !selectedUserIds.includes(item.uid)
          );
          setAllGroup(filteredData);
          setSelectedRows([]);
          setTaskIdDelete([]);
        }
        setStatusQuit(false);
      } else if (res.data && res.data.status === "FAILURE") {
        toast.error("Xóa bạn thất bại");
        setStatusQuit(false);
      }
      setResultQuit(res.data ? res.data.status : "UNKNOWN");
    } catch (error) {
      setResultQuit("FAILURE");
      toast.error("Đã xảy ra lỗi khi xóa bạn");
      setStatusQuit(false);
    }
  };

  useEffect(() => {
    if (taskIdDelete?.id_task) {
      const interval = setInterval(() => {
        checkResultQuit(taskIdDelete.id_task, rowDataDelete, typeDelete);
      }, 3000);
      if (resultQuit === "SUCCESS" || resultQuit === "FAILURE") {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [resultQuit, taskIdDelete, rowDataDelete, typeDelete]);
  const handleExit = () => {
    setAllGroup([]);
    setSelectedItems([]);
    setSelectedRows([]);
    setOpenFriend(false);
  };
  const filterNameFriend = allGroup.filter((friend) =>
    friend.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const getDataAllFrDowload = async () => {
    if (!selectedItems[0]?.id) {
      alert("Vui lòng chọn tài khoản trước khi xuất file");
    }
    try {
      const res = await axios.get(
        `${apiAllFriend}?number_per_page=5000&id_account=${selectedItems[0]?.id}`,
        headers
      );
      setDownload(res.data.results);
    } catch (err) {}
  };
  const exportHTML = () => {
    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Exported HTML</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  margin: 20px;
              }
              h1 {
                  width:100%;
                  color: #4CAF50;
                  text-align:center
              }
              ul {
                  font-size: 18px;
                  list-style-type: square;
              }
              .nav-file-load{
                width:100%;
                display:flex;
                flex-wrap:wrap;
                justify-content:center;
                align-items:center;
                padding:0.5rem;
                gap:0.5rem
              }
              .nav-content-load{
                display:flex;
                align-items:center;
                gap:0.5rem;
                border : 2px solid;
                padding:0.5rem;
                width:300px;
              }
              .nav-content-load >img{
                border-radius:50%;
                width:35px;
                height:35px;
              }
             
          </style>
      </head>
      <body>
          <h1>Danh Sách Bạn Bè - ${selectedItems[0]?.name} - ${
      selectedItems[0]?.phone_number
    }</h1>
          <nav class="nav-file-load">
          ${download
            ?.map(
              (item) => `<nav class="nav-content-load">
            <img src=${item.avatar} />
            <p>${item.display_name}</p>
            </nav>`
            )
            .join("")}
          </nav>
      </body>
      </html>
    `;

    const blob = new Blob([htmlContent], { type: "text/html" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "banbe.html";
    link.click();
  };
  useEffect(() => {
    if (download?.length > 0) {
      exportHTML();
    }
  }, [download]);
  return (
    <Dialog
      header={
        <div
          style={{ position: "relative" }}
          className="d-flex align-items-center gap-2"
        >
          <span>Danh sách bạn bè</span>
          <Button
            onClick={scanListFriends}
            disabled={statusScan}
            className="button-blue radius"
            style={{ height: "30px" }}
          >
            <div style={{ fontSize: "12px" }}>
              {statusScan ? (
                <div className="d-flex flex-column h-100 justify-content-center align-items-center gap-2">
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "1.2em" }}
                  ></i>
                </div>
              ) : (
                "Quét"
              )}
            </div>
          </Button>
          <Button
            onClick={getDataAllFrDowload}
            className="button-blue radius"
            style={{ height: "30px" }}
          >
            <div style={{ fontSize: "12px" }}>Xuất file backup bạn bè</div>
          </Button>
          {selectedRows.length > 0 && (
            <Button
              className=" button-red radius"
              severity="danger"
              style={{
                padding: "4px 6px",
                fontSize: "12px",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: "0",
                right: "0",
              }}
              onClick={() => deleteFriend(selectedRows, "all")}
            >
              Xóa bạn đã chọn
            </Button>
          )}
          <div>
            <IconField iconPosition="left">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                className="p-inputtext-sm"
                style={{ fontSize: "12px" }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Tìm kiếm bạn bè"
              />
            </IconField>
          </div>
        </div>
      }
      visible={openFriend}
      style={{ position: "relative", width: "71vw" }}
      onHide={handleExit}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            onClick={handleExit}
            className="d-flex justify-content-center button-red"
          >
            Thoát
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="d-flex gap-1 w-100 h-100">
          <div style={{ width: "40%" }} className="h-100">
            <ListAccount
              getDataAllAddFr={getDataAllAddFr}
              listFr={listFr}
              setListFr={setListFr}
              refresh={refresh}
              dataZalo={dataZalo}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              allGroup={allGroup}
            />
          </div>
          <div style={{ width: "60%" }} className=" h-100">
            <ListFrOfAccount
              filterNameFriend={filterNameFriend}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              deleteFriend={deleteFriend}
              statusQuit={statusQuit}
              setTotalPage={setTotalPage}
              renderKey={renderKey}
              totalPage={totalPage}
              setGetTotal={setGetTotal}
              getTotal={getTotal}
              rows={rows}
              setRows={setRows}
              getDataAllAddFr={getDataAllAddFr}
              refresh={refresh}
              setRefresh={setRefresh}
              toast={toast}
              listFr={listFr}
              setListFr={setListFr}
              statusScan={statusScan}
              allGroup={allGroup}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
              setAllGroup={setAllGroup}
              setManagerScript={setManagerScript}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
