import React, { useState, useEffect } from "react";
import { IoMdAddCircle } from "react-icons/io";
import { Image } from "primereact/image";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { Tooltip } from "primereact/tooltip";
import { FaEyeSlash } from "react-icons/fa";

import axios from "axios";
export const ListProductShop = ({
  allProduct,
  API_URL,
  idAccount,
  idCategory,
  API_ROUTES_ZALO,
  headers,
  handleGetAllProduct,
  setEditProduct,
}) => {
  const navigate = useNavigate();
  const apiDeleteProduct = API_URL + API_ROUTES_ZALO.DELETE_PRODUCT_MINI_SHOP;
  const apiGetDomain = API_URL + API_ROUTES_ZALO.GET_DOMAIN;
  const apiActive = API_URL + API_ROUTES_ZALO.ACTIVE_PRODUCT_MINISHOP;
  const apiUnActive = API_URL + API_ROUTES_ZALO.UNACTIVE_PRODUCT_MINISHOP;
  const [domain, setDomain] = useState(null);
  const getDomainShop = async () => {
    try {
      const res = await axios.get(apiGetDomain, headers);
      setDomain(res.data);
    } catch (err) {}
  };
  useEffect(() => {
    getDomainShop();
  }, []);
  const handleDelete = async (item) => {
    const deleteArlet = window.confirm("Bạn có chắc chắn muốn xoá không");
    if (deleteArlet) {
      const body = {
        id_account: idAccount,
        id_product: item.id,
      };
      try {
        await axios.post(apiDeleteProduct, body, headers);
        handleGetAllProduct(idAccount, idCategory);
      } catch (err) {}
    }
  };
  const formatTextWithLineBreaks = (text) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  const handleActiveProduct = async (item) => {
    const body = {
      id_product: item.id,
    };
    try {
      await axios.post(apiActive, body, headers);
      handleGetAllProduct(idAccount, idCategory);
    } catch (err) {}
  };
  const handleUnActiveProduct = async (item) => {
    const body = {
      id_product: item.id,
    };
    try {
      await axios.post(apiUnActive, body, headers);
      handleGetAllProduct(idAccount, idCategory);
    } catch (err) {}
  };
  return (
    <div className="w-100 h-100 d-flex flex-column">
      <div style={{ borderBottom: "2px solid" }}>
        <nav
          onClick={() => {
            navigate(`/minishop/${idAccount}/${idCategory}/create`);
            setEditProduct([]);
          }}
          className="nav-item-shop"
        >
          <IoMdAddCircle size={40} />
          <p>Thêm sản phẩm</p>
        </nav>
      </div>
      <div className="d-flex flex-column gap-2 p-2 overflow-auto">
        {allProduct?.map((item, index) => (
          <nav
            key={index}
            style={{
              pointerEvents: item.status === 0 ? "none" : "",
              opacity: item.status === 0 ? "0.5" : "",
            }}
          >
            <a
              target="blank"
              href={`https://${domain}/showproduct/${idAccount}/${idCategory}/${item.id}`}
            >
              <nav
                className="mini-shop-container"
              >
                {item.images.length > 0 && (
                  <Image
                    className="mini-shop-image"
                    width="80px"
                    height="80px"
                    src={`${API_URL}${item.images[0]}`}
                    preview
                    alt="sanpham"
                  />
                )}
                <nav
                  style={{ width: "-webkit-fill-available" }}
                  className="mini-shop-info"
                >
                  <p className="mini-shop-description">
                    {formatTextWithLineBreaks(item.title)}
                  </p>
                  <nav className="mini-shop-variants">
                    {item.variants?.map((variant, index) => (
                      <p key={index} className="mini-shop-variant d-flex gap-2">
                        {`${variant.price}đ - Tổng ${
                          variant.total_quantity
                        } - Đã bán ${variant.sold_quantity} - Tồn kho ${
                          variant.inventory_quantity || 0
                        } - ${variant.options
                          ?.map((option) => option.value)
                          .join(", ")}`}
                        <p style={{ color: "red", fontSize: "14px" }}>
                          {item?.variants[index]?.stock_status
                            ?.toLowerCase()
                            .includes("hết")
                            ? item?.variants[index]?.stock_status
                            : ""}
                        </p>
                      </p>
                    ))}
                  </nav>
                  <p>{`${item.created_at?.split(".")[0]?.split("T")[1]} - ${
                    item.created_at?.split(".")[0]?.split("T")[0].split("-")[2]
                  }-${
                    item.created_at?.split(".")[0]?.split("T")[0].split("-")[1]
                  }-${
                    item.created_at?.split(".")[0]?.split("T")[0].split("-")[0]
                  }`}</p>
                </nav>
                <nav
                  style={{ width: "15%" }}
                  className="d-flex flex-column align-items-end justify-content-center"
                >
                  {item.status === 0 && Number(idAccount) === item.user && (
                    <>
                      <FaEye
                        style={{ opacity: "1", pointerEvents: "all" }}
                        id={`tooltip-icon-${item.id}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleActiveProduct(item);
                        }}
                        size={30}
                      />
                      <Tooltip
                        target={`#tooltip-icon-${item.id}`}
                        content={`Xác nhận sản phẩm do nhân viên ${item.creator_name} tạo`}
                        position="left"
                        mouseTrack
                        mouseTrackTop={15}
                      />
                    </>
                  )}
                  {item.status === 1 && Number(idAccount) === item.user && (
                    <>
                      <FaEyeSlash
                        style={{ opacity: "1", pointerEvents: "all" }}
                        id={`tooltip-icon-${item.id}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleUnActiveProduct(item);
                        }}
                        size={30}
                      />
                      <Tooltip
                        target={`#tooltip-icon-${item.id}`}
                        content={`Ẩn sản phẩm`}
                        position="left"
                        mouseTrack
                        mouseTrackTop={15}
                      />
                    </>
                  )}
                  <>
                    <FaRegEdit
                      style={{ opacity: "1", pointerEvents: "all" }}
                      id={`tooltip-icon-edit-${item.id}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setEditProduct(item);
                        navigate(`/minishop/${idAccount}/${idCategory}/edit`);
                      }}
                      size={30}
                    />
                    <Tooltip
                      target={`#tooltip-icon-edit-${item.id}`}
                      content={`Chỉnh sửa sản phẩm`}
                      position="left"
                      mouseTrack
                      mouseTrackTop={15}
                    />
                  </>
                  {Number(idAccount) === item.user && (
                    <>
                      <FaDeleteLeft
                        style={{ opacity: "1", pointerEvents: "all" }}
                        id={`tooltip-icon-delete-${item.id}`}
                        color="red"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleDelete(item);
                        }}
                        size={30}
                      />
                      <Tooltip
                        target={`#tooltip-icon-delete-${item.id}`}
                        content={`Xoá sản phẩm`}
                        position="left"
                        mouseTrack
                        mouseTrackTop={15}
                      />
                    </>
                  )}
                </nav>
              </nav>
            </a>
          </nav>
        ))}
      </div>
    </div>
  );
};
