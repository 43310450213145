import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState, useRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { RiExpandDiagonal2Line } from "react-icons/ri";
import { API_ROUTES_NTD, API_ROUTES_ZALO, API_URL } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { Dropdown } from "primereact/dropdown";
import { ShowGroupInvine } from "./ShowGroupInvine";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { ShowFrInvine } from "./ShowFrInvine";
import { ResultsInvineJionGr } from "./ResultsInvineJionGr";
export const InvineFrJoinGr = ({
  props,
  openInvine,
  setOpenInvine,
  dataZalo,
  toast,
  accoutDown,
  setAccountDown,
  setOpenRsInvine,
}) => {
  const headers = useHeaders();
  const apiJoinGr = API_URL + API_ROUTES_ZALO.INVITE_JOIN_GROUP;
  const apiRsJoinGr = API_URL + API_ROUTES_ZALO.RESULTS_INVITE_JOIN_GROUP;
  const [delayTime, setDelayTime] = useState(120);
  const [allGroup, setAllGroup] = useState([]);
  const [forcusGr, setForcusGr] = useState([]);
  const [forcusFr, setForcusFr] = useState([]);
  const [type, setType] = useState("uid");
  const [taskCre, setTaskCre] = useState([]);
  const [nameGr, setNameGr] = useState(false);
  const intervalRef = useRef();
  const [allFr, setAllFr] = useState([]);
  const [listUid, setListUid] = useState("");
  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        {option.avatar && (
          <img
            alt={option.name}
            src={option.avatar}
            style={{ width: "25px", borderRadius: "50%" }}
          />
        )}
        <div>{option.name}</div>
      </div>
    );
  };
  const handleJoinGr = async () => {
    const body = {
      id_account: accoutDown?.id,
      id_group: forcusGr?.id,
      type: type,
      ...(type === "phone"
        ? { phones: listUid?.split("\n") }
        : { uids: forcusFr?.map((item) => item.uid) }),
      delay_time: 120,
    };
    try {
      const res = await axios.post(apiJoinGr, body, headers);
      toast.success("Đang thực hiện ");
    } catch (err) {}
  };
  return (
    <Dialog
      header="Mời bạn bè tham gia nhóm"
      visible={openInvine}
      style={{ position: "relative", width: "70vw" }}
      onHide={() => setOpenInvine(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button onClick={handleJoinGr} className="button-blue radius">
            Mời bạn bè
          </Button>
          <Button
            onClick={() => {
              if (!accoutDown?.id) {
                return alert("Chọn tài khoản muốn xem kết quả");
              }
              setOpenRsInvine(true);
            }}
            className="button-blue radius"
          >
            Kết quả
          </Button>
          <Button
            className="button-red radius"
            onClick={() => setOpenInvine(false)}
          >
            Thoát
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex gap-2 h-100" style={{ overflow: "auto" }}>
        <div className="d-flex flex-column gap-2" style={{ width: "50%" }}>
          <Dropdown
            value={accoutDown}
            onChange={(e) => {
              setAccountDown(e.value);
            }}
            options={dataZalo?.filter(
              (item) => item.checkpoint === false && item.proxy.status
            )}
            itemTemplate={countryOptionTemplate}
            optionLabel="name"
            placeholder="Chọn tài khoản"
            className="w-full "
          />
          {accoutDown?.id && (
            <nav className="h-100 overflow-auto">
              <ShowGroupInvine
                setForcusGr={setForcusGr}
                forcusGr={forcusGr}
                allGroup={allGroup}
                setAllGroup={setAllGroup}
                accoutDown={accoutDown}
              />
            </nav>
          )}
        </div>
        {accoutDown?.id && (
          <div className="d-flex flex-column gap-2" style={{ width: "50%" }}>
            <div className="flex align-items-center">
              <RadioButton
                inputId="ingredient1"
                name="pizza"
                value="uid"
                onChange={(e) => setType(e.value)}
                checked={type === "uid"}
              />
              <label htmlFor="ingredient1" className="ml-2">
                Mời bạn bè bằng UID
              </label>
            </div>
            <div className="flex align-items-center">
              <RadioButton
                inputId="ingredient1"
                name="pizza"
                value="phone"
                onChange={(e) => setType(e.value)}
                checked={type === "phone"}
              />
              <label htmlFor="ingredient1" className="ml-2">
                Mời bạn bè bằng số điện thoại
              </label>
            </div>
            <nav className=" mt-4 d-flex gap-2 flex-column">
              <p>Thời gian chờ </p>
              <InputNumber
                placeholder="Thời gian chờ"
                value={delayTime}
                onChange={(e) => {
                  const inputValue = e.value;
                  if (inputValue < 120) {
                    setDelayTime(120);
                  } else {
                    setDelayTime(inputValue);
                  }
                }}
                className="w-100"
                min={120}
              />
            </nav>
            <nav className=" mt-4 d-flex gap-2 flex-column overflow-auto">
              {type === "uid" ? (
                <>
                  <ShowFrInvine
                    setForcusFr={setForcusFr}
                    forcusFr={forcusFr}
                    allFr={allFr}
                    setAllFr={setAllFr}
                    accoutDown={accoutDown}
                  />
                </>
              ) : (
                <>
                  <p>Danh sach số điện thoại</p>
                  <InputTextarea
                    value={listUid}
                    onChange={(e) => setListUid(e.target.value)}
                    placeholder={`0854663112\n0854795330`}
                    className="h-100"
                  />
                </>
              )}
            </nav>
          </div>
        )}
      </div>
    </Dialog>
  );
};
