import React, { useEffect, useRef, memo } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES, API_ROUTES_NTD, WS_URL } from "../../api";
import { Footer } from "./Footer";
import { useHeaders } from "../../const/headers";
import { updateTitle } from "../../const/updateTitle";
import { useUser } from "../../contexts/UserContext";
import { locale } from "primereact/api";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { TableUidInteract } from "../toolsfb/TableUidInteract";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { TableUidPage } from "../toolsfb/TableUidPage";
import { TableUidGroup } from "../toolsfb/TableUidGroup";
import { getAxiosResults } from "../../const/getAxios";
import { TableMemberGroup } from "../toolsfb/TableMemberGroup";
import { TableFriendOfUid } from "../toolsfb/TableFriendOfUid";
const ToolsFb = () => {
  locale("vi");
  updateTitle("Chốt Care - Quản Lý Bài Viết");
  const { userInfo } = useUser();
  const headers = useHeaders();
  const [dataFb, setDataFb] = useState();
  const [refresh, setRefresh] = useState(true);
  const [selecUser, setSelecUser] = useState([]);
  const [selecPage, setSelecPage] = useState([]);
  const [selecGroup, setSelecGroup] = useState([]);
  const [selectedMember, setSelectedMember] = useState([]);
  const [selectedFriend, setSelectedFriend] = useState([]);
  const [resultsUser, setResultsUser] = useState([]);
  const [resultPage, setResultPage] = useState([]);
  const [resultsGroup, setResultsGroup] = useState([]);
  const [resultsMember, setResultsMember] = useState([]);
  const [resultsFriend, setResultsFriend] = useState([]);
  const [listResults, setListResults] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [rows, setRows] = useState(100);
  const access = localStorage.getItem("access");
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const resultsPage = API_URL + API_ROUTES_NTD.TOOLBOX_PAGE_RESULT;
  const resultGroup = API_URL + API_ROUTES_NTD.TOOLBOX_GROUP_RESULT;
  const resultUser = API_URL + API_ROUTES_NTD.TOOLBOX_USER_RESULT;
  const resultsMemberGr = API_URL + API_ROUTES_NTD.TOOL_BOX_RESULT_MEMBER;
  const resultFriend = API_URL + API_ROUTES_NTD.TOOL_BOX_RESULTS_FRIEND;
  const socketRef = useRef(null);
  const stepperRef = useRef(null);
  const [renderKey, setRenderKey] = useState(Date.now());
  useEffect(() => {
    if (resultPage?.length > rows) {
      getAxiosResults(
        resultsPage,
        setResultPage,
        setGetTotal,
        rows,
        totalPage + 1
      );
    }
    if (resultsGroup?.length > rows) {
      getAxiosResults(
        resultGroup,
        setResultsGroup,
        setGetTotal,
        rows,
        totalPage + 1
      );
    }
    if (resultsMember?.length > rows) {
      getAxiosResults(
        resultsMemberGr,
        setResultsMember,
        setGetTotal,
        rows,
        totalPage + 1
      );
    }
    if (resultsUser?.length > rows) {
      getAxiosResults(
        resultUser,
        setResultsUser,
        setGetTotal,
        rows,
        totalPage + 1
      );
    }
    if (resultsFriend?.length > rows) {
      getAxiosResults(
        resultFriend,
        setResultsFriend,
        setGetTotal,
        rows,
        totalPage + 1
      );
    }
  }, [resultPage, resultsGroup, resultsUser, resultsMember, resultsFriend]);
  useEffect(() => {
    const client = new W3CWebSocket(
      `wss://${WS_URL}/ws/toolbox/${userInfo?.id}/?token=${access}`
    );
    socketRef.current = client;
    client.onerror = function () {};
    client.onopen = function () {};
    client.onmessage = function (message) {
      const parsedData = JSON.parse(message.data);
      if (parsedData.user !== undefined) {
        setResultsUser((prevWsResults) => [...prevWsResults, parsedData.user]);
      }
      if (parsedData.page !== undefined) {
        setResultPage((prevWsResults) => [...prevWsResults, parsedData.page]);
      }
      if (parsedData.group !== undefined) {
        setResultsGroup((prevWsResults) => [
          ...prevWsResults,
          parsedData.group,
        ]);
      }
      if (parsedData.friend !== undefined) {
        setResultsFriend((prevWsResults) => [
          ...prevWsResults,
          parsedData.friend,
        ]);
      }
      if (parsedData.member_group !== undefined) {
        setResultsMember((prevWsResults) => [
          ...prevWsResults,
          parsedData.member_group,
        ]);
      }
    };
    return () => {
      if (client) {
        client.close();
      }
    };
  }, []);
  useEffect(() => {
    axios
      .get(getDataFb, headers)
      .then(function (response) {
        setDataFb(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
        }
      });
  }, []);
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <Stepper
          ref={stepperRef}
          className="w-100 h-100 flex flex-column steppPannel flexbox-center"
        >
          <StepperPanel
            className="h-100 d-flex flexbox-center"
            header="Quét UID Tương Tác"
          >
            <TableUidInteract
              resultsUser={resultsUser}
              setResultsUser={setResultsUser}
              toast={toast}
              setRefresh={setRefresh}
              refresh={refresh}
              dataFb={dataFb}
              selecUser={selecUser}
              setSelecUser={setSelecUser}
              rows={rows}
              setRows={setRows}
              setListResults={setListResults}
              totalPage={totalPage}
              setTotalPage={setTotalPage}
              getTotal={getTotal}
              setGetTotal={setGetTotal}
            />
          </StepperPanel>
          <StepperPanel header="Quét UID Page">
            <TableUidPage
              resultPage={resultPage}
              setResultPage={setResultPage}
              toast={toast}
              setRefresh={setRefresh}
              refresh={refresh}
              dataFb={dataFb}
              selecPage={selecPage}
              setSelecPage={setSelecPage}
              rows={rows}
              setRows={setRows}
              setListResults={setListResults}
              totalPage={totalPage}
              setTotalPage={setTotalPage}
              getTotal={getTotal}
              setGetTotal={setGetTotal}
              renderKey={renderKey}
            />
          </StepperPanel>
          <StepperPanel header="Quét nhóm">
            <TableUidGroup
              resultsGroup={resultsGroup}
              setResultsGroup={setResultsGroup}
              toast={toast}
              setRefresh={setRefresh}
              refresh={refresh}
              dataFb={dataFb}
              selecGroup={selecGroup}
              setSelecGroup={setSelecGroup}
              rows={rows}
              setRows={setRows}
              setListResults={setListResults}
              totalPage={totalPage}
              setTotalPage={setTotalPage}
              getTotal={getTotal}
              setGetTotal={setGetTotal}
            />
          </StepperPanel>
          <StepperPanel header="Quét thành viên nhóm">
            <TableMemberGroup
              resultsMember={resultsMember}
              setResultsMember={setResultsMember}
              toast={toast}
              setRefresh={setRefresh}
              refresh={refresh}
              dataFb={dataFb}
              selectedMember={selectedMember}
              setSelectedMember={setSelectedMember}
              rows={rows}
              setRows={setRows}
              setListResults={setListResults}
              totalPage={totalPage}
              setTotalPage={setTotalPage}
              getTotal={getTotal}
              setGetTotal={setGetTotal}
            />
          </StepperPanel>
          <StepperPanel header="Quét bạn bè">
            <TableFriendOfUid
              resultsFriend={resultsFriend}
              setResultsFriend={setResultsFriend}
              selectedFriend={selectedFriend}
              setSelectedFriend={setSelectedFriend}
              toast={toast}
              setRefresh={setRefresh}
              refresh={refresh}
              dataFb={dataFb}
              rows={rows}
              setRows={setRows}
              setListResults={setListResults}
              totalPage={totalPage}
              setTotalPage={setTotalPage}
              getTotal={getTotal}
              setGetTotal={setGetTotal}
            />
          </StepperPanel>
        </Stepper>
      </div>
      <Footer />
    </div>
  );
};

export default memo(ToolsFb);
