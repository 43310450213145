import { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Button } from "primereact/button";
import { ViewNameGr } from "./ViewNameGr";
import { API_URL, API_ROUTES_ZALO } from "../../../../api";
import { useHeaders } from "../../../../const/headers";
import { ViewAvatarGr } from "./ViewAvatarGr";
export const SettingGroups = ({
  isSetting,
  setIsSetting,
  setImageStranger,
  getLinkAttack,
  handleSendMessenger,
  setRfGetMember,
  rfGetMember,
  idAcc,
  toast,
  memberGr,
  setRfChangeGr,
  rfChangeGr,
}) => {
  const headers = useHeaders();
  const intervalRef = useRef();
  const apiGetMember = API_URL + API_ROUTES_ZALO.GET_MEMBER_GROUP;
  const apiRsGetMember = API_URL + API_ROUTES_ZALO.RESULTS_GET_MEMBER_GROUP;
  const [taskCre, setTaskCre] = useState([]);
  const [nameGr, setNameGr] = useState(false);
  const [name, setName] = useState("");
  const uidAcc = localStorage.getItem("id_account");
  const [type, setType] = useState("name");
  const itemMember = JSON.parse(localStorage.getItem("itemMember"));
  const memberForcus = memberGr?.filter((item) => item.uid === uidAcc)[0];
  const handleGetMember = async () => {
    if (idAcc) {
      const body = {
        id_account: Number(idAcc),
        id_group: itemMember[0]?.group_id,
      };
      try {
        const res = await axios.post(apiGetMember, body, headers);
        setTaskCre(res.data.id_task);
        resultsGetMemeber(res.data.id_task);
      } catch (err) {}
    }
  };
  const resultsGetMemeber = async (idtask) => {
    const body = {
      id_task: idtask,
    };
    if (idtask.length > 0) {
      try {
        const res = await axios.post(apiRsGetMember, body, headers);
        if (res.data.status === "SUCCESS") {
          setNameGr(true);
          setTaskCre([]);
        } else if (res.data.status === "FAILURE") {
          setNameGr(true);
          setTaskCre([]);
        }
      } catch (error) {
        setNameGr(true);
        setTaskCre([]);
      }
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      resultsGetMemeber(taskCre);
    }, 3000);
    if (nameGr) {
      setNameGr(false);
      setRfGetMember(!rfGetMember);
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [taskCre]);
  useEffect(() => {
    if (itemMember) {
      handleGetMember();
    }
  }, [isSetting]);
  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Cài đặt"
        visible={isSetting}
        onHide={() => setIsSetting(false)}
        style={{ width: "70vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div
          className="d-flex  gap-4  pb-2"
          style={{ borderBottom: "2px solid" }}
        >
          {memberForcus?.is_admin ||
            (memberForcus?.is_creator && (
              <Button
                onClick={() => setType("name")}
                className="button-blue radius w-100"
                label="Đổi tên nhóm"
              />
            ))}
          {memberForcus?.is_admin ||
            (memberForcus?.is_creator && (
              <Button
                onClick={() => setType("avt")}
                className="button-blue radius w-100"
                label="Đổi ảnh đại diện"
              />
            ))}
          {memberForcus?.is_creator && (
            <Button
              onClick={() => setType("admin")}
              className="button-blue radius w-100"
              label="Thêm Trưởng nhóm"
            />
          )}
          {memberForcus?.is_creator && (
            <Button
              onClick={() => setType("rmadmin")}
              className="button-blue radius w-100"
              label="Xoá trưởng nhóm"
            />
          )}
        </div>
        <div className="d-flex p-2 align-items-center justify-content-center">
          {(memberForcus?.is_admin || memberForcus?.is_creator) &&
            type === "name" && (
              <ViewNameGr
                toast={toast}
                rfChangeGr={rfChangeGr}
                setRfChangeGr={setRfChangeGr}
                memberForcus={memberForcus}
                name={name}
                setName={setName}
                Button={Button}
                headers={headers}
                intervalRef={intervalRef}
                idAcc={idAcc}
                rfGetMember={rfGetMember}
                setRfGetMember={setRfGetMember}
              />
            )}
          {(memberForcus?.is_admin || memberForcus?.is_creator) &&
            type === "avt" && (
              <ViewAvatarGr
                toast={toast}
                rfChangeGr={rfChangeGr}
                setRfChangeGr={setRfChangeGr}
                memberForcus={memberForcus}
                Button={Button}
                headers={headers}
                intervalRef={intervalRef}
                idAcc={idAcc}
                rfGetMember={rfGetMember}
                setRfGetMember={setRfGetMember}
              />
            )}
        </div>
        {!memberForcus?.is_admin && !memberForcus?.is_creator && (
          <p>Tài khoản này không đủ quyền để tự hiện chức năng này</p>
        )}
      </Dialog>
    </div>
  );
};
