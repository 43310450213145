import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { MdAccountCircle } from "react-icons/md";
import { MdOutlinePassword } from "react-icons/md";
import { Calendar } from "primereact/calendar";
import { FaCalendarAlt } from "react-icons/fa";
import { Button } from "primereact/button";
import moment from "moment-timezone";
import axios from "axios";
import { Headers } from "../../../const/headers";
import { API_URL, API_ROUTES } from "../../../api";
import { useUser } from "../../../contexts/UserContext";
import { Password } from "primereact/password";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { useHeaders } from "../../../const/headers";
import { MultiSelect } from "primereact/multiselect";
import { MdSupervisorAccount } from "react-icons/md";
import { FaPager } from "react-icons/fa";
import "./ManageEmployee.css";
export const ManageEmployee = ({
  setVisible,
  visible,
  setRefresh,
  refresh,
  listEmpl,
}) => {
  const headers = useHeaders();
  const currentDate = moment().tz("Asia/Ho_Chi_Minh").toDate();
  const { userInfo, setUserInfo, dataFb } = useUser();
  const [date, setDate] = useState(null);
  const createEmployee = API_URL + API_ROUTES.CREATE_EMPLOYEE;
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [fullName, setFullName] = useState("");
  const [numberAccount, setNumberAccount] = useState(0);
  const [selectedCities, setSelectedCities] = useState(null);
  const { t } = useTranslation();
  const flattenedArray = listEmpl
    ?.map((item) => item.pages)
    .flatMap((item) => item);
  const filteredData = dataFb?.filter(
    (item) => !flattenedArray?.map((item) => item.id).includes(item.id)
  );
  const cities = filteredData;
  const onChangePass = (e) => {
    setPassword(e.target.value);
  };
  const onChangeUser = (e) => {
    const value = e.target.value;
    if (/\s/.test(value)) {
      setError("Tên đăng nhập không được chứa khoảng trắng.");
    } else if (/[^a-zA-Z0-9]/.test(value)) {
      setError("Tên đăng nhập không được chứa dấu và các ký tự đặc biệt.");
    } else {
      setError("");
      setUsername(value);
    }
  };

  const onChangeName = (e) => {
    setFullName(e.target.value);
  };

  const styles = {
    mb0ml2: {
      width: "205px",
      justifyContent: "flex-start",
    },
  };
  const addEmployee = async () => {
    if (listEmpl?.filter((item) => item.username === username).length > 0) {
      alert("Tên đăng nhập này đã tồn tại vui vòng nhập tên mới");
    } else if (/\s/.test(username)) {
      alert("Tên đăng nhập không được chưa khoảng trắng");
    } else if (!username || !password || !fullName || !numberAccount) {
      alert("Vui lòng điền đầy đủ thông tin");
    } else {
      const body = {
        username: username,
        password: password,
        fullname: fullName,
        account_limit: numberAccount,
      };
      setRefresh(!refresh);
      setVisible(false);
      try {
        const response = await axios.post(createEmployee, body, headers);
      } catch (err) { }
    }
  };
  return (
    <div className="card flex  justify-content-center">
      <Dialog
        header="
        "
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p style={{ color: "red" }}>Chức năng này cần nâng cấp có phí...</p>
        </div>
      </Dialog>
      {userInfo?.employee_limit === 0 ? (
        <Dialog
          header="Thông tin tài khoản"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => setVisible(false)}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p>
              Vui lòng nâng cấp lên tài khoản quản lý để có thể sử dụng tính
              năng tạo nhân viên
            </p>
            <Button label="" style={{ width: "max-content" }}>
              <a
                className="text-dark"
                href="https://www.facebook.com/chotnhanh.vn"
                target="blank"
              >
                Nâng cấp ngay
              </a>
            </Button>
          </div>
        </Dialog>
      ) : (
        <Dialog
          header="Thông tin tài khoản"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => setVisible(false)}
        >
          <div className="card flex flex-column  gap-3" style={{ border: "0" }}>
            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon" style={styles.mb0ml2}>
                <MdAccountCircle />
                <p className="mb-0 ml-2">Tên đăng nhập</p>
              </span>
              <InputText
                onChange={(e) => onChangeUser(e)}
                placeholder="Tên đăng nhập"
              />
            </div>
            {error && (
              <p style={{ color: "red", marginBottom: "0" }}>{error}</p>
            )}
            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon" style={styles.mb0ml2}>
                <MdOutlinePassword />
                <p className="mb-0 ml-2">Mật khẩu</p>
              </span>
              <Password
                style={{ fontSize: "16px", fontFamily: "sans-serif" }}
                toggleMask
                feedback={t("")}
                promptLabel={t("")}
                onChange={(e) => onChangePass(e)}
                placeholder="Mật khẩu đăng nhập"
              />
            </div>
            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon" style={styles.mb0ml2}>
                <MdDriveFileRenameOutline />
                <p className="mb-0 ml-2">Họ Tên nhân viên</p>
              </span>
              <InputText
                onChange={(e) => onChangeName(e)}
                placeholder="Họ Tên nhân viên"
              />
            </div>

            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon" style={styles.mb0ml2}>
                <MdSupervisorAccount />
                <p className="mb-0 ml-2">Cấp tài khoản</p>
              </span>
              <InputNumber
                className="p-inputgroup"
                value={numberAccount}
                defaultValue={1}
                onChange={(e) => setNumberAccount(e.value)}
                placeholder="Số lượng tài khoản được sử dụng"
              />
            </div>
            {/* <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon" style={styles.mb0ml2}>
                <FaPager />
                <p className="mb-0 ml-2">Chọn Page</p>
              </span>
              <MultiSelect
                value={selectedCities}
                onChange={(e) => setSelectedCities(e.value)}
                options={cities}
                optionLabel="name"
                display="chip"
                placeholder="Chọn Page"
                maxSelectedLabels={3}
                className="w-full md:w-20rem"
              />
            </div> */}
          </div>
          <div className="d-flex justify-content-end gap-2 mt-2">
            <Button
              className=" button-blue radius"
              label="Tạo"
              onClick={() => addEmployee()}
            />
            <Button
              className=" button-red radius"
              label="Hủy"
              onClick={() => setVisible(false)}
              severity="danger"
            />
          </div>
        </Dialog>
      )}
    </div>
  );
};
