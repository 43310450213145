import React, { useState, useEffect, useRef } from "react";
import Navigate from "../../container/Navigate";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Footer } from "../Footer";
import { getAxios } from "../../../const/getAxios";
import { API_URL, API_ROUTES_ZALO, WS_URL, API_ROUTES } from "../../../api";
import { ListAccZalo } from "../../container/zalomess/ListAccZalo";
import { ListFriendZalo } from "../../container/zalomess/ListFriendZalo";
import { useLocation } from "react-router-dom";
import { ViewMessenger } from "../../../components/container/zalomess/ViewMessenger";
import { CreateOrderZalo } from "../../../components/container/zalomess/CreateOrderZalo";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useUser } from "../../../contexts/UserContext";
import axios from "axios";
import validator from "validator";
import { useHeaders } from "../../../const/headers";
import bellSound from "../../audio/messting.mp3";
import { SendMessNewFriend } from "../../../components/container/zalomess/SendMessNewFriend";
import ModalShareMess from "../../../components/container/zalomess/ModalShareMess";
import { updateTitle } from "../../../const/updateTitle";
import { ModalAutoInbox } from "../../../components/container/labelModal/ModalAutoInbox";
import { ModalForcusAvatar } from "../../../components/container/zalomess/ModalForcusAvatar";
import { SettingGroups } from "../../../components/container/zalomess/setting/SettingGroups";
export const MessZaloId = () => {
  const apiGetAccout = API_URL + API_ROUTES_ZALO.ALL_ACCOUNT_MESS;
  const getLinkAttack = API_URL + API_ROUTES.GET_LINK_FILE;
  const socketRef = useRef(null);
  const access = localStorage.getItem("access");
  const location = useLocation();
  const headers = useHeaders();
  const inputImageRef = useRef();
  const idLocation = location?.pathname.split("/")[2];
  const [allAccount, setAllAccount] = useState([]);
  const [account, setAccout] = useState([]);
  const [messengerText, setMessengerText] = useState("");
  const [filterLabel, setFilterLabel] = useState([]);
  const [conversation, setConversation] = useState({
    next: "",
    results: [],
  });
  const [typeQuote, setTypeQuote] = useState([]);
  const [widtNavigate, setWidthNavigate] = useState("");
  const [hiddenOrder, setHiddenOrder] = useState(false);
  const [newConver, setNewConver] = useState([]);
  const [idFriend, setIdFriend] = useState("");
  const { userInfo } = useUser();
  const [resetScroll, setResetScroll] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [listLinkFile, setListLinkFile] = useState([]);
  const idFriendRef = useRef(idFriend);
  const [itemShare, setItemShare] = useState([]);
  const [listConver, setListConver] = useState([]);
  const [textShare, setTextShare] = useState("");
  const [rfFriend, setRfFiend] = useState(false);
  const [textStranger, setTextStranger] = useState("");
  const [phoneStranger, setPhoneStranger] = useState("");
  const [imageStranger, setImageStranger] = useState(null);
  const [itemTitle, setItemTitle] = useState([]);
  const [modalSendStranger, setModalSendStranger] = useState();
  const [addLabel, setAddLabel] = useState(false);
  const [categoryClassify, setCategoryClassify] = useState([]);
  const [listFrLabel, setListFrLabel] = useState([]);
  const [rfClassify, setRfClassify] = useState(false);
  const [itemFilter, setItemFilter] = useState([]);
  const [isAutoInbox, setIsAutoInbox] = useState(false);
  const [messCard, setMessCard] = useState([]);
  const [forcusAvt, setForcusAvt] = useState(false);
  const [itemForcusAvt, setItemForcusAvt] = useState([]);
  const [isSetting, setIsSetting] = useState(false);
  const [rfGetMember, setRfGetMember] = useState(false);
  const [reloadConver, setReloadConver] = useState(false);
  const [memberGr, setMemberGr] = useState([]);
  const [rfChangeGr, setRfChangeGr] = useState(false);
  const [messenger, setMessenger] = useState({
    next: "",
    results: [],
  });
  useEffect(() => {
    idFriendRef.current = idFriend;
  }, [idFriend]);
  useEffect(() => {
    setIdFriend(location?.pathname.split("/")[3]);
  }, [location]);
  useEffect(() => {
    getAxios(apiGetAccout, setAllAccount);
  }, [reloadConver]);
  useEffect(() => {
    updateTitle("Bạn có tin nhắn mới");
    const timer = setTimeout(() => {
      updateTitle("Chốt Care - Quản Lý Tin Nhắn");
    }, 3000);

    // Cleanup function để xóa bộ hẹn giờ khi component unmount hoặc itemTitle thay đổi
    return () => clearTimeout(timer);
  }, [itemTitle]);

  useEffect(() => {
    const updateWidth = () => {
      const navigateElement = document.getElementById("navigate-contair");
      if (navigateElement) {
        setWidthNavigate(navigateElement.offsetWidth);
      }
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);
  const handleGetLinkFile = async (newFiles) => {
    if (newFiles?.length > 0) {
      for (let i = 0; i < newFiles.length; i++) {
        const formData = new FormData();
        formData.append("file", newFiles[i].file);
        try {
          const res = await axios.post(getLinkAttack, formData, headers);
          const newLink = {
            link: res.data.file,
            name: newFiles[i].name,
          };
          setListLinkFile((pre) => [...pre, newLink]);
        } catch (err) {}
      }
    }
  };

  useEffect(() => {
    if (userInfo?.id) {
      const client = new W3CWebSocket(
        `wss://${WS_URL}/ws/chat/${userInfo?.id}/?token=${access}`
      );
      socketRef.current = client;
      client.onerror = function () {};
      client.onopen = function () {};
      client.onmessage = function (message) {
        const parsedData = JSON.parse(message.data);
        if (parsedData.message !== undefined) {
          setNewConver(parsedData.message);
          setConversation((pre) => {
            const filteredResults = pre.results.filter(
              (item) => item.id !== parsedData.message.id
            );
            return {
              next: pre.next,
              results: [parsedData.message, ...filteredResults],
            };
          });
        }
        if (parsedData.message_details !== undefined) {
          setResetScroll(Date.now());
          updateTitle("Bạn có tin nhắn mới từ");

          if (parsedData.message_details.uidFrom !== "0") {
            setItemTitle(parsedData.message_details);
            const audio = new Audio(bellSound);
            audio
              .play()
              .then(() => {})
              .catch((error) => {});
          }
          if (
            Number(parsedData.message_details?.message_model) ===
            Number(idFriendRef.current)
          ) {
            setMessenger((pre) => ({
              results: [...pre?.results, parsedData.message_details],
            }));
          }
        }
        if (parsedData.account !== undefined) {
          setAllAccount((pre) => {
            const filteredResults = pre.filter(
              (item) => item.id !== parsedData.account.id
            );
            return [parsedData.account, ...filteredResults];
          });
        }
      };
      return () => {
        if (socketRef.current) {
          socketRef.current.close();
        }
      };
    }
  }, [userInfo]);

  const handleSendMessenger = (item) => {
    const bodySendTextStranger = {
      id_conversation: Number(idFriend),
      id_account: Number(idLocation),
      message: textStranger,
      attachment: null,
      chat_type: "send-message-phone",
      phone_number: phoneStranger,
      message_details: null,
    };
    const bodySendImageStranger = {
      id_conversation: Number(idFriend),
      id_account: Number(idLocation),
      message: "",
      attachment: imageStranger,
      chat_type: "send-message-phone",
      phone_number: phoneStranger,
      message_details: null,
    };
    const bodyTextMess = typeQuote?.id
      ? {
          id_conversation: Number(idFriend),
          message: messengerText,
          attachment: null,
          chat_type: "quote",
          phone_number: null,
          message_details: {
            uidFrom:
              typeQuote.uidFrom === "0"
                ? allAccount?.filter((item) => item.id == idLocation)[0].uid
                : typeQuote.uidFrom,
            qmsgId: typeQuote.msgId,
            cliMsgId: typeQuote.cliMsgId,
            ts: typeQuote.ts,
            text_message: typeQuote?.text_message[0]?.text || "",
          },
        }
      : {
          id_conversation: Number(idFriend),
          message: messengerText,
          attachment: null,
          chat_type: "send-message",
          message_details: null,
          phone_number: null,
        };
    if (itemShare?.id) {
      for (let i = 0; i < listConver?.length; i++) {
        socketRef.current.send(
          JSON.stringify(
            itemShare?.text_message.length > 0
              ? {
                  id_conversation: listConver[i]?.id,
                  message: itemShare?.text_message[0]?.text,
                  attachment: null,
                  chat_type: "send-message",
                  message_details: null,
                  phone_number: null,
                }
              : itemShare?.attachments[0]?.action === "recommened.link"
              ? {
                  id_conversation: listConver[i]?.id,
                  message: "",
                  chat_type: "share-link",
                  share_info: {
                    linkTitle: itemShare?.attachments[0]?.href,
                    src: JSON.parse(itemShare?.attachments[0]?.params).src,
                    link: itemShare?.attachments[0]?.href,
                    description: itemShare?.attachments[0]?.description,
                    thumb: itemShare?.attachments[0]?.thumb,
                    mediaTitle: JSON.parse(itemShare?.attachments[0]?.params)
                      .mediaTitle,
                  },
                }
              : itemShare?.msgType === "chat.photo"
              ? {
                  id_conversation: listConver[i]?.id,
                  message: "",
                  attachment: null,
                  chat_type: "share-photo",
                  message_details: {
                    uidFrom:
                      itemShare.uidFrom === "0"
                        ? allAccount?.filter((item) => item.id == idLocation)[0]
                            .uid
                        : itemShare.uidFrom,
                    qmsgId: itemShare.msgId,
                    cliMsgId: itemShare.cliMsgId,
                    ts: itemShare.ts,
                    text_message: "",
                  },
                  share_info: {
                    oriUrl: itemShare?.attachments[0]?.href,
                    thumb: itemShare?.attachments[0]?.href,
                    width: JSON.parse(itemShare?.attachments[0]?.params).width,
                    height: JSON.parse(itemShare?.attachments[0]?.params)
                      .height,
                  },
                  phone_number: null,
                }
              : itemShare?.msgType === "share.file"
              ? {
                  id_conversation: listConver[i]?.id,
                  message: "",
                  chat_type: "share-file",
                  share_info: {
                    url: itemShare?.attachments[0]?.href,
                    fileName: itemShare?.attachments[0]?.title,
                    extension: JSON.parse(itemShare?.attachments[0]?.params)
                      .fileExt,
                    checksum: JSON.parse(itemShare?.attachments[0]?.params)
                      .checksum,
                    size: JSON.parse(itemShare?.attachments[0]?.params)
                      .fileSize,
                    fType: `${
                      JSON.parse(itemShare?.attachments[0]?.params).fType
                    }`,
                  },
                }
              : {}
          )
        );
        if (textShare) {
          socketRef.current.send(
            JSON.stringify({
              id_conversation: listConver[i]?.id,
              message: textShare,
              attachment: null,
              chat_type: "send-message",
              message_details: null,
              phone_number: null,
            })
          );
        }
      }
      setItemShare([]);
      setListConver([]);
      setOpenShare(false);
    }
    if (messengerText) {
      socketRef.current.send(JSON.stringify(bodyTextMess));
      setMessengerText("");
      setTypeQuote([]);
    }
    if (listLinkFile?.length > 0) {
      for (let i = 0; i < listLinkFile.length; i++) {
        socketRef.current.send(
          JSON.stringify(
            typeQuote?.id
              ? {
                  id_conversation: Number(idFriend),
                  message: "",
                  attachment: listLinkFile[i].link,
                  chat_type: "quote",
                  phone_number: null,
                  message_details: null,
                  message_details: {
                    uidFrom:
                      typeQuote.uidFrom === "0"
                        ? allAccount?.filter((item) => item.id == idLocation)[0]
                            .uid
                        : typeQuote.uidFrom,
                    qmsgId: typeQuote.msgId,
                    cliMsgId: typeQuote.cliMsgId,
                    ts: typeQuote.ts,
                    text_message: typeQuote?.text_message[0]?.text || "",
                  },
                }
              : {
                  id_conversation: Number(idFriend),
                  message: "",
                  attachment: listLinkFile[i].link,
                  chat_type: "send-message",
                  phone_number: null,
                  message_details: null,
                }
          )
        );
        setListLinkFile([]);
        setTypeQuote([]);
      }
    }

    if (item?.new_message) {
      socketRef.current.send(
        JSON.stringify({
          id_conversation: item.id,
          chat_type: "reset-unread-count",
        })
      );
    }
    if (phoneStranger) {
      if (!validator.isMobilePhone(phoneStranger, "vi-VN")) {
        alert("Số điện thoại không hợp lệ");
        return;
      }
      if (textStranger) {
        socketRef.current.send(JSON.stringify(bodySendTextStranger));
      }
      if (imageStranger) {
        socketRef.current.send(JSON.stringify(bodySendImageStranger));
      }
      setTextStranger("");
      setPhoneStranger("");
      setImageStranger(null);
      setModalSendStranger(false);
    }
    if (messCard?.id) {
      const bodyCard = {
        id_account: Number(idLocation),
        message: textStranger,
        chat_type: "send-message-uid",
        uid: messCard?.attachments[0]?.params,
      };
      socketRef.current.send(JSON.stringify(bodyCard));
      setTextStranger("");
      setMessCard([]);
      setModalSendStranger(false);
    }
    if (itemForcusAvt?.uidFrom) {
      const bodyForcus = {
        id_account: Number(idLocation),
        message: textStranger,
        chat_type: "send-message-uid",
        uid: itemForcusAvt?.uidFrom,
      };
      socketRef.current.send(JSON.stringify(bodyForcus));
      setTextStranger("");
      setItemForcusAvt([]);
      setForcusAvt(false);
    }
  };
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div id="navigate-contair" className="navigate-container">
          <Navigate />
        </div>
        <div
          className="d-flex flex-column h-100 mgtop-respon-47"
          style={{ width: `calc(100vw - ${widtNavigate}px)` }}
        >
          <div style={{ height: "auto" }}>
            <ListAccZalo
              reloadConver={reloadConver}
              setReloadConver={setReloadConver}
              toast={toast}
              filterLabel={filterLabel}
              rfClassify={rfClassify}
              setRfClassify={setRfClassify}
              categoryClassify={categoryClassify}
              setCategoryClassify={setCategoryClassify}
              setModalSendStranger={setModalSendStranger}
              setRfFiend={setRfFiend}
              rfFriend={rfFriend}
              allAccount={allAccount}
              account={account}
              setAccout={setAccout}
              setConversation={setConversation}
              conversation={conversation}
              setIsAutoInbox={setIsAutoInbox}
              isAutoInbox={isAutoInbox}
            />
          </div>
          {idLocation && (
            <div
              className=" w-100 d-flex height-view-chat"
              style={{ overflowX: "hidden", overflowY: "auto" }}
            >
              <ListFriendZalo
                rfChangeGr={rfChangeGr}
                setFilterLabel={setFilterLabel}
                itemFilter={itemFilter}
                setItemFilter={setItemFilter}
                listFrLabel={listFrLabel}
                setListFrLabel={setListFrLabel}
                addLabel={addLabel}
                setAddLabel={setAddLabel}
                rfFriend={rfFriend}
                resetScroll={resetScroll}
                setResetScroll={setResetScroll}
                conversation={conversation}
                setConversation={setConversation}
                account={account}
                API_URL={API_URL}
                API_ROUTES_ZALO={API_ROUTES_ZALO}
                idLocation={idLocation}
                idFriend={idFriend}
                setMessengerText={setMessengerText}
                setListLinkFile={setListLinkFile}
                setTypeQuote={setTypeQuote}
                handleSendMessenger={handleSendMessenger}
              />
              {idFriend && (
                <ViewMessenger
                  memberGr={memberGr}
                  setMemberGr={setMemberGr}
                  rfGetMember={rfGetMember}
                  setIsSetting={setIsSetting}
                  setForcusAvt={setForcusAvt}
                  setItemForcusAvt={setItemForcusAvt}
                  setModalSendStranger={setModalSendStranger}
                  setMessCard={setMessCard}
                  rfClassify={rfClassify}
                  setRfClassify={setRfClassify}
                  rfFriend={rfFriend}
                  setRfFiend={setRfFiend}
                  toast={toast}
                  setListFrLabel={setListFrLabel}
                  listFrLabel={listFrLabel}
                  categoryClassify={categoryClassify}
                  addLabel={addLabel}
                  setAddLabel={setAddLabel}
                  headers={headers}
                  getLinkAttack={getLinkAttack}
                  setItemShare={setItemShare}
                  setOpenShare={setOpenShare}
                  typeQuote={typeQuote}
                  setTypeQuote={setTypeQuote}
                  handleGetLinkFile={handleGetLinkFile}
                  listLinkFile={listLinkFile}
                  setListLinkFile={setListLinkFile}
                  inputImageRef={inputImageRef}
                  handleSendMessenger={handleSendMessenger}
                  setMessengerText={setMessengerText}
                  messengerText={messengerText}
                  resetScroll={resetScroll}
                  messenger={messenger}
                  setMessenger={setMessenger}
                  newConver={newConver}
                  hiddenOrder={hiddenOrder}
                  setHiddenOrder={setHiddenOrder}
                  idLocation={idLocation}
                  allAccount={allAccount}
                  conversation={conversation}
                  idFriend={idFriend}
                />
              )}
            </div>
          )}
        </div>
        {hiddenOrder && idFriend && (
          <div className="col-xl-2 bg-dark overflow-auto open-order-mobie">
            <CreateOrderZalo userInfo={userInfo} />
          </div>
        )}
        <SendMessNewFriend
          messCard={messCard}
          handleSendMessenger={handleSendMessenger}
          API_URL={API_URL}
          headers={headers}
          getLinkAttack={getLinkAttack}
          textStranger={textStranger}
          setTextStranger={setTextStranger}
          phoneStranger={phoneStranger}
          setPhoneStranger={setPhoneStranger}
          modalSendStranger={modalSendStranger}
          setModalSendStranger={setModalSendStranger}
          imageStranger={imageStranger}
          setImageStranger={setImageStranger}
        />
        <ModalShareMess
          textShare={textShare}
          setTextShare={setTextShare}
          handleSendMessenger={handleSendMessenger}
          listConver={listConver}
          setListConver={setListConver}
          itemShare={itemShare}
          openShare={openShare}
          setOpenShare={setOpenShare}
          idLocation={idLocation}
          idFriend={idFriend}
        />
        <ModalForcusAvatar
          setTextStranger={setTextStranger}
          textStranger={textStranger}
          handleSendMessenger={handleSendMessenger}
          itemForcusAvt={itemForcusAvt}
          forcusAvt={forcusAvt}
          setForcusAvt={setForcusAvt}
        />
        <ModalAutoInbox
          toast={toast}
          getPageAtib={idLocation}
          setIsAutoInbox={setIsAutoInbox}
          isAutoInbox={isAutoInbox}
        />
        <SettingGroups
          rfChangeGr={rfChangeGr}
          setRfChangeGr={setRfChangeGr}
          memberGr={memberGr}
          toast={toast}
          idAcc={idLocation}
          rfGetMember={rfGetMember}
          setRfGetMember={setRfGetMember}
          isSetting={isSetting}
          setIsSetting={setIsSetting}
        />
      </div>
      <Footer />
    </div>
  );
};
