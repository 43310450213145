import React, { useEffect } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { API_URL, API_ROUTES } from "../../../api";
import { TiDelete } from "react-icons/ti";
import { useLocation } from "react-router-dom";
export default function ViewLabelInFr({
  forcusLabel,
  setForcusLabel,
  headers,
  itemAccount,
  getDataAllAddFr,
  allFriend,
}) {
  const location = useLocation();
  const apiRemove = API_URL + API_ROUTES.REMOVE_LABEL_CONVERSION;
  const handleRemove = async (item) => {
    const body = location.pathname.includes("client")
      ? {
          id_category: item.id,
          id_friends: [forcusLabel?.id],
        }
      : {
          id_category: item.id,
          id_groups: [forcusLabel?.id],
        };
    try {
      await axios.post(apiRemove, body, headers);
      getDataAllAddFr(itemAccount);
    } catch (err) {}
  };
  useEffect(() => {
    setForcusLabel(allFriend?.filter((item) => item.id === forcusLabel.id));
  }, [allFriend]);
  return (
    <Dialog
      header={`Danh sách nhãn được gán cho ${forcusLabel?.name}`}
      visible={forcusLabel?.id}
      style={{ width: "max-content" }}
      onHide={() => {
        setForcusLabel([]);
      }}
    >
      {forcusLabel?.labels?.length === 0 ? (
        <p>Chưa có nhãn nào được gán</p>
      ) : (
        forcusLabel?.category_messages?.map((item, index) => (
          <p
            className="m-0 mt-2 text-light p-2 border mb-2 border-2 rounded gap-2 position-relative"
            style={{ width: "max-content", background: item.color }}
          >
            {item?.name}
            <TiDelete
              cursor="pointer"
              onClick={() => handleRemove(item)}
              size={25}
              color="red"
              style={{ position: "absolute", top: "-10px", right: "-10px" }}
            />
          </p>
        ))
      )}
    </Dialog>
  );
}
