import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../api";
export const ListCategoryShop = ({
  idAccountInloaction,
  allCateShop,
  cover,
  setAllCateShop,
  getAllCateShop,
  getNoheaders,
}) => {
  useEffect(() => {
    getNoheaders(
      `${getAllCateShop}?id_employee=${idAccountInloaction}`,
      setAllCateShop
    );
  }, []);
  const navigate = useNavigate();
  return (
    <div className="featured-categories sectionshop h-100">
      <div className="container d-flex flex-column justify-content-center h-100">
        <div className="row">
          <div className="col-12">
            <div className="shop-title" style={{ display: "contents" }}>
              {/* <img
                src={`${API_URL}${cover.image}`}
                style={{ height: "200px" }}
              /> */}
              <h2 className="text-light">{cover.name}</h2>
            </div>
          </div>
        </div>
        <div className="row overflow-auto">
          {allCateShop
            ?.filter((item) => item.status === 1)
            ?.map((item, index) => (
              <div
                className="col-lg-4 col-md-6 cursor-pointer mb-2"
                onClick={() =>
                  navigate(`/shoplinkhome/${idAccountInloaction}/${item.id}`)
                }
              >
                <div
                  className="single-category d-flex h-100 align-items-center justify-content-between gap-2"
                  style={{ height: "106px" }}
                >
                  <div className="heading">
                    {item.name}
                    <p>Số lượng sản phẩm: {item.product_count}</p>
                  </div>
                  {item.avt && (
                    <img
                      style={{ width: "50px", height: "50px" }}
                      src={`${API_URL}/${item.avt}`}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};