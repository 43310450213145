import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
  rowClassName,
  onRowClick,
} from "../../../../const/checkboxPrimeReactjs";
import { API_URL, API_ROUTES_NTD, API_ROUTES_ZALO } from "../../../../api";
import { useHeaders } from "../../../../const/headers";
import axios from "axios";
import { statusRunTools } from "../../../../const/updateTitle";
import { EditScriptMessGr } from "./EditScriptMessGr";
import { set } from "lodash";
import { getAxios } from "../../../../const/getAxios";
import { useTranslation } from "react-i18next";
export const ListScriptMessToGroup = ({
  allCate,
  setRefresh,
  refresh,
  setListMktCate,
  listMktCate,
  handleCopyCate,
  setOpenManager,
  setItemCate,
  setAllCate,
}) => {
  const deleteCate = API_URL + API_ROUTES_ZALO.MESSAGE_TO_GROUP.DELETE_CATEGORY;
  const headers = useHeaders();
  const [editCate, setEditCate] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const getDataAllFr = () => { };
  const { t } = useTranslation();
  const handleDeleteCate = async (item) => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá !");
    if (confirm) {
      const body = { id_category: item.id };
      try {
        const res = await axios.post(deleteCate, body, headers);
        setRefresh(!refresh);
      } catch (err) { }
    }
  };
  const optionAcc = (rowData) => {
    return (
      <div className="d-flex gap-2 btnActArticle">
        <Button
          className="button-blue radius btnArticle"
          onClick={() => {
            setOpenManager(true);
            setItemCate(rowData);
          }}
          style={{ padding: "6px 8px" }}
        >
          {t("Quản lý kịch bản")}
        </Button>
        <Button
          className="button-blue radius btnArticle"
          onClick={() => {
            handleCopyCate(rowData);
          }}
          style={{ padding: "6px 8px" }}
        >
          {t("Sao chép kịch bản")}
        </Button>
        <Button
          className="button-blue radius btnArticle"
          onClick={() => {
            setEditCate(rowData);
            setModalEdit(true);
          }}
          style={{ padding: "6px 8px" }}
        >
          {t("Chi tiết")}
        </Button>
        <Button
          className="button-red radius btnArticle"
          severity="danger"
          onClick={() => handleDeleteCate(rowData)}
          style={{ padding: "6px 8px" }}
        >
          {t("Xoá")}
        </Button>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  const listAccount = (rowData) => {
    return (
      <div className="w-100 d-flex flex-wrap gap-2 align-items-center">
        <p>
          {rowData?.group?.length === 0
            ? "Chưa có nhóm nào được gán"
            : `Có ${rowData?.group?.length} nhóm được gán`}
        </p>
      </div>
    );
  };
  return (
    <div className=" h-100 " style={{ border: "1px solid", width: "100%" }}>
      <DataTable
        size="small"
        className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ width: "100%" }}
        value={allCate?.results || []}
        rowClassName={(rowData) => rowClassName(rowData, listMktCate)}
        onRowClick={(e) => onRowClick(e.data, setListMktCate, listMktCate)}
      >
        <Column
          header={headerCheckbox(
            listMktCate,
            allCate?.results,
            toggleSelectAll,
            setListMktCate
          )}
          body={(rowData) =>
            bodyCheckbox(rowData, listMktCate, setListMktCate, getDataAllFr)
          }
        />
        <Column body={indexTemplate} field="UID" header={t("STT")}></Column>
        <Column field="name" sortable header={t("Tên Kịch bản")}></Column>
        <Column
          field="name"
          body={(rowData) => listAccount(rowData)}
          header={t("Tài khoản được gán")}
        ></Column>
        <Column
          field=""
          body={(rowData) => statusRunTools(rowData, t)}
          sortable
          header={t("Trạng thái")}
        ></Column>
        <Column
          body={(rowData) => optionAcc(rowData)}
          header={t("Tuỳ chọn")}
          style={{ width: "400px" }}
          headerClassName="options-category"
        ></Column>
      </DataTable>
      <EditScriptMessGr
        setRefresh={setRefresh}
        refresh={refresh}
        editCate={editCate}
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
      />
    </div>
  );
};
