import React, { useState, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES } from "../../../api";
import { Button } from "primereact/button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "../../../contexts/UserContext";
import { useTranslation } from "react-i18next";
export default function BasicDemoAlert() {
  const { setRfAlert, rfAlert, alerts, setVisibleTb } = useUser();
  const headers = useHeaders();
  const create = API_URL + API_ROUTES.CREATE_ALERT;
  const upload = API_URL + API_ROUTES.UPLOAD_FILE_SERVER;
  const [value, setValue] = useState(alerts?.content || "");
  const [value1, setValue1] = useState(alerts?.link || "");
  const [fileImg, setFileImg] = useState(null);
  const [imageLink, setImageLink] = useState(null);
  const { t } = useTranslation();
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileImg(file);
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataURL = reader.result;
      const imgElement = document.getElementById("previewImage");
      if (imgElement) {
        imgElement.src = imageDataURL;
      }
    };
    reader.readAsDataURL(file);
  };
  const upLoadSever = async () => {
    if (fileImg !== null) {
      const formData = new FormData();
      formData.append("image", fileImg);
      try {
        const res = await axios.post(upload, formData, headers);
        setImageLink(res.data.image);
      } catch (err) { }
    }
  };
  useEffect(() => {
    upLoadSever();
  }, [fileImg]);
  const createAlert = async () => {
    const body = {
      active: true,
      content: value,
      link: value1,
      image: imageLink || alerts?.image,
    };
    try {
      await axios.post(create, body, headers);
      toast.success("Thành công");
      setRfAlert(!rfAlert);
    } catch (err) {
      toast.error("Lỗi");
    }
  };
  const deleteImg = async () => {
    const confirm = window.confirm("Xoá ảnh này");
    if (confirm) {
      const body = {
        active: true,
        content: alerts.content,
        link: alerts.link,
        image: "",
      };
      try {
        await axios.post(create, body, headers);
        toast.success("Thành công");
        setImageLink(null);
        setRfAlert(!rfAlert);
      } catch (err) {
        toast.error("Lỗi");
      }
    }
  };
  const activeAlerts = async () => {
    const body = {
      active: alerts?.active === true ? false : true,
      content: alerts?.content,
      link: alerts?.link,
      image: alerts?.image,
    };
    try {
      await axios.post(create, body, headers);
      toast.success("Thành công");
      setRfAlert(!rfAlert);
    } catch (err) {
      toast.error("Lỗi");
    }
  };
  useEffect(() => {
    setValue1(alerts?.link);
    setValue(alerts?.content);
  }, [alerts]);
  return (
    <div className="flex-column flex justify-content-center">
      <ToastContainer />
      <p>{t("Nội dung")}</p>
      <InputTextarea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        rows={5}
        cols={30}
      />
      <p>Link</p>
      <InputText value={value1} onChange={(e) => setValue1(e.target.value)} />
      <label htmlFor="fileInput" style={{ width: "max-content" }}>
        <button
          className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
          onClick={() => document.getElementById("fileInput").click()}
        >
          <p className="mb-0">{t("Chọn ảnh")}</p>
        </button>
      </label>
      <input
        id="fileInput"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => handleImageChange(e)}
      />
      {imageLink === null && alerts.image === "" ? (
        <div></div>
      ) : (
        <img
          style={{
            width: "100px",
            display: "block",
          }}
          alt="Selected Image"
          src={`${API_URL}/${imageLink || alerts.image}`}
        />
      )}
      <div className="d-flex gap-2 mt-2">
        <Button
          className="button-blue radius"
          style={{ width: "max-content", padding: "6px 12px" }}
          label={t("Lưu")}
          onClick={() => createAlert()}
        />
        <Button
          className="button-blue radius"
          style={{ width: "max-content", padding: "6px 12px" }}
          label={t("Xem Popup")}
          onClick={() => setVisibleTb(true)}
        />
        <Button
          className="button-blue radius"
          style={{ width: "max-content", padding: "6px 12px" }}
          label={`${alerts?.active === true ? t("Tắt Popup") : t("Bật Popup")}`}
          onClick={() => activeAlerts(true)}
        />
        <Button
          className="button-red radius"
          style={{
            width: "max-content",
            padding: "6px 12px",
          }}
          label={t("Xoá ảnh")}
          onClick={() => deleteImg()}
        />
      </div>
    </div>
  );
}
