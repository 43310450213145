import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useLocation } from "react-router-dom";
import { PickList } from "primereact/picklist";
import { useHeaders } from "../../../const/headers";
import { API_ROUTES, API_URL, API_ROUTES_NTD } from "../../../api";
import { TiTick } from "react-icons/ti";
import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import { RiExpandDiagonal2Line } from "react-icons/ri";

export const ManagerCateMarketing = ({
  setOpenManager,
  openManager,
  dataFb,
  itemCate,
  allCate,
  toast,
  setRefresh,
  refresh,
}) => {
  const headers = useHeaders();
  const addCateInMkt = API_URL + API_ROUTES_NTD.ADD_CATEGORY_IN_ACCOUNT;
  const deleteCate = API_URL + API_ROUTES_NTD.REMOVE_CATEGORY_IN_ACCOUNT;
  const [checked, setChecked] = useState(false);
  const [listAccount, setListAccount] = useState([]);
  const addCateInAcc = async () => {
    if (listAccount?.length > 0) {
      const body = {
        id_categories: [itemCate.id],
        id_accounts: listAccount?.map((item) => item.id),
      };
      try {
        const res = await axios.post(addCateInMkt, body, headers);
        toast.success("Thêm kịch bản thành công");
        setListAccount([]);
        setRefresh(!refresh);
        setChecked(false);
      } catch (err) {}
    } else {
      alert("Chọn ít nhất 1 kịch bản và 1 tài khoản");
    }
  };
  const handleRemoveChip = async () => {
    const body = {
      id_category: itemCate?.id,
      id_accounts: listAccount?.map((item) => item.id),
    };
    try {
      await axios.post(deleteCate, body, headers);
      setRefresh(!refresh);
      setListAccount([]);
      setChecked(false);
    } catch (err) {}
  };
  useEffect(() => {
    setListAccount([]);
    setChecked(false);
  }, [openManager]);

  const handleItemClick = (item) => {
    const isSelected = listAccount.includes(item);
    if (isSelected) {
      setListAccount(listAccount.filter((i) => i !== item));
    } else {
      setListAccount([...listAccount, item]);
    }
  };
  useEffect(() => {
    checked
      ? setListAccount(dataFb?.filter((item) => item.checkpoint === false))
      : setListAccount([]);
  }, [checked]);
  const itemTemplate = (item) => {
    const isSelected = listAccount.includes(item);
    return (
      <div
        className={`flex flex-wrap p-2 align-items-center gap-3 ${
          isSelected ? "selected-item" : ""
        }`}
        onClick={() => handleItemClick(item)}
        style={{ cursor: "pointer" }}
      >
        <img
          style={{
            width: "36px",
            borderRadius: "50%",
          }}
          src={item.avatar}
          alt={item.name}
        />
        <div className="flex-1 flex gap-2 justify-content-between">
          <span style={{ fontSize: "12px" }}>{item.name}</span>
          {itemCate?.account?.some((i) => i === item.id) && (
            <p style={{ color: "blue", fontSize: "12px" }}>
              Đã được gán
              <TiTick />
            </p>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Gán kịch bản"
        visible={openManager}
        onHide={() => setOpenManager(false)}
        style={{ position: "relative", width: "30vw" }}
        className="overFlowJoinGr"
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        footer={
          <div className="d-flex justify-content-end gap-2 mt-2">
            <Button
              className="button-blue border"
              style={{ borderRadius: "0", fontSize: "12px" }}
              onClick={addCateInAcc}
            >
              Gán kịch bản
            </Button>
            <Button
              onClick={handleRemoveChip}
              style={{ fontSize: "12px" }}
              severity="danger"
              className=" button-red border"
            >
              Bỏ Gán
            </Button>
            <RiExpandDiagonal2Line
              size={20}
              style={{
                position: "absolute",
                right: "0",
                bottom: "0",
              }}
            />
          </div>
        }
      >
        <div className="d-flex flex-column h-100 w-100">
          <div className="d-flex w-100" style={{ height: "60vh" }}>
            <div className="w-100 h-100 d-flex flex-column gap-2">
              <nav className="d-flex gap-2 align-items-center">
                <p style={{ fontWeight: "700" }}>Chọn tài khoản</p>
                <Checkbox
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                ></Checkbox>
                <p style={{ fontSize: "12px" }}>Chọn tất cả</p>
              </nav>
              <div className="card-picklist-style h-100  w-100">
                {dataFb
                  ?.filter((item) => item.checkpoint === false)
                  ?.map((item) => itemTemplate(item))}
              </div>
            </div>
          </div>
          {/* <div className="w-100 text-right">
            <Button
              className="button-blue border"
              style={{ borderRadius: "0" }}
              onClick={addCateInAcc}
            >
              Gán kịch bản
            </Button>
            <Button
              onClick={handleRemoveChip}
              severity="danger"
              className=" button-red border"
            >
              Bỏ Gán
            </Button>
          </div> */}
        </div>
      </Dialog>
    </div>
  );
};
