import { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import axios from "axios";
import { useHeaders } from "../../../../const/headers";
import { useAuth } from "../../../../AuthContext";
import { toast } from "react-toastify";
import {
  API_URL,
  API_ROUTES_NTD,
  WS_URL,
  API_ROUTES_ZALO,
  API_ROUTES,
} from "../../../../api";
import { getAxios, getAxiosResults } from "../../../../const/getAxios";
import { Sidebar } from "primereact/sidebar";
import { ListScriptMessToPhone } from "./ListScriptMessToPhone";
import { ResultsMessToPhone } from "./ResultsMessToPhone";
import { CreateScriptMessPhone } from "./CreateScriptMessPhone";
import { ModalDetailsMessPhone } from "./ModalDetailsMessPhone";
import { ModalCategoriesMessPhone } from "./ModalCategoriesMessPhone";
import { useTranslation } from "react-i18next";
export const ListTableMessageToPhone = ({
  dataFb,
  refresh,
  setRefresh,
  isInitialRender,
  renderKey,
  setAllCate,
  allCate,
  userInfo,
  numberRandom,
}) => {
  const headers = useHeaders();
  const apiGetAllCategories =
    API_URL + API_ROUTES_ZALO.MESS_TO_PHONE.GET_ALL_CATEGORY;
  const startMarketing = API_URL + API_ROUTES_ZALO.MESS_TO_PHONE.START_CATEGORY;
  const stopMarketing = API_URL + API_ROUTES_ZALO.MESS_TO_PHONE.STOP_CATEGORY;
  const deleteResultArticle =
    API_URL + API_ROUTES_ZALO.MESS_TO_PHONE.DELETE_RESULTS;
  const allResults = API_URL + API_ROUTES_ZALO.MESS_TO_PHONE.GET_RESULTS;
  const copyCate = API_URL + API_ROUTES_ZALO.MESS_TO_PHONE.COPY_CATEGORY;
  const [listMktCate, setListMktCate] = useState([]);
  const [wsResults, setWsResults] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [itemResults, setItemResults] = useState([]);
  const [listResults, setListResults] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openManager, setOpenManager] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(100);
  const [allGroup, setAllGroup] = useState([]);
  const [openAddScript, setOpenAddScript] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [itemCate, setItemCate] = useState([]);
  const [listFr, setListFr] = useState([]);
  const [managerScript, setManagerScript] = useState(false);
  const [itemAccout, setItemAccount] = useState([]);
  const { t } = useTranslation();
  const deleteResults = async () => {
    const body = { id_results: listResults?.map((item) => item?.id) };
    try {
      const res = await axios.post(deleteResultArticle, body, headers);
      getAxiosResults(
        allResults,
        setWsResults,
        setGetTotal,
        rows,
        totalPage + 1
      );
      toast.success("Xoá thành công");
      setListResults([]);
    } catch (err) { }
  };
  useEffect(() => {
    getAxios(apiGetAllCategories, setAllCate);
  }, [refresh, numberRandom]);
  useEffect(() => {
    if (allCate.length > 0 && itemCate) {
      const updatedItemCate = allCate?.results.filter(
        (item) => item.id === itemCate.id
      )[0];
      if (updatedItemCate) {
        setItemCate(updatedItemCate);
      }
    }
  }, [allCate]);
  const startMarketingCate = async (type) => {
    if (listMktCate?.length === 0) {
      alert("Chọn ít nhất 1 kịch bản để chạy ");
    } else if (listMktCate?.some((item) => item.account.length === 0)) {
      alert("Có tài khoản chưa được gán kịch bản nào vui lòng kiểm tra lại");
    } else {
      const body = {
        id_categories: listMktCate?.map((item) => item.id),
        type: type,
      };
      try {
        const res = await axios.post(startMarketing, body, headers);
        setRefresh(!refresh);
        toast.success("Chạy chiến dịch thành công");
      } catch (err) {
        toast.error(err.response.data.error);
      }
    }
  };
  const stopMarketingCate = async () => {
    if (listMktCate?.length === 0) {
      alert("Chọn ít nhất 1 kịch bản để dừng ");
    } else {
      const body = {
        id_categories: listMktCate?.map((item) => item.id),
      };
      try {
        const res = await axios.post(stopMarketing, body, headers);
        setRefresh(!refresh);
        toast.success("Dừng chiến dịch thành công");
      } catch (err) {
        toast.error("Dừng chiến dịch thất bại");
      }
    }
  };
  const handleCopyCate = async (item) => {
    if (!item.id) {
      alert("Chọn 1 kịch bản để thực hiện hành động này");
      return;
    }
    const userName = prompt("Nhập tên kịch bản mới");
    if (userName === null) {
      return;
    }
    if (userName.trim() === "") {
      alert("Tên kịch bản không được để trống");
      return;
    }
    const body = { id_category: item.id, name: userName };
    try {
      await axios.post(copyCate, body, headers);
      setRefresh(!refresh);
      setListMktCate([]);
    } catch (err) { }
  };

  return (
    <div className="d-flex  h-100 w-100">
      <div
        className="overflow-auto h-100 d-flex flex-column"
        style={{
          width: "100%",
        }}
      >
        <div
          className="d-flex gap-2 w-100 justify-content-end pt-2 pl-2 pr-2"
          id="lisstSeclect"
        >
          <Button
            onClick={() => setOpenAddScript(true)}
            style={{
              width: "200px",
              fontSize: "12px",
            }}
            className="d-flex justify-content-center button-blue radius btnActArticle"
          >
            {t("Thêm kịch bản")}
          </Button>
          <Button
            onClick={() => startMarketingCate("new")}
            style={{
              width: "200px",
              fontSize: "12px",
            }}
            className="d-flex justify-content-center button-blue radius btnActArticle"
          >
            {t("Chạy")}
          </Button>
          <Button
            onClick={() => setOpenSidebar(true)}
            style={{
              width: "200px",
              fontSize: "12px",
            }}
            className="d-flex justify-content-center button-blue radius btnActArticle"
          >
            {t("Kết quả")}
          </Button>
          <Button
            severity="danger"
            style={{
              width: "200px",
              fontSize: "12px",
            }}
            className="d-flex justify-content-center button-red radius btnActArticle"
            onClick={stopMarketingCate}
          >
            {t("Dừng")}
          </Button>
        </div>
        <div
          className="d-flex flex-column  gap-2 h-100 m-2"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex w gap-2" style={{ height: "100%" }}>
            <ListScriptMessToPhone
              managerScript={managerScript}
              setManagerScript={setManagerScript}
              setItemCate={setItemCate}
              openManager={openManager}
              setOpenManager={setOpenManager}
              handleCopyCate={handleCopyCate}
              listMktCate={listMktCate}
              setListMktCate={setListMktCate}
              allCate={allCate}
              setAllCate={setAllCate}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </div>
          <Sidebar
            visible={openSidebar}
            onHide={() => setOpenSidebar(null)}
            position="bottom"
            className="sidebar-market"
          >
            <div
              className="d-flex flex-column w-100"
              style={{ height: "100%" }}
            >
              <Button
                severity="danger"
                style={{
                  width: "200px",
                  display: listResults.length === 0 ? "none" : "block",
                  fontSize: "12px",
                }}
                className="justify-content-center button-red"
                onClick={deleteResults}
              >
                Xoá kết quả
              </Button>
              <div className="d-flex column" style={{ height: "100%" }}>
                <ResultsMessToPhone
                  dataFb={dataFb}
                  renderKey={renderKey}
                  isInitialRender={isInitialRender}
                  setModalDetails={setModalDetails}
                  rows={rows}
                  setRows={setRows}
                  toast={toast}
                  openDetails={openDetails}
                  setOpenDetails={setOpenDetails}
                  itemResults={itemResults}
                  setItemResults={setItemResults}
                  allCate={allCate}
                  setAllCate={setAllCate}
                  wsResults={wsResults}
                  setWsResults={setWsResults}
                  listResults={listResults}
                  setListResults={setListResults}
                  totalPage={totalPage}
                  setTotalPage={setTotalPage}
                  getTotal={getTotal}
                  setGetTotal={setGetTotal}
                />
              </div>
            </div>
          </Sidebar>
        </div>
      </div>
      <CreateScriptMessPhone
        refresh={refresh}
        setRefresh={setRefresh}
        openAddScript={openAddScript}
        setOpenAddScript={setOpenAddScript}
      />
      <ModalDetailsMessPhone
        itemResults={itemResults}
        modalDetails={modalDetails}
        setModalDetails={setModalDetails}
      />
      <ModalCategoriesMessPhone
        managerScript={managerScript}
        setManagerScript={setManagerScript}
        itemAccout={itemAccout}
        setItemAccount={setItemAccount}
        first={first}
        setFirst={setFirst}
        setTotalPage={setTotalPage}
        rows={rows}
        setRows={setRows}
        totalPage={totalPage}
        setGetTotal={setGetTotal}
        getTotal={getTotal}
        renderKey={renderKey}
        allCate={allCate}
        setAllCate={setAllCate}
        allGroup={allGroup}
        setAllGroup={setAllGroup}
        listFr={listFr}
        setListFr={setListFr}
        itemCate={itemCate}
        setItemCate={setItemCate}
        dataFb={dataFb}
        openManager={openManager}
        setOpenManager={setOpenManager}
        refresh={refresh}
        setRefresh={setRefresh}
        toast={toast}
      />
    </div>
  );
};
