import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./EditProxy.css";
export const EditProxy = ({
  setShowData,
  showData,
  toast,
  props,
  setRefresh,
  headers,
  setSelectedItemEdit,
  selectedItemEdit,
  refresh,
  t,
}) => {
  const editData = API_URL + API_ROUTES_ZALO.EDIT_PROXY;
  const [dateInput, setDateInput] = useState("");

  useEffect(() => {
    if (selectedItemEdit?.date_expiration) {
      const dateParts = selectedItemEdit.date_expiration
        .split("T")[0]
        .split("-");
      const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
      setDateInput(formattedDate);
    }
  }, [selectedItemEdit]);
  const handleNoteChange = (event) => {
    setSelectedItemEdit((prevItem) => ({
      ...prevItem,
      note: event.target.value,
    }));
  };
  const handleProxyChange = (event) => {
    setSelectedItemEdit((prevItem) => ({
      ...prevItem,
      proxy: event.target.value,
    }));
  };
  const handleDateChange = (event) => {
    setDateInput(event.target.value);
  };
  const editDataFb = async () => {
    const newArray = {
      id: selectedItemEdit.id,
      proxy: selectedItemEdit.proxy,
      note: selectedItemEdit.note,
      date_expiration: dateInput,
    };
    try {
      const body = newArray;
      const response = await axios.post(editData, body, headers);
      setShowData(false);
      setRefresh(!refresh);
    } catch (error) {
      toast.error(t("sửa không thành công"));
    }
  };
  return (
    <Dialog
      header={t("Chỉnh sửa thông tin")}
      visible={showData}
      style={{ width: "50vw" }}
      onHide={() => setShowData(false)}
    >
      <div className="w-100" xs={6} md={4}>
        <div className="p-inputgroup flex-1">
          <span
            style={{ width: "9rem" }}
            className="input-group-text"
            id="addon-wrapping"
          >
            Proxy
          </span>
          <InputText
            value={selectedItemEdit?.proxy || ""}
            onChange={handleProxyChange}
          />
        </div>
        <div className="p-inputgroup flex-1">
          <span
            style={{ width: "9rem" }}
            className="input-group-text"
            id="addon-wrapping"
          >
            Ngày hết hạn
          </span>
          <InputText value={dateInput} onChange={handleDateChange} />{" "}
        </div>
        <div className="p-inputgroup flex-1">
          <span
            style={{ width: "9rem" }}
            className="input-group-text"
            id="addon-wrapping"
          >
            {t("Ghi chú")}
          </span>
          <InputText
            value={selectedItemEdit?.note || ""}
            onChange={handleNoteChange}
          />
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className="button-blue radius"
            label={t("Lưu")}
            onClick={() => editDataFb()}
          />
        </div>
      </div>
    </Dialog>
  );
};
