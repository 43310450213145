import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES, API_ROUTES_ZALO } from "../../../api";
import { TableAccountClientAddFr } from "./TableAccountClientAddFr";
import { TableAddFrOfAccount } from "./TableAddFrOfAccount";
import "primeicons/primeicons.css";
import { RiExpandDiagonal2Line } from "react-icons/ri";
import { useAuth } from "../../../AuthContext";
export const ModalScanAddFr = ({
  openCategoryAddFr,
  setOpenCategoryAddFr,
  setRefresh,
  refresh,
  dataZalo,
  setDataZalo,
  checkboxes,
  setCheckboxes,
  scriptClient,
  setScriptClient,
  listFr,
  setListFr,
  toast,
}) => {
  const headers = useHeaders();
  const zaloAccount = API_URL + API_ROUTES.GET_ACC_FB;
  const scanFriendRequest = API_URL + API_ROUTES_ZALO.SCAN_LIST_FRIEND_REQUEST;
  const resultScan = API_URL + API_ROUTES_ZALO.LIST_FRIEND_REQUEST_RESULT;
  const { setUserLoggedIn } = useAuth();
  const [allAddFriend, setAllAddFriend] = useState([]);
  const [taskId, setTaskId] = useState([]);
  const [dataScanRs, setDataScanRs] = useState("");
  const [statusRun, setStatusRun] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const intervalRef = useRef();
  const handleExit = () => {
    setDataScanRs([]);
    setSelectedItems([]);
    setOpenCategoryAddFr(false);
  };
  const getScanAddFriend = async () => {
    if (selectedItems?.length === 0) {
      return;
    }
    if (selectedItems[0]?.length === 0) {
      return;
    }
    const body = { id_account: selectedItems?.find((item) => item.id)?.id };
    try {
      const res = await axios.post(scanFriendRequest, body, headers);
      setTaskId(res.data.id_task);
      setStatusRun(true);
      axios
        .get(zaloAccount, headers)
        .then(function (response) {
          setDataZalo(response.data);
        })
        .catch(function (error) {
          if (error?.response?.status === 401) {
            setUserLoggedIn(false);
          }
        });
    } catch (err) {}
  };
  const checkResultScan = async () => {
    if (taskId?.length === 0) {
      return;
    }
    const body = { id_task: taskId };
    try {
      const res = await axios.post(resultScan, body, headers);
      setDataScanRs(res.data);
    } catch (err) {
      toast.error("Đã xảy ra lỗi khi quét lời mời kết bạn");
      setDataScanRs({ status: "FAILURE" });
      setStatusRun(false);
    }
  };
  useEffect(() => {
    if (dataScanRs?.status === "SUCCESS" || dataScanRs?.status === "FAILURE") {
      setTaskId([]);
      setStatusRun(false);
      setDataScanRs((pre) => ({ ...pre, status: "" }));
      return;
    }
    intervalRef.current = setInterval(() => {
      checkResultScan();
    }, 3000);
    return () => clearInterval(intervalRef.current);
  }, [taskId]);
  useEffect(() => {
    if (dataScanRs?.status === "SUCCESS" || dataScanRs?.status === "FAILURE") {
      setStatusRun(false);
      clearInterval(intervalRef.current);
      setRefresh(!refresh);
    }
  }, [dataScanRs]);
  return (
    <Dialog
      header={
        <div className="d-flex align-items-center gap-2">
          <span>Lời mời Kết bạn</span>
        </div>
      }
      visible={openCategoryAddFr}
      style={{ position: "relative", width: "71vw" }}
      onHide={handleExit}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            onClick={handleExit}
            className="d-flex justify-content-center button-red"
          >
            Thoát
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="d-flex gap-1 w-100 h-100">
          <div style={{ width: "40%" }} className="h-100">
            <TableAccountClientAddFr
              statusRun={statusRun}
              getScanAddFriend={getScanAddFriend}
              taskId={taskId}
              setAllAddFriend={setAllAddFriend}
              allAddFriend={allAddFriend}
              setRefresh={setRefresh}
              refresh={refresh}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              dataZalo={dataZalo}
              checkboxes={checkboxes}
              setCheckboxes={setCheckboxes}
            />
          </div>
          <div style={{ width: "60%" }} className=" h-100">
            <TableAddFrOfAccount
              setSelectedItems={setSelectedItems}
              getScanAddFriend={getScanAddFriend}
              statusRun={statusRun}
              dataScanRs={dataScanRs}
              setDataScanRs={setDataScanRs}
              setAllAddFriend={setAllAddFriend}
              allAddFriend={allAddFriend}
              refresh={refresh}
              setRefresh={setRefresh}
              selectedItems={selectedItems}
              scriptClient={scriptClient}
              setScriptClient={setScriptClient}
              listFr={listFr}
              setListFr={setListFr}
              toast={toast}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
