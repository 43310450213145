import { InputTextarea } from "primereact/inputtextarea";

export const ListMessGroup = ({ linkGroup, setLinkGroup }) => {
  return (
    <div className="</div>mt-2 d-flex gap-2 justify-content-between w-100">
      <div className="w-100 h-100">
        <p className="mb-2 mt-2">Nhập link nhóm zalo muốn tham gia</p>
        <InputTextarea
          style={{ width: "100%", height: "100%" }}
          value={linkGroup}
          onChange={(e) => setLinkGroup(e.target.value)}
          placeholder=""
          rows={5}
          cols={30}
        />
      </div>
    </div>
  );
};
