import { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Button } from "primereact/button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { getNoheaders } from "../../../const/getAxios";
import { postNoHeaders } from "../../../const/postAxios";
import { FaMinus, FaPlus } from "react-icons/fa";
import ModalAddProduct from "./ModalAddProduct";
import { FloatLabel } from "primereact/floatlabel";
function ModalEditProduct({
  openEdit,
  setOpenEdit,
  productEdit,
  setRefes,
  refes,
  toast,
}) {
  const headers = useHeaders();
  const apiCity = API_URL + API_ROUTES_ZALO.GET_CITY_SHOP;
  const apiDistrict = API_URL + API_ROUTES_ZALO.GET_DISTRICT_SHOP;
  const apiWard = API_URL + API_ROUTES_ZALO.GET_WARD_SHOP;
  const apiUpdateOrder = API_URL + API_ROUTES_ZALO.UPDATE_ORDER_PRODUCT;
  const [allCity, setAllCity] = useState([]);
  const [district, setDistrict] = useState([]);
  const [ward, setWard] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  let totalMonney = 0;
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedWard, setSelectedWard] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    full_name: "",
    phone_number: "",
    address: "",
    note: "",
  });
  const [listQuantity, setListQuantity] = useState([]);
  useEffect(() => {
    const list = productEdit?.items?.map((item, index) => ({
      id: item.id,
      quantity: item.quantity,
    }));
    setListQuantity(list);
  }, [productEdit]);

  const validateForm = () => {
    const validationErrors = {};

    if (!form.full_name || form.full_name.trim().length < 2) {
      validationErrors.full_name = "Tên đầy đủ phải có ít nhất 2 ký tự.";
    }

    const phoneRegex = /^[0-9]{10,11}$/;
    if (!form.phone_number || !phoneRegex.test(form.phone_number)) {
      validationErrors.phone_number =
        "Số điện thoại không hợp lệ. Vui lòng nhập 10 hoặc 11 số.";
    }

    if (!form.address || form.address.trim() === "") {
      validationErrors.address = "Vui lòng nhập địa chỉ.";
    }

    return validationErrors;
  };

  const handleUpdateOrders = async (item) => {
    console.log(item);
    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    try {
      const bodyUpdate = {
        id_order: productEdit.id,
        full_name: form.full_name,
        phone_number: form.phone_number,
        city: selectedCity?.city,
        district: selectedDistrict?.district,
        ward: selectedWard?.ward,
        address: form.address,
        note: form.note,
        status: productEdit.status,
        items: item,
        total_amount: totalMonney,
      };
      await axios.post(apiUpdateOrder, bodyUpdate, headers);
      setErrors({});
    } catch (err) {
      setRefes(!refes);
      alert(err.response.data.error);
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    getNoheaders(apiCity, setAllCity);
  }, []);
  useEffect(() => {
    if (productEdit?.id) {
      setForm({
        full_name: productEdit.full_name,
        phone_number: productEdit.phone_number,
        address: productEdit.address,
        note: productEdit.note,
      });
      setErrors({});
    }
    const city = allCity.find((c) => c.city === productEdit?.city);
    setSelectedCity(city || null);
    const bodyDis = {
      id_city: city?.id,
    };
    if (city?.id) {
      postNoHeaders(apiDistrict, bodyDis, setDistrict);
    }
    setErrors({});
  }, [productEdit, openEdit]);
  useEffect(() => {
    const dis = district.find((c) => c.district === productEdit?.district);
    setSelectedDistrict(dis || null);
    const bodyWard = {
      id_district: dis?.id,
    };
    if (dis?.id) {
      postNoHeaders(apiWard, bodyWard, setWard);
    }
  }, [district]);
  useEffect(() => {
    const war = ward.find((c) => c.ward === productEdit?.ward);
    setSelectedWard(war || null);
  }, [ward]);
  const handleChange = (e, field) => {
    setForm({
      ...form,
      [field]: e.target.value,
    });
  };
  const handleGetDistrict = (e) => {
    setSelectedDistrict(null);
    setSelectedWard(null);
    setSelectedCity(e.value);
    const body = {
      id_city: e.value.id,
    };
    postNoHeaders(apiDistrict, body, setDistrict);
  };
  const handleGetWard = (e) => {
    setSelectedDistrict(e.value);
    const body = {
      id_district: e.value.id,
    };
    postNoHeaders(apiWard, body, setWard);
  };
  const handleMinusOrder = (item) => {
    const itemFilter = listQuantity.filter(
      (quantity) => quantity.id === item.id
    )[0].quantity;
    if (itemFilter < 1) {
      toast.error("Số lượng không thể nhỏ hơn 1");
      return;
    }
    const currentValue = itemFilter - 1;
    setListQuantity((pre) => [
      ...pre.filter((qua) => qua.id !== item.id),
      { id: item.id, quantity: currentValue },
    ]);
    handleUpdateOrders([
      ...listQuantity.filter((qua) => qua.id !== item.id),
      { id: item.id, quantity: currentValue },
    ]);
  };
  const handlePlusOrder = (item) => {
    const itemFilter = listQuantity.filter(
      (quantity) => quantity.id === item.id
    )[0].quantity;
    const currentValue = itemFilter + 1;
    setListQuantity((pre) => [
      ...pre.filter((qua) => qua.id !== item.id),
      { id: item.id, quantity: currentValue },
    ]);
    handleUpdateOrders([
      ...listQuantity.filter((qua) => qua.id !== item.id),
      { id: item.id, quantity: currentValue },
    ]);
  };
  for (let i = 0; i < productEdit?.items?.length; i++) {
    totalMonney =
      totalMonney +
      productEdit?.items[i]?.price * productEdit?.items[i].quantity;
  }
  return (
    <Dialog
      header="Chỉnh sửa đơn hàng"
      className="dialog-shop"
      visible={openEdit}
      style={{ width: "50%" }}
      onHide={() => {
        if (!openEdit) return;
        setOpenEdit(false);
      }}
    >
      <ToastContainer />
      <div className="d-flex flex-column w-100 mt-4 ">
        <FloatLabel>
          <InputText
            onChange={(e) => handleChange(e, "full_name")}
            value={form.full_name}
            placeholder=""
            className="input-product"
          />{" "}
          <label for="Họ và tên">Họ và tên</label>
          {errors.full_name && (
            <p style={{ color: "red" }}>{errors.full_name}</p>
          )}
        </FloatLabel>
        <nav className="d-flex w-100 mt-4">
          <FloatLabel className="w-full">
            <Dropdown
              value={selectedCity}
              onChange={(e) => handleGetDistrict(e)}
              options={allCity}
              optionLabel="city"
              placeholder=""
              className="w-full "
              filter
            />
            <label for="Tỉnh/Thành phố">Tỉnh/Thành phố</label>
          </FloatLabel>
          <FloatLabel className="w-full">
            <Dropdown
              value={selectedDistrict}
              onChange={(e) => handleGetWard(e)}
              options={district}
              optionLabel="district"
              placeholder="Quận/Huyện"
              className="w-full "
              filter
            />
            <label for="Quận/Huyện">Quận/Huyện</label>
          </FloatLabel>
          <FloatLabel className="w-full">
            <Dropdown
              value={selectedWard}
              onChange={(e) => setSelectedWard(e.value)}
              options={ward}
              optionLabel="ward"
              placeholder="Xã"
              className="w-full "
              filter
            />
            <label for="Xã">Xã</label>
          </FloatLabel>
        </nav>
        <FloatLabel className="mt-4">
          <InputText
            onChange={(e) => handleChange(e, "address")}
            value={form.address}
            className="input-product"
            placeholder=""
          />
          <label for="Địa chỉ cụ thể">Địa chỉ cụ thể</label>
          {errors.address && <p style={{ color: "red" }}>{errors.address}</p>}
        </FloatLabel>
        <FloatLabel className="mt-4">
          <InputText
            onChange={(e) => handleChange(e, "phone_number")}
            value={form.phone_number}
            className="input-product"
            placeholder=""
          />
          <label for="Số điện thoại">Số điện thoại</label>
          {errors.phone_number && (
            <p style={{ color: "red" }}>{errors.phone_number}</p>
          )}
        </FloatLabel>
        <FloatLabel className="mt-4">
          <InputText
            onChange={(e) => handleChange(e, "note")}
            value={form.note}
            className="input-product"
            placeholder=""
          />
          <label for="Ghi chú">Ghi chú</label>
        </FloatLabel>
        <nav className="w-100 d-flex flex-column">
          <p className="mt-2 mb-2">Sản phẩm:</p>
          <div className="product-desc">
            {productEdit?.items?.map((pro, i) => (
              <nav className="mb-2 d-flex gap-2 justify-content-between align-items-center">
                <nav className="d-flex gap-2 align-items-center">
                  {" "}
                  <img
                    src={`${API_URL}${pro.image}`}
                    style={{ width: "40px", height: "40px" }}
                    alt=""
                  />
                  <p>{`${pro.title} - ${pro?.options?.map(
                    (name) => `${name.name} - ${name.value}`
                  )} - SL[${pro.quantity}] * ${pro.price.toLocaleString(
                    "vi-VN"
                  )}`}</p>
                </nav>
                <nav
                  className="d-flex gap-2 align-items-center"
                  style={{ width: "max-content" }}
                >
                  <p style={{ width: "max-content" }}>{`${(
                    pro.quantity * pro.price
                  ).toLocaleString("vi-VN")} VND`}</p>
                  <div className="d-flex gap-2 align-items-center">
                    <FaMinus
                      cursor="pointer"
                      onClick={() => handleMinusOrder(pro)}
                    />
                    <input
                      style={{
                        width: "30px",
                        textAlign: "center",
                      }}
                      value={
                        listQuantity?.filter(
                          (quantity) => quantity.id === pro.id
                        )[0]?.quantity
                      }
                      disabled="true"
                    />
                    <FaPlus
                      cursor="pointer"
                      onClick={() => handlePlusOrder(pro)}
                    />
                  </div>
                  <Button
                    style={{
                      padding: "4px 8px",
                      maxWidth: "max-content",
                      background: "red",
                    }}
                    onClick={() => {
                      handleUpdateOrders([
                        ...listQuantity.filter((qua) => qua.id !== pro.id),
                        { id: pro.id, quantity: 0 },
                      ]);
                    }}
                  >
                    Xoá
                  </Button>
                </nav>
              </nav>
            ))}
          </div>
          <Button
            style={{
              padding: "4px 6px",
              width: "max-content",
              background: "blue",
              marginBottom: "0.5rem",
            }}
            onClick={() => setModalAdd(true)}
          >
            Thêm sản phẩm
          </Button>
          <p style={{ fontSize: "18px", fontWeight: "700" }}>
            Tổng: {`${totalMonney?.toLocaleString("vi-VN")} VND`}
          </p>
        </nav>
      </div>
      <ModalAddProduct
        toast={toast}
        setRefes={setRefes}
        refes={refes}
        productEdit={productEdit}
        modalAdd={modalAdd}
        setModalAdd={setModalAdd}
      />
      <nav className="w-100 mt-2 d-flex align-items-center justify-content-end gap-2">
        <Button
          style={{
            padding: "8px 12px",
            width: "max-content",
            background: "red",
          }}
          onClick={() => {
            setOpenEdit(false);
          }}
        >
          Huỷ
        </Button>
        <Button
          style={{
            padding: "8px 12px",
            width: "max-content",
            background: "#0167f3",
          }}
          onClick={() => {
            {
              handleUpdateOrders(listQuantity);
              setOpenEdit(false);
            }
          }}
        >
          Chỉnh sửa
        </Button>
      </nav>
    </Dialog>
  );
}

export default ModalEditProduct;
