import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { FaGift } from "react-icons/fa";
import { IoIosCheckmark } from "react-icons/io";
import { IoStar } from "react-icons/io5";
import "./Redue.css";
import { API_ROUTES, API_URL } from "../../api";
import axios from "axios";
import ModalFromRedue from "./redue/ModalFromRedue";
import img1 from "./redue/new100.png";
import { TiTick } from "react-icons/ti";
import { Dialog } from "primereact/dialog";
import lienheimg from "./redue/contact.png";
import { useUser } from "./../../contexts/UserContext";
import { NavZalo } from "../layout/homezalo/NavZalo";

const Redue = () => {
  const { userInfo } = useUser();
  const getLogo = API_URL + API_ROUTES.LOGO_IMAGE;
  const [newLogo, setNewLogo] = useState("");
  const fetchDataLogo = async () => {
    try {
      const res = await axios.get(getLogo);
      setNewLogo(res?.data?.link);
    } catch (err) { }
  };
  const [openModal, setOpenModal] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [openPaySuccess, setOpenPaySuccess] = useState(false);
  useEffect(() => {
    fetchDataLogo();
  }, [newLogo]);

  const handleOpenModal = (type) => {
    setOpenModal(true);
    setTypeModal(type);
  };
  const dataCommon = [
    {
      content: "Thời hạn sử dụng 180 ngày",
    },
    {
      content: "Sử dụng trên mọi thiết bị",
    },
    {
      content: "Đầy đủ các tính năng",
    },
    {
      content: "Cập nhật miễn phí",
    },
    {
      content: "Support & cài đặt miễn phí qua Zalo",
    },
    {
      content: "Tài liệu, Video hướng dẫn",
    },
  ];

  const dataThreeMonths = [
    {
      content: "Thời hạn sử dụng 366 ngày",
    },
    {
      content: "Sử dụng trên mọi thiết bị",
    },
    {
      content: "Đầy đủ các tính năng",
    },
    {
      content: "Cập nhật miễn phí",
    },
    {
      content: "Support & cài đặt miễn phí qua Zalo",
    },
    {
      content: "Tài liệu, Video hướng dẫn & nhóm Support riêng",
    },
  ];
  const dataSixMonths = [
    {
      content: "Thời hạn sử dụng 730 ngày",
    },
    {
      content: "Sử dụng trên mọi thiết bị",
    },
    {
      content: "Đầy đủ các tính năng",
    },
    {
      content: "Cập nhật miễn phí",
    },
    {
      content: "Support & cài đặt miễn phí qua Zalo",
    },
    {
      content: "Tài liệu, Video hướng dẫn & nhóm Support riêng",
    },
  ];
  const dataOneyear = [
    {
      content: "Thời hạn sử dụng 1,095 ngày",
    },
    {
      content: "Sử dụng trên mọi thiết bị",
    },
    {
      content: "Đầy đủ các tính năng",
    },
    {
      content: "Cập nhật miễn phí",
    },
    {
      content: "Support & cài đặt miễn phí qua Zalo",
    },
    {
      content: "Tài liệu, Video hướng dẫn & nhóm Support riêng",
    },
  ];
  const dataGift = [
    {
      content: "Hỗ trợ chiến lược khai thác",
    },
    {
      content: "Tư vấn giải đáp tận tình",
    },
    {
      content: "Luôn đồng hành cùng khách hàng",
    },
    {
      content: "Tham gia các buổi chia sẻ định hướng chiến lược",
    },
  ];
  return (
    <>

      <div className="home-container">
        <div className="navRedue"> <NavZalo /></div>
        <div
          style={{ padding: "20px 20px", position: "relative", top: "80px" }}
          className="mobile-redue d-flex flex-column gap-5 h-100 justify-content-center align-items-center"
        >

          <div className=" d-flex flex-column align-items-center">
            <h1
              className=" title-mobile d-flex align-items-center gap-3"
              style={{ fontWeight: "700" }}
            >
              BẢNG GIÁ CHỐT CARE{" "}
              <img
                className="logo-redue"
                src={`${API_URL}/${newLogo}`}
                alt=""
              />
            </h1>
            <p style={{ fontSize: "18px", lineHeight: "22px" }}>
              Với những tính năng, lợi ích. Đội ngũ Chốt Care đưa ra mức giá đầu
              tư phù hợp cho các nhà bán hàng như sau:
            </p>
          </div>
          <div className="Price flex-wrap d-flex gap-4 justify-content-center">
            <div
              className="card-mobile "
              style={{
                width: "350px",
                padding: "10px",
                background: "linear-gradient(to right, #42a5f5, #90caf9",
                borderRadius: "10px",
              }}
            >
              <Card
                style={{ width: "100%", height: "100%" }}
                className="hover-redue rare"
              >
                <div className="d-flex flex-wrap flex-column gap-3 ">
                  <h3 className="title-common ">GÓI 6 THÁNG</h3>
                  <h1 className="price-title shadow-box-common">1.500.000 đ</h1>
                  {dataCommon.map((item, index) => (
                    <p className="content-redue" key={index}>
                      <IoIosCheckmark className="icon-redue-check" />
                      {item.content}
                    </p>
                  ))}
                  <div className="btn-price">
                    <Button
                      className="btn-redue-common"
                      label="Mua ngay"
                      onClick={() => handleOpenModal("common")}
                      outlined
                    />
                  </div>
                </div>
              </Card>
            </div>
            <div className="card-container card-mobile " style={{ width: "350px" }}>
              <div className="most-purchased-banner d-flex gap-2">
                <IoStar style={{ width: "15px", height: "15px" }} />
                ĐƯỢC MUA NHIỀU NHẤT
              </div>
              <div
                style={{
                  height: "100%",
                  padding: "10px",
                  background: "linear-gradient(to right, #1565c0, #1976d2",
                  borderRadius: "10px",
                }}
              >
                <Card
                  style={{ width: "100%", height: "100%" }}
                  className="hover-redue common  "
                >
                  <div className="d-flex flex-wrap flex-column gap-3">
                    <h3 className="title-three">GÓI 1 NĂM</h3>
                    <h1 className="price-title shadow-box-three">
                      2.500.000 đ
                    </h1>
                    {dataThreeMonths.map((item, index) => (
                      <p className="content-redue" key={index}>
                        <IoIosCheckmark className="icon-redue-check" />
                        {item.content}
                      </p>
                    ))}
                    <div className="btn-price">
                      <Button
                        className="btn-redue-three"
                        label="Mua ngay"
                        onClick={() => handleOpenModal("threemonth")}
                        outlined
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div
              className="card-mobile "
              style={{
                width: "350px",
                padding: "10px",
                background: "linear-gradient(to right, #009688, #4db6ac",
                borderRadius: "10px",
              }}
            >
              <Card
                style={{ width: "100%", height: "100%" }}
                className="hover-redue rare  "
              >
                <div className="d-flex flex-wrap flex-column gap-3">
                  <h3 className="title-six ">GÓI 2 NĂM</h3>
                  <h1 className="price-title shadow-box-six">3.500.000 đ</h1>
                  {dataSixMonths.map((item, index) => (
                    <p className="content-redue" key={index}>
                      <IoIosCheckmark className="icon-redue-check" />
                      {item.content}
                    </p>
                  ))}
                  <div className="btn-price">
                    <Button
                      className="btn-redue-six"
                      label="Mua ngay"
                      onClick={() => handleOpenModal("sixmonth")}
                      outlined
                    />
                  </div>
                </div>
              </Card>
            </div>

            <div
              className="card-mobile"
              style={{
                width: "350px",
                padding: "10px",
                background: "linear-gradient(to right, #d32f2f, #ff6f61",
                borderRadius: "10px",
              }}
            >
              <Card
                style={{ width: "100%", height: "100%" }}
                className="hover-redue legend  "
              >
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-wrap flex-column gap-3">
                    <h3 className="title-year">GÓI 3 NĂM</h3>
                    <h1 className="price-title shadow-box-year">4.000.000 đ</h1>
                    {dataOneyear.map((item, index) => (
                      <p className="content-redue" key={index}>
                        <IoIosCheckmark className="icon-redue-check" />
                        {item.content}
                      </p>
                    ))}
                    <div className="btn-price">
                      <Button
                        className="btn-redue-year"
                        label="Mua ngay"
                        onClick={() => handleOpenModal("oneyear")}
                        outlined
                      />
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <FaGift className="icon-redue" />
                    <h3 className="title">BỘ QUÀ TẶNG</h3>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    {dataGift.map((item, index) => (
                      <p className="content-redue" key={index}>
                        <IoIosCheckmark className="icon-redue-check" />
                        {item.content}
                      </p>
                    ))}
                  </div>
                </div>
              </Card>
            </div>
          </div>

          <ModalFromRedue
            openPaySuccess={openPaySuccess}
            setOpenPaySuccess={setOpenPaySuccess}
            typeModal={typeModal}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </div>
        <a
          href="https://www.facebook.com/messages/t/352162647978429"
          target="_blank"
          className="fixed-contact-link"
        >
          <div className="fixed-contact">
            {/* <p>
      Bạn đang cần liên hệ.
      <br />
      Chúng tôi luôn sẵn sàng tại đây:
    </p> */}
            <a
              href="https://www.facebook.com/messages/t/100095368608961"
              target="_blank"
            >
              <img
                className="contact-mobile"
                src={lienheimg}
                style={{ width: "300px" }}
                alt=""
              />
            </a>
          </div>
        </a>
        <footer
          className="footer-mobile-redue"
          style={{
            backgroundColor: "#263238",
            color: "#fff",
          }}
        >
          <div
            style={{ padding: "20px" }}
            className="content-footer-mobile d-flex justify-content-around align-items-center"
          >
            <div className="d-flex flex-column align-items-center">
              <h3>CAM KẾT CỦA CHÚNG TÔI !</h3>
              <img style={{ width: "150px", height: "100%" }} src={img1} />
            </div>

            <nav className="commit-mobile d-flex align-items-center gap-2 flex-column">
              <div className="footer-mobile-mb d-flex flex-column mt-2">
                <nav className="w-100 d-flex gap-2">
                  <TiTick size={15} color="green" />
                  <p
                    className="footer-mobile-font"
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "24px",
                    }}
                  >
                    Hỗ trợ chiến lược khai thác hiệu quả cao
                  </p>
                </nav>
                <nav className="w-100 d-flex gap-2 align-items-center">
                  <TiTick size={15} color="green" />
                  <p
                    className="footer-mobile-font"
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "24px",
                    }}
                  >
                    Tư vấn giải đáp tận tình thắc mắc của khách hàng
                  </p>
                </nav>
                <nav className="w-100 d-flex gap-2 align-items-center">
                  <TiTick size={15} color="green" />
                  <p
                    className="footer-mobile-font"
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "24px",
                    }}
                  >
                    Luôn đồng hành cùng khách hàng trong suốt quá trình sử dụng
                  </p>
                </nav>
                <nav className="w-100 d-flex gap-2 align-items-center">
                  <TiTick size={15} color="green" />
                  <p
                    className="footer-mobile-font"
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "24px",
                    }}
                  >
                    Liên tục cải tiến sản phẩm, giúp khách hàng có trải nghiệm tốt
                    nhất
                  </p>
                </nav>
              </div>
            </nav>
          </div>
        </footer>
      </div>

    </>
  );
};

export default Redue;
