import { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FloatLabel } from "primereact/floatlabel";
import { FcAddImage } from "react-icons/fc";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { TiDelete } from "react-icons/ti";
import { useHeaders } from "../../../const/headers";
import { useNavigate } from "react-router-dom";
import { API_URL, API_ROUTES, API_ROUTES_ZALO } from "../../../api";
import axios from "axios";
import { Image } from "primereact/image";
import { RadioButton } from "primereact/radiobutton";
import { IoIosCloseCircle } from "react-icons/io";
import imgQr from "./getQr.jpg";
export const CreateProductShop = ({
  handleGetAllProduct,
  idAccount,
  idCategory,
  toast,
  editProduct,
  setEditProduct,
}) => {
  const navigate = useNavigate();
  const apiGetLink = API_URL + API_ROUTES.GET_LINK_FILE;
  const apiCreateProduct = API_URL + API_ROUTES_ZALO.CREATE_PRODUCT_MINI_SHOP;
  const apiGetGr = API_URL + API_ROUTES_ZALO.GET_LINK_QR_ZALO;
  const inputImageRef = useRef();
  const inputQrRef = useRef();
  const headers = useHeaders();
  const [height, setHeight] = useState("");
  const [listImage, setListImage] = useState([]);
  const [title, setTitle] = useState("");
  const [linkSocial, setLinkSocial] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [imageQr, setImageQr] = useState(null);
  const [checkRights, setCheckRights] = useState("0");
  const [itemCategory, setItemcategory] = useState([
    {
      classify: "",
      price: "",
      options: [{ name: "", value: "" }],
      total_quantity: "",
      sold_quantity: 0,
    },
  ]);
  useEffect(() => {
    setHeight(document.getElementById("height-text")?.offsetHeight);
  }, []);
  useEffect(() => {
    if (editProduct?.id) {
      setDescription(editProduct.description);
      setPhone(editProduct.phone_number);
      setTitle(editProduct.title);
      setCheckRights(`${editProduct.sell_option}`);
      setItemcategory(editProduct.variants);
      setListImage(editProduct.images);
      setImageQr(editProduct.link_zalo);
      setLinkSocial(editProduct.original_video);
    }
  }, [editProduct]);
  const handleCategoryChange = (index, key, value) => {
    const updatedItemCategory = [...itemCategory];
    updatedItemCategory[index][key] = value;
    setItemcategory(updatedItemCategory);
  };
  const handleOptionChange = (cateIndex, optIndex, key, value) => {
    const updatedItemCategory = [...itemCategory];
    updatedItemCategory[cateIndex].options[optIndex][key] = value;
    setItemcategory(updatedItemCategory);
  };
  const addOption = (cateIndex) => {
    const updatedItemCategory = [...itemCategory];
    updatedItemCategory[cateIndex].options.push({ name: "", value: "" });
    setItemcategory(updatedItemCategory);
  };
  const addCategory = () => {
    setItemcategory((pre) => [
      ...pre,
      {
        classify: "",
        price: "",
        options: [{ name: "", value: "" }],
        total_quantity: "",
        sold_quantity: 0,
      },
    ]);
  };
  const copyOption = (cateIndex) => {
    setItemcategory((pre) => [...pre, ...pre]);
  };
  const removeOption = (cateIndex, optIndex) => {
    const updatedItemCategory = [...itemCategory];
    updatedItemCategory[cateIndex].options.splice(optIndex, 1);
    setItemcategory(updatedItemCategory);
  };

  const removeCategory = (indexCate) => {
    const updatedItemCategory = [...itemCategory];
    updatedItemCategory.splice(indexCate, 1);
    setItemcategory(updatedItemCategory);
  };
  const handleGetListLink = async (files) => {
    const formData = new FormData();
    formData.append("file", files);
    try {
      const res = await axios.post(apiGetLink, formData, headers);
      setListImage((pre) => [...pre, res.data.file]);
    } catch (err) {}
  };
  const handleFileChange = async () => {
    const fileInput = inputImageRef.current;
    const files = fileInput.files;
    for (let i = 0; i < files.length; i++) {
      handleGetListLink(files[i]);
    }
  };
  const handleGetFileImage = (e) => {
    const getImage = async () => {
      const formData = new FormData();
      formData.append("qr_code_image", e.target.files[0]);
      try {
        const res = await axios.post(apiGetGr, formData, headers);
        if (res.data.link_zalo === "") {
          toast.error("Lỗi , Vui lòng kiểm tra lại mã QR");
        }
        setImageQr(res.data.Link_zalo);
      } catch (err) {}
    };
    getImage();
  };
  const handleCreateProduct = async () => {
    const body = {
      id_category: Number(idCategory),
      title: title,
      description: description,
      phone_number: phone,
      link_zalo: imageQr,
      sell_option: Number(checkRights),
      images: listImage,
      variants: itemCategory,
      video: linkSocial,
      ...(editProduct?.id && { id_product: editProduct.id }),
    };
    if (!body.title || body.title.trim() === "") {
      alert("Tên sản phẩm đề là bắt buộc.");
      return;
    }
    if (!body.description || body.description.trim() === "") {
      alert("Mô tả là bắt buộc.");
      return;
    }
    if (!body.phone_number || body.phone_number.trim() === "") {
      alert("Số điện thoại là bắt buộc.");
      return;
    }
    if (
      !body.images ||
      !Array.isArray(body.images) ||
      body.images.length === 0
    ) {
      alert("Cần ít nhất một hình ảnh.");
      return;
    }
    if (
      !body.variants ||
      !Array.isArray(body.variants) ||
      !body.variants[0].classify
    ) {
      alert("Cần ít nhất một thể loại.");
      return;
    }
    if (checkRights === "1" && !imageQr) {
      alert("Thêm link liên hệ");
      return;
    }
    try {
      await axios.post(apiCreateProduct, body, headers);
      handleGetAllProduct(idAccount, idCategory);
      navigate(`/minishop/${idAccount}/${idCategory}`);
      setEditProduct([]);
    } catch (err) {}
  };
  return (
    <div className="p-2 h-100 w-100 d-flex flex-column position-relative justify-content-around">
      <nav
        style={{ padding: "0 4.5rem" }}
        className="d-flex align-items-center justify-content-between"
      >
        <h3>{editProduct?.id ? "Chỉnh sửa sản phẩm" : "Thêm sản phẩm mới"}</h3>
        <IoIosCloseCircle
          cursor="pointer"
          onClick={() => {
            navigate(`/minishop/${idAccount}/${idCategory}`);
            setEditProduct([]);
          }}
          size={30}
        />
      </nav>
      <div
        className="overflow-auto w-100 d-flex flex-column align-items-center"
        style={{ padding: "1.5rem 4.5rem" }}
      >
        <FloatLabel className="w-100 mb-4">
          <InputText
            id="username"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-100"
          />
          <label for="Tên sản phẩm">Tên sản phẩm</label>
        </FloatLabel>
        <FloatLabel className="w-100 mb-2 mt-2">
          <InputTextarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="username"
            className="w-100"
          />
          <label for="Mô tả">Mô tả</label>
        </FloatLabel>
        <FloatLabel className="w-100 mb-2 mt-4">
          <InputText
            id="username"
            value={linkSocial}
            onChange={(e) => setLinkSocial(e.target.value)}
            className="w-100"
          />
          <label for="Link Video">
            Link Video (Vui lòng chỉ nhập link video của Facebook , Tiktok hoặc
            Youtube)
          </label>
        </FloatLabel>

        <nav className="w-100 text-start mb-4 d-flex gap-2 align-items-center">
          <FcAddImage
            cursor="pointer"
            onClick={() => inputImageRef.current.click()}
            size={50}
          />
          {listImage?.length > 0 &&
            listImage?.map((item, index) => (
              <nav className="position-relative">
                <Image
                  src={`${API_URL}${item}`}
                  preview={`${API_URL}${item}`}
                  width="40px"
                  height="40px"
                />
                <TiDelete
                  cursor="pointer"
                  onClick={() =>
                    setListImage(listImage.filter((image) => image !== item))
                  }
                  size={20}
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-5px",
                    color: "red",
                  }}
                />
              </nav>
            ))}
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={inputImageRef}
            accept="image/*,video/*"
          />
        </nav>
        <FloatLabel className="w-100 mb-2">
          <InputText
            className="w-100"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <label for="Số điện thoại">Số điện thoại</label>
        </FloatLabel>
        {itemCategory?.map((itemCate, indexCate) => (
          <nav
            key={indexCate}
            className="border p-4 d-flex mb-4 flex-column w-100"
          >
            <nav className="d-flex w-100 mb-4">
              <FloatLabel
                className="w-100"
                style={{ height: "max-content" }}
                id="height-text"
              >
                <InputText
                  className="w-100"
                  style={{ height: "max-content" }}
                  value={itemCate.classify}
                  onChange={(e) =>
                    handleCategoryChange(indexCate, "classify", e.target.value)
                  }
                />
                <label for="Tên phân loại">Mã sản phẩm</label>
              </FloatLabel>
              <FloatLabel className="w-100" style={{ height: `${height}px` }}>
                <InputNumber
                  className="w-100"
                  style={{ height: `${height}px` }}
                  value={itemCate.total_quantity}
                  onValueChange={(e) =>
                    handleCategoryChange(indexCate, "total_quantity", e.value)
                  }
                />
                <label for="Số lượng">Số lượng</label>
              </FloatLabel>
              <FloatLabel className="w-100" style={{ height: `${height}px` }}>
                <InputNumber
                  className="w-100"
                  style={{ height: `${height}px` }}
                  value={itemCate.sold_quantity}
                  onValueChange={(e) =>
                    handleCategoryChange(indexCate, "sold_quantity", e.value)
                  }
                />
                <label for="Đã bán">Đã bán</label>
              </FloatLabel>
              <FloatLabel className="w-100" style={{ height: `${height}px` }}>
                <InputNumber
                  className="w-100"
                  style={{ height: `${height}px` }}
                  value={itemCate.price}
                  onValueChange={(e) =>
                    handleCategoryChange(indexCate, "price", e.value)
                  }
                />
                <label for="Giá">Giá</label>
              </FloatLabel>
            </nav>
            <nav className="w-100 gap-2 justify-content-between d-flex flex-column">
              {itemCate.options?.map((option, index) => (
                <nav
                  className="d-flex mb-2 align-items-center  gap-4"
                  style={{ width: "48%" }}
                  key={index}
                >
                  <nav className="w-100 d-flex flex-column gap-4 border p-2">
                    <FloatLabel style={{ width: "100%" }}>
                      <InputText
                        placeholder="Ví dụ(Màu sắc , Size....)"
                        style={{ width: "100%" }}
                        value={option.name}
                        onChange={(e) =>
                          handleOptionChange(
                            indexCate,
                            index,
                            "name",
                            e.target.value
                          )
                        }
                      />
                      <label for="cái gì đó">Tên phân loại</label>
                    </FloatLabel>
                    <FloatLabel style={{ width: "100%" }}>
                      <InputText
                        placeholder="Ví dụ(Xanh , XXl....)"
                        style={{ width: "100%" }}
                        value={option.value}
                        onChange={(e) =>
                          handleOptionChange(
                            indexCate,
                            index,
                            "value",
                            e.target.value
                          )
                        }
                      />
                      <label for="cái gì đó">Giá trị</label>
                    </FloatLabel>
                  </nav>
                  <TiDelete
                    onClick={() => removeOption(indexCate, index)}
                    color="red"
                    cursor="pointer"
                    size={50}
                  />
                </nav>
              ))}
            </nav>
            <button
              onClick={() => addOption(indexCate)}
              style={{ width: "max-content" }}
            >
              Thêm phân loại
            </button>
            <div className="d-flex gap-2 justify-content-end">
              <div className=" text-end ">
                <button
                  onClick={() => {
                    copyOption(indexCate);
                  }}
                  style={{ width: "max-content" }}
                >
                  Sao chép Sản phẩm
                </button>
              </div>
              <div className=" text-end">
                <button
                  onClick={() => removeCategory(indexCate)}
                  style={{ width: "max-content" }}
                >
                  Xoá Sản phẩm
                </button>
              </div>
            </div>
          </nav>
        ))}
        <nav className="w-100 text-start">
          <button onClick={addCategory} style={{ width: "max-content" }}>
            Thêm sản phẩm
          </button>
        </nav>
        <nav className="w-100 d-flex h-100 mt-4">
          <FloatLabel className="w-100  h-100">
            <InputText
              id="username"
              value={imageQr}
              onChange={(e) => setImageQr(e.target.value)}
              className="w-100"
            />
            <label for="Link Zalo liên hệ">Link liên hệ</label>
          </FloatLabel>
          <Button
            className="h-100 d-flex justify-content-center button-blue"
            style={{ width: "20%" }}
            onClick={() => inputQrRef.current.click()}
          >
            Tải lên mã QR
          </Button>
          <input
            type="file"
            onChange={(e) => handleGetFileImage(e)}
            style={{ display: "none" }}
            ref={inputQrRef}
            accept="image/*,video/*"
          />
        </nav>
        <div className="d-flex mt-4 w-100 align-items-center">
          <p style={{ width: "100%" }}>Tuỳ chọn đặt hàng</p>
          <div className="flex align-items-center w-100">
            <RadioButton
              inputId="ingredient1"
              name="pizza"
              value="0"
              onChange={(e) => setCheckRights(e.value)}
              checked={checkRights === "0"}
            />
            <label htmlFor="ingredient1" className="ml-2">
              Cho phép đặt hàng trên shop
            </label>
          </div>
          <div className="flex align-items-center w-100">
            <RadioButton
              inputId="ingredient1"
              name="pizza"
              value="1"
              onChange={(e) => setCheckRights(e.value)}
              checked={checkRights === "1"}
            />
            <label htmlFor="ingredient1" className="ml-2">
              Liên hệ người bán
            </label>
          </div>
        </div>
        <nav className="d-flex flex-column w-100 mt-4 justify-content-center align-items-center">
          <h3>Hướng dẫn lấy mã QR code cá nhân</h3>
          <img src={imgQr} className="w-100" />
        </nav>
      </div>
      <nav className="w-100 text-end">
        <Button
          onClick={handleCreateProduct}
          className="button-blue button-minishop-edit"
        >
          {editProduct?.id ? "Chỉnh Sửa" : "Tạo sản phẩm"}
        </Button>
      </nav>
    </div>
  );
};
