import { Dialog } from "primereact/dialog";
import { IoIosLink } from "react-icons/io";
import { MdContentCopy } from "react-icons/md";
import { useHeaders } from "../../../const/headers";
import { API_ROUTES, API_URL } from "../../../api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../../../contexts/UserContext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from 'primereact/inputnumber';
import './FeatureZalo.css'
import { FaPercent } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
export const FeatureZalo2 = () => {
    const { userInfo } = useUser();
    const [rf, setRf] = useState(false);
    const [image, setImage] = useState("");
    const [imageFirst, setImageFirst] = useState("");
    const [content, setContent] = useState("");
    const [content1, setContent1] = useState("");
    const [content2, setContent2] = useState("");
    const [content3, setContent3] = useState("");
    const [content4, setContent4] = useState("");
    const [content5, setContent5] = useState("");
    const [description, setDescription] = useState("");
    const [description1, setDescription1] = useState("");
    const [description2, setDescription2] = useState("");
    const [description3, setDescription3] = useState("");
    const [description4, setDescription4] = useState("");
    const [description5, setDescription5] = useState("");
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const [visible4, setVisible4] = useState(false);
    const [visible5, setVisible5] = useState(false);
    const [landing, setLanding] = useState([]);
    const [file, setFile] = useState(null);
    const headers = useHeaders();
    const uploadEndpoint = API_URL + API_ROUTES.UPLOAD_FILE_SERVER;
    const createUpdateEndpoint = API_URL + API_ROUTES.EDIT_LANDING;
    const apiGetLanding = API_URL + API_ROUTES.GET_ALL_LANDING;

    const fetchData = async () => {
        try {
            const res = await axios.get(apiGetLanding);
            setLanding(res.data);
        } catch (err) { }
    };

    useEffect(() => {
        fetchData();
    }, [rf]);
    const navigate = useNavigate();
    const handleNavi = (link) => {
        navigate(link);
    };
    const contentFistMain = landing?.filter((item) => item?.id === 28);
    const contentFistChart = landing?.filter((item) => item.id === 29);
    const contentSecondChart = landing?.filter((item) => item.id === 30);
    const contentThirdChart = landing?.filter((item) => item.id === 31);
    const contentFourChart = landing?.filter((item) => item.id === 32);
    const contentFiveChart = landing?.filter((item) => item.id === 33);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        setFile(file);
        const reader = new FileReader();
        reader.onload = () => {
            const imageDataURL = reader.result;
            const imgElement = document.getElementById("previewImage");
            if (imgElement) {
                imgElement.src = imageDataURL;
            }
        };
        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };
        reader.readAsDataURL(file);
    };

    const onChangeContent = (e) => {
        setContent(e.target.value);
    };

    const onChangeContent1 = (e) => {
        setContent1(e.target.value);
    };
    const onChangeContent2 = (e) => {
        setContent2(e.target.value);
    };
    const onChangeContent3 = (e) => {
        setContent3(e.target.value);
    };
    const onChangeContent4 = (e) => {
        setContent4(e.target.value);
    };
    const onChangeContent5 = (e) => {
        setContent5(e.target.value);
    };

    const onChangeLink = (e) => {
        setDescription(e.target.value);
    };
    const onChangePercent1 = (e) => {
        setDescription1(e.target.value)
    }
    const onChangePercent2 = (e) => {
        setDescription2(e.target.value)
    }
    const onChangePercent3 = (e) => {
        setDescription3(e.target.value)
    }
    const onChangePercent4 = (e) => {
        setDescription4(e.target.value)
    }
    const onChangePercent5 = (e) => {
        setDescription5(e.target.value)
    }

    const uploadToServer = async () => {
        if (file !== null) {
            const formData = new FormData();
            formData.append("image", file);
            try {
                const response = await axios.post(uploadEndpoint, formData, headers);
                setImage(response.data.image);
            } catch (error) { }
        }
    };

    useEffect(() => {
        setImage(contentFistMain[0]?.image);
        setDescription(contentFistMain[0]?.content);
        setContent(contentFistMain[0]?.title);
    }, [visible]);

    useEffect(() => {
        setContent1(contentFistChart[0]?.title);
        setContent2(contentSecondChart[0]?.title);
        setContent3(contentThirdChart[0]?.title);
        setContent4(contentFourChart[0]?.title);
        setContent5(contentFiveChart[0]?.title);
        setDescription1(contentFistChart[0]?.content);
        setDescription2(contentSecondChart[0]?.content);
        setDescription1(contentThirdChart[0]?.content);
        setDescription1(contentFourChart[0]?.content);
        setDescription1(contentFiveChart[0]?.content);
    }, [visible1, visible2, visible3, visible4, visible5])

    useEffect(() => {
        uploadToServer();
    }, [file]);

    const createResource = async () => {
        const data = {
            id: contentFistMain[0]?.id,
            title: content,
            content: description,
            image: image === "" ? contentFistMain[0]?.image : image,
        };
        try {
            const response = await axios.post(createUpdateEndpoint, data, headers);
            setRf(!rf);
            setDescription("");
            setContent("");
            setVisible(false);
        } catch (error) { }
    };
    const createResource1 = async () => {
        const data = {
            id: contentFistChart[0]?.id,
            title: content1,
            content: description1,
            image: ""
        };
        try {
            const response = await axios.post(createUpdateEndpoint, data, headers);
            setRf(!rf);
            setDescription1("");
            setContent1("");
            setVisible1(false);
        } catch (error) { }
    };
    const createResource2 = async () => {
        const data = {
            id: contentSecondChart[0]?.id,
            title: content2,
            content: description2,
            image: ""
        };
        try {
            const response = await axios.post(createUpdateEndpoint, data, headers);
            setRf(!rf);
            setDescription2("");
            setContent2("");
            setVisible2(false);
        } catch (error) { }
    };
    const createResource3 = async () => {
        const data = {
            id: contentThirdChart[0]?.id,
            title: content3,
            content: description3,
            image: ""
        };
        try {
            const response = await axios.post(createUpdateEndpoint, data, headers);
            setRf(!rf);
            setDescription3("");
            setContent3("");
            setVisible3(false);
        } catch (error) { }
    };
    const createResource4 = async () => {
        const data = {
            id: contentFourChart[0]?.id,
            title: content4,
            content: description4,
            image: ""
        };
        try {
            const response = await axios.post(createUpdateEndpoint, data, headers);
            setRf(!rf);
            setDescription4("");
            setContent4("");
            setVisible4(false);
        } catch (error) { }
    };
    const createResource5 = async () => {
        const data = {
            id: contentFiveChart[0]?.id,
            title: content5,
            content: description5,
            image: ""
        };
        try {
            const response = await axios.post(createUpdateEndpoint, data, headers);
            setRf(!rf);
            setDescription5("");
            setContent5("");
            setVisible5(false);
        } catch (error) { }
    };
    return (
        <>
            <div className="skills section">
                <div
                    className="container aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="100"
                >
                    <div className="row">

                        <div className="col-lg-6 pt-4 pt-lg-0 content">
                            <h3>{contentFistMain[0]?.title}</h3>
                            <p
                                className="fst-italic"
                                style={{ whiteSpace: "pre-wrap" }}
                                dangerouslySetInnerHTML={{
                                    __html: contentFistMain[0]?.content,
                                }}
                            />
                            {/* <div className="skills-content skills-animation">
                <div className="progress">
                  <span className="skill">
                    <span>{contentFistChart[0]?.title}</span> <i class="val">{`${contentFistChart[0]?.content}%`}</i>
                  </span>
                  <div className="progress-bar-wrap">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${contentFistChart[0]?.content}%` }}
                    ></div>
                  </div>
                  {
                    userInfo?.is_admin === true ? (
                      <button
                        className="btnChart"
                        onClick={() => setVisible1(true)}
                      >Sửa</button>
                    ) : (
                      <div></div>
                    )
                  }
                  <Dialog
                    header="Quản lý"
                    visible={visible1}
                    style={{ width: "50vw" }}
                    onHide={() => {
                      if (!visible1) return;
                      setVisible1(false);
                    }}
                  >
                    <div className="d-flex flex-column gap-2 mr-2 mb-2">
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <MdContentCopy />
                        </span>
                        <InputText
                          onChange={(e) => onChangeContent1(e)}
                          placeholder="Tiêu đề"
                          value={content1}
                        />
                      </div>
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <FaPercent />
                        </span>
                        <InputNumber
                          onValueChange={(e) => onChangePercent1(e)}
                          value={description1}
                          placeholder="Phần Trăm" />

                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2 mt-2">
                      <Button
                        className="button-blue radius"
                        label="Sửa"
                        onClick={() => createResource1()}
                      />
                      <Button
                        className="button-red radius"
                        label="Hủy"
                        onClick={() => setVisible1(false)}
                        severity="danger"
                      />
                    </div>
                  </Dialog>
                </div>
                <div className="progress">
                  <span className="skill">
                    <span>{contentSecondChart[0]?.title}</span> <i class="val">{`${contentSecondChart[0]?.content}%`}</i>
                  </span>
                  <div className="progress-bar-wrap">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${contentSecondChart[0]?.content}%` }}
                    ></div>
                  </div>
                  {
                    userInfo?.is_admin === true ? (
                      <button
                        className="btnChart"
                        onClick={() => setVisible2(true)}
                      >Sửa</button>
                    ) : (
                      <div></div>
                    )
                  }
                  <Dialog
                    header="Quản lý"
                    visible={visible2}
                    style={{ width: "50vw" }}
                    onHide={() => {
                      if (!visible2) return;
                      setVisible2(false);
                    }}
                  >
                    <div className="d-flex flex-column gap-2 mr-2 mb-2">
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <MdContentCopy />
                        </span>
                        <InputText
                          onChange={(e) => onChangeContent2(e)}
                          placeholder="Tiêu đề"
                          value={content2}
                        />
                      </div>
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <FaPercent />
                        </span>
                        <InputNumber
                          onValueChange={(e) => onChangePercent2(e)}
                          value={description2}
                          placeholder="Phần Trăm"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2 mt-2">
                      <Button
                        className="button-blue radius"
                        label="Sửa"
                        onClick={() => createResource2()}
                      />
                      <Button
                        className="button-red radius"
                        label="Hủy"
                        onClick={() => setVisible2(false)}
                        severity="danger"
                      />
                    </div>
                  </Dialog>
                </div>
                <div className="progress">
                  <span className="skill">
                    <span>{contentThirdChart[0]?.title}</span> <i class="val">{`${contentThirdChart[0]?.content}%`}</i>
                  </span>
                  <div className="progress-bar-wrap">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${contentThirdChart[0]?.content}%` }}
                    ></div>
                  </div>
                  {
                    userInfo?.is_admin === true ? (
                      <button
                        className="btnChart"
                        onClick={() => setVisible3(true)}
                      >Sửa</button>
                    ) : (
                      <div></div>
                    )
                  }
                  <Dialog
                    header="Quản lý"
                    visible={visible3}
                    style={{ width: "50vw" }}
                    onHide={() => {
                      if (!visible3) return;
                      setVisible3(false);
                    }}
                  >
                    <div className="d-flex flex-column gap-2 mr-2 mb-2">
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <MdContentCopy />
                        </span>
                        <InputText
                          onChange={(e) => onChangeContent3(e)}
                          placeholder="Tiêu đề"
                          value={content3}
                        />
                      </div>
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <FaPercent />
                        </span>
                        <InputNumber
                          onValueChange={(e) => onChangePercent3(e)}
                          value={description3}
                          placeholder="Phần Trăm"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2 mt-2">
                      <Button
                        className="button-blue radius"
                        label="Sửa"
                        onClick={() => createResource3()}
                      />
                      <Button
                        className="button-red radius"
                        label="Hủy"
                        onClick={() => setVisible3(false)}
                        severity="danger"
                      />
                    </div>
                  </Dialog>
                </div>
                <div className="progress">
                  <span className="skill">
                    <span>{contentFourChart[0]?.title}</span> <i class="val">{`${contentFourChart[0]?.content}%`}</i>
                  </span>
                  <div className="progress-bar-wrap">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${contentFourChart[0]?.content}%` }}
                    ></div>
                  </div>
                  {
                    userInfo?.is_admin === true ? (
                      <button
                        className="btnChart"
                        onClick={() => setVisible4(true)}
                      >Sửa</button>
                    ) : (
                      <div></div>
                    )
                  }
                  <Dialog
                    header="Quản lý"
                    visible={visible4}
                    style={{ width: "50vw" }}
                    onHide={() => {
                      if (!visible4) return;
                      setVisible1(false);
                    }}
                  >
                    <div className="d-flex flex-column gap-2 mr-2 mb-2">
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <MdContentCopy />
                        </span>
                        <InputText
                          onChange={(e) => onChangeContent4(e)}
                          placeholder="Tiêu đề"
                          value={content4}
                        />
                      </div>
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <FaPercent />
                        </span>
                        <InputNumber
                          onValueChange={(e) => onChangePercent4(e)}
                          value={description4}
                          placeholder="Phần Trăm"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2 mt-2">
                      <Button
                        className="button-blue radius"
                        label="Sửa"
                        onClick={() => createResource4()}
                      />
                      <Button
                        className="button-red radius"
                        label="Hủy"
                        onClick={() => setVisible4(false)}
                        severity="danger"
                      />
                    </div>
                  </Dialog>
                </div>
                <div className="progress">
                  <span className="skill">
                    <span>{contentFiveChart[0]?.title}</span> <i class="val">{`${contentFiveChart[0]?.content}%`}</i>
                  </span>
                  <div className="progress-bar-wrap">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${contentFiveChart[0]?.content}%` }}
                    ></div>
                  </div>
                  {
                    userInfo?.is_admin === true ? (
                      <button
                        className="btnChart"
                        onClick={() => setVisible5(true)}
                      >Sửa</button>
                    ) : (
                      <div></div>
                    )
                  }
                  <Dialog
                    header="Quản lý"
                    visible={visible5}
                    style={{ width: "50vw" }}
                    onHide={() => {
                      if (!visible5) return;
                      setVisible1(false);
                    }}
                  >
                    <div className="d-flex flex-column gap-2 mr-2 mb-2">
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <MdContentCopy />
                        </span>
                        <InputText
                          onChange={(e) => onChangeContent5(e)}
                          placeholder="Tiêu đề"
                          value={content5}
                        />
                      </div>
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <FaPercent />
                        </span>
                        <InputNumber
                          onValueChange={(e) => onChangePercent5(e)}
                          value={description5}
                          placeholder="Phần Trăm"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2 mt-2">
                      <Button
                        className="button-blue radius"
                        label="Sửa"
                        onClick={() => createResource5()}
                      />
                      <Button
                        className="button-red radius"
                        label="Hủy"
                        onClick={() => setVisible5(false)}
                        severity="danger"
                      />
                    </div>
                  </Dialog>
                </div>
              </div> */}
                        </div>
                        <div className="col-lg-6 align-items-center d-flex flex-column">
                            <img
                                src={`${API_URL}/${contentFistMain[0]?.image}`}
                                className="img-fluid"
                            />
                            <button
                                onClick={() => handleNavi("/register")}
                                className="btn-get-started"
                            >
                                Dùng ngay miễn phí
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {userInfo?.is_admin === true ? (
                <button
                    style={{
                        width: "100%",
                        border: "none",
                        backgroundColor: "#0866ff",
                        color: "#fff",
                        fontSize: "1rem",
                        padding: "0.5rem 1rem",
                        cursor: "pointer",
                        outline: "none",
                        transition: "all 0.2s ease-in-out",
                    }}
                    onClick={() => setVisible(true)}
                >
                    Sửa
                </button>
            ) : (
                <></>
            )}
            <Dialog
                header="Quản lý"
                visible={visible}
                style={{ width: "50vw" }}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
            >
                <div className="d-flex flex-column gap-2 mr-2 mb-2">
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <MdContentCopy />
                        </span>
                        <InputText
                            onChange={(e) => onChangeContent(e)}
                            placeholder="Tiêu đề"
                            value={content}
                        />
                    </div>
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <IoIosLink />
                        </span>
                        <InputTextarea
                            onChange={(e) => onChangeLink(e)}
                            value={description}
                            placeholder="Mô tả"
                        />
                    </div>
                    <h3>Chọn Ảnh</h3>
                    <label htmlFor="fileInput">
                        <button
                            className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
                            onClick={() => document.getElementById("fileInput").click()}
                        >
                            <p className="mb-0">Chọn ảnh</p>
                        </button>
                    </label>
                    <input
                        id="fileInput"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                    />
                    {image === "" && imageFirst === "" ? (
                        <div></div>
                    ) : (
                        <img
                            style={{
                                width: "100px",
                                display: "block",
                            }}
                            alt="Selected Image"
                            src={`${API_URL}/${image === "" ? imageFirst : image}`}
                        />
                    )}
                </div>
                <div className="d-flex justify-content-end gap-2 mt-2">
                    <Button
                        className="button-blue radius"
                        label="Sửa"
                        onClick={() => createResource()}
                    />
                    <Button
                        className="button-red radius"
                        label="Hủy"
                        onClick={() => setVisible(false)}
                        severity="danger"
                    />
                </div>
            </Dialog>
        </>
    );
};
