import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES } from "../../../api";
import { Button } from "primereact/button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "../../../contexts/UserContext";
import { useTranslation } from "react-i18next";

export default function Term() {
  const [numPages, setNumPages] = React.useState(null);
  const { termData, setModalAcceptTerms, rfTerm, setRfTerm } = useUser();
  const headers = useHeaders();
  const create = API_URL + API_ROUTES.CREATE_POPUP_TERM;
  const [value, setValue] = useState("");
  const { t } = useTranslation();
  const createAlert = async () => {
    const body = {
      id: termData?.id,
      content: value || termData?.content,
      image: "",
    };
    try {
      await axios.post(create, body, headers);
      toast.success("Thành công");
      setRfTerm(!rfTerm);
    } catch (err) {
      toast.error("Lỗi");
    }
  };
  useEffect(() => {
    if (termData?.content) {
      setValue(termData.content);
    }
  }, [termData]);
  return (
    <div className="flex-column flex justify-content-center">
      <ToastContainer />
      <p>{t("Nội dung")}</p>

      <div className="d-flex gap-2 mt-4">
        <Button
          style={{
            width: "max-content",
            height: "100%",
            background: "#054ddb",
            border: "#054ddb",
            borderRadius: "5px",
          }}
          label={t("Lưu")}
          className="mb-2"
          onClick={createAlert}
        />
        <Button
          style={{
            width: "max-content",
            height: "100%",
            background: "#054ddb",
            border: "#054ddb",
            borderRadius: "5px",
          }}
          label={t("Xem Popup")}
          className="mb-2"
          onClick={() => setModalAcceptTerms(true)}
        />
      </div>
    </div>
  );
}
