import { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Button } from "primereact/button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
function ModalViewCart({
  rfCart,
  setRfCart,
  viewCart,
  setViewCart,
  session_key,
  setOpenOrder,
  allCart,
  iduser,
}) {
  const updateToCart = API_URL + API_ROUTES_ZALO.UPDATE_CART_SHOP;
  const [listQuantity, setListQuantity] = useState([]);
  useEffect(() => {
    const list = allCart.items?.map((item, index) => ({
      id: item.id_product_variant,
      quantity: item.quantity,
    }));
    setListQuantity(list);
  }, [allCart]);
  const handleUpdateOrder = async (id_variant, quantity) => {
    const body = {
      id_employee: iduser,
      session_key: session_key,
      id_variant: id_variant,
      quantity: quantity,
    };
    try {
      await axios.post(updateToCart, body);
      setRfCart(!rfCart);
    } catch (err) {}
  };
  const handleMinusOrder = (item) => {
    const itemFilter = listQuantity.filter(
      (quantity) => quantity.id === item.id_product_variant
    )[0].quantity;
    if (itemFilter < 1) {
      toast.error("Số lượng không thể nhỏ hơn 1");
      return;
    }
    const currentValue = itemFilter - 1;
    setListQuantity((pre) => [
      ...pre.filter((qua) => qua.id !== item.id_product_variant),
      { id: item.id_product_variant, quantity: currentValue },
    ]);
    handleUpdateOrder(item.id_product_variant, currentValue);
  };
  const handlePlusOrder = (item) => {
    const itemFilter = listQuantity.filter(
      (quantity) => quantity.id === item.id_product_variant
    )[0].quantity;
    const currentValue = itemFilter + 1;
    setListQuantity((pre) => [
      ...pre.filter((qua) => qua.id !== item.id_product_variant),
      { id: item.id_product_variant, quantity: currentValue },
    ]);
    handleUpdateOrder(item.id_product_variant, currentValue);
  };

  return (
    <Dialog
      header="Giỏ hàng"
      className="dialog-shop"
      visible={viewCart}
      style={{ width: "60%" }}
      onHide={() => {
        if (!viewCart) return;
        setViewCart(false);
      }}
    >
      <ToastContainer />
      <div className="w-100 d-flex justify-content-between mb-4">
        <p style={{ width: "20%", fontWeight: "600", fontSize: "16px" }}>
          Tiêu đề
        </p>
        <p style={{ width: "20%", fontWeight: "600", fontSize: "16px" }}>
          Mã sản phẩm
        </p>
        <p style={{ width: "20%", fontWeight: "600", fontSize: "16px" }}>
          Phân loại
        </p>
        <p style={{ width: "20%", fontWeight: "600", fontSize: "16px" }}>
          Đơn giá
        </p>
        <p style={{ width: "20%", fontWeight: "600", fontSize: "16px" }}>
          Số lượng
        </p>
        <p style={{ width: "20%", fontWeight: "600", fontSize: "16px" }}>
          Thành tiền
        </p>
      </div>
      <div className="d-flex flex-column gap-2">
        {allCart?.items?.map((item, index) => {
          return (
            <div
              className="d-flex justify-content-between w-100 align-items-center"
              key={index}
            >
              <div
                style={{ width: "20%" }}
                className="category-container-order cursor-pointer p-0"
              >
                <p className="">{`${item.title}`}</p>
              </div>
              <div
                style={{ width: "20%" }}
                className="category-container-order cursor-pointer p-0"
              >
                <p className="">{`${item.product_variant.classify}`}</p>
              </div>
              <div style={{ width: "20%" }}>
                <nav className="d-flex gap-2 flex-wrap">
                  {item.product_variant.options?.map((option, num) => (
                    <p
                      className=""
                      key={num}
                    >{`${option?.name} - ${option?.value}`}</p>
                  ))}
                </nav>
              </div>
              <div
                style={{ width: "20%" }}
                className="d-flex align-items-center"
              >
                <p>
                  {`${item.product_variant.price?.toLocaleString("vi-VN")} VND`}
                </p>
              </div>
              <div
                style={{ width: "20%" }}
                className="d-flex gap-2 align-items-center"
              >
                <FaMinus
                  cursor="pointer"
                  onClick={() => handleMinusOrder(item)}
                />
                <input
                  style={{
                    width: "30px",
                    textAlign: "center",
                  }}
                  value={
                    listQuantity?.filter(
                      (quantity) => quantity.id === item.id_product_variant
                    )[0]?.quantity
                  }
                  disabled="true"
                />
                <FaPlus
                  cursor="pointer"
                  onClick={() => handlePlusOrder(item)}
                />
              </div>
              <div
                style={{ width: "20%" }}
                className="d-flex align-items-center"
              >
                <p>
                  {`${(
                    listQuantity?.filter(
                      (quantity) => quantity.id === item.id_product_variant
                    )[0]?.quantity * item.product_variant.price
                  )?.toLocaleString("vi-VN")} VND`}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <nav className="w-100 mt-2 d-flex align-items-center justify-content-end gap-2">
        <nav className=" mr-2">
          {allCart?.total_amount && (
            <p style={{ fontWeight: "700", fontSize: "18px" }}>
              Tổng: {`${allCart?.total_amount?.toLocaleString("vi-VN")} VND`}
            </p>
          )}
        </nav>
        <Button
          style={{
            padding: "8px 12px",
            width: "max-content",
            background: "red",
          }}
          onClick={() => setViewCart(false)}
        >
          Huỷ
        </Button>
        <Button
          style={{
            padding: "8px 12px",
            width: "max-content",
            background: "#0167f3",
          }}
          onClick={() => setOpenOrder(true)}
        >
          Mua hàng
        </Button>
      </nav>
    </Dialog>
  );
}

export default ModalViewCart;
