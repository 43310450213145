import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { API_URL } from "./../../../../api";
import { FaMinus, FaPlus } from "react-icons/fa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
const ModalCart = ({
  openCart,
  setOpenCart,
  toast,
  productEdit,
  listVariant,
}) => {
  const product = listVariant?.map((item) => item.product).flat();
  const detailProduct = product?.map((item) => item?.variants).flat();
  const productId = listVariant.map((item) => item.product_variant_id);
  const classifiedProducts = detailProduct?.filter((item) =>
    productId.includes(item.id)
  );
  const classifiedVariants = [...new Set(classifiedProducts)];

  // Tạo đối tượng để lưu trữ số lượng và hình ảnh
  const variantDetails = {};
  listVariant.forEach((item) => {
    const variantId = item.product_variant_id;
    if (variantDetails[variantId]) {
      variantDetails[variantId] += item.quantity;
    } else {
      variantDetails[variantId] = {
        quantity: item.quantity,
        images: item.product[0].images[0],
      };
    }
  });
  const classifiedVariantsWithQuantity = classifiedVariants.map((variant) => ({
    ...variant,
    quantity: variantDetails[variant.id]?.quantity || 0,
    images: variantDetails[variant.id]?.images || "",
  }));
  const listProductSelected = [...new Set(classifiedVariantsWithQuantity)];
  const imageProductConvert = (rowData) => {
    return (
      <img src={`${API_URL}/${rowData.images}`} alt="Product" width="100px" />
    );
  };
  return (
    <Dialog
      header="Giỏ hàng"
      visible={openCart}
      style={{ width: "50vw" }}
      onHide={() => {
        if (!openCart) return;
        setOpenCart(false);
      }}
    >
      <div className="card overflow-auto">
        <DataTable
          value={listProductSelected}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="classify" header="Tên"></Column>
          <Column
            body={imageProductConvert}
            field="images"
            header="Hình ảnh"
          ></Column>
          <Column field="quantity" header="Số lượng"></Column>
          <Column field="price" header="Giá"></Column>
          <Column
            header="Thành tiền"
            body={(rowData) => rowData.quantity * rowData.price}
          ></Column>
        </DataTable>
      </div>
    </Dialog>
  );
};

export default ModalCart;
