import React, { useEffect } from "react";
import Navigate from "../container/Navigate";
import { Table } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES } from "../../api";
import { Footer } from "./Footer";
import { useUser } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { ManageSep } from "../container/managesep/ManagerSep";
import { ModalAcceptAcc } from "../container/accwait/ModalAcceptAcc";
import { useHeaders } from "../../const/headers";
import { InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { updateTitle } from "../../const/updateTitle";
export const AccountWaitting = () => {
  updateTitle("Chốt Care - Tài Khoản Đang Chờ");
  const headers = useHeaders();
  const getAllAcc = API_URL + API_ROUTES.GET_WAITING_ACC;
  const deleteAcc = API_URL + API_ROUTES.DELETE_ACCOUNT_REGISTER;
  const getMail = API_URL + API_ROUTES.GET_MAIL_REGISTER_SYSTEM;
  const changeMail = API_URL + API_ROUTES.CHANGE_MAIL_REGISTER_SYSTEM;
  const { userInfo } = useUser();
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const [listEmpl, setListEmpl] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [hiddenTable, setHiddenTable] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [getItemEdit, setGetItemEdit] = useState([]);
  const [search, SetSearch] = useState("");
  const [value, setValue] = useState(userInfo.mail);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [mail, setMail] = useState({ mail_register: "" });
  const searchChange = (event) => {
    const { value } = event.target;
    SetSearch(value);
  };
  const deleteAccount = async (id) => {
    const confirm = window.confirm(
      "Bạn có chắc chắn muốn xóa tài khoản này không"
    );
    if (confirm) {
      const body = {
        id: id,
      };
      try {
        const res = await axios.post(deleteAcc, body, headers);
        setRefresh(!refresh);
      } catch (err) { }
    }
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setValue(email);
    setIsEmailValid(validateEmail(email));
  };

  const getMailChange = async () => {
    try {
      const res = await axios.get(getMail, headers);
      setMail(res.data);
    } catch (err) { }
  };

  useEffect(() => {
    getMailChange();
  }, []);

  useEffect(() => {
    if (mail.mail_register) {
      setValue(mail.mail_register);
    }
  }, [mail]);

  const handleChangeMail = async () => {
    if (!isEmailValid) {
      toast.error("Định dạng mail không đúng");
      return;
    }
    const body = {
      mail: value,
    };
    try {
      const res = await axios.post(changeMail, body, headers);
      toast.success("Email đã được cập nhật");
    } catch (err) {
      toast.error("Có lỗi xảy ra khi cập nhật email");
    }
  };

  useEffect(() => {
    getDataEmpl();
  }, [refresh]);
  const getDataEmpl = async () => {
    try {
      const response = await axios.get(getAllAcc, headers);
      setListEmpl(response.data);
    } catch (err) { }
  };
  function formatDateTime(dateString) {
    const date = new Date(dateString);

    const pad = (num) => String(num).padStart(2, "0");

    const timePart = `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
      date.getSeconds()
    )}`;
    const datePart = `${pad(date.getDate())}-${pad(
      date.getMonth() + 1
    )}-${date.getFullYear()}`;

    return `${timePart} - ${datePart}`;
  }
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="d-flex h-100 w-100 accountwaitting-wrapper mgtop-respon-47">
          <div
            className="overflow-auto h-100 d-flex flex-column"
            style={{
              width: "100%",
              borderRight: "0.5px solid",
              borderLeft: "0.5px solid",
            }}
          >
            <div
              id="heigt-top"
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid",
              }}
              className="thead-fixed justify-content-between"
            >
              <h3
                className="manage-domain-respon"
                id="title"
                style={{
                  padding: "20px",
                  margin: "0",
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#545454",
                }}
              >
                {t("Tài khoản đang chờ")}
              </h3>
              <div className="d-flex gap-2 align-items-center">
                <h3
                  className="manage-domain-respon"
                  style={{
                    margin: "0",
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "#545454",
                  }}
                >
                  {t("Mail nhận thông báo")}:
                </h3>
                <InputGroup
                  className="responsive-width-acc"
                  style={{
                    width: "auto",
                    borderRadius: "0",
                  }}
                >
                  <Form.Control
                    placeholder={t("Nhập mail nhận thông báo")}
                    aria-describedby="basic-addon2"
                    value={value}
                    style={{
                      borderRadius: "0",
                    }}
                    onChange={handleEmailChange}
                  />
                </InputGroup>
                <Button
                  className=" button-blue radius"
                  style={{
                    height: "40px",
                  }}
                  onClick={handleChangeMail}
                >
                  {t("Thay đổi")}
                </Button>
              </div>
              <InputGroup
                className="responsive-width-acc"
                style={{
                  width: "auto",
                  borderRadius: "0",
                  margin: "10px",
                }}
              >
                <Form.Control
                  placeholder={t("Tìm kiếm nhanh")}
                  aria-describedby="basic-addon2"
                  value={search}
                  style={{
                    borderRadius: "0",
                  }}
                  onChange={searchChange}
                />
              </InputGroup>
            </div>
            <ManageSep
              setVisible={setVisible}
              visible={visible}
              setRefresh={setRefresh}
              refresh={refresh}
              listEmpl={listEmpl}
            />
            <div className="overflow-auto h-100 ">
              <Table striped bordered hover>
                <thead className="thead-fixed">
                  <tr style={{ borderTop: "0" }}>
                    <th>{t("STT")}</th>
                    <th>{t("Tên đăng nhập")} </th>
                    <th>Link Facebook</th>
                    <th>{t("Mật khẩu")}</th>
                    <th>{t("Họ tên")}</th>
                    <th>{t("Số điện thoại")}</th>
                    <th>{t("Mail")}</th>
                    <th>IP</th>
                    <th>{t("Ngày tạo")}</th>
                    <th>{t("Tùy chọn")}</th>
                  </tr>
                </thead>
                <tbody>
                  {listEmpl?.map((item, index) => (
                    <tr
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => setHiddenTable(!hiddenTable)}
                    >
                      <td>{index + 1}</td>
                      <td>{item?.username}</td>
                      <td>
                        <a target="blank" href={item?.facebook_link}>
                          {item?.facebook_link}
                        </a>
                      </td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          wordWrap: "break-word",
                        }}
                      >
                        {item?.password}
                      </td>
                      <td>{item?.fullname}</td>
                      <td>{item?.phone_number}</td>
                      <td>{item?.mail}</td>
                      <td>{item?.IP}</td>
                      <td>{formatDateTime(item?.created_at)}</td>
                      <td>
                        <Button
                          onClick={() => {
                            setModalEdit(true);
                            setGetItemEdit(item);
                          }}
                          label="Xác nhận"
                          severity="info"
                          style={{
                            padding: "6px 12px",
                          }}
                          className=" button-blue radius"
                        />
                        <Button
                          onClick={() => deleteAccount(item?.id)}
                          label="Xóa"
                          severity="danger"
                          style={{ padding: "6px 12px" }}
                          className=" button-red radius"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <ModalAcceptAcc
                setRefresh={setRefresh}
                refresh={refresh}
                getItemEdit={getItemEdit}
                setModalEdit={setModalEdit}
                modalEdit={modalEdit}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
