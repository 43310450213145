import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { IoInformationCircle } from "react-icons/io5";
import { RiExpandDiagonal2Line } from "react-icons/ri";
import { API_ROUTES_NTD, API_ROUTES_ZALO, API_URL } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { ListMessGroup } from "./script/ListMessGroup";
export const ModalCreateCateGroup = ({
  props,
  openAddScript,
  setOpenAddScript,
  setRefresh,
  refresh,
}) => {
  const headers = useHeaders();
  const apiJoinGr = API_URL + API_ROUTES_ZALO.JOIN_GROUP.CREATE_OR_EDIT;
  const [linkGroup, setLinkGroup] = useState("");
  const [name, setName] = useState("");
  const [delayTime, setDelayTime] = useState(60);
  const [numberCount, setNumberCount] = useState(0);
  const [waitTime, setWaitTime] = useState(0);
  const createCateJoinGr = async () => {
    if (!name) {
      alert("Vui lòng nhập đầy đủ thông tin");
    } else {
      const body = {
        id_category: null,
        name: name,
        delay_time: delayTime,
        wait_time: waitTime,
        number_count: numberCount,
        list_group: linkGroup.split("\n")?.filter((item) => item !== ""),
      };
      try {
        const res = await axios.post(apiJoinGr, body, headers);
        setName("");
        setLinkGroup("");
        setOpenAddScript(!openAddScript);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  return (
    <Dialog
      header="Kịch bản tham gia nhóm"
      visible={openAddScript}
      style={{ position: "relative", width: "50vw" }}
      onHide={() => setOpenAddScript(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button className="button-blue radius" onClick={createCateJoinGr}>
            Lưu kịch bản
          </Button>
          <Button
            className="button-red radius"
            onClick={() => setOpenAddScript(false)}
          >
            Thoát không lưu
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon " style={{ width: "9rem" }}>
            Tên kịch bản
          </span>
          <InputText
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nhập tên kịch bản"
          />
        </div>
        <div className="p-inputgroup flex-1 w-100">
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Thời gian chờ (Giây)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Thời gian chờ giữa mỗi hành động trong một chuỗi nhiều
                      hành động
                      <br />- Ví dụ: Bạn tích chọn 3 chức năng "Gửi tin nhắn +
                      Bình luận + Like bài viết". Thì đây là một chuỗi 3 hành
                      động liên tiếp. Bạn nên để thời gian chờ tối thiểu là 60s
                      và khuyến khích là 180s để an toàn cho tài khoản. Hành
                      động quá nhanh sẽ dễ bị hạn chế tính năng trên tài khoản
                      do cơ chế chống spam của Facebook
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>

            <InputText
              style={{ width: "100%" }}
              value={delayTime}
              placeholder="Thời gian tính bằng giây (s)"
              onChange={(e) => setDelayTime(e.value)}
              useGrouping={false}
              min={60}
              type="number"
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Số lượt</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Tài khoản đi tương tác với mỗi một khách hàng được tính là
                      1 lượt. Nên để số lượt là 1. Và cài đặt thêm thời gian chờ
                      lặp lại ở ô bên cạnh
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputText
              style={{ width: "100%" }}
              value={numberCount}
              placeholder=""
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 1) {
                  setNumberCount(value);
                } else {
                  setNumberCount(1);
                }
              }}
              useGrouping={false}
              min={1}
              type="number"
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span style={{ width: "" }}>Thời gian chờ lặp lại (Giờ)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Chức năng này có tác dụng khi bạn cài đặt số lượt (số lượt
                      được cài đặt ở ô bên cạnh) Chức năng này giúp chạy tiếp
                      chiến dịch hoàn toàn tự động khi bạn đặt số lượt ở ô bên
                      cạnh. Nên để thời gian là 1 giờ
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputText
              style={{ width: "100%" }}
              value={waitTime}
              placeholder="Thời gian tính bằng giờ (h)"
              onChange={(e) => setWaitTime(e.value)}
              useGrouping={false}
              min={1}
              type="number"
            />
          </div>
        </div>
        <ListMessGroup linkGroup={linkGroup} setLinkGroup={setLinkGroup} />
      </div>
    </Dialog>
  );
};
