import axios from "axios";
export const getLinkFile = async (
  files,
  setListFile,
  listFile,
  apiGetLink,
  headers
) => {
  const formData = new FormData();
  formData.append("file", files);
  try {
    const res = await axios.post(apiGetLink, formData, headers);
    setListFile([...listFile, res.data.file]);
  } catch (err) {}
};

export const getLinkOneFile = async (
  files,
  setListFile,
  apiGetLink,
  headers
) => {
  const formData = new FormData();
  formData.append("file", files);
  try {
    const res = await axios.post(apiGetLink, formData, headers);
    setListFile(res.data.file);
  } catch (err) {}
};
