import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_URL, API_ROUTES_ZALO, WS_URL } from "../../../api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { useUser } from "../../../contexts/UserContext";
import { IoIosAddCircle } from "react-icons/io";
import { InputTextarea } from "primereact/inputtextarea";
export const CreateProxy = ({
  setModalShow,
  modalShow,
  toast,
  headers,
  setRefresh,
  props,
  t,
  refresh,
  setDataFb,
}) => {
  const addProxy = API_URL + API_ROUTES_ZALO.ADD_PROXY;
  const { userInfo } = useUser();
  const [dataProxy, setDataProxy] = useState({
    proxy: "",
    expiryDate: "",
    note: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataProxy((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCreateProxy = async () => {
    const body = {
      proxies: dataProxy.proxy.split("\n"),
      date_expiration: dataProxy.expiryDate,
      note: dataProxy.note,
    };
    try {
      await axios.post(addProxy, body, headers);
      setRefresh(!refresh);
      setModalShow(false);
    } catch (err) {}
  };
  return (
    <div className="flex justify-content-center">
      <Dialog
        header={t("Thêm Proxy")}
        visible={modalShow}
        style={{ width: "50vw" }}
        onHide={() => setModalShow(false)}
      >
        <div className="w-100 flex flex-column gap-4 mt-4">
          <FloatLabel>
            <InputTextarea
              className="w-100"
              name="proxy"
              value={dataProxy.proxy}
              onChange={handleChange}
            />
            <label htmlFor="proxy">Proxy</label>
          </FloatLabel>
          <FloatLabel>
            <InputText
              className="w-100"
              name="expiryDate"
              value={dataProxy.expiryDate}
              onChange={handleChange}
            />
            <label htmlFor="expiryDate">
              Ngày hết hạn (Ví dụ: 22-03-2025 )
            </label>
          </FloatLabel>
          <FloatLabel>
            <InputText
              className="w-100"
              name="note"
              value={dataProxy.note}
              onChange={handleChange}
            />
            <label htmlFor="note">Ghi chú</label>
          </FloatLabel>
        </div>
        <div className="w-100 mt-2 d-flex justify-content-end">
          <Button onClick={handleCreateProxy} className="button-blue ">
            Tạo
          </Button>
          <Button className="button-red ">Huỷ</Button>
        </div>
      </Dialog>
    </div>
  );
};
