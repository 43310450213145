import { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Sidebar } from "primereact/sidebar";
import { truncateLabel } from "../../../../../const/splittext";
import { removeItem } from "../../../../../const/splittext";
import { API_URL, API_ROUTES } from "../../../../../api";
import { useHeaders } from "../../../../../const/headers";
import { Image } from "primereact/image";
import { toast } from "react-toastify";
import { formatText } from "../../../../../const/splittext";
import { TiDelete } from "react-icons/ti";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { deleteFileOnServer } from "../../../../../const/deleteFileOnServer";
import { IoInformationCircle } from "react-icons/io5";
import axios from "axios";
export const DataEditScriptArticlePost = ({
  listImage,
  setListImage,
  valueInbox,
  setValueInbox,
  inbox,
  setInbox,
  inboxSpilt,
  setInboxSplit,
}) => {
  const headers = useHeaders();
  const inputRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const apiGetLink = API_URL + API_ROUTES.GET_LINK_FILE;
  const [imageURLs, setImageURLs] = useState([]);
  const [listFile, setListFile] = useState([]);
  const showFileInput = () => {
    document.getElementById(`fileInput`).click();
  };
  const handleGetImg = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await axios.post(apiGetLink, formData, headers);
      setListImage((prevURLs) => [...prevURLs, res.data.file]);
    } catch (err) {}
  };
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    for (let i = 0; i < files.length; i++) {
      handleGetImg(files[i]);
    }
    setListFile(files);
    const newImageURLs = files.map((file) => URL.createObjectURL(file));
    setImageURLs((prevURLs) => [...prevURLs, ...newImageURLs]);
  };
  const handleRemoveImage = (index) => {
    const updatedURLs = [...imageURLs];
    const updatedFile = [...listFile];
    const updatedImage = [...listImage];
    deleteFileOnServer(listImage[index]);
    updatedURLs.splice(index, 1);
    updatedFile.splice(index, 1);
    updatedImage.splice(index, 1);
    setImageURLs(updatedURLs);
    setListFile(updatedFile);
    setListImage(updatedImage);
  };
  const handleKeyCmt = (e) => {
    if (e.type === "keydown") {
      return;
    }
    e.preventDefault();
    if (valueInbox?.trim() !== "") {
      setInbox((prevComments) => [...prevComments, valueInbox?.trim()]);
      setValueInbox("");
    }
  };
  useEffect(() => {
    if (inboxSpilt?.length > 0) {
      inboxSpilt?.map((item, index) => setInbox([...inbox, item]));
    }
  }, [inboxSpilt]);
  const copyContent = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Sao chép thành công");
      })
      .catch(() => {
        toast.error("Sao chép thất bại");
      });
  };
  return (
    <div className="mt-2 d-flex gap-2 justify-content-between w-100">
      <div className="d-flex flex-column" style={{ width: "50%" }}>
        <nav className="d-flex align-items-center gap-2">
          <p className="mb-0">Nội dung</p>
          <OverlayTrigger
            key="tooltip"
            placement="top"
            overlay={
              <Tooltip id="tooltip-right" className="custom-tooltip">
                <div className="text-start">...</div>
              </Tooltip>
            }
          >
            <span
              style={{
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              <IoInformationCircle size={15} />
            </span>
          </OverlayTrigger>
          <Button
            className="button-blue radius"
            style={{ padding: "6px 8px" }}
            onClick={handleKeyCmt}
          >
            Thêm nội dung
          </Button>
        </nav>
        <div className="p-inputgroup flex-1 mt-2">
          <InputTextarea
            value={valueInbox}
            onChange={(e) => setValueInbox(e.target.value)}
            onKeyDown={handleKeyCmt}
            placeholder={``}
            rows={5}
            cols={30}
            ref={inputRef}
          />
        </div>
        <div className="d-flex gap-2 flex-wrap w-100 mt-2">
          {inbox?.map((item, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <Button
                className="button-blue radius"
                style={{ padding: "6px 8px", fontSize: "12px" }}
                label={truncateLabel(item)}
                onClick={() => setActiveIndex(index)}
              />{" "}
              <Sidebar
                visible={activeIndex === index}
                onHide={() => setActiveIndex(null)}
              >
                <Button
                  className="button-blue radius"
                  label="Copy"
                  onClick={() => copyContent(item)}
                  style={{ marginBottom: "10px" }}
                />
                {item.includes("[ngatcau]") ? (
                  <ul>
                    {item.split("[ngatcau]").map((item, index) => (
                      <li
                        style={{
                          backgroundColor: "blue",
                          color: "white",
                          padding: "10px",
                          margin: "4px",
                          borderRadius: "10px",
                        }}
                        key={index}
                      >
                        {formatText(item)}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p
                    style={{
                      backgroundColor: "blue",
                      color: "white",
                      padding: "10px",
                      margin: "4px",
                      borderRadius: "10px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item?.replace(/\n/g, "<br>"),
                    }}
                  />
                )}
              </Sidebar>
              <TiDelete
                className="p-button-rounded p-button-danger p-button-sm "
                style={{
                  position: "absolute",
                  top: "-8px",
                  right: "-8px",
                  width: "25px",
                  height: "25px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() => {
                  removeItem(index, setInbox);
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div style={{ width: "50%" }}>
        <nav className="d-flex align-items-center gap-2">
          <p className="mb-0">Danh sách ảnh</p>
          <Button
            className="button-blue radius"
            style={{ padding: "6px 8px" }}
            onClick={showFileInput}
          >
            Thêm ảnh
          </Button>
        </nav>
        <div className="flex flex-wrap">
          {listImage?.map((url, index) => (
            <div key={index} className="position-relative m-2">
              <Image
                width="50"
                height="50"
                src={`${API_URL}/${url}`}
                alt={`Selected ${index}`}
                preview
              />
              <button
                className="btn-remove"
                style={{ position: "absolute", top: "-6px", right: "-8px" }}
                onClick={() => handleRemoveImage(index)}
              >
                <TiDelete size={15} />
              </button>
            </div>
          ))}
        </div>
        <input
          id={`fileInput`}
          type="file"
          multiple
          style={{ display: "none" }}
          onChange={(e) => handleImageChange(e)}
        />
      </div>
    </div>
  );
};
