import { useState, useEffect } from "react";
import { CiSettings } from "react-icons/ci";
import { IoMdAddCircle } from "react-icons/io";
import { CiShop } from "react-icons/ci";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { ModalSettingCoverShop } from "./ModalSettingCoverShop";
import { useNavigate } from "react-router-dom";
import { FaDeleteLeft } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { Tooltip } from "primereact/tooltip";

export const CategoryShopInAccount = ({
  listCateShop,
  iduser,
  setRfShop,
  rfShop,
  idCate,
  setIdCate,
  itemCover,
  setItemCover,
  idCategory,
  toast,
}) => {
  const navigate = useNavigate();
  const headers = useHeaders();
  const apiCreateEdit = API_URL + API_ROUTES_ZALO.CREATE_UPDATE_CATEGORY_SHOP;
  const apiDelete = API_URL + API_ROUTES_ZALO.DELETE_CATEGORY_MINISHOP;
  const apiActive = API_URL + API_ROUTES_ZALO.ACTIVE_CATEGORY_MINISHOP;
  const apiCoverShop = API_URL + API_ROUTES_ZALO.GET_COVER_SHOP;

  const [openSetting, setOpenSetting] = useState(false);
  const handleAddEndCategory = async (item) => {
    const name = window.prompt("Nhập tên danh mục mới");
    if (name) {
      const body = item?.id
        ? {
            id_user: iduser,
            id_category: item?.id,
            name: name,
          }
        : {
            id_user: iduser,
            name: name,
          };
      try {
        await axios.post(apiCreateEdit, body, headers);
        if (itemCover.user !== Number(iduser)) {
          toast.success(
            item?.id
              ? "Đã sửa tên danh mục , Vui lòng đợi quản lý duyệt"
              : "Đã tạo danh mục , Vui lòng đợi quản lý duyệt"
          );
        }
        setRfShop(!rfShop);
      } catch (err) {}
    }
  };
  const handleDeleteCate = async (item) => {
    const confirm = window.confirm(`Xoá ${item.name}`);
    if (confirm) {
      const body = {
        id_user: iduser,
        id_category: item?.id,
      };
      try {
        await axios.post(apiDelete, body, headers);
        setRfShop(!rfShop);
      } catch (err) {}
    }
  };
  const handleActiveCate = async (item) => {
    const body = {
      id_category: item?.id,
    };
    try {
      await axios.post(apiActive, body, headers);
      setRfShop(!rfShop);
    } catch (err) {}
  };
  const getCoverShop = async () => {
    try {
      const res = await axios.get(`${apiCoverShop}?id_employee=${iduser}`);
      setItemCover(res.data);
    } catch (err) {}
  };
  useEffect(() => {
    getCoverShop();
  }, []);
  return (
    <div className="d-flex flex-column category-minishop">
      <div
        className="d-flex btn-category-minishop"
        style={{
          width: "350px",
          borderBottom: "2px solid",
          borderRight: "2px solid",
        }}
      >
        <nav
          className="nav-item-shop"
          onClick={() => handleAddEndCategory("no")}
        >
          <IoMdAddCircle size={40} />
          <p>Thêm danh mục</p>
        </nav>
        {itemCover.user === Number(iduser) && (
          <nav className="nav-item-shop" onClick={() => setOpenSetting(true)}>
            <CiSettings size={40} />
            <p>Thiết lập shop</p>
          </nav>
        )}
      </div>
      <div
        className="h-100 p-2 d-flex flex-column gap-2 overflow-auto"
        style={{ overflowX: "hidden", borderRight: "2px solid" }}
      >
        {listCateShop?.map((item, index) => (
          <nav
            className="d-flex align-items-center nav-item-shop position-relative"
            style={{
              width: "100%",
              background:
                idCategory == item.id
                  ? "linear-gradient(55deg, rgb(61 65 62), rgba(40, 96, 129, 0.23))"
                  : "linear-gradient(55deg, #5486cd,#2860813b)",
              opacity: item.status === 0 ? "0.5" : "",
              pointerEvents: item.status === 0 ? "none" : "",
            }}
            onClick={() => {
              navigate(`/minishop/${iduser}/${item.id}`);
              setIdCate(!idCate);
            }}
          >
            {item?.avt ? (
              <img
                src={`${API_URL}${item.avt}`}
                style={{ width: "40px", height: "40px", borderRadius: "12px" }}
              />
            ) : (
              <CiShop size={40} />
            )}
            <p
              className="text-over"
              style={{ fontWeight: "bold", width: "60%" }}
            >
              {item.name}
            </p>
            <nav
              className="gap-2 list-option-minishop"
              style={{ position: "absolute", right: "4px" }}
            >
              {item.status === 0 && iduser === item.user && (
                <>
                  <FaEye
                    id={`tooltip-icon-${item.id}`}
                    onClick={() => handleActiveCate(item)}
                    size={20}
                    cursor="pointer"
                    style={{ opacity: "1", pointerEvents: "all" }}
                  />
                  <Tooltip
                    target={`#tooltip-icon-${item.id}`}
                    content={`Xác nhận danh mục do nhân viên ${item.creator_name} tạo`}
                    position="bottom"
                    mouseTrack
                    mouseTrackTop={15}
                  />
                </>
              )}
              <>
                <FaEdit
                  id={`tooltip-icon-edit-${item.id}`}
                  onClick={() => handleAddEndCategory(item)}
                  size={20}
                  cursor="pointer"
                  style={{ opacity: "1", pointerEvents: "all" }}
                />
                <Tooltip
                  target={`#tooltip-icon-edit-${item.id}`}
                  content={`Chỉnh sửa danh mục`}
                  position="bottom"
                  mouseTrack
                  mouseTrackTop={15}
                />
              </>
              {Number(iduser) === item.user && (
                <>
                  <FaDeleteLeft
                    id={`tooltip-icon-delete-${item.id}`}
                    color="red"
                    onClick={() => handleDeleteCate(item)}
                    cursor="pointer"
                    size={20}
                    style={{ opacity: "1", pointerEvents: "all" }}
                  />
                  <Tooltip
                    target={`#tooltip-icon-delete-${item.id}`}
                    content={` Xoá danh mục`}
                    position="bottom"
                    mouseTrack
                    mouseTrackTop={15}
                  />
                </>
              )}
            </nav>
          </nav>
        ))}
      </div>
      <ModalSettingCoverShop
        itemCover={itemCover}
        toast={toast}
        setItemCover={setItemCover}
        iduser={iduser}
        openSetting={openSetting}
        getCoverShop={getCoverShop}
        setOpenSetting={setOpenSetting}
      />
    </div>
  );
};
