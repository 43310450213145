import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useAuth } from "../../../AuthContext";
import { useUser } from "../../../contexts/UserContext";
import { API_URL, API_ROUTES } from "../../../api";
import axios from "axios";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import filePdf from "./dksd.pdf";
import { pdfjs } from "react-pdf";
export default function AcceptTerms() {
  const {
    userInfo,
    termData,
    modalAcceptTerms,
    setModalAcceptTerms,
    setUserInfo,
  } = useUser();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const { userLoggedIn } = useAuth();
  const getAcceptColse = API_URL + API_ROUTES.GET_ACCEPT_TERM;
  const getInfor = API_URL + API_ROUTES.GET_INFO_UERS;
  const rfLocal = localStorage.getItem("access");
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(getInfor, {
        headers: {
          Authorization: `Bearer ${rfLocal}`,
        },
      });
      setUserInfo(response.data);
    } catch (err) {}
  };
  const clickAccept = async () => {
    try {
      await axios.get(getAcceptColse, {
        headers: {
          Authorization: `Bearer ${rfLocal}`,
        },
      });
      fetchData();
    } catch (err) {}
  };
  useEffect(() => {
    if (userInfo?.accept_terms === false) {
      setModalAcceptTerms(true);
    } else {
      setModalAcceptTerms(false);
    }
  }, [userInfo, userLoggedIn]);
  return (
    <div className="flex justify-content-center">
      <Dialog
        header=""
        visible={false}
        style={{ width: "50vw" }}
        headerStyle={{ paddingTop: "24px" }}
        onHide={() => setModalAcceptTerms(false)}
        closable={userInfo?.is_admin === true ? true : false}
        baseZIndex={2000}
      >
        <div className="d-flex flex-column gap-2 w-100 ">
          {/* <div
            className="d-flex align-items-center gap-2"
            style={{ width: "100%" }}>
            <p
              style={{ wordWrap: "break-word" }}
              className="m-0"
              dangerouslySetInnerHTML={{ __html: termData?.content }}
            />
          </div> */}
          <div style={{ height: "100%", width: "100%" }}>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={filePdf}
                plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          </div>
          <div className="d-flex flex-column align-items-end mt-2 gap-2 w-100">
            <input
              checked={isChecked}
              onChange={handleCheckboxChange}
              type="checkbox"
              style={{ height: "30px", width: "30px" }}
            />
            <Button
              style={{
                display: isChecked ? "block" : "none",
                background: "#054ddb",
                border: "#054ddb",
                borderRadius: "5px",
              }}
              label="Tôi đã đọc và đồng ý điều khoản này"
              onClick={() => clickAccept()}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
