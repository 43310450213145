import React from "react";
import { useEffect } from "react";
import iconzl from "./zalologo.webp";
import { Image } from "primereact/image";
import { FaReply } from "react-icons/fa";
import { FaShareAlt } from "react-icons/fa";
import { convertTimestamp } from "../../../const/convertDate";
import { truncateMarket } from "../../../const/splittext";
export const InforConversation = ({
  messenger,
  itemFriend,
  itemAccout,
  setScrollTop,
  resetScroll,
  setTypeQuote,
  setOpenShare,
  setItemShare,
  setMessCard,
  setModalSendStranger,
  setForcusAvt,
  setItemForcusAvt,
  typeMess,
}) => {
  const itemMember = JSON.parse(localStorage.getItem("itemMember"));
  const detailsMess = messenger.results;
  const chatContainer = document.querySelector(".chat-containers");
  function scrollToBottom() {
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }
  useEffect(() => {
    scrollToBottom();
  }, [itemFriend, resetScroll]);
  chatContainer?.addEventListener("scroll", function () {
    if (chatContainer.scrollTop === 0) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  });
  function isValidURL(string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }
  const formatTextWithLineBreaks = (text) => {
    // Thay thế ký tự xuống dòng bằng thẻ <br />
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div
      className="d-flex mb-2 flex-column h-100 w-100 gap-2 chat-containers pr-2 pl-2"
      style={{ overflow: "auto" }}
    >
      {detailsMess
        ?.sort((a, b) => parseInt(a.ts, 10) - parseInt(b.ts, 10))
        ?.filter(
          (item) =>
            !item?.text_message[0]?.text?.includes(
              "Chỉ trưởng/phó nhóm được gửi tin nhắn vào nhóm, bạn có quyền xem và thả cảm"
            )
        )
        ?.map((item, index) => (
          <div
            className={`${
              item.uidFrom === "0" ? "mess-to-me" : "mess-to-fr"
            } client-mess`}
            key={index}
          >
            <img
              onClick={() => {
                if (item.uidFrom !== "0" && typeMess?.group?.account) {
                  setItemForcusAvt(item);
                  setForcusAvt(true);
                }
              }}
              src={
                itemFriend?.id &&
                (itemFriend?.group
                  ? item.uidFrom === "0"
                    ? itemAccout[0]?.avatar
                    : itemMember?.filter((i) => i.uid === item.uidFrom)[0]?.avt
                  : item.uidFrom === "0"
                  ? itemAccout[0]?.avatar
                  : itemFriend?.friend?.avatar)
              }
              alt=""
              className="account-avatar cursor-pointer"
            />
            <nav
              style={{
                position: "relative",
                maxWidth: "50%",
                boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
              }}
            >
              <p
                className="right-mess"
                style={{ width: "max-content", background: "#d5d8d9" }}
              >
                {itemFriend?.id &&
                  (itemFriend?.group
                    ? item.uidFrom === "0"
                      ? itemAccout[0]?.name
                      : itemMember?.filter((i) => i.uid === item.uidFrom)[0]
                          ?.name
                    : item.uidFrom === "0"
                    ? itemAccout[0]?.name
                    : itemFriend?.friend?.name)}
              </p>
              <div
                className="message-wrapper"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {item?.quote.length > 0 && (
                  <div>
                    {itemFriend?.friend ? (
                      <div className="mess-quote">
                        <p>
                          {item.quote[0]?.ownerId === itemAccout[0]?.uid
                            ? itemAccout[0]?.name
                            : itemFriend?.dName}
                        </p>
                        <p>{truncateMarket(item.quote[0]?.msg, 50)}</p>
                      </div>
                    ) : (
                      <div className="mess-quote">
                        <p>
                          {
                            itemMember?.filter(
                              (i) => i.uid === item?.quote[0]?.ownerId
                            )[0]?.name
                          }
                        </p>
                        <p>{truncateMarket(item.quote[0]?.msg, 50)}</p>
                      </div>
                    )}
                  </div>
                )}
                {item?.text_message?.length > 0 &&
                !item?.text_message[0]?.text?.includes(
                  "Chỉ trưởng/phó nhóm được gửi tin nhắn vào nhóm, bạn có quyền xem và thả cảm"
                ) ? (
                  <p className="message-content">
                    {isValidURL(item?.text_message[0]?.text) ? (
                      <a
                        href={item?.text_message[0]?.text}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item?.text_message[0]?.text}
                      </a>
                    ) : (
                      formatTextWithLineBreaks(item?.text_message[0]?.text)
                    )}
                  </p>
                ) : item?.attachments[0]?.action === "recommened.link" ? (
                  <p className="message-content messenger-attackment">
                    {isValidURL(item?.attachments[0]?.href) ? (
                      <a
                        href={item?.attachments[0]?.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item?.attachments[0]?.href}
                      </a>
                    ) : (
                      item?.attachments[0]?.href
                    )}
                    <p>{item?.attachments[0]?.description}</p>
                    <Image
                      className="message-content"
                      src={item?.attachments[0]?.thumb}
                      alt="Image"
                      width="auto"
                      height="200"
                    />
                  </p>
                ) : item.msgType === "share.file" ? (
                  <a target="blank" href={item?.attachments[0]?.href}>
                    {item?.attachments[0]?.title}
                  </a>
                ) : item.msgType === "chat.sticker" ? (
                  <Image
                    className="message-content"
                    src={`https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=${item.sticker[0]?.id_sticker}&size=130`}
                    alt="Image"
                  />
                ) : item.msgType === "chat.recommended" &&
                  item.attachments[0]?.action === "recommened.user" ? (
                  <div className="border p-2 w-100 d-flex flex-column gap-2">
                    <div className="w-100 d-flex gap-4">
                      <nav className="w-100 d-flex flex-column justify-content-between align-items-center">
                        <img
                          className="message-content"
                          src={item?.attachments[0]?.thumb}
                          alt="Image"
                          width="40px"
                          height="40px"
                          style={{ borderRadius: "50%" }}
                        />
                        <p
                          style={{
                            width: "max-content",
                            padding: "0",
                            fontWeight: "600",
                          }}
                        >
                          {item?.attachments[0]?.title}
                        </p>
                        {item?.attachments[0]?.description && (
                          <p
                            style={{
                              width: "max-content",
                              padding: "0",
                            }}
                          >
                            {
                              JSON.parse(item?.attachments[0]?.description)
                                ?.phone
                            }
                          </p>
                        )}
                      </nav>
                      <nav className="d-flex flex-column align-items-center">
                        <p style={{ width: "max-content" }}>Danh thiếp Zalo</p>
                        {item?.attachments[0]?.description && (
                          <Image
                            className="message-content"
                            src={
                              JSON.parse(item?.attachments[0]?.description)
                                .qrCodeUrl
                            }
                            alt="Image"
                            width="80px"
                            height="80px"
                          />
                        )}
                      </nav>
                    </div>
                    <div
                      style={{ borderTop: "1px solid" }}
                      className="d-flex justify-content-around"
                    >
                      <nav
                        style={{
                          borderRight: "1px solid",
                          width: "50%",
                          textAlign: "center",
                        }}
                      >
                        <p
                          className="w-100 text-center"
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Kết bạn
                        </p>
                      </nav>
                      <nav
                        onClick={() => {
                          setMessCard(item);
                          setModalSendStranger(true);
                        }}
                        style={{ width: "50%" }}
                      >
                        <p
                          className="w-100 text-center"
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#0f5fb5",
                          }}
                        >
                          Nhắn tin
                        </p>
                      </nav>
                    </div>
                  </div>
                ) : item?.attachments?.length > 0 ? (
                  item.msgType === "chat.photo" ? (
                    <>
                      <Image
                        className="message-content"
                        src={item?.attachments[0]?.thumb}
                        alt="Image"
                        width="300px"
                        height="100px"
                        preview
                      />
                      <p>{item?.attachments[0]?.title}</p>
                    </>
                  ) : (
                    <video
                      className="message-content"
                      style={{
                        borderRadius: "12px",
                        width: "200px",
                      }}
                      controls
                    >
                      <source
                        src={item?.attachments[0]?.href}
                        type="video/mp4"
                      />
                    </video>
                  )
                ) : (
                  ""
                )}
              </div>
              <div className="message-time">
                <p style={{ background: "transparent", fontWeight: "300" }}>
                  {convertTimestamp(item.ts)}
                </p>
                <FaReply
                  onClick={() => setTypeQuote(item)}
                  size={20}
                  cursor="pointer"
                />
                <FaShareAlt
                  onClick={() => {
                    setItemShare(item);
                    setOpenShare(true);
                  }}
                  size={20}
                  cursor="pointer"
                />
              </div>
            </nav>
          </div>
        ))}
    </div>
  );
};
