import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { IoInformationCircle } from "react-icons/io5";
import { RiExpandDiagonal2Line } from "react-icons/ri";
import { API_ROUTES_NTD, API_ROUTES_ZALO, API_URL } from "../../../../api";
import { useHeaders } from "../../../../const/headers";
import { InputTextarea } from "primereact/inputtextarea";
import { ContentCreateMessPhone } from "./create/ContentCreateMessPhone";
import { divide } from "lodash";
export const CreateScriptMessPhone = ({
  props,
  openAddScript,
  setOpenAddScript,
  setRefresh,
  refresh,
}) => {
  const headers = useHeaders();
  const createCateArticlePost =
    API_URL + API_ROUTES_ZALO.MESS_TO_PHONE.CREATE_OR_EDIT;
  const [listImage, setListImage] = useState([]);
  const [name, setName] = useState("");
  const [valueInbox, setValueInbox] = useState("");
  const [inbox, setInbox] = useState([]);
  const [inboxSpilt, setInboxSplit] = useState([]);
  const [delayTime, setDelayTime] = useState(60);
  const [numberCount, setNumberCount] = useState(0);
  const [waitTime, setWaitTime] = useState(0);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const addCateMkt = async () => {
    if (!name) {
      alert("Vui lòng nhập đầy đủ thông tin");
    } else if (!inbox && listImage?.length === 0) {
      alert("Vui lòng thêm nội dung hoặc ảnh");
    } else if (phoneNumbers?.length === 0) {
      alert("Vui lòng nhập ít nhất 1 số điện thoại muốn nhắn tin");
    } else {
      const body = {
        id_category: null,
        name: name,
        phone_numbers: phoneNumbers?.split("\n")?.filter((i) => i !== ""),
        contents: inbox,
        images: listImage,
        delay_time: delayTime,
        wait_time: waitTime,
        number_count: numberCount,
        divide: isChecked,
      };
      try {
        const res = await axios.post(createCateArticlePost, body, headers);
        setName("");
        setListImage([]);
        setInbox([]);
        setOpenAddScript(!openAddScript);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  return (
    <Dialog
      header="Kịch bản nhắn tin vào nhóm"
      visible={openAddScript}
      style={{ position: "relative", width: "70vw" }}
      onHide={() => setOpenAddScript(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button className="button-blue radius" onClick={addCateMkt}>
            Lưu kịch bản
          </Button>
          <Button
            className="button-red radius"
            onClick={() => setOpenAddScript(false)}
          >
            Thoát không lưu
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon " style={{ width: "9rem" }}>
            Tên kịch bản
          </span>
          <InputText
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nhập tên kịch bản"
          />
        </div>
        <div className="p-inputgroup flex-1 w-100">
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Thời gian chờ (Giây)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Thời gian chờ giữa mỗi hành động trong một chuỗi nhiều
                      hành động
                      <br />- Ví dụ: Bạn tích chọn 3 chức năng "Gửi tin nhắn +
                      Bình luận + Like bài viết". Thì đây là một chuỗi 3 hành
                      động liên tiếp. Bạn nên để thời gian chờ tối thiểu là 60s
                      và khuyến khích là 180s để an toàn cho tài khoản. Hành
                      động quá nhanh sẽ dễ bị hạn chế tính năng trên tài khoản
                      do cơ chế chống spam của Facebook
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>

            <InputNumber
              style={{ width: "100%" }}
              value={delayTime}
              placeholder="Thời gian tính bằng giây (s)"
              onValueChange={(e) => setDelayTime(e.value)}
              useGrouping={false}
              min={60}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Số lượt</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Tài khoản đi tương tác với mỗi một khách hàng được tính là
                      1 lượt. Nên để số lượt là 1. Và cài đặt thêm thời gian chờ
                      lặp lại ở ô bên cạnh
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={numberCount}
              placeholder=""
              onValueChange={(e) => setNumberCount(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span style={{ width: "" }}>Thời gian chờ lặp lại (Giờ)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Chức năng này có tác dụng khi bạn cài đặt số lượt (số lượt
                      được cài đặt ở ô bên cạnh) Chức năng này giúp chạy tiếp
                      chiến dịch hoàn toàn tự động khi bạn đặt số lượt ở ô bên
                      cạnh. Nên để thời gian là 1 giờ
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputNumber
              style={{ width: "100%" }}
              value={waitTime}
              placeholder="Thời gian tính bằng giờ (h)"
              onValueChange={(e) => setWaitTime(e.value)}
              useGrouping={false}
              min={1}
            />
          </div>
        </div>
        <div className="d-flex gap-4">
          <nav className="mt-2" style={{ width: "50%" }}>
            <div className="d-flex">
              <p className="mb-2 mr-2">Số điện thoại muốn nhắn tin</p>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">...</div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </div>
            <InputTextarea
              style={{ width: "100%", height: "80%" }}
              value={phoneNumbers}
              onChange={(e) => setPhoneNumbers(e.target.value)}
              placeholder=""
              rows={5}
              cols={30}
            />
          </nav>
          <div style={{ marginTop: "30px" }}>
            <label className="d-flex gap-2">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                style={{ width: "20px", height: "auto" }}
              />
              Chia
            </label>
          </div>
        </div>
        <ContentCreateMessPhone
          listImage={listImage}
          setListImage={setListImage}
          valueInbox={valueInbox}
          setInbox={setInbox}
          inbox={inbox}
          setValueInbox={setValueInbox}
          inboxSpilt={inboxSpilt}
          setInboxSplit={setInboxSplit}
        />
      </div>
    </Dialog>
  );
};
