import React, { useEffect } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES, API_ROUTES_NTD } from "../../api";
import { Footer } from "./Footer";
import { Button } from "primereact/button";
import { useHeaders } from "../../const/headers";
import { useUser } from "../../contexts/UserContext";
import { updateTitle } from "../../const/updateTitle";
import { useAuth } from "../../AuthContext";
import { getAxios } from "../../const/getAxios";
import { locale } from "primereact/api";
import { useNavigate } from "react-router-dom";
import { ListAccMarketplace } from "../container/marketplace/ListAccMarketplace";
import { ListCateMarketplace } from "../container/marketplace/ListCateMarketplace";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoInformationCircle } from "react-icons/io5";
export const ManageMarketplace = () => {
  locale("vi");
  const headers = useHeaders();
  updateTitle("Chốt Care - Marketplace");
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const getAllCateMkt = API_URL + API_ROUTES_NTD.GET_CATEGORY_MARKETPLACE;
  const addCateInMkt = API_URL + API_ROUTES_NTD.ADD_CATE_ACC_MARKETPLACE;
  const startMarketing = API_URL + API_ROUTES_NTD.START_MARKETPLACE;
  const apiResultsStart = API_URL + API_ROUTES_NTD.RESULTS_START_MARKETPLACE;
  const copyCate = API_URL + API_ROUTES_NTD.COPY_CATEGORY_MARKETPLACE;
  const changeMail = API_URL + API_ROUTES.EDIT_MAIL_ORDER;
  const navigate = useNavigate();
  const { userInfo } = useUser();
  const { setUserLoggedIn } = useAuth();
  const [dataFb, setDataFb] = useState();
  const [refresh, setRefresh] = useState(true);
  const [allCate, setAllCate] = useState([]);
  const [listAccMkt, setListAccMkt] = useState([]);
  const [listMktCate, setListMktCate] = useState([]);
  const [selecCate, setSelecCate] = useState({});
  const [idTask, setIdTask] = useState([]);
  const [status, setStatus] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [loading, setLoading] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const [value, setValue] = useState("");
  const [mailPresent, setMailPresent] = useState(userInfo.mail_order);
  const [isEmailValid, setIsEmailValid] = useState(true);
  useEffect(() => {
    axios
      .get(getDataFb, headers)
      .then(function (response) {
        setDataFb(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          setUserLoggedIn(false);
        }
      });
  }, [refresh, loading]);
  useEffect(() => {
    getAxios(getAllCateMkt, setAllCate);
  }, [refresh]);
  const addCateInAcc = async () => {
    if (listMktCate?.length === 1 && listAccMkt?.length > 0) {
      const body = {
        id_category: listMktCate[0].id,
        id_accounts: listAccMkt?.map((item) => item.id),
      };
      try {
        const res = await axios.post(addCateInMkt, body, headers);
        toast.success("Thêm kịch bản thành công");
        setListMktCate([]);
        setListAccMkt([]);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setValue(email);
    setIsEmailValid(validateEmail(email));
  };
  const handleChangeMail = async (e) => {
    e.preventDefault();
    if (!isEmailValid) {
      toast.error("Định dạng mail không đúng");
      return;
    }
    const body = {
      mail_order: value,
    };
    try {
      const res = await axios.post(changeMail, body, headers);
      toast.success("Email đã được cập nhật");
      setMailPresent(value);
      setValue("");
    } catch (err) {
      toast.error("Có lỗi xảy ra khi cập nhật email");
    }
  };
  useEffect(() => {
    addCateInAcc();
  }, [listMktCate]);
  const startMarketingCate = async (type) => {
    if (!selecCate.id) {
      return alert("Chọn 1 kịch bản để chạy ");
    } else if (listAccMkt?.length === 0) {
      return alert("Chọn ít nhất 1 tài khoản");
    } else {
      const body = {
        id_category: selecCate.id,
        id_accounts: listAccMkt?.map((item) => item.id),
      };
      try {
        const res = await axios.post(startMarketing, body, headers);
        toast.success("Đang đăng bài");
        setIdTask(res.data.id_task);
        setLoading(true);
        setListAccMkt([]);
      } catch (err) {
        toast.error(err.response.data.message);
      }
    }
  };
  const getResultsTask = async () => {
    const body = {
      id_task: idTask,
    };
    try {
      const res = await axios.post(apiResultsStart, body, headers);
      if (res.data.status === "SUCCESS") {
        setLoading(false);
        toast.success("Đăng thành công");
      }
      setStatus(res.data.status);
    } catch (err) {
      setStatus(err.response.data.status);
      setLoading(false);
      toast.error("Đăng thất bại");
    }
  };
  const handleCopyCate = async () => {
    if (!selecCate.id) {
      return alert("Vui lòng chọn sản phẩm muốn sao chép");
    } else {
      const nameCate = window.prompt("Nhập tiêu đề sản phẩm mới");
      if (nameCate) {
        const body = {
          id_category: selecCate.id,
          title: nameCate,
        };
        try {
          await axios.post(copyCate, body, headers);
          getAxios(getAllCateMkt, setAllCate);
          toast.success("Sao chép thành công");
        } catch (err) {
          toast.error("Sao chép thất bại");
        }
      }
    }
  };
  useEffect(() => {
    if (idTask.length > 0) {
      const interval = setInterval(() => {
        getResultsTask(idTask);
      }, 3000);
      if (status === "SUCCESS" || status === "FAILURE") {
        clearInterval(interval);
        setRefresh(!refresh);
      }
      return () => clearInterval(interval);
    }
  }, [status, idTask]);
  const widthNavi = document.getElementById("width-navigate")?.offsetWidth;
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container" id="width-navigate">
          <Navigate />
        </div>
        <div
          className="d-flex h-100 "
          style={{ width: `calc(100vw - ${widthNavi}px)` }}
        >
          <div
            className="overflow-auto h-100 d-flex flex-column"
            style={{
              width: "100%",
              borderRight: "0.5px solid",
              borderLeft: "0.5px solid",
            }}
          >
            <div
              className="d-flex gap-2 w-100 justify-content-end pt-2 pl-2 pr-2"
              id="lisstSeclect"
            >
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Đây là Email nhận thông báo khi có đơn hàng mới của bạn
                      trên Market từ phần mềm Chốt Sale.
                      <br />
                      - Vui lòng di chuyển Email của chúng tôi ra khỏi hòm thư
                      spam hoặc hộp thư rác để nhận thông báo đơn hàng nhanh
                      nhất.
                      <br />- Lưu ý : Bạn có thể thay đổi Email này.
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
              <Button
                className=" button-blue"
                onClick={() => setOpenMail(true)}
              >
                Email
              </Button>

              <InputText
                style={{
                  width: "200px",
                  borderRadius: "0",
                  textAlign: "center",
                }}
                placeholder="Tìm kiếm sản phẩm"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
              />
              <Button
                style={{
                  width: "200px",
                }}
                onClick={() => navigate("/statisticsmarket")}
                className="d-flex justify-content-center button-blue"
              >
                Thống kê
              </Button>
              {/* <Button
                style={{
                  width: "200px",
                }}
                onClick={() => navigate("/addmarket")}
                className="d-flex justify-content-center button-blue"
              >
                Tạo sản phẩm mới
              </Button> */}
              <Button
                style={{
                  width: "200px",
                }}
                onClick={() => navigate("/createmarket")}
                className="d-flex justify-content-center button-blue"
              >
                Tạo sản phẩm mới
              </Button>
              <Button
                style={{
                  width: "150px",
                }}
                onClick={() => navigate(`/listmarket/list`)}
                className="button-blue d-flex justify-content-center"
              >
                Sản phẩm đã niêm yết
              </Button>
              <Button
                style={{
                  width: "150px",
                }}
                onClick={handleCopyCate}
                className="button-blue d-flex justify-content-center"
              >
                Sao chép sản phẩm
              </Button>
              <Button
                style={{
                  width: "150px",
                }}
                onClick={() => navigate("/markettrend")}
                className="button-blue d-flex justify-content-center"
              >
                Tìm sản phẩm trend
              </Button>
              <Button
                onClick={() => startMarketingCate("new")}
                style={{
                  width: "200px",
                }}
                disabled={loading}
                className="d-flex justify-content-center button-blue mr-0"
              >
                {loading ? "Đang đăng bài..." : "Đăng bài"}
              </Button>
            </div>
            <div
              className="d-flex flex-column  gap-2 h-100 m-2"
              style={{ overflow: "hidden" }}
            >
              <div className="d-flex w gap-2" style={{ height: "100%" }}>
                <ListCateMarketplace
                  keywords={keywords}
                  selecCate={selecCate}
                  setSelecCate={setSelecCate}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  dataFb={dataFb}
                  listAccMkt={listAccMkt}
                  setListAccMkt={setListAccMkt}
                  allCate={allCate}
                  setAllCate={setAllCate}
                  toast={toast}
                />
                <ListAccMarketplace
                  loading={loading}
                  selecCate={selecCate}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  dataFb={dataFb}
                  listAccMkt={listAccMkt}
                  setListAccMkt={setListAccMkt}
                  allCate={allCate}
                  toast={toast}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header="Email nhận thông báo"
        visible={openMail}
        style={{ width: "20vw" }}
        onHide={() => {
          if (!openMail) return;
          setOpenMail(false);
        }}
      >
        <form onSubmit={handleChangeMail}>
          {" "}
          <p className="m-0 d-flex flex-column gap-3">
            <div>
              <h6>Email hiện tại của bạn</h6>
              <InputText
                placeholder="Nhập Email"
                value={mailPresent}
                onChange={handleEmailChange}
                style={{
                  width: "100%",
                }}
              ></InputText>
            </div>
            <div>
              <h6>Email mới</h6>
              <InputText
                placeholder="Nhập Email"
                value={value}
                onChange={handleEmailChange}
                style={{
                  width: "100%",
                }}
              ></InputText>
            </div>
            <div className="d-flex justify-content-end">
              <Button type="submit" className=" button-blue">
                Thay đổi
              </Button>
            </div>
          </p>
        </form>
      </Dialog>
      <Footer />
    </div>
  );
};
