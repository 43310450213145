import React, { memo, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { ManageAccount } from "./components/layout/ManageAccount";
import { ManagerProducts } from "./components/layout/product/ManagerProducts";
import OderManager from "./components/layout/OderManager";
import { ManageEmplo } from "./components/layout/ManageEmplo";
import { SettingPage } from "./components/layout/SettingPage";
import ChatBotMess from "./components/minipage/ChatBotMess";
import Login from "./components/container/Login";
import { useAuth } from "./AuthContext";
import { useUser } from "./contexts/UserContext";
import { Sep } from "./components/layout/Sep";
import "./App.css";
import { useHeaders } from "./const/headers";
import { Resources } from "./components/layout/Resources";
import { API_URL, API_ROUTES } from "./api";
import Huongdan from "./components/layout/Huongdan";
import { Register } from "./components/layout/Registers";
import { AccountWaitting } from "./components/layout/AccountWaitting";
import axios from "axios";
import QuenMatKhau from "./components/QuenMatKhau";
import { Debug } from "./components/layout/Debug";
import { DebugManual } from "./components/layout/DebugManual";
import ManageArticle from "./components/layout/ManageArticle";
import Statistics from "./components/layout/Statistics";
import ToolsFb from "./components/layout/ToolsFb";
import { Marketplace } from "./components/layout/Marketplace";
import { useStatus } from "./contexts/StatusContext";
import { ManageMarketplace } from "./components/layout/ManageMarketplace";
import { EditMarketplace } from "./components/layout/EditMarketplace";
import { ListPostMarketplace } from "./components/marketplace/ListPostMarketplace";
import { ModalListPostMarket } from "./components/container/marketplace/ModalListPostMarket";
import StatisticsMarket from "./components/container/marketplace/StatisticsMarket";
import { MarketingFeils } from "./components/layout/MarketingFeils";
import { ManagerClientVipPro } from "./components/layout/ManagerClientVipPro";
import CreateMarket from "./components/container/marketplace/createMarket/CreateMarket";
import { MarketplaceVehicle } from "./components/layout/MarketplaceVehicle";
import { MarketplaceHouse } from "./components/layout/MarketplaceHouse";
import Redue from "./components/container/Redue";
import { ManagerProxy } from "./components/layout/ManagerProxy";
import { useLocation } from "react-router-dom";
import { HomeZalo } from "./components/layout/homezalo/HomeZalo";
import { MessZaloId } from "./components/layout/ChatZaloFile/MessZaloId";
import { ManageGroup } from "./components/layout/ManageGroup";
import { ShopLinkHome } from "./components/layout/homeshoplink/ShopLinkHome";
import { ManagerMiniShop } from "./components/layout/ManagerMiniShop";
import ManageDomain from "./components/layout/ManageDomain";
const NavigateMemo = memo(Navigate);
const AppContent = () => {
  const headers = useHeaders();
  const location = useLocation();
  const navigate = useNavigate();
  const rfLocal = localStorage.getItem("access");
  const getDataFb = API_URL + API_ROUTES.GET_ACCOUNT_MESS;
  const getInfor = API_URL + API_ROUTES.GET_INFO_UERS;
  const { userLoggedIn, setUserLoggedInState } = useAuth();
  const { bellSource, setListAccount } = useStatus();
  const { userInfo, setUserInfo, fetchDataLogo, fetchAlert, rfMe } = useUser();
  const pathMapping = {
    redue: "/redue",
    login: "/login",
    register: "/register",
    shoplinkhome: "/shoplinkhome",
    store: "/showproduct",
  };
  const pathKey = Object.keys(pathMapping).find((key) =>
    location?.pathname.includes(key)
  );
  const fetchData = async () => {
    try {
      const response = await axios.get(getInfor, {
        headers: {
          Authorization: `Bearer ${rfLocal}`,
        },
      });
      setUserInfo(response.data);
    } catch (err) {
      setUserLoggedInState(false);
      if (pathKey) {
      } else {
        if (!location?.pathname.includes("showproduct")) navigate("/");
      }
    }
  };
  useEffect(() => {
    fetchData();
    fetchDataLogo();
    fetchAlert();
  }, [rfMe]);
  const getListAccount = async () => {
    try {
      const res = await axios.get(getDataFb, headers);
      setListAccount(res.data);
    } catch (err) {}
  };
  useEffect(() => {
    getListAccount();
  }, [bellSource, rfMe]);
  return (
    <Routes>
      {userLoggedIn ? (
        <>
          <Route path="/" element={<HomeZalo />} />
          <Route path="/redue" element={<Redue />} />
          <Route path="/register" element={<NavigateMemo to="/account" />} />
          <Route
            path="/account"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <ManageAccount />
              ) : (
                <ManageAccount />
              )
            }
          />
          <Route
            path="/product"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <ManagerProducts />
              ) : (
                <ManagerProducts />
              )
            }
          />
          {window.location.hostname === "store.chotnhanh.vn" && (
            <Route
              path="*"
              element={<Navigate to="/showproduct/:idacc/:idcate/:idproduct" />}
            />
          )}
          <Route
            path="/client"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <ManagerClientVipPro />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/minishop"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <ManagerMiniShop />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/minishop/:id"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <ManagerMiniShop />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/minishop/:idAcc/:idCate"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <ManagerMiniShop />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/minishop/:idAcc/:idCate/create"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <ManagerMiniShop />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/minishop/:idAcc/:idCate/edit"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <ManagerMiniShop />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/proxy"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <ManagerProxy />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/domain"
            element={
              userInfo?.is_manager || userInfo?.is_admin  ? (
                <ManageDomain />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/marketing"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <MarketingFeils />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/market"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <ManageMarketplace />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/editmarket/:id"
            element={
              userInfo?.is_manager || userInfo?.is_admin ? (
                <EditMarketplace />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/listmarket/:id"
            element={
              userInfo?.is_manager || userInfo?.is_admin ? (
                <ListPostMarketplace />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/statisticsmarket"
            element={
              userInfo?.is_manager || userInfo?.is_admin ? (
                <StatisticsMarket />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/markettrend"
            element={
              userInfo?.is_manager || userInfo?.is_admin ? (
                <ModalListPostMarket />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/addmarket"
            element={
              userInfo?.is_manager || userInfo?.is_admin ? (
                <Marketplace />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/vehiclemarket"
            element={
              userInfo?.is_manager || userInfo?.is_admin ? (
                <MarketplaceVehicle />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/editvehicle/:id"
            element={
              userInfo?.is_manager || userInfo?.is_admin ? (
                <MarketplaceVehicle />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/housemarket"
            element={
              userInfo?.is_manager || userInfo?.is_admin ? (
                <MarketplaceHouse />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/edithouse/:id"
            element={
              userInfo?.is_manager || userInfo?.is_admin ? (
                <MarketplaceHouse />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/createmarket"
            element={
              userInfo?.is_manager || userInfo?.is_admin ? (
                <CreateMarket />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/group"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <ManageGroup />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/article"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <ManageArticle />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/toolsfb"
            element={
              userInfo?.is_manager || userInfo?.is_admin ? (
                <ToolsFb />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route path="/resource" element={<Resources />} />
          <Route path="/huongdan" element={<Huongdan />} />
          {/* <Route path="/mess" element={<MessZalo />} /> */}
          <Route path="/homechat" element={<MessZaloId />} />
          <Route path="/homechat/:id" element={<MessZaloId />} />
          <Route path="/homechat/:idacc/:idfr" element={<MessZaloId />} />
          <Route path="/odermanager" element={<OderManager />} />
          <Route
            path="/manageemployee"
            element={
              userInfo?.is_admin || userInfo?.is_manager ? (
                <ManageEmplo />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/statistics"
            element={
              userInfo?.is_manager || userInfo?.is_admin || userInfo?.is_employee ? (
                <Statistics />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path={`${userInfo?.is_admin ? "/sep" : "/"}`}
            element={userInfo?.is_admin ? <Sep /> : <HomeZalo />}
          />
          <Route
            path={`${userInfo?.is_admin ? "/accountwaitting" : "/"}`}
            element={userInfo?.is_admin ? <AccountWaitting /> : <HomeZalo />}
          />
          <Route
            path="/setting"
            element={userInfo?.is_admin ? <SettingPage /> : <HomeZalo />}
          />
          <Route
            path="/debug"
            element={
              userInfo?.is_admin || userInfo?.is_developer ? (
                <Debug />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route
            path="/debugmanual"
            element={
              userInfo?.is_admin ||
              userInfo?.is_developer ||
              userInfo?.is_saler ? (
                <DebugManual />
              ) : (
                <HomeZalo />
              )
            }
          />
          <Route path="/chatbot" element={<ChatBotMess />} />
          <Route path="/login" element={<NavigateMemo to="/" />} />
        </>
      ) : (
        <>
          <Route path="/account" element={<NavigateMemo to="/login" />} />
          <Route path="/product" element={<NavigateMemo to="/login" />} />
          <Route path="/mess" element={<NavigateMemo to="/login" />} />
          <Route path="/autoinbox" element={<NavigateMemo to="/login" />} />
          <Route path="/resource" element={<NavigateMemo to="/login" />} />
          <Route path="/odermanager" element={<NavigateMemo to="/login" />} />
          <Route path="/huongdan" element={<NavigateMemo to="/login" />} />
          <Route
            path="/manageemployee"
            element={<NavigateMemo to="/login" />}
          />
          <Route path="/setting" element={<NavigateMemo to="/login" />} />
          <Route path="/chatbot" element={<NavigateMemo to="/login" />} />
          <Route path="/addproducts" element={<NavigateMemo to="/login" />} />
          <Route path="/redue" element={<Redue />} />
          <Route path="/login" element={<Login />} />
        </>
      )}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/quenmatkhau" element={<QuenMatKhau />} />
      <Route path="/" element={<HomeZalo />} />
      <Route path="/redue" element={<Redue />} />
      <Route path="/shoplinkhome/:idacc/:idcate" element={<ShopLinkHome />} />
      <Route path="/shoplinkhome/:idacc" element={<ShopLinkHome />} />
      <Route
        path="/showproduct/:idacc/:idcate/:idproduct"
        element={<ShopLinkHome />}
      />
    </Routes>
  );
};

function App() {
  return (
    <Router>
      <div>
        <AppContent />
      </div>
    </Router>
  );
}

export default App;
