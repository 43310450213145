import { useEffect, useState } from "react";
import { getAxios } from "../../../const/getAxios";
import iconzl from "./zalologo.webp";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { BiMessageRoundedError } from "react-icons/bi";
import { MdOutlineNewLabel } from "react-icons/md";
import { timeAgo } from "../../../const/convertDate";
import { Checkbox } from "primereact/checkbox";
import { MdLabel } from "react-icons/md";
import { FaCaretSquareLeft } from "react-icons/fa";
import { FaCaretSquareRight } from "react-icons/fa";

export const ListFriendZalo = ({
  API_ROUTES_ZALO,
  API_URL,
  conversation,
  setConversation,
  idLocation,
  idFriend,
  setResetScroll,
  setMessengerText,
  setListLinkFile,
  setTypeQuote,
  handleSendMessenger,
  rfFriend,
  setAddLabel,
  addLabel,
  listFrLabel,
  setListFrLabel,
  setFilterLabel,
  rfChangeGr,
}) => {
  const headers = useHeaders();
  const navigate = useNavigate();
  const apiGetConversation = API_URL + API_ROUTES_ZALO.CONVERSATION_MESS;
  const [scrollTop, setScrollTop] = useState(false);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [isFriend, setIsFriend] = useState(false);
  useEffect(() => {
    const getConver = async () => {
      try {
        const res = await axios.get(
          `${apiGetConversation}?id_account=${idLocation}`,
          headers
        );
        setFilterLabel(res.data);
        setConversation(res.data);
      } catch (err) {}
    };
    setIsFriend(true);
    getConver();
  }, [idLocation, rfFriend, rfChangeGr]);
  useEffect(() => {
    const getAccoutScroll = async () => {
      const numberPage = conversation.next.split("page=")[1];
      try {
        const res = await axios.get(
          `${apiGetConversation}?id_account=${idLocation}&page=${numberPage}`,
          headers
        );
        setConversation((pre) => ({
          next: res?.data?.next,
          results: [...res?.data.results, ...pre?.results],
        }));
      } catch (err) {}
    };
    if (scrollTop && conversation.next) {
      getAccoutScroll();
    }
  }, [scrollTop]);
  const element = document.getElementById("list-account-conversation");
  element?.addEventListener("scroll", function () {
    if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  });
  const handleClickItem = (item) => {
    navigate(`/homechat/${idLocation}/${item.id}`);
    setResetScroll(Date.now());
    setListLinkFile([]);
    setMessengerText("");
    setTypeQuote([]);
    handleSendMessenger(item);
    setIsFriend(false);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);
  const handleCheckbox = (item) => {
    if (listFrLabel?.some((i) => i.id === item.id)) {
      setListFrLabel(listFrLabel?.filter((i) => i.id !== item.id));
    } else {
      setListFrLabel((pre) => [...pre, item]);
    }
  };
  return (
    <div
      className={`col-xl-2 h-100 list-friend-mobie ${
        isFriend ? "list-friend-mobie left-list-fr" : "right-list-fr"
      }`}
      id="list-account-conversation"
      style={{
        background: "#2860813b",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      {isFriend ? (
        <FaCaretSquareLeft
          style={{
            position: "fixed",
            left: isFriend ? `${element.offsetWidth}px` : "0",
          }}
          onClick={() => setIsFriend(!isFriend)}
          size={30}
          className="none-mobie"
        />
      ) : (
        <FaCaretSquareRight
          style={{
            position: "fixed",
            left: isFriend ? `${element.offsetWidth}px` : "0",
          }}
          onClick={() => setIsFriend(!isFriend)}
          size={30}
          className="none-mobie"
        />
      )}
      {conversation?.results
        ?.filter((item) => item?.account === Number(idLocation))
        ?.sort((a, b) => {
          const timeA = a.updated_time ? parseInt(a.updated_time, 10) : null;
          const timeB = b.updated_time ? parseInt(b.updated_time, 10) : null;
          if (timeA === null && timeB === null) return 0;
          if (timeA === null) return 1;
          if (timeB === null) return -1;
          return timeB - timeA;
        })
        ?.map((item, index) => (
          <nav
            style={{ position: "relative" }}
            onClick={() => {
              handleClickItem(item);
            }}
            className={`friend-item ${
              item.id == idFriend ? "item-focus" : ""
            } justify-content-start `}
            key={index}
          >
            {addLabel && (
              <Checkbox
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleCheckbox(item);
                }}
                checked={listFrLabel?.some((i) => i.id === item.id)}
              ></Checkbox>
            )}
            <img
              src={
                item?.group
                  ? item?.group?.avt || iconzl
                  : item?.friend?.avatar || iconzl
              }
              alt=""
              className="account-avatar"
            />
            <nav
              className="w-100 d-flex flex-column "
              style={{ position: "relative" }}
            >
              <p
                className="account-name friend-name"
                style={{ width: "70%", textAlign: "start" }}
              >
                {item?.group ? item.group?.name : item?.friend?.name}
              </p>
              <p>{timeAgo(Number(item.updated_time))}</p>
              <nav className="d-flex w-100">
                {item.category_message?.map((label, index) => (
                  <MdLabel color={label.color} />
                ))}
              </nav>
            </nav>
            {item?.new_message && item.id !== Number(idFriend) && (
              <nav
                className="d-flex"
                style={{
                  width: "max-content",
                  position: "absolute",
                  right: "4px",
                  top: "0",
                }}
              >
                <BiMessageRoundedError color="red" size={25} />
              </nav>
            )}
            <nav
              className="d-flex"
              style={{
                width: "max-content",
                position: "absolute",
                right: "4px",
                top: "23px",
              }}
            >
              <MdOutlineNewLabel onClick={() => setAddLabel(true)} size={25} />
            </nav>
          </nav>
        ))}
    </div>
  );
};
