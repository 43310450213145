import { useNavigate } from "react-router-dom";

export const Contact = () => {
  const navigate = useNavigate();
  const handleNavi = (link) => {
    navigate(link);
  };
  return (
    <div style={{ padding: "10px" }}>
      <button
        className="btn-footer d-flex justify-content-center"
        onClick={() => handleNavi("/register")}
      >
        DÙNG NGAY MIỄN PHÍ
      </button>
    </div>
  );
};
