import React, { useEffect, useRef, useState } from "react";
import Navigate from "../Navigate";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Fieldset } from "primereact/fieldset";
import { Card } from "primereact/card";
import { useHeaders } from "../../../const/headers";
import { useAuth } from "../../../AuthContext";
import Yourlistings from "./statisticsMarket/Yourlistings";
import MarketplaceInsights from "./statisticsMarket/MarketplaceInsights";
import { API_URL, API_ROUTES, API_ROUTES_NTD } from "../../../api";
import { IoMdArrowBack } from "react-icons/io";
import { Dropdown } from "primereact/dropdown";

import { Button } from "primereact/button";
import axios from "axios";

const StatisticsMarket = () => {
  const headers = useHeaders();
  const { setUserLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [dataFb, setDataFb] = useState();
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [taskId, setTaskId] = useState([]);
  const [dataStatis, setDataStatis] = useState("");
  const intervalRef = useRef();
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const getStatistical = API_URL + API_ROUTES_NTD.GET_STATIC_MARKET;
  const getRsStatistical = API_URL + API_ROUTES_NTD.GET_RESULTS_STATIC_MARKET;
  const [selectedTime, setSelectedTime] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const time = [
    { name: "7 ngày qua", code: "ONE_WEEK" },
    { name: "14 ngày qua", code: "TWO_WEEKS" },
    { name: "30 ngày qua", code: "ONE_MONTH" },
  ];
  const handleTimeChange = (value) => {
    if (isProcessing === true) {
      alert("Vui lòng đợi xử lý xong thông tin đã chọn trước đó.");
      return; // Exit the function early if another request is in progress
    }
    setSelectedTime(value);
    getStatisticalMarket(selectedAccount, value);
  };
  // Chọn những acc FB có checkpoint = false
  const dataFbWithOutCheckPoint = dataFb?.filter(
    (item) => item?.checkpoint === false
  );
  const getAccount = async () => {
    try {
      const response = await axios.get(getDataFb, headers);
      setDataFb(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAccount();
  }, []);
  const handleSelectAccount = (item) => {
    if (isProcessing === true) {
      alert("Vui lòng đợi tài khoản hiện tại xử lý xong thông tin.");
      return; 
    }
    setSelectedAccount(item);
    if (selectedTime) {
      getStatisticalMarket(item, selectedTime);
    } else {
      getStatisticalMarket(item);
    }
  };
  const getStatisticalMarket = async (item, value) => {
    // if (isProcessing === true) {
    //   alert("Vui lòng đợi tài khoản hiện tại xử lý xong thông tin.");
    //   return; // Exit the function early if another request is in progress
    // }
    if (!item?.id) {
      // Hiển thị thông báo yêu cầu chọn tài khoản
      alert("Vui lòng chọn tài khoản trước khi chọn thời gian.");
      setSelectedTime(null);
      return; // Dừng xử lý sớm nếu không có tài khoản được chọn
    }
    const body = { id_account: item?.id, time: value?.code };
    try {
      const response = await axios.post(getStatistical, body, headers);
      setTaskId(response.data.id_task);
      toast.info("Đang xử lý Thông tin...");
      setIsProcessing(true);
    } catch (error) {}
  };
  const checkResultsStatis = async () => {
    if (taskId?.length === 0) {
      return;
    }
    const body = { id_task: taskId };
    try {
      const res = await axios.post(getRsStatistical, body, headers);
      setDataStatis(res.data);
    } catch (err) {
      setDataStatis("FAILURE");
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      checkResultsStatis();
    }, 3000);

    return () => clearInterval(intervalRef.current);
  }, [taskId]);
  useEffect(() => {
    if (dataStatis?.status === "SUCCESS" || dataStatis?.status === "FAILURE") {
      toast.success("Thông tin đã được xử lý thành công.");
      setIsProcessing(false);
      setTaskId([]);
      clearInterval(intervalRef.current);
    }
  }, [dataStatis]);
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container" id="width-navigate">
          <Navigate />
        </div>
        <div
          className="d-flex flex-column gap-2"
          style={{
            paddingBottom: "100px",
            paddingRight: "100px",
            paddingLeft: "100px",
            backgroundColor: "#dee2e6",
            flex: "1",
            position: "relative",
            overflow: "auto",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "8px",
              left: "8px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/market")}
          >
            <IoMdArrowBack fontSize={30} />
          </div>
          <div className="card">
            <div
              style={{
                width: "100%",
                backgroundColor: "#fff",
                position: "relative",
              }}
              className="w-moblie d-flex flex-column card"
            >
              <div className=" flex justify-content-center">
                <Dropdown
                  style={{
                    position: "absolute",
                    right: "14px",
                    top: "14px",
                    backgroundColor: "#ccc",
                    height: "35px",
                  }}
                  value={selectedTime}
                  onChange={(e) => handleTimeChange(e.value)}
                  options={time}
                  optionLabel="name"
                  placeholder="Chọn thời gian thống kê"
                  className="w-full md:w-14rem"
                />
              </div>
              <div className="title">
                <h3
                  className="w-100 p-2 m-2"
                  //   style={{
                  //     color: "#1976D2",
                  //   }}
                >
                  Thống kê
                </h3>
              </div>
              <div className="title">
                <h5
                  style={{ fontWeight: "400" }}
                  className="w-100 p-2 m-2"
                  //   style={{
                  //     color: "#1976D2",
                  //   }}
                >
                  Tài khoản
                </h5>
              </div>
              <div
                style={{ boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)" }}
                className="middle-dash h-100 d-flex text-center justify-content-around w-100 "
              >
                <div
                  style={{
                    paddingRight: "30px",
                    paddingLeft: "30px",
                  }}
                  className="d-flex w-100 gap-2"
                >
                  <ul
                    style={{ overflow: "auto", width: "100%" }}
                    className="d-flex gap-4"
                  >
                    {dataFbWithOutCheckPoint?.map((item, index) => (
                      <li
                        onClick={() => handleSelectAccount(item)}
                        style={{
                          cursor: "pointer",
                        }}
                        className={`d-flex flex-column gap-2 align-items-center gap-2 w-100 ${
                          selectedAccount?.id === item.id ? "selected" : ""
                        }`}
                        key={index}
                      >
                        <img
                          style={{
                            borderRadius: "50%",
                            width: "50px",
                            border:
                              selectedAccount?.id === item.id
                                ? "3px solid #007bff"
                                : "none", // Thêm viền sáng khi được chọn
                          }}
                          src={item?.avatar}
                          alt="ảnh đại diện"
                        />
                        <p style={{ width: "max-content" }}>{item.name}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              style={{ width: "100%", backgroundColor: "#fff" }}
              className="w-moblie d-flex flex-column card"
            >
              <div className="title">
                <h4
                  className="w-100  p-2"
                  //   style={{
                  //     color: "#1976D2",
                  //   }}
                >
                  Tổng quan
                </h4>
              </div>
              <div
                style={{ boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)" }}
                className="middle-dash h-100 d-flex text-center justify-content-around w-100 "
              >
                <div
                  style={{
                    paddingRight: "30px",
                    paddingLeft: "30px",
                    paddingBottom: "30px",
                    flexWrap: "wrap",
                  }}
                  className="d-flex w-100 gap-2"
                >
                  <div
                    // className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                      border: "0.5px solid #ccc",
                      width: "24%",
                      borderRadius: "8px",
                      padding: "12px",
                    }}
                  >
                    <h4 className="d-flex w-100 fw-bold">
                      {dataStatis?.data?.unread_message_count}
                    </h4>
                    <p
                      style={{ fontWeight: "300" }}
                      className="d-flex w-100 fs-6"
                    >
                      Đoạn chat cần trả lời
                    </p>
                  </div>
                  <div
                    style={{
                      border: "0.5px solid #ccc",
                      width: "24%",
                      borderRadius: "8px",
                      padding: "12px",
                    }}
                  >
                    <h4 className="d-flex w-100 fw-bold">
                      {dataStatis?.data?.five_star_ratings_average}
                    </h4>
                    <p
                      style={{ fontWeight: "300" }}
                      className="d-flex w-100 fs-6"
                    >
                      Xếp hạn người bán
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Yourlistings
            dataStatis={dataStatis}
            selectedAccount={selectedAccount}
            getStatisticalMarket={getStatisticalMarket}
            checkResultsStatis={checkResultsStatis}
          />
          <MarketplaceInsights
            dataStatis={dataStatis}
            selectedAccount={selectedAccount}
            getStatisticalMarket={getStatisticalMarket}
            checkResultsStatis={checkResultsStatis}
            dataFb={dataFb}
            setDataFB={setDataFb}
          />
        </div>
      </div>
    </div>
  );
};

export default StatisticsMarket;
