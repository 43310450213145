export const sortDataByDate = (data) => {
  return data.sort((a, b) => {
    let dateA = new Date(a.created_at);
    let dateB = new Date(b.created_at);
    return dateB - dateA;
  });
};

export const sortDateGroup = (data) => {
  return data.sort((a, b) => {
    let dateA = new Date(a.run_at);
    let dateB = new Date(b.run_at);
    return dateB - dateA;
  });
};

export const sortDataByDateAr = (data) => {
  return data.sort((a, b) => {
    let dateA = new Date(a.created_time);
    let dateB = new Date(b.created_time);
    return dateB - dateA;
  });
};