import { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { API_URL, API_ROUTES_ZALO } from "../../../../api";
export const ViewNameGr = ({
  idAcc,
  Button,
  setName,
  name,
  headers,
  intervalRef,
  setRfGetMember,
  rfGetMember,
  memberForcus,
  setRfChangeGr,
  rfChangeGr,
  toast,
}) => {
  const apiChangeName = API_URL + API_ROUTES_ZALO.CHANGE_NAME_GR;
  const apiRsChangeName = API_URL + API_ROUTES_ZALO.RESULTS_CHANGE_NAME;
  const [taskCre, setTaskCre] = useState([]);
  const [nameGr, setNameGr] = useState(false);

  const handleChangeName = async () => {
    if (idAcc) {
      const body = {
        id_group: memberForcus?.group_id,
        id_account: Number(idAcc),
        name: name,
      };
      try {
        const res = await axios.post(apiChangeName, body, headers);
        setTaskCre(res.data.id_task);
        resultsChangeName(res.data.id_task);
        toast.success("Đang đổi tên");
      } catch (err) {}
    }
  };
  const resultsChangeName = async (idtask) => {
    const body = {
      id_task: idtask,
    };
    if (idtask.length > 0) {
      try {
        const res = await axios.post(apiRsChangeName, body, headers);
        if (res.data.status === "SUCCESS") {
          setNameGr(true);
          setTaskCre([]);
        } else if (res.data.status === "FAILURE") {
          setNameGr(true);
          setTaskCre([]);
        }
      } catch (error) {
        setNameGr(true);
        setTaskCre([]);
      }
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      resultsChangeName(taskCre);
    }, 3000);
    if (nameGr) {
      setNameGr(false);
      setRfChangeGr(!rfChangeGr);
      toast.success("Thành công");
      setName("");
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [taskCre]);
  return (
    <div className="d-flex gap-2 flex-column" style={{ width: "50%" }}>
      <InputText
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Nhập tên nhóm"
      />
      <Button
        onClick={() => handleChangeName()}
        className="button-blue radius w-100"
        label="Đổi tên"
      />
    </div>
  );
};
