import { useState, useEffect } from "react";
import { TbCategory } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import "./zalomess.css";
import { useLocation } from "react-router-dom";
import { Classify } from "./Classify";
import { BiMessageRoundedError } from "react-icons/bi";
import { TbMessageChatbot } from "react-icons/tb";
export const ListAccZalo = ({
  allAccount,
  account,
  setAccout,
  conversation,
  setConversation,
  setRfFiend,
  rfFriend,
  setModalSendStranger,
  categoryClassify,
  setCategoryClassify,
  rfClassify,
  setRfClassify,
  filterLabel,
  setIsAutoInbox,
  isAutoInbox,
  toast,
  setReloadConver,
  reloadConver,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const idAccout = location.pathname.split("/")[2];
  const [isClassify, setIsClassify] = useState(false);
  const [isListAccout, setIsListAccout] = useState(false);
  useEffect(() => {
    if (location.pathname.split("/")[3]) {
      setIsListAccout(false);
    } else {
      setIsListAccout(true);
    }
  }, [location.pathname.split("/")[3]]);
  return (
    <div className={` ${isClassify ? "classify-column" : ""} d-flex w-100 `}>
      <nav className={`list-icon-mobie`}>
        <MdAccountCircle
          size={35}
          color="#fff"
          onClick={() => setIsListAccout(!isListAccout)}
        />
        <TbCategory
          size={35}
          onClick={() => setIsClassify(!isClassify)}
          color="#fff"
        />
      </nav>
      <div className={`col-xl-2 ${isClassify ? "" : "classify-hidden"}`}>
        <Classify
          allAccount={allAccount}
          toast={toast}
          idAccout={idAccout}
          setIsAutoInbox={setIsAutoInbox}
          isAutoInbox={isAutoInbox}
          TbMessageChatbot={TbMessageChatbot}
          filterLabel={filterLabel}
          rfClassify={rfClassify}
          setRfClassify={setRfClassify}
          categoryClassify={categoryClassify}
          setCategoryClassify={setCategoryClassify}
          setModalSendStranger={setModalSendStranger}
          setRfFiend={setRfFiend}
          conversation={conversation}
          setConversation={setConversation}
          rfFriend={rfFriend}
          reloadConver={reloadConver}
          setReloadConver={setReloadConver}
        />
      </div>
      <div
        className={`account-container justify-content-start gap-2 w-100 ${
          isListAccout ? "" : "classify-hidden"
        }`}
        style={{ height: "max-content", overflow: "auto" }}
      >
        {allAccount
          ?.sort(
            (a, b) =>
              parseInt(b.updated_time, 10) - parseInt(a.updated_time, 10)
          )
          ?.filter((item) => item.checkpoint === false)
          ?.map((item, index) => (
            <nav
              onClick={() => {
                navigate(`/homechat/${item.id}`);
                localStorage.setItem("id_account", item.uid);
                // setConversation({
                //   next: "",
                //   results: [],
                // });
              }}
              key={index}
              className={`account-item flex flex-column align-items-center p-2 cursor-pointer ${
                idAccout == item.id ? "item-focus" : ""
              } position-relative`}
              style={{ width: "max-content" }}
            >
              <img
                src={item.avatar}
                alt=""
                className="account-avatar"
                style={{ marginBottom: "2px" }}
              />
              <p style={{ maxWidth: "80px" }} className="account-name ">
                {item.name}
              </p>
              {item.new_message && (
                <BiMessageRoundedError
                  style={{ position: "absolute", top: "0", right: "0" }}
                  color="red"
                  size={25}
                />
              )}
            </nav>
          ))}
      </div>
    </div>
  );
};
