import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState, useRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { RiExpandDiagonal2Line } from "react-icons/ri";
import { API_ROUTES_NTD, API_ROUTES_ZALO, API_URL } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { Checkbox } from "primereact/checkbox";
export const ModalGetLinkGr = ({
  props,
  openGetLink,
  setOpenGetLink,
  dataZalo,
  toast,
}) => {
  const headers = useHeaders();
  const apiGetLinkGr = API_URL + API_ROUTES_ZALO.GET_LINK_GROUP;
  const apiResultsLink = API_URL + API_ROUTES_ZALO.RESULTS_LINK_GROUP;
  const [itemAccout, setItemAccout] = useState([]);
  const [taskCre, setTaskCre] = useState([]);
  const [nameGr, setNameGr] = useState(false);
  const [listLink, setListLink] = useState([]);
  const intervalRef = useRef();
  const [itemForcus, setItemForcus] = useState([]);
  const [checked, setChecked] = useState(false);
  const handleGetLink = async () => {
    if (!itemAccout.id) {
      alert("Chọn tài khoản muốn lấy danh sách link");
      return;
    }
    const body = {
      id_account: Number(itemAccout.id),
    };
    try {
      const res = await axios.post(apiGetLinkGr, body, headers);
      setTaskCre(res.data.id_task);
      // setNameGr(true);
      toast.success("Đang lấy thông tin ");
      resultsGetLink(res.data.id_task);
    } catch (err) {}
  };
  const resultsGetLink = async (idtask) => {
    const body = {
      id_task: idtask,
    };
    if (idtask.length > 0) {
      try {
        const res = await axios.post(apiResultsLink, body, headers);
        if (res.data.status === "SUCCESS") {
          setNameGr(true);
          toast.success("Lấy thông tin thành công");
          setTaskCre([]);
          if (res.data.data) {
            setListLink(res.data.data);
          }
        } else if (res.data.status === "FAILURE") {
          toast.error("Lấy thông tin thất bại");
          setNameGr(true);
          setTaskCre([]);
        }
      } catch (error) {
        setNameGr(true);
        setTaskCre([]);
      }
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      resultsGetLink(taskCre);
    }, 3000);
    if (nameGr) {
      setNameGr(false);
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [taskCre]);
  function copyText(type) {
    if (type === "all") {
      const dataCopy = itemForcus
        ?.map((item) => `${item.name} - ${item.link_group}`)
        .join("\n");
      navigator.clipboard.writeText(dataCopy).then(
        () => {
          alert("Sao chép thành công!");
        },
        (err) => {}
      );
    } else {
      const dataCopy = itemForcus?.map((item) => item.link_group).join("\n");
      navigator.clipboard.writeText(dataCopy).then(
        () => {
          alert("Sao chép thành công!");
        },
        (err) => {}
      );
    }
  }
  const handleForcusItem = (item) => {
    if (itemForcus?.includes(item)) {
      setItemForcus(
        itemForcus.filter((fors) => fors.link_group !== item.link_group)
      );
    } else {
      setItemForcus((pre) => [...pre, item]);
    }
  };
  return (
    <Dialog
      header="Lấy nhóm từ link"
      visible={openGetLink}
      style={{ position: "relative", width: "50vw" }}
      onHide={() => setOpenGetLink(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className="button-red radius"
            onClick={() => setOpenGetLink(false)}
          >
            Thoát
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-column flex-1 mb-2 w-100 overflow-auto">
          <nav className="d-flex gap-2 overflow-auto w-100 mb-2">
            {dataZalo
              ?.filter((item) => item.checkpoint === false && item.proxy.status)
              ?.map((item, index) => (
                <nav
                  onClick={() => {
                    setItemAccout(item);
                    setListLink([]);
                  }}
                  style={{
                    background: itemAccout.id === item.id ? "bisque" : "",
                  }}
                  className="cursor-pointer d-flex p-2 rounded w-100 flex-column gap-2  align-items-center"
                >
                  <img
                    style={{ width: "30px", borderRadius: "50%" }}
                    src={item.avatar}
                  />
                  <p style={{ width: "max-content" }}>{item.name}</p>
                </nav>
              ))}
          </nav>
          <nav className="d-flex gap-2">
            <Button
              className="button-blue radius"
              style={{ width: "max-content" }}
              onClick={handleGetLink}
            >
              Lấy link nhóm
            </Button>
            <Button
              className="button-blue radius"
              style={{ width: "max-content" }}
              onClick={() => copyText("all")}
            >
              Sao chép tất cả thông tin
            </Button>
            <Button
              className="button-blue radius"
              style={{ width: "max-content" }}
              onClick={() => copyText("link")}
            >
              Sao chép link
            </Button>
          </nav>
        </div>
      </div>
      <nav className="d-flex gap-2 align-items-center mb-2">
        <Checkbox
          onChange={(e) => {
            setChecked(e.checked);
            if (e.checked) {
              setItemForcus(listLink);
            } else {
              setItemForcus([]);
            }
          }}
          checked={checked}
        ></Checkbox>

        <p>Chọn tất cả</p>
      </nav>
      <div className="title-list-link-gr">
        <p>Tên nhóm</p>
        <p>Link nhóm</p>
        <p>Số thành viên</p>
      </div>
      <div
        className="d-flex flex-column w-100 overflow-auto"
        style={{ height: "50vh" }}
      >
        {listLink?.map((item, index) => (
          <div
            key={index}
            onClick={() => handleForcusItem(item)}
            className="content-list-link-gr"
            style={{ background: itemForcus?.includes(item) ? "bisque" : "" }}
          >
            <p>{item.name}</p>
            <p>{item.link_group}</p>
            <p>{`${item?.total_member} thành viên`}</p>
          </div>
        ))}
      </div>
    </Dialog>
  );
};
