import { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Button } from "primereact/button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { getNoheaders } from "../../../const/getAxios";
import { postNoHeaders } from "../../../const/postAxios";
import { FloatLabel } from "primereact/floatlabel";

function ModalConfirmOrder({
  rfCart,
  setRfCart,
  openOder,
  setOpenOrder,
  session_key,
  allCart,
  allProduct,
  iduser,
  setDataSuccess,
  setViewCart,
  setOpenSuccess,
}) {
  const [allCity, setAllCity] = useState([]);
  const [district, setDistrict] = useState([]);
  const [ward, setWard] = useState([]);
  const apiCity = API_URL + API_ROUTES_ZALO.GET_CITY_SHOP;
  const apiDistrict = API_URL + API_ROUTES_ZALO.GET_DISTRICT_SHOP;
  const apiWard = API_URL + API_ROUTES_ZALO.GET_WARD_SHOP;
  const apiCreateOrder = API_URL + API_ROUTES_ZALO.CREATE_ORDER_SHOP;
  useEffect(() => {
    getNoheaders(apiCity, setAllCity);
  }, []);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedWard, setSelectedWard] = useState(null);
  const [form, setForm] = useState({
    full_name: "",
    phone_number: "",
    address: "",
    note: "",
  });
  const des = allCart.items?.map(
    (item) =>
      `${item.title} ${item.product_variant.options?.map(
        (item) => `${item.name} ${item.value}`
      )} Sl[${item.quantity}] * ${item.product_variant.price} = ${
        item.quantity * item.product_variant.price
      }`
  );
  const handleChange = (e, field) => {
    setForm({
      ...form,
      [field]: e.target.value,
    });
  };
  const handleGetDistrict = (e) => {
    setSelectedCity(e.value);
    const body = {
      id_city: e.value.id,
    };
    postNoHeaders(apiDistrict, body, setDistrict);
  };
  const handleGetWard = (e) => {
    setSelectedDistrict(e.value);
    const body = {
      id_district: e.value.id,
    };
    postNoHeaders(apiWard, body, setWard);
  };
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^(0|\+84)[3|5|7|8|9]\d{8}$/;
    return phoneRegex.test(phoneNumber);
  };
  const handleCreateOrder = async () => {
    const body = {
      id_employee: iduser,
      session_key: session_key,
      full_name: form.full_name,
      phone_number: form.phone_number,
      city: selectedCity?.city,
      district: selectedDistrict?.district,
      ward: selectedWard?.ward,
      address: form.address,
      note: form.note,
      description: des?.join(", "),
      total_amount: allCart?.total_amount,
    };
    if (
      !form.full_name ||
      !form.address ||
      !selectedCity?.city ||
      !selectedDistrict?.district ||
      !selectedWard?.ward
    ) {
      alert("Vui lòng nhập đầy đủ thông tin!");
      return;
    }
    if (!validatePhoneNumber(form.phone_number)) {
      alert("Số điện thoại không hợp lệ!");
      return;
    }
    setDataSuccess(body);
    try {
      await axios.post(apiCreateOrder, body);
      localStorage.removeItem("session_key");
      setOpenOrder(false);
      setViewCart(false);
      setOpenSuccess(true);

      setForm({});
    } catch (err) {}
  };
  return (
    <Dialog
      header="Thông tin người nhận"
      className="dialog-shop"
      visible={openOder}
      style={{ width: "50%" }}
      onHide={() => {
        if (!openOder) return;
        setOpenOrder(false);
      }}
    >
      <ToastContainer />
      <div className="d-flex flex-column w-100 mt-4 gap-4">
        <FloatLabel>
          <InputText
            onChange={(e) => handleChange(e, "full_name")}
            value={form.full_name}
            placeholder=""
            className="input-product"
          />{" "}
          <label for="Họ và tên">Họ và tên</label>
        </FloatLabel>

        <nav className="d-flex w-100">
          <Dropdown
            value={selectedCity}
            onChange={(e) => handleGetDistrict(e)}
            options={allCity}
            optionLabel="city"
            placeholder="Tỉnh/Thành phố"
            className="w-full "
            filter
          />
          <Dropdown
            value={selectedDistrict}
            onChange={(e) => handleGetWard(e)}
            options={district}
            optionLabel="district"
            placeholder="Quận/Huyện"
            className="w-full "
            filter
          />
          <Dropdown
            value={selectedWard}
            onChange={(e) => setSelectedWard(e.value)}
            options={ward}
            optionLabel="ward"
            placeholder="Xã"
            className="w-full "
            filter
          />
        </nav>
        <FloatLabel>
          <InputText
            onChange={(e) => handleChange(e, "address")}
            value={form.address}
            className="input-product"
            placeholder=""
          />
          <label for="Địa chỉ cụ thể">Địa chỉ cụ thể</label>
        </FloatLabel>
        <FloatLabel>
          <InputText
            onChange={(e) => handleChange(e, "phone_number")}
            value={form.phone_number}
            className="input-product"
            placeholder=""
          />
          <label for="Số điện thoại">Số điện thoại</label>
        </FloatLabel>
        <FloatLabel>
          <InputText
            onChange={(e) => handleChange(e, "note")}
            value={form.note}
            className="input-product"
            placeholder=""
          />
          <label for="Ghi chú">Ghi chú</label>
        </FloatLabel>
        <nav className="w-100 d-flex flex-column">
          <p className="mt-2 mb-2">Sản phẩm:</p>
          {allCart?.items?.map((item, index) => (
            <nav className="d-flex gap-2 mb-2">
              <img
                style={{ width: "50px", height: "50px" }}
                src={`${API_URL}/${item.image}`}
              />
              <nav>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    marginBottom: "6px",
                  }}
                >{`${item.title} ${item.product_variant.options?.map(
                  (item) => `${item.name} ${item.value}`
                )}`}</p>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >{`SL ${
                  item.quantity
                } * ${item.product_variant.price.toLocaleString(
                  "vi-VN"
                )} VND = ${(
                  item.quantity * item.product_variant.price
                ).toLocaleString("vi-VN")} VND`}</p>
              </nav>
            </nav>
          ))}
          <p style={{ fontSize: "18px", fontWeight: "700" }}>
            Tổng: {`${allCart?.total_amount?.toLocaleString("vi-VN")} VND`}
          </p>
        </nav>
      </div>
      <nav className="w-100 mt-2 d-flex align-items-center justify-content-end gap-2">
        <Button
          style={{
            padding: "8px 12px",
            width: "max-content",
            background: "red",
          }}
        >
          Huỷ
        </Button>
        <Button
          style={{
            padding: "8px 12px",
            width: "max-content",
            background: "#0167f3",
          }}
          onClick={handleCreateOrder}
        >
          Mua hàng
        </Button>
      </nav>
    </Dialog>
  );
}

export default ModalConfirmOrder;
