import React, { useState, memo, useEffect, useRef } from "react";
import { Nav } from "react-bootstrap";
import { HiMenu } from "react-icons/hi";
import { PiChatsCircleBold } from "react-icons/pi";
import { MdOutlineManageAccounts } from "react-icons/md";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { TbPasswordFingerprint } from "react-icons/tb";
import { IoMdLogOut } from "react-icons/io";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import { CiSettings } from "react-icons/ci";
import { IoCaretBack } from "react-icons/io5";
import useLogout from "../../const/logout";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import { API_URL, API_ROUTES } from "../../api";
import { FaChartBar, FaPager, FaToolbox } from "react-icons/fa";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { useUser } from "../../contexts/UserContext";
import { IoMdLogIn } from "react-icons/io";
import { useHeaders } from "../../const/headers";
import { IoCaretBackSharp } from "react-icons/io5";
import { IoCaretForwardOutline } from "react-icons/io5";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { MdNoAccounts } from "react-icons/md";
import FooterDemo from "./modalDialog/ModalThongBao";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import { FaShoppingCart } from "react-icons/fa";
import { CgDebug } from "react-icons/cg";
import { GiHumanPyramid } from "react-icons/gi";
import { BsFileEarmarkPost, BsShop } from "react-icons/bs";
import { FaPeopleGroup } from "react-icons/fa6";
import AcceptTerms from "./modalDialog/ModalAcceptTerms";
import { SiCoinmarketcap } from "react-icons/si";
import { MdMarkEmailUnread } from "react-icons/md";
import { useStatus } from "../../contexts/StatusContext";
import bellSound from "../audio/messting.mp3";
import { useTranslation } from "react-i18next";
import { FcDataConfiguration } from "react-icons/fc";
const Navigate = memo(
  ({ iconNavi, openProduct, setOpenProduct, setOpenPage, openPage }) => {
    const getInfor = API_URL + API_ROUTES.GET_INFO_UERS;
    const scrollContainerRef = useRef(null);
    const token = localStorage.getItem("access");

    const { userInfo, setUserInfo, newLogo, closeList, setCloseList } =
      useUser();
    const { listAccout, bellSource } = useStatus();
    useEffect(() => {
      const hasNewMsg = listAccout?.some(
        (item) => item.total_unread_count === true
      );
      if (hasNewMsg) {
        const audio = new Audio(bellSound);
        audio
          .play()
          .then(() => { })
          .catch((error) => { });
      }
    }, [bellSource, listAccout]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [timeEnd, setTimeEnd] = useState("");
    const { userLoggedIn, setUserLoggedInState } = useAuth();
    const handleClose = () => {
      setCloseList(!closeList);
    };
    const headers = useHeaders();
    const handleOpenProduct = () => {
      setOpenProduct(!openProduct);
      setOpenPage(false);
    };
    const handleOpenPage = () => {
      setOpenPage(!openPage);
      setOpenProduct(false);
    };
    const fetchData = async () => {
      try {
        const response = await axios.get(getInfor, headers);
        setUserInfo(response.data);
      } catch (err) {
        navigate("/");
        setUserLoggedInState(false);
      }
    };
    useEffect(() => {
      fetchData();
      const currentTime = new Date();
      const userInfoTime = new Date(userInfo?.expiration_date);
      if (currentTime.getTime() > userInfoTime.getTime()) {
        setTimeEnd("Tài khoản đã hết hạn sử dụng");
      } else if (currentTime.getTime() < userInfoTime.getTime()) {
      } else {
      }
    }, []);

    const redirectSelect = (link) => {
      const scrollTop = scrollContainerRef.current
        ? scrollContainerRef.current.scrollTop
        : 0;
      navigate(link, { replace: true });
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop = scrollTop;
        }
      }, 100);
    };
    const logout = useLogout();
    const location = useLocation();
    const [expanded, setExpanded] = useState("");
    const toggleMenu = () => {
      if (expanded) {
        setExpanded("");
      } else {
        setExpanded("add-clas-togle");
      }
    };
    const expirationDate = userInfo?.expiration_date;
    let formattedDate = "";

    if (expirationDate) {
      const [date, time] = expirationDate.split(" ");
      const [year, month, day] = date.split("-");
      if (date && time && year && month && day) {
        formattedDate = `${day}-${month}-${year}`;
      }
    }

    return (
      <div className="h-100">
        <div
          className="toogle-hiden"
          style={{ cursor: "pointer", display: "flex", height: "46px" }}
        >
          <HiMenu size={40} onClick={toggleMenu} />
          <IoCaretBack
            className={`ioCretBack ${expanded}`}
            size={40}
            onClick={toggleMenu}
          />
          <FaPager
            onClick={handleOpenPage}
            style={{ display: iconNavi === false ? "block" : "none" }}
            size={40}
          />
          {/* 
          <MdOutlineProductionQuantityLimits
            onClick={() => handleOpenProduct()}
            style={{ display: iconNavi === false ? "block" : "none" }}
            size={40}
          /> */}
        </div>
        <AcceptTerms />
        <div
          className={`h-100 d-flex flex-column navigate-overflow menu-togle-navigate  ${expanded} `}
          style={{
            background: "#2c343f",
            width: closeList === true ? "230px" : "100%",
          }}
        // ref={scrollContainerRef}
        >
          <div
            style={{
              background: "#2c343f",
              padding: "0 20px",
            }}
            className={`thead-fixed ${closeList === false
              ? "d-none"
              : "d-flex justify-content-center w-100"
              }`}
          >
            <Link to="/">
              {" "}
              <img
                className="animated-image"
                alt="logochotnhanh"
                src={`${API_URL}/${newLogo}`}
                style={{
                  height: "82px",
                  margin: "0.5rem 0 0px 0",
                  width: "100%",
                }}
              />
            </Link>
          </div>
          <div className="d-flex flex-column h-100 justify-content-between align-items-center">
            <Nav
              style={{
                width: "100%",
                padding: "13px 20px 1px 20px",
                display: closeList === false ? "none" : "",
                // overflow: "auto",
              }}
              defaultActiveKey="/home"
            >
              {userLoggedIn === false ? (
                <Nav.Item
                  className="hover-navigate w-100 d-flex align-items-center"
                  style={{
                    paddingLeft: "10px",
                    background:
                      location.pathname === "/product"
                        ? "#4880FF"
                        : "transparent",
                  }}
                >
                  <IoMdLogIn className="text-light" />
                  <Nav.Link
                    onClick={() => redirectSelect("/login")}
                    style={{
                      textDecoration: "none",
                      fontSize: "14px",
                      color: "#fff",
                      paddingRight: "0",
                    }}
                  // href=
                  >
                    {t("Đăng nhập")}
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <nav></nav>
              )}
              {userLoggedIn === true ? (
                <div className="w-100">
                  <Nav.Item
                    className="hover-navigate w-100 d-flex align-items-center"
                    onClick={() => redirectSelect("/account")}
                    style={{
                      paddingLeft: "10px",
                      background:
                        location.pathname === "/account"
                          ? "#4880FF"
                          : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <MdOutlineManageAccounts className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    // href=
                    >
                      {t("Tài Khoản Zalo")}
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item
                    className="hover-navigate w-100 d-flex align-items-center"
                    onClick={() => redirectSelect("/proxy")}
                    style={{
                      paddingLeft: "10px",
                      background:
                        location.pathname === "/proxy"
                          ? "#4880FF"
                          : "transparent",
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <FcDataConfiguration className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    // href=
                    >
                      {t("Quản Lý Proxy")}
                    </Nav.Link>
                    {listAccout?.some(
                      (item) => item.total_unread_count === true
                    ) && (
                        <MdMarkEmailUnread
                          size={20}
                          style={{
                            position: "absolute",
                            top: "-9px",
                            right: "-6px",
                            color: "yellow",
                          }}
                        />
                      )}
                  </Nav.Item>
                  {userInfo?.is_manager === true ||
                    userInfo?.is_admin === true ? (
                    <Nav.Item
                      className="hover-navigate w-100 d-flex align-items-center"
                      onClick={() => redirectSelect("/domain")}
                      style={{
                        paddingLeft: "10px",
                        background:
                          location.pathname === "/domain"
                            ? "#4880FF"
                            : "transparent",
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      <FcDataConfiguration className="text-light" />
                      <Nav.Link
                        style={{
                          textDecoration: "none",
                          fontSize: "14px",
                          color: "#fff",
                          paddingRight: "0",
                        }}
                      // href=
                      >
                        {t("Quản Lý Tên Miền")}
                      </Nav.Link>
                      {listAccout?.some(
                        (item) => item.total_unread_count === true
                      ) && (
                          <MdMarkEmailUnread
                            size={20}
                            style={{
                              position: "absolute",
                              top: "-9px",
                              right: "-6px",
                              color: "yellow",
                            }}
                          />
                        )}
                    </Nav.Item>
                  ) : (
                    <div></div>
                  )}
                  <Nav.Item
                    className="hover-navigate w-100 d-flex align-items-center"
                    onClick={() => redirectSelect("/homechat")}
                    style={{
                      paddingLeft: "10px",
                      background: location.pathname.includes("homechat")
                        ? "#4880FF"
                        : "transparent",
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <IoChatbubbleEllipsesSharp className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    // href=
                    >
                      {t("Tin Nhắn")}
                    </Nav.Link>
                    {listAccout?.some(
                      (item) => item.total_unread_count === true
                    ) && (
                        <MdMarkEmailUnread
                          size={20}
                          style={{
                            position: "absolute",
                            top: "-9px",
                            right: "-6px",
                            color: "yellow",
                          }}
                        />
                      )}
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => redirectSelect("/chatbot")}
                    className="hover-navigate w-100 d-flex align-items-center"
                    style={{
                      paddingLeft: "10px",
                      background:
                        location.pathname === "/chatbot"
                          ? "#4880FF"
                          : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <PiChatsCircleBold className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    // href=
                    >
                      Chatbot
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item
                    className="hover-navigate w-100 d-flex align-items-center"
                    onClick={() => redirectSelect("/client")}
                    style={{
                      paddingLeft: "10px",
                      background:
                        location.pathname === "/client"
                          ? "#4880FF"
                          : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <GiHumanPyramid className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    // href=
                    >
                      {t("Quản Lý Bạn Bè")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => redirectSelect("/group")}
                    className="hover-navigate w-100 d-flex align-items-center"
                    style={{
                      paddingLeft: "10px",
                      background:
                        location.pathname === "/group"
                          ? "#4880FF"
                          : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <FaPeopleGroup className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    >
                      {t("Quản Lý Nhóm")}
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item
                    onClick={() => redirectSelect("/marketing")}
                    className="hover-navigate w-100 d-flex align-items-center"
                    style={{
                      paddingLeft: "10px",
                      background:
                        location.pathname === "/marketing"
                          ? "#4880FF"
                          : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <BsShop className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    >
                      {t("Marketing Bán Hàng")}
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item
                    className="hover-navigate w-100 d-flex align-items-center"
                    onClick={() => redirectSelect("/article")}
                    style={{
                      paddingLeft: "10px",
                      background:
                        location.pathname === "/article"
                          ? "#4880FF"
                          : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <BsFileEarmarkPost className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    >
                      {t("Chiến Dịch Tự Động")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    className="hover-navigate w-100 d-flex align-items-center"
                    onClick={() => redirectSelect("/product")}
                    style={{
                      paddingLeft: "10px",
                      background:
                        location.pathname === "/product"
                          ? "#4880FF"
                          : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <BsFileEarmarkPost className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    >
                      {t("Đơn hàng")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    className="hover-navigate w-100 d-flex align-items-center"
                    onClick={() => redirectSelect("/minishop")}
                    style={{
                      paddingLeft: "10px",
                      background: location.pathname.includes("minishop")
                        ? "#4880FF"
                        : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <BsFileEarmarkPost className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    >
                      {t("Mini Shop")}
                    </Nav.Link>
                  </Nav.Item>
                  {userInfo?.is_manager === true ||
                    userInfo?.is_admin === true ? (
                    <Nav.Item
                      onClick={() => redirectSelect("/manageemployee")}
                      className="hover-navigate w-100 d-flex align-items-center"
                      style={{
                        paddingLeft: "10px",
                        background:
                          location.pathname === "/manageemployee"
                            ? "#4880FF"
                            : "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <TbPasswordFingerprint className="text-light" />
                      <Nav.Link
                        style={{
                          textDecoration: "none",
                          fontSize: "14px",
                          color: "#fff",
                          paddingRight: "0",
                        }}
                      // href=
                      >
                        {t("Quản Lý Nhân Viên")}
                      </Nav.Link>
                    </Nav.Item>
                  ) : (
                    <div></div>
                  )}
                  {/* <Nav.Item
                    onClick={() => redirectSelect("/statistics")}
                    className="hover-navigate w-100 d-flex align-items-center"
                    style={{
                      paddingLeft: "10px",
                      background:
                        location.pathname === "/statistics"
                          ? "#4880FF"
                          : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <FaChartBar className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    // href=
                    >
                      {t("Thống Kê")}
                    </Nav.Link>
                  </Nav.Item> */}
                  {userInfo?.is_admin === true ? (
                    <Nav.Item
                      onClick={() => redirectSelect("/sep")}
                      className="hover-navigate w-100 d-flex align-items-center"
                      style={{
                        paddingLeft: "10px",
                        background:
                          location.pathname === "/sep"
                            ? "#4880FF"
                            : "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <TbPasswordFingerprint className="text-light" />
                      <Nav.Link
                        style={{
                          textDecoration: "none",
                          fontSize: "14px",
                          color: "#fff",
                          paddingRight: "0",
                        }}
                      // href=
                      >
                        {t("Quản Lý Người Dùng")}
                      </Nav.Link>
                    </Nav.Item>
                  ) : (
                    <div></div>
                  )}
                  {userInfo?.is_admin === true ? (
                    <Nav.Item
                      onClick={() => redirectSelect("/accountwaitting")}
                      className="hover-navigate w-100 d-flex align-items-center"
                      style={{
                        paddingLeft: "10px",
                        background:
                          location.pathname === "/accountwaitting"
                            ? "#4880FF"
                            : "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <MdNoAccounts className="text-light" />
                      <Nav.Link
                        style={{
                          textDecoration: "none",
                          fontSize: "14px",
                          color: "#fff",
                          paddingRight: "0",
                        }}
                      // href=
                      >
                        {t("Tài Khoản Đang Chờ")}
                      </Nav.Link>
                    </Nav.Item>
                  ) : (
                    <div></div>
                  )}
                  <Nav.Item
                    className="hover-navigate w-100 d-flex align-items-center"
                    onClick={() => redirectSelect("/resource")}
                    style={{
                      paddingLeft: "10px",
                      background:
                        location.pathname === "/resource"
                          ? "#4880FF"
                          : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <CiSettings className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    // href=
                    >
                      {t("Tài Nguyên")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    className="hover-navigate w-100 d-flex align-items-center"
                    onClick={() => redirectSelect("/huongdan")}
                    style={{
                      paddingLeft: "10px",
                      background:
                        location.pathname === "/huongdan"
                          ? "#4880FF"
                          : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <MdOutlineIntegrationInstructions className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    // href=
                    >
                      {t("Hướng Dẫn")}
                    </Nav.Link>
                  </Nav.Item>
                  {userInfo?.is_admin === true ? (
                    <Nav.Item
                      onClick={() => redirectSelect("/setting")}
                      className="hover-navigate w-100 d-flex align-items-center"
                      style={{
                        paddingLeft: "10px",
                        background:
                          location.pathname === "/setting"
                            ? "#4880FF"
                            : "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <CiSettings className="text-light" />
                      <Nav.Link
                        style={{
                          textDecoration: "none",
                          fontSize: "14px",
                          color: "#fff",
                          paddingRight: "0",
                        }}
                      // href=
                      >
                        {t("Cài Đặt")}
                      </Nav.Link>
                    </Nav.Item>
                  ) : (
                    <div></div>
                  )}
                  {userInfo?.is_developer === true ? (
                    <Nav.Item
                      className="hover-navigate w-100 d-flex align-items-center"
                      onClick={() => redirectSelect("/debug")}
                      style={{
                        paddingLeft: "10px",
                        background:
                          location.pathname === "/debug"
                            ? "#4880FF"
                            : "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <CgDebug className="text-light" />
                      <Nav.Link
                        style={{
                          textDecoration: "none",
                          fontSize: "14px",
                          color: "#fff",
                          paddingRight: "0",
                        }}
                      // href=
                      >
                        Debug
                      </Nav.Link>
                    </Nav.Item>
                  ) : (
                    <div></div>
                  )}
                  {userInfo?.is_saler === true ||
                    userInfo?.is_developer === true ? (
                    <Nav.Item
                      onClick={() => redirectSelect("/debugmanual")}
                      className="hover-navigate w-100 d-flex align-items-center"
                      style={{
                        paddingLeft: "10px",
                        background:
                          location.pathname === "/debugmanual"
                            ? "#4880FF"
                            : "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <CgDebug className="text-light" />
                      <Nav.Link
                        style={{
                          textDecoration: "none",
                          fontSize: "14px",
                          color: "#fff",
                          paddingRight: "0",
                        }}
                      // href=
                      >
                        {t("Debug Thủ Công")}
                      </Nav.Link>
                    </Nav.Item>
                  ) : (
                    <div></div>
                  )}
                  <Nav.Item
                    className="hover-navigate w-100 d-flex align-items-center"
                    onClick={logout}
                    style={{
                      paddingLeft: "10px",
                      background:
                        location.pathname === "/contact"
                          ? "#4880FF"
                          : "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <IoMdLogOut className="text-light" />
                    <Nav.Link
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: "#fff",
                        paddingRight: "0",
                      }}
                    >
                      {t("Đăng Xuất")}
                    </Nav.Link>
                  </Nav.Item>
                </div>
              ) : (
                <nav></nav>
              )}
            </Nav>
            <FooterDemo />
            <div className="h-100 d-flex flex-column justify-content-between">
              <div
                style={{ cursor: "pointer" }}
                className="d-flex justify-content-end w-100 dropdown-navigate-hidden"
                onClick={() => handleClose()}
              >
                {closeList === true ? (
                  <IoCaretBackSharp className="text-light " size={30} />
                ) : (
                  <div className="d-flex flex-column w-100 justify-content-center align-item-center ml-2 mt-2 gap-important">
                    <OverlayTrigger
                      key="tooltip"
                      placement="right"
                      overlay={
                        <Tooltip
                          id={`tooltip-right`}
                          className="custom-tooltip"
                        >
                          {t("Quản lý tài khoản")}
                        </Tooltip>
                      }
                    >
                      <div>
                        <MdOutlineManageAccounts
                          onClick={() => navigate("/account")}
                          size={26}
                          className="text-light icon-wrapper"
                        />
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="tooltip"
                      placement="right"
                      overlay={
                        <Tooltip
                          id={`tooltip-right`}
                          className="custom-tooltip"
                        >
                          Quản lý tin nhắn
                        </Tooltip>
                      }
                    >
                      <div>
                        <IoChatbubbleEllipsesSharp
                          onClick={() => navigate("/homechat")}
                          size={26}
                          className="text-light icon-wrapper"
                        />
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="tooltip"
                      placement="right"
                      overlay={
                        <Tooltip
                          id={`tooltip-right`}
                          className="custom-tooltip"
                        >
                          Chatbot
                        </Tooltip>
                      }
                    >
                      <div>
                        <PiChatsCircleBold
                          onClick={() => navigate("/chatbot")}
                          size={26}
                          className="text-light icon-wrapper"
                        />
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="tooltip"
                      placement="right"
                      overlay={
                        <Tooltip
                          id={`tooltip-right`}
                          className="custom-tooltip"
                        >
                          Quản Lý Khách Hàng
                        </Tooltip>
                      }
                    >
                      <div>
                        <GiHumanPyramid
                          onClick={() => navigate("/client")}
                          size={26}
                          className="text-light icon-wrapper"
                        />
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="tooltip"
                      placement="right"
                      overlay={
                        <Tooltip
                          id={`tooltip-right`}
                          className="custom-tooltip"
                        >
                          Quản Lý Nhóm
                        </Tooltip>
                      }
                    >
                      <div>
                        <FaPeopleGroup
                          onClick={() => navigate("/group")}
                          size={26}
                          className="text-light icon-wrapper"
                        />
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="tooltip"
                      placement="right"
                      overlay={
                        <Tooltip
                          id={`tooltip-right`}
                          className="custom-tooltip"
                        >
                          Marketing Bán Hàng
                        </Tooltip>
                      }
                    >
                      <div>
                        <BsShop
                          onClick={() => navigate("/marketing")}
                          size={26}
                          className="text-light icon-wrapper"
                        />
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="tooltip"
                      placement="right"
                      overlay={
                        <Tooltip
                          id={`tooltip-right`}
                          className="custom-tooltip"
                        >
                          Quản Lý Bài Viết
                        </Tooltip>
                      }
                    >
                      <div>
                        <BsFileEarmarkPost
                          onClick={() => navigate("/article")}
                          size={26}
                          className="text-light icon-wrapper"
                        />
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="tooltip"
                      placement="right"
                      overlay={
                        <Tooltip
                          id={`tooltip-right`}
                          className="custom-tooltip"
                        >
                          Công Cụ Facebook
                        </Tooltip>
                      }
                    >
                      <div>
                        <FaToolbox
                          onClick={() => navigate("/toolsfb")}
                          size={26}
                          className="text-light icon-wrapper"
                        />
                      </div>
                    </OverlayTrigger>
                    {userInfo?.is_manager === true ? (
                      <OverlayTrigger
                        key="tooltip"
                        placement="right"
                        overlay={
                          <Tooltip
                            id={`tooltip-right`}
                            className="custom-tooltip"
                          >
                            Quản lý nhân viên
                          </Tooltip>
                        }
                      >
                        <div>
                          <TbPasswordFingerprint
                            onClick={() => navigate("/manageemployee")}
                            size={26}
                            className="text-light icon-wrapper"
                          />
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <></>
                    )}
                    {userInfo?.is_admin === true ? (
                      <OverlayTrigger
                        key="tooltip"
                        placement="right"
                        overlay={
                          <Tooltip
                            id={`tooltip-right`}
                            className="custom-tooltip"
                          >
                            Quản lý người dùng
                          </Tooltip>
                        }
                      >
                        <div>
                          <TbPasswordFingerprint
                            onClick={() => navigate("/sep")}
                            size={26}
                            className="text-light icon-wrapper"
                          />
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <></>
                    )}
                    {userInfo?.is_admin === true ? (
                      <OverlayTrigger
                        key="tooltip"
                        placement="right"
                        overlay={
                          <Tooltip
                            id={`tooltip-right`}
                            className="custom-tooltip"
                          >
                            {t("Tài khoản đang chờ")}
                          </Tooltip>
                        }
                      >
                        <div
                          style={{ display: userInfo?.is_admin ? "none" : "" }}
                        >
                          <MdNoAccounts
                            onClick={() => navigate("/accountwaitting")}
                            size={26}
                            className="text-light icon-wrapper"
                          />
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <></>
                    )}
                    <OverlayTrigger
                      key="tooltip"
                      placement="right"
                      overlay={
                        <Tooltip
                          id={`tooltip-right`}
                          className="custom-tooltip"
                        >
                          Tài nguyên
                        </Tooltip>
                      }
                    >
                      <div>
                        <CiSettings
                          onClick={() => navigate("/resource")}
                          size={26}
                          className="text-light icon-wrapper"
                        />
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="tooltip"
                      placement="right"
                      overlay={
                        <Tooltip
                          id={`tooltip-right`}
                          className="custom-tooltip"
                        >
                          Hướng dẫn
                        </Tooltip>
                      }
                    >
                      <div>
                        <MdOutlineIntegrationInstructions
                          onClick={() => navigate("/huongdan")}
                          size={26}
                          className="text-light icon-wrapper"
                        />
                      </div>
                    </OverlayTrigger>
                    {userInfo?.is_admin === true ? (
                      <OverlayTrigger
                        key="tooltip"
                        placement="right"
                        overlay={
                          <Tooltip
                            id={`tooltip-right`}
                            className="custom-tooltip"
                          >
                            Cài đặt
                          </Tooltip>
                        }
                      >
                        <div
                          style={{ display: userInfo?.is_admin ? "none" : "" }}
                        >
                          <CiSettings
                            onClick={() => navigate("/setting")}
                            className="text-light icon-wrapper"
                            size={30}
                          />
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <></>
                    )}
                    <OverlayTrigger
                      key="tooltip"
                      placement="right"
                      overlay={
                        <Tooltip
                          id={`tooltip-right`}
                          className="custom-tooltip"
                        >
                          Đăng xuất
                        </Tooltip>
                      }
                    >
                      <div>
                        <IoMdLogOut
                          onClick={logout}
                          className="text-light icon-wrapper"
                          size={30}
                        />
                      </div>
                    </OverlayTrigger>
                    <IoCaretForwardOutline
                      className="text-light icon-wrapper mb-2"
                      size={30}
                    />
                  </div>
                )}
              </div>
              <div
                className="w-100  "
                style={{ display: closeList === false ? "none" : "block" }}
              >
                {/* <hr></hr> */}
                <hr></hr>
                <p className="mb-0 text-light">
                  {t("Tài khoản")}: {userInfo?.fullname || ""}
                </p>
                <p className="mb-0 text-light">
                  {t("Trạng thái tài khoản")}:{" "}
                  {userInfo?.employee_limit === 0 &&
                    userInfo?.is_manager === true
                    ? t("Thường")
                    : userInfo?.is_admin === true
                      ? t("Admin")
                      : userInfo?.is_developer
                        ? t("Lập trình viên")
                        : userInfo?.is_saler
                          ? t("CSKH")
                          : userInfo?.is_employee
                            ? t("Nhân viên")
                            : t("Quản lý")}
                </p>
                <p className="mb-0 text-light">
                  {userInfo?.is_employee === true && (
                    t("Quản lý:") + " " + userInfo.manager_name
                  )}
                </p>
                <nav className="d-flex gap-2 ">
                  <p style={{ color: "#fff", padding: "0", margin: "0" }}>
                    {t("Hạn sử dụng")}:
                  </p>
                  <p className="text-light m-0">
                    {" "}
                    {formattedDate || "Invalid date"}
                  </p>
                </nav>
                <p style={{ color: "red" }}>{timeEnd}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default Navigate;
