import { useEffect, useState } from "react";
import "./shop.css";
import { ListCategoryShop } from "./ListCategoryShop";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { ProductMiniShop } from "./ProductMiniShop";
import { postNoHeaders } from "../../../const/postAxios";
import { useLocation } from "react-router-dom";
import { updateTitle, updateFavicon } from "../../../const/updateTitle";
import { ShowProduct } from "./ShowProduct";
import ModalConfirmOrder from "./ModalConfirmOrder";
import DialogSuccess from "./DialogSuccess";
import ModalViewCart from "./ModalViewCart";
import axios from "axios";
import { getNoheaders } from "../../../const/getAxios";
export const ShopLinkHome = () => {
  const location = useLocation();
  const getAllCart = API_URL + API_ROUTES_ZALO.GET_ALL_CART_SHOP;
  const apiCover = API_URL + API_ROUTES_ZALO.GET_COVER_SHOP;
  const fistDomain = location.pathname.split("/")[1];
  const idAccountInloaction = location.pathname.split("/")[2];
  const idCategoryInlocation = location.pathname.split("/")[3];
  const [allCateShop, setAllCateShop] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [viewCart, setViewCart] = useState(false);
  const [allCart, setAllCart] = useState([]);
  const [openOder, setOpenOrder] = useState(false);
  const [rfCart, setRfCart] = useState(false);
  const [cover, setCover] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [dataSuccess, setDataSuccess] = useState([]);
  updateTitle(`${cover.name}`);
  updateFavicon(`${API_URL}${cover.image}`);
  const session_key = localStorage.getItem("session_key");
  const getAllCartShop = async () => {
    const body = { id_employee: idAccountInloaction, session_key: session_key };
    try {
      const res = await axios.post(getAllCart, body);
      setAllCart(res.data);
    } catch (err) { }
  };
  useEffect(() => {
    getAllCartShop();
  }, [rfCart]);
  const getAllCateShop = API_URL + API_ROUTES_ZALO.GET_CATEGORY_SHOP;
  useEffect(() => {
    getNoheaders(
      `${getAllCateShop}?id_employee=${idAccountInloaction}`,
      setAllCateShop
    );
    getNoheaders(`${apiCover}?id_employee=${idAccountInloaction}`, setCover);
  }, []);
  return (
    <div
      className="home-shop-link"
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${API_URL}${cover.image})`,
      }}
    >
      {idCategoryInlocation && fistDomain === "shoplinkhome" ? (
        <ProductMiniShop
          setViewCart={setViewCart}
          allCart={allCart}
          allProduct={allProduct}
          setAllProduct={setAllProduct}
          allCateShop={allCateShop}
          idAccountInloaction={idAccountInloaction}
          idCategoryInlocation={idCategoryInlocation}
        />
      ) : !idCategoryInlocation && fistDomain === "shoplinkhome" ? (
        <ListCategoryShop
          getNoheaders={getNoheaders}
          setAllCateShop={setAllCateShop}
          cover={cover}
          getAllCateShop={getAllCateShop}
          allCateShop={allCateShop}
          idAccountInloaction={idAccountInloaction}
        // idCategoryInlocation={idCategoryInlocation}
        />
      ) : (
        <ShowProduct
          cover={cover}
          rfCart={rfCart}
          setRfCart={setRfCart}
          openOder={openOder}
          setOpenOrder={setOpenOrder}
          viewCart={viewCart}
          setViewCart={setViewCart}
          allCart={allCart}
          setAllCart={setAllCart}
          openCart={openCart}
          setOpenCart={setOpenCart}
          allCateShop={allCateShop}
          allProduct={allProduct}
          API_URL={API_URL}
        />
      )}
      <ModalConfirmOrder
        setOpenSuccess={setOpenSuccess}
        setDataSuccess={setDataSuccess}
        iduser={idAccountInloaction}
        allProduct={allProduct}
        allCart={allCart}
        openOder={openOder}
        setOpenOrder={setOpenOrder}
        session_key={session_key}
        rfCart={rfCart}
        setRfCart={setRfCart}
        setViewCart={setViewCart}
      />
      <ModalViewCart
        iduser={idAccountInloaction}
        setOpenOrder={setOpenOrder}
        allCart={allCart}
        viewCart={viewCart}
        setViewCart={setViewCart}
        session_key={session_key}
        rfCart={rfCart}
        setRfCart={setRfCart}
      />
      <DialogSuccess
        rfCart={rfCart}
        setRfCart={setRfCart}
        setDataSuccess={setDataSuccess}
        dataSuccess={dataSuccess}
        allCart={allCart}
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
      />
    </div>
  );
};
