import axios from "axios";
import { InputText } from "primereact/inputtext";
import { API_ROUTES_ZALO, API_URL } from "./../../../api";
import { useHeaders } from "./../../../const/headers";
import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { toast, ToastContainer } from "react-toastify";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import ModalAddProductMes from "./CreateOderManual/ModalAddProductMes";
import ModalCart from "./CreateOderManual/ModalCart";
export const CreateOrderZalo = ({ userInfo }) => {
  const headers = useHeaders();
  const apiAllOrder = API_URL + API_ROUTES_ZALO.ALL_ORDER_SHOP;
  const apiGetCity = API_URL + API_ROUTES_ZALO.GET_CITY_SHOP;
  const apiGetDistrict = API_URL + API_ROUTES_ZALO.GET_DISTRICT_SHOP;
  const apiGetWard = API_URL + API_ROUTES_ZALO.GET_WARD_SHOP;
  const apiCreateOrder = API_URL + API_ROUTES_ZALO.CREATE_ORDER_SHOP_MANUAL;
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [productEdit, setProductEdit] = useState([]);
  const [listVariant, setListVariant] = useState([]);
  const [city, setCity] = useState([]);
  const [district, setDistrict] = useState([]);
  const [ward, setWard] = useState([]);
  const [addressDetail, setAddressDetail] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [note, setNote] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedWard, setSelectedWard] = useState(null);
  const countries = city;
  const districts = district;
  const wards = ward;
  const selectedCountryTemplate = (city, props) => {
    if (city) {
      return (
        <div className="flex align-items-center">
          <div>{city.city}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const selectedDistrictTemplate = (district, props) => {
    if (district) {
      return (
        <div className="flex align-items-center">
          <div>{district.district}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };
  const selectedWardTemplate = (ward, props) => {
    if (ward) {
      return (
        <div className="flex align-items-center">
          <div>{ward.ward}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (city) => {
    return (
      <div className="flex align-items-center">
        <div>{city.city}</div>
      </div>
    );
  };
  const districtOptionTemplate = (district) => {
    return (
      <div className="flex align-items-center">
        <div>{district.district}</div>
      </div>
    );
  };
  const wardOptionTemplate = (ward) => {
    return (
      <div className="flex align-items-center">
        <div>{ward.ward}</div>
      </div>
    );
  };
  const getCity = async () => {
    try {
      const res = await axios.get(apiGetCity, headers);
      setCity(res.data);
    } catch (err) {}
  };
  const getDistrict = async () => {
    if (!selectedCity) return;
    const body = {
      id_city: selectedCity?.id,
    };
    try {
      const res = await axios.post(apiGetDistrict, body, headers);
      setDistrict(res.data);
    } catch (err) {}
  };
  const getWard = async () => {
    if (!selectedDistrict) return;
    const body = {
      id_district: selectedDistrict?.id,
    };
    try {
      const res = await axios.post(apiGetWard, body, headers);
      setWard(res.data);
    } catch (err) {}
  };

  useEffect(() => {
    getCity();
  }, []);

  useEffect(() => {
    getDistrict();
    getWard();
  }, [selectedCity]);

  useEffect(() => {
    getWard();
  }, [selectedDistrict]);
  //Tao don hang
  const createOrder = async () => {
    const body = {
      id_user: userInfo?.id,
      full_name: name,
      phone_number: phone,
      city: selectedCity?.city,
      district: selectedDistrict?.district,
      ward: selectedWard?.ward,
      address: addressDetail,
      note: note,
      description: description,
      total_amount: price,
      products: listVariant?.filter((item) => item.quantity > 0),
    };
    try {
      const res = await axios.post(apiCreateOrder, body, headers);
      toast.success("tạo đơn hàng thành công");
      setName("");
      setAddressDetail("");
      setNote("");
      setPhone("");
      setSelectedCity(null);
      setSelectedWard(null);
      setSelectedDistrict(null);
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddProduct = () => {
    setOpenAddProduct(true);
  };
  const handleOpenCart = () => {
    setOpenCart(true);
  };
  const calculateTotalPrice = () => {
    return listVariant.reduce((total, item) => {
      return total + item.quantity * item.price;
    }, 0);
  };
  useEffect(() => {
    setPrice(calculateTotalPrice());
  }, [listVariant]);
  return (
    <div
      className="gap-2"
      style={{ padding: "10px", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          backgroundColor: "GrayText",
          padding: "10px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        <p style={{ color: "#fff", fontSize: "18px" }}>TẠO ĐƠN HÀNG</p>
      </div>
      <div>
        <label style={{ color: "#fff" }} htmlFor="">
          Tên khách hàng
        </label>
        <InputText
          style={{ fontSize: "16px" }}
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="p-inputtext-sm"
          type="text"
          placeholder=""
        />
      </div>
      <div>
        <label style={{ color: "#fff" }} htmlFor="">
          Số điện thoại
        </label>
        <InputText
          style={{ fontSize: "16px" }}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="p-inputtext-sm"
          keyfilter="int"
          placeholder=""
        />
      </div>
      <div
        className="gap-2"
        style={{ width: "100%", display: "flex", flexDirection: "column" }}
      >
        <label style={{ color: "#fff" }} htmlFor="">
          Địa chỉ
        </label>
        <Dropdown
          className="p-inputtext-sm"
          style={{ width: "100%" }}
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.value)}
          options={countries}
          optionLabel="name"
          placeholder="Tỉnh/Thành phố"
          filter
          filterBy="city"
          valueTemplate={selectedCountryTemplate}
          itemTemplate={countryOptionTemplate}
        />
        <Dropdown
          className="p-inputtext-sm"
          style={{ width: "100%" }}
          value={selectedDistrict}
          onChange={(e) => setSelectedDistrict(e.value)}
          options={districts}
          optionLabel="name"
          placeholder="Quận/Huyện"
          filter
          filterBy="district"
          valueTemplate={selectedDistrictTemplate}
          itemTemplate={districtOptionTemplate}
        />
        <Dropdown
          className="p-inputtext-sm"
          style={{ width: "100%" }}
          value={selectedWard}
          onChange={(e) => setSelectedWard(e.value)}
          options={wards}
          optionLabel="name"
          placeholder="Xã/Phường"
          filter
          filterBy="ward"
          valueTemplate={selectedWardTemplate}
          itemTemplate={wardOptionTemplate}
        />
        <InputText
          value={addressDetail}
          onChange={(e) => setAddressDetail(e.target.value)}
          className="p-inputtext-sm"
          type="text"
          placeholder="Địa chỉ cụ thể"
          style={{ fontSize: "16px" }}
        />
      </div>
      {/* <div>
        <label style={{ color: "#fff" }} htmlFor="">
          Mô tả sản phẩm
        </label>
        <InputTextarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          autoResize
          className="p-inputtext-lg"
          type="text"
          placeholder=""
        />
      </div> */}
      <div className=" flex justify-content-center">
        <Button
          onClick={handleAddProduct}
          style={{
            fontSize: "14px",
            backgroundColor: "#054ddb",
            border: "none",
            width: "100%",
            borderRadius: "10px",
          }}
          label="Chọn sản phẩm"
        />
        <ModalAddProductMes
          productEdit={productEdit}
          openAddProduct={openAddProduct}
          setOpenAddProduct={setOpenAddProduct}
          setListVariant={setListVariant}
          listVariant={listVariant}
        />
      </div>
      <div className=" flex justify-content-center">
        <Button
          onClick={handleOpenCart}
          style={{
            fontSize: "14px",
            backgroundColor: "#054ddb",
            border: "none",
            width: "100%",
            borderRadius: "10px",
          }}
          label="Sản phẩm đã chọn"
        />
        <ModalCart
          listVariant={listVariant}
          openCart={openCart}
          toast={toast}
          setOpenCart={setOpenCart}
        />
      </div>
      <div>
        <label style={{ color: "#fff" }} htmlFor="">
          Thành tiền
        </label>
        <InputNumber
          style={{ width: "100%" }}
          className="p-inputtext-sm"
          inputId="currency-vn"
          value={price}
          onValueChange={(e) => setPrice(e.value)}
          mode="currency"
          currency="VND"
          locale="vi-VN"
        />
      </div>
      <div>
        <label style={{ color: "#fff" }} htmlFor="">
          Ghi chú
        </label>
        <InputTextarea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          autoResize
          className="p-inputtext-lg"
          type="text"
          placeholder=""
        />
      </div>
      <div className=" flex justify-content-center">
        <Button
          onClick={createOrder}
          style={{
            fontSize: "14px",
            backgroundColor: "#054ddb",
            border: "none",
            width: "100%",
            borderRadius: "10px",
          }}
          label="Tạo đơn hàng"
        />
      </div>
    </div>
  );
};
