import { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FcAddImage } from "react-icons/fc";
import { FloatLabel } from "primereact/floatlabel";
import { InputTextarea } from "primereact/inputtextarea";
import axios from "axios";
import { Image } from "primereact/image";
import { TiDelete } from "react-icons/ti";
import { Button } from "primereact/button";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
export const ModalForcusAvatar = ({
  forcusAvt,
  setForcusAvt,
  textStranger,
  setTextStranger,
  handleSendMessenger,
  messCard,
  itemForcusAvt,
}) => {
  const intervalRef = useRef();
  const headers = useHeaders();
  const itemMember = JSON.parse(localStorage.getItem("itemMember"));
  const apiGetInfor = API_URL + API_ROUTES_ZALO.GET_INFOR_FORSUS_FR;
  const apiRsGetInfor = API_URL + API_ROUTES_ZALO.RESULTS_GET_INFOR_FORSUS_FR;
  const apiUnFr = API_URL + API_ROUTES_ZALO.UNFRIEND;
  const apiRsUnFr = API_URL + API_ROUTES_ZALO.UNFRIEND_RESULT;
  const apiAddFr = API_URL + API_ROUTES_ZALO.ADD_FRIEND_INMESS;
  const apiRsAddFr = API_URL + API_ROUTES_ZALO.RESULTS_ADD_FRIEND_INMESS;
  const [taskCre, setTaskCre] = useState([]);
  const [taskUn, setTaskUn] = useState([]);
  const [taskAdd, setTaskAdd] = useState([]);
  const [nameGr, setNameGr] = useState(false);
  const [inforFr, setInforFr] = useState([]);
  const [isMess, setIsMess] = useState(false);
  const [status, setStatus] = useState(false);
  const forcusMember = itemMember?.filter(
    (item) => item.uid === itemForcusAvt.uidFrom
  )[0];
  //information
  const handleGetLink = async () => {
    const body = {
      id_account: itemForcusAvt.account_id,
      uid: itemForcusAvt.uidFrom,
    };
    try {
      const res = await axios.post(apiGetInfor, body, headers);
      setTaskCre(res.data.id_task);
      resultsGetLink(res.data.id_task);
      setStatus(true);
    } catch (err) {}
  };
  const resultsGetLink = async (idtask) => {
    const body = {
      id_task: idtask,
    };
    if (idtask.length > 0) {
      try {
        const res = await axios.post(apiRsGetInfor, body, headers);
        if (res.data.status === "SUCCESS") {
          setNameGr(true);
          setTaskCre([]);
          if (res.data.data) {
            setInforFr(res.data.data);
          }
        } else if (res.data.status === "FAILURE") {
          setNameGr(true);
          setTaskCre([]);
        }
      } catch (error) {
        setNameGr(true);
        setTaskCre([]);
      }
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      resultsGetLink(taskCre);
    }, 3000);
    if (nameGr) {
      setNameGr(false);
      setStatus(false);
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [taskCre]);
  //unfriend
  const handleUnFr = async () => {
    const body = {
      id_account: itemForcusAvt.account_id,
      fids: [itemForcusAvt.uidFrom],
    };
    try {
      const res = await axios.post(apiUnFr, body, headers);
      setTaskUn(res.data.id_task);
      resultsUnFr(res.data.id_task);
      setStatus(true);
    } catch (err) {}
  };
  const resultsUnFr = async (idtask) => {
    const body = {
      id_task: idtask,
    };
    if (idtask.length > 0) {
      try {
        const res = await axios.post(apiRsUnFr, body, headers);
        if (res.data.status === "SUCCESS") {
          setNameGr(true);
          setTaskUn([]);
          if (res.data.data) {
            setInforFr(res.data.data);
          }
        } else if (res.data.status === "FAILURE") {
          setNameGr(true);
          setTaskUn([]);
        }
      } catch (error) {
        setNameGr(true);
        setTaskUn([]);
      }
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      resultsUnFr(taskUn);
    }, 3000);
    if (nameGr) {
      setNameGr(false);
      setStatus(false);
      handleGetLink();
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [taskUn]);
  const handleAddFr = async () => {
    const body = {
      id_account: itemForcusAvt.account_id,
      uids: [itemForcusAvt.uidFrom],
    };
    try {
      const res = await axios.post(apiAddFr, body, headers);
      setTaskAdd(res.data.id_task);
      resultsAddFr(res.data.id_task);
      setStatus(true);
    } catch (err) {}
  };
  const resultsAddFr = async (idtask) => {
    const body = {
      id_task: idtask,
    };
    if (idtask.length > 0) {
      try {
        const res = await axios.post(apiRsAddFr, body, headers);
        if (res.data.status === "SUCCESS") {
          setNameGr(true);
          setTaskAdd([]);
          if (res.data.data) {
            setInforFr(res.data.data);
          }
        } else if (res.data.status === "FAILURE") {
          setNameGr(true);
          setTaskAdd([]);
        }
      } catch (error) {
        setNameGr(true);
        setTaskAdd([]);
      }
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      resultsAddFr(taskAdd);
    }, 3000);
    if (nameGr) {
      setNameGr(false);
      setStatus(false);
      handleGetLink();
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [taskAdd]);
  useEffect(() => {
    if (itemForcusAvt?.account_id) {
      handleGetLink();
    }
    setIsMess(false);
  }, [forcusAvt]);
  return (
    <div className="flex justify-content-center">
      <Dialog
        header=""
        visible={forcusAvt}
        onHide={() => setForcusAvt(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="d-flex gap-2 align-items-center mb-4">
          <img
            src={forcusMember?.avt}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
          <p style={{ fontWeight: "600", fontSize: "16px" }}>
            {forcusMember?.name}
          </p>
        </div>
        {status ? (
          <p>Đang lấy thông tin...</p>
        ) : (
          <div>
            <nav className="d-flex gap-2 ">
              {!isMess && (
                <Button
                  onClick={() => setIsMess(true)}
                  className="button-blue radius"
                  label="Gửi tin nhắn"
                />
              )}
              {inforFr.isFr === 0 ? (
                <Button
                  onClick={() => {
                    handleAddFr();
                  }}
                  className="button-blue radius"
                  label="Kết bạn"
                />
              ) : (
                <Button
                  onClick={() => {
                    handleUnFr();
                  }}
                  className="button-red radius"
                  label="Huỷ kết bạn"
                />
              )}
            </nav>
            {isMess && (
              <div className="d-flex flex-column gap-4 mt-4 p-2">
                <FloatLabel className="w-100">
                  <InputTextarea
                    value={textStranger}
                    onChange={(e) => setTextStranger(e.target.value)}
                    className="w-100"
                  />
                  <label for="username">{`Gửi tin nhắn cho ${forcusMember?.name}`}</label>
                </FloatLabel>
              </div>
            )}
            {isMess && (
              <nav className="d-flex w-100 justify-content-end">
                <Button
                  onClick={() => {
                    handleSendMessenger();
                    setIsMess(false);
                  }}
                  className="button-blue radius"
                  label="Gửi tin nhắn"
                />
              </nav>
            )}
          </div>
        )}
      </Dialog>
    </div>
  );
};
