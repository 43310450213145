import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { getAxios } from "../../../const/getAxios";
import iconzl from "./zalologo.webp";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
export default function ModalShareMess({
  setOpenShare,
  openShare,
  idLocation,
  itemShare,
  idFriend,
  setListConver,
  listConver,
  handleSendMessenger,
  setTextShare,
  textShare,
}) {
  const apiGetConversation = API_URL + API_ROUTES_ZALO.CONVERSATION_MESS;
  const [conversation, setConversation] = useState([]);
  const [scrollTop, setScrollTop] = useState(false);
  const headers = useHeaders();
  const [typeFilter, setTypeFilter] = useState("all");
  const [listFilter, setListFilter] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  useEffect(() => {
    if (idLocation) {
      getAxios(
        `${apiGetConversation}?id_account=${idLocation}`,
        setConversation
      );
      setListFilter(conversation);
    }
  }, [openShare]);
  useEffect(() => {
    const getAccoutScroll = async () => {
      const numberPage = conversation.next.split("page=")[1];
      try {
        const res = await axios.get(
          `${apiGetConversation}?id_account=${idLocation}&page=${numberPage}`,
          headers
        );
        setConversation((pre) => ({
          next: res?.data?.next,
          results: [...res?.data.results, ...pre?.results],
        }));
      } catch (err) {}
    };
    if (scrollTop && conversation.next) {
      getAccoutScroll();
    }
  }, [scrollTop]);
  const handleGetItem = (item) => {
    if (listConver?.some((i) => i.id === item.id)) {
      setListConver(listConver?.filter((i) => i.id !== item.id));
    } else setListConver((pre) => [...pre, item]);
  };
  const element = document.getElementById("share-mess");
  element?.addEventListener("scroll", function () {
    if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  });
  useEffect(() => {
    if (typeFilter === "all") {
      setListFilter(conversation);
    } else if (typeFilter === "group") {
      setListFilter((pre) => ({
        next: pre.next,
        results: conversation?.results?.filter((item) => item?.group !== null),
      }));
    } else {
      setListFilter((pre) => ({
        next: pre.next,
        results: conversation?.results?.filter((item) => item?.friend !== null),
      }));
    }
  }, [typeFilter, scrollTop]);
  const handleSelectAll = (e) => {
    setCheckAll(e);
    if (e) {
      setListConver(conversation.results);
    } else {
      setListConver([]);
    }
  };
  return (
    <Dialog
      header="Chia sẻ"
      visible={openShare}
      style={{ width: "50vw" }}
      onHide={() => {
        if (!openShare) return;
        setOpenShare(false);
      }}
    >
      <div
        className="d-flex flex-column w-100 justify-content-between"
        style={{ height: "70vh" }}
      >
        <div
          className="h-100 w-100 d-flex flex-column"
          style={{ overflow: "hidden" }}
        >
          <nav
            className="d-flex gap-2 align-items-center"
            style={{ marginLeft: "10px" }}
          >
            <Checkbox
              onChange={(e) => handleSelectAll(e.checked)}
              checked={checkAll}
            ></Checkbox>
            <p
              onClick={() => setTypeFilter("all")}
              className={`cursor-pointer p-2 ${
                typeFilter === "all" ? "item-filter" : ""
              }`}
            >
              Gần đây
            </p>
            <p
              onClick={() => setTypeFilter("group")}
              className={`cursor-pointer p-2 ${
                typeFilter === "group" ? "item-filter" : ""
              }`}
            >
              Nhóm trò chuyện
            </p>
            <p
              onClick={() => setTypeFilter("friend")}
              className={`cursor-pointer p-2 ${
                typeFilter === "friend" ? "item-filter" : ""
              }`}
            >
              Bạn bè
            </p>
          </nav>
          <div
            id="share-mess"
            className="overflow-auto"
            style={{ height: "100%" }}
          >
            {listFilter?.results?.map((item, index) => (
              <nav
                onClick={() => {
                  handleGetItem(item);
                }}
                className={`friend-item `}
                key={index}
              >
                <Checkbox
                  checked={listConver?.some((i) => i.id === item.id)}
                ></Checkbox>
                <img
                  src={
                    item?.group
                      ? item?.group?.avt || iconzl
                      : item?.friend?.avatar || iconzl
                  }
                  alt=""
                  className="account-avatar"
                />
                <p
                  className="account-name friend-name"
                  style={{ width: "min-content" }}
                >
                  {item?.group ? item.group?.name : item?.friend?.name}
                </p>
              </nav>
            ))}
          </div>
          <nav className="w-100 mt-2 mb-2">
            <InputText
              className="w-100"
              value={textShare}
              onChange={(e) => setTextShare(e.target.value)}
              placeholder="Nhập tin nhắn..."
            />
          </nav>
        </div>
        <nav className="w-100 d-flex gap-2 justify-content-end">
          <Button className="button-blue" onClick={handleSendMessenger}>
            Chia sẻ
          </Button>
          <Button className="button-red" onClick={() => setOpenShare(false)}>
            Huỷ
          </Button>
        </nav>
      </div>
    </Dialog>
  );
}
