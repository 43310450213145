import React, { useEffect } from "react";
import Navigate from "../container/Navigate";
import { Table } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES } from "../../api";
import { Footer } from "./Footer";
import { Button } from "primereact/button";
import { ManageSep } from "../container/managesep/ManagerSep";
import { useHeaders } from "../../const/headers";
import { InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { updateTitle } from "../../const/updateTitle";
import { ModalAcceptDebug } from "../container/debug/ModalAcceptDebug";
import { EditSystemDebug } from "../container/debug/EditSystemDebug";
import { FaFilter } from "react-icons/fa6";
import DescriptionModal from "../container/debug/DescriptionModal";
import { Paginator } from "primereact/paginator";
import { useTranslation } from "react-i18next";
export const Debug = () => {
  updateTitle("Chốt Care - Debug");
  const headers = useHeaders();
  const getAllSystemDebug = API_URL + API_ROUTES.GET_ALL_SYSTEM_DEBUG;
  const deleteAcc = API_URL + API_ROUTES.DELETE_SYSTEM_DEBUG;
  const deleteAllAcc = API_URL + API_ROUTES.DELETE_ALL_SYSTEM_DEBUG;
  const [heightTop, setHeightTop] = useState("");
  const [visible, setVisible] = useState(false);
  const [listEmpl, setListEmpl] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [hiddenTable, setHiddenTable] = useState(false);
  const [debugAcc, setDebugAcc] = useState(false);
  const [getItemEdit, setGetItemEdit] = useState([]);
  const [openEditDebug, setOpenEditDebug] = useState(false);
  const [search, SetSearch] = useState("");
  const [filterAll, setFilterAll] = useState([]);
  const [filterOrSTT, setFilterOrSTT] = useState(false);
  const [filterOrTime, setFilterOrTime] = useState(false);
  const [filterOrStatus, setFilterOrStatus] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");
  const [first, setFirst] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [rows, setRows] = useState(100);
  const { t } = useTranslation();
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  const searchChange = (event) => {
    const { value } = event.target;
    SetSearch(value);
  };
  const handleOpenModal = (description, id) => {
    setCurrentDescription(description);
    setShowDescriptionModal(true);
  };
  const handleOpenNoteModal = (note, id) => {
    setCurrentDescription(note);
    setShowDescriptionModal(true);
  };
  const handleDeleteBug = async () => {
    const body = { id_debugs: selectedItems };
    setRefresh(!refresh);
    setSelectedItems([]);
    try {
      const res = await axios.post(deleteAcc, body, headers);
    } catch (err) { }
  };
  const handleDeleteAllBug = async () => {
    setRefresh(!refresh);
    setSelectedItems([]);
    try {
      const res = await axios.get(deleteAllAcc, headers);
      toast.success("Xóa tất cả thành công!");
    } catch (err) {
      toast.error("Có lỗi xảy ra khi xóa!");
    }
  };
  const handleDeleteBugOne = async (item) => {
    const body = { id_debugs: [item] };
    setRefresh(!refresh);
    setSelectedItems([]);
    try {
      await axios.post(deleteAcc, body, headers);
    } catch (err) { }
  };
  useEffect(() => {
    getDataEmpl();
  }, [refresh]);
  useEffect(() => {
    setHeightTop(document?.getElementById("heigt-top")?.offsetHeight);
    getDataEmpl();
  }, [totalPage, rows]);
  const getDataEmpl = async () => {
    try {
      const response = await axios.get(
        `${getAllSystemDebug}?number_per_page=${10}&page=${totalPage + 1}`,
        headers
      );
      setGetTotal(response.data);
      setListEmpl(response.data.results);
      setFilterAll(response.data.results);
    } catch (err) { }
  };
  function formatDateTime(dateString) {
    const date = new Date(dateString);
    const pad = (num) => String(num).padStart(2, "0");
    const timePart = `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
      date.getSeconds()
    )}`;
    const datePart = `${pad(date.getDate())}-${pad(
      date.getMonth() + 1
    )}-${date.getFullYear()}`;
    return `${timePart} - ${datePart}`;
  }
  useEffect(() => {
    let sortedList = [...listEmpl];
    if (filterOrSTT) {
      sortedList.sort((a, b) => a.stt - b.stt);
    } else {
      sortedList.sort((a, b) => b.stt - a.stt);
    }
    setFilterAll(sortedList);
  }, [filterOrSTT, listEmpl]);
  useEffect(() => {
    if (filterOrTime) {
      setFilterAll(
        listEmpl?.sort((a, b) => new Date(a.time) - new Date(b.time))
      );
    } else {
      setFilterAll(
        listEmpl?.sort((a, b) => new Date(b.time) - new Date(a.time))
      );
    }
  }, [filterOrTime]);
  useEffect(() => {
    if (filterOrStatus) {
      setFilterAll(listEmpl.sort((a, b) => b.confirm - a.confirm));
    } else {
      setFilterAll(listEmpl.sort((a, b) => a.confirm - b.confirm));
    }
  }, [filterOrStatus]);
  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(filterAll.map((item) => item.id));
    }
    setAllSelected(!allSelected);
  };
  const handleSelectItem = (id) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(id)
        ? prevSelectedItems.filter((item) => item !== id)
        : [...prevSelectedItems, id]
    );
  };
  const handleCopyClick = (item) => {
    if (item?.description) {
      navigator.clipboard
        .writeText(item?.description)
        .then(() => { })
        .catch((err) => { });
    }
  };
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="d-flex h-100 w-100 debug-wrapper mgtop-respon-47">
          <div
            className="overflow-auto h-100 d-flex flex-column"
            style={{
              width: "100%",
              borderRight: "0.5px solid",
              borderLeft: "0.5px solid",
            }}
          >
            <div
              id="height-top"
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid",
              }}
              className="thead-fixed justify-content-between column-respon"
            >
              <h3
                className="manage-domain-respon uppercase-respon"
                style={{
                  padding: "20px",
                  margin: "0",
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#545454",
                }}
              >
                {t("Lỗi")}
              </h3>
              <nav className="d-flex gap-2 w90-respon">
                <Button
                  label={t("Làm mới")}
                  className="button-blue radius"
                  onClick={() => setRefresh(!refresh)}
                />
                <Button
                  label={t("Xoá")}
                  className="button-red radius"
                  onClick={() => handleDeleteBug()}
                />
                <Button
                  label={t("Xoá tất cả")}
                  className="button-red radius"
                  onClick={() => handleDeleteAllBug()}
                />
                <InputGroup
                  className="responsive-width-acc"
                  style={{
                    width: "auto",
                    borderRadius: "0",
                  }}
                >
                  <Form.Control
                    placeholder={t("Tìm kiếm nhanh")}
                    aria-describedby="basic-addon2"
                    value={search}
                    style={{
                      borderRadius: "0",
                    }}
                    onChange={searchChange}
                  />
                </InputGroup>
              </nav>
            </div>
            <ManageSep
              setVisible={setVisible}
              visible={visible}
              setRefresh={setRefresh}
              refresh={refresh}
              listEmpl={listEmpl}
            />
            <div className="overflow-auto h-100 custom-scrollbar">
              <Table striped bordered hover>
                <thead className="thead-fixed">
                  <tr style={{ borderTop: "0" }}>
                    <th style={{ width: "30px" }}>
                      <input
                        type="checkbox"
                        checked={allSelected}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th
                      style={{ width: "70px" }}
                      onClick={() => setFilterOrSTT(!filterOrSTT)}
                    >
                      {t("STT")}
                      <FaFilter
                        style={{ marginLeft: "4px", cursor: "pointer" }}
                      />
                    </th>
                    <th style={{ width: "60px" }}>ID Bug</th>
                    <th style={{ width: "120px" }}>{t("Tiêu đề")}</th>
                    <th> {t("Nội dung")} </th>
                    <th>{t("Ghi chú")}</th>
                    <th style={{ width: "120px" }}>{t("Kỹ thuật viên")}</th>
                    <th
                      style={{ width: "120px" }}
                      onClick={() => setFilterOrStatus(!filterOrStatus)}
                    >
                      {t("Trạng thái")}
                      <FaFilter
                        style={{ marginLeft: "4px", cursor: "pointer" }}
                      />
                    </th>
                    <th
                      style={{ width: "120px" }}
                      onClick={() => setFilterOrTime(!filterOrTime)}
                    >
                      {t("Thời gian")} <FaFilter style={{ cursor: "pointer" }} />
                    </th>
                    <th style={{ width: "200px" }}>{t("Tùy chọn")}</th>
                  </tr>
                </thead>
                <tbody>
                  {filterAll?.map((item, index) => (
                    <tr
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => setHiddenTable(!hiddenTable)}
                    >
                      <th>
                        <input
                          type="checkbox"
                          checked={selectedItems.includes(item.id)}
                          onChange={() => handleSelectItem(item.id)}
                        />
                      </th>
                      <td>{index + 1}</td>
                      <td>{item?.id}</td>
                      <td>{item?.title}</td>
                      <td>
                        {item?.description?.length > 400
                          ? `${item?.description?.substring(0, 400)}... `
                          : item?.description}
                        <button
                          className="button-blue radius"
                          onClick={() =>
                            handleOpenModal(item?.description, item?.id)
                          }
                        >
                          {t("Xem thêm")}
                        </button>
                      </td>
                      <td>
                        {item?.note?.length > 400
                          ? `${item?.note?.substring(0, 400)}... `
                          : item?.note}
                        {item?.note !== null ? (
                          <button
                            className="button-blue radius"
                            onClick={() =>
                              handleOpenNoteModal(item?.note, item?.id)
                            }
                          >
                            {t("Xem thêm")}
                          </button>
                        ) : null}
                      </td>
                      <td>{item?.fixer}</td>
                      <td
                        style={{
                          color: item?.confirm === true ? "blue" : "red",
                        }}
                      >
                        {item?.confirm === true ? "Fixed" : "Bug"}
                      </td>
                      <td>{formatDateTime(item?.time)}</td>
                      <td>
                        <Button
                          onClick={() => {
                            setOpenEditDebug(true);
                            setGetItemEdit(item);
                          }}
                          label={t("Sửa")}
                          severity="info"
                          className="mr-2 mb-2 button-blue radius"
                        />
                        <Button
                          onClick={() => {
                            handleCopyClick(item);
                          }}
                          label="Copy"
                          severity="info"
                          className="mr-2 mb-2 button-blue radius"
                        />
                        <Button
                          onClick={() => handleDeleteBugOne(item.id)}
                          label={t("Xoá")}
                          className="mr-2 mb-2 button-red radius"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <ModalAcceptDebug
                setRefresh={setRefresh}
                refresh={refresh}
                getItemEdit={getItemEdit}
                setDebugAcc={setDebugAcc}
                debugAcc={debugAcc}
              />
              <EditSystemDebug
                setOpenEditDebug={setOpenEditDebug}
                openEditDebug={openEditDebug}
                getItemEdit={getItemEdit}
                setRefresh={setRefresh}
                refresh={refresh}
              />
              {showDescriptionModal && (
                <DescriptionModal
                  showDescriptionModal={showDescriptionModal}
                  setShowDescriptionModal={setShowDescriptionModal}
                  description={currentDescription}
                  onClose={() => setShowDescriptionModal(false)}
                />
              )}
            </div>
            <Paginator
              className="pagination-markerting"
              first={first}
              rows={rows}
              totalRecords={getTotal?.count}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
