import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { Dialog } from "primereact/dialog";
import { API_ROUTES, API_URL, API_ROUTES_ZALO } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { useUser } from "../../../contexts/UserContext";
import { MdLabel } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";
import { InputTextarea } from "primereact/inputtextarea";
import { IoMdAdd } from "react-icons/io";
// import moment from "moment-timezone";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { el, vi } from "date-fns/locale";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { deleteFileOnServer } from "../../../const/deleteFileOnServer";
import { IoInformationCircle } from "react-icons/io5";
export const ModalAutoInbox = ({
  setIsAutoInbox,
  isAutoInbox,
  getPageAtib,
  toast,
}) => {
  const headers = useHeaders();
  const { label } = useUser();
  const formData = new FormData();
  const [elementHeight, setElementHeight] = useState(0);
  const startInbox = API_URL + API_ROUTES.START_AUTO_INBOX_MESS;
  const getLinkImg = API_URL + API_ROUTES.GET_LINK_FILE;
  const getResult = API_URL + API_ROUTES.RESULT_AUTO_INBOX;
  const saveScriptContent = API_URL + API_ROUTES.SAVE_AUTO_INBOX_MESS;
  const getScriptContent = API_URL + API_ROUTES.GET_AUTO_INBOX_MESS;
  const statusInbox = API_URL + API_ROUTES.STATUS_AUTO_INBOX_MESS;
  const stopInbox = API_URL + API_ROUTES.STOP_AUTO_INBOX_MESS;
  const getAllCate = API_URL + API_ROUTES.GET_ALL_CATEGORY_BY_MESS;
  const isInitialRender = useRef(true);
  // const deleteFile = API_URL + API_ROUTES.DELETE_FILE;
  const [getResults, setGetResults] = useState([]);
  const [allContent, setAllContent] = useState([]);
  const [fileChanged, setFileChanged] = useState(false);
  const [getScript, setGetScript] = useState([]);
  const [callCount, setCallCount] = useState(0);
  const [number, setNumber] = useState(0);
  const [status, setStatus] = useState([]);
  const [changeStatus, setChangeStatus] = useState(false);
  const [indexImg, setIndexImg] = useState();
  const [getNhan, setGetNhan] = useState([]);
  const [elementButton, setElementButton] = useState();
  const [labelAcc, setLabelAcc] = useState([]);
  const inputRef = useRef(null);
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setHours(6, 0, 0, 0);
    return date;
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    date.setHours(22, 0, 0, 0);
    return date;
  });
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  const [contents, setContents] = useState([
    { id: 1, value: "", imageURL: null },
  ]);
  const handleStartDateChange = (date) => {
    if (date?.getTime() === endDate?.getTime()) {
      const newEndDate = new Date(date.getTime() + 15 * 60000);
      setEndDate(newEndDate);
    }
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    if (date?.getTime() === startDate?.getTime()) {
      const newStartDate = new Date(date.getTime() - 15 * 60000);
      setStartDate(newStartDate);
    }
    setEndDate(date);
  };
  const handleItemClick = (item) => {
    setGetNhan((prevGetNhan) => {
      if (prevGetNhan.includes(item)) {
        return prevGetNhan.filter((i) => i !== item);
      } else {
        return [...prevGetNhan, item];
      }
    });
  };
  const addContent = () => {
    const newId = contents.length;
    setContents([...contents, { id: newId + 1, value: "", imageURL: null }]);
  };
  const handleDeleteContent = (id) => {
    const newContents = contents.filter((content) => content.id !== id);
    setContents(newContents);
  };
  const [delayTime, setDelayTime] = useState(
    getScript?.delay_time || allContent?.delay_time || 60
  );

  const handleChangess = (e) => {
    // let value = parseInt(e.target.value);
    // if (value < 60) {
    //   value = 60;
    // }
    setDelayTime(e.target.value);
  };
  const getStatusInbox = async () => {
    const body = {
      id_account: Number(getPageAtib),
    };
    try {
      const res = await axios.post(statusInbox, body, headers);
      setStatus(res.data);
    } catch (err) {}
  };
  useEffect(() => {
    let intervalId;
    if (status === true) {
      setChangeStatus(true);
    } else if (status === false) {
      setChangeStatus(false);
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
      setChangeStatus(false);
    };
  }, [number, callCount, status, changeStatus]);

  const stopAutoInbox = async () => {
    const body = {
      id_account: Number(getPageAtib),
    };
    try {
      await axios.post(stopInbox, body, headers);
      setChangeStatus(false);
      getStatusInbox();
    } catch (err) {}
  };
  const postContent = async () => {
    if (
      !contents ||
      contents.length === 0 ||
      contents.some((item) => !item.value)
    ) {
      alert("Vui lòng nhập nội dung");
      return;
    }
    if (!getNhan || getNhan.length === 0) {
      alert("Vui lòng chọn nhãn");
      return;
    } else if (contents.length < 5) {
      alert("Vui lòng nhập ít nhất 5 nội dung");
      return;
    }
    const body = {
      id_account: Number(getPageAtib),
      contents: contents?.map((item, index) => ({
        message: item?.value,
        image: item.imageURL,
      })),
      list_category: getNhan?.map((item) => item.id),
      delay_time: Number(delayTime) || 60,
      send_to_user: 0,
      from_time:
        startDate instanceof Date
          ? startDate.toLocaleTimeString("en-GB", { hour12: false })
          : null,
      to_time:
        endDate instanceof Date
          ? endDate.toLocaleTimeString("en-GB", { hour12: false })
          : null,
    };
    try {
      await axios.post(saveScriptContent, body, headers);
      getContent();
      toast.success("Lưu thành công");
    } catch (err) {}
  };
  const getContent = async () => {
    if (!getPageAtib?.length) {
      return;
    }
    const body = { id_account: Number(getPageAtib) };
    try {
      const res = await axios.post(getScriptContent, body, headers);
      setGetScript(res.data);
    } catch (err) {}
  };
  const totalResult = getResults?.filter((item) => item.result === true);
  const startAutoInbox = async (type) => {
    if (getScript.length === 0) {
      alert("Tài khoản này chưa có kịch bản");
    } else {
      setNumber(allContent?.total_send || getScript?.total_send);
      setCallCount(0);
      const body = {
        type: type,
        id_account: Number(getPageAtib),
      };
      try {
        await axios.post(startInbox, body, headers);
        getStatusInbox();
        toast.success("Đang chạy");
      } catch (err) {}
    }
  };

  useEffect(() => {
    getContent();
  }, [getPageAtib]);
  const linkImage = async (index) => {
    const selectedFile = contents[index]?.imageURL;
    if (selectedFile !== null) {
      formData.append("file", selectedFile || "");
      try {
        const res = await axios.post(getLinkImg, formData, headers);
        const updatedContents = contents.map((content, i) =>
          i === index ? { ...content, imageURL: res.data.file } : content
        );
        setContents(updatedContents);
      } catch (err) {}
    }
  };
  useEffect(() => {
    if (getScript?.contents && getScript.account === Number(getPageAtib)) {
      setContents(
        getScript?.contents?.map((item, index) => ({
          id: index + 1,
          value: item.message,
          imageURL: item.image || null,
        }))
      );
      setDelayTime(getScript?.delay_time);
      setGetNhan(
        label?.filter((category) =>
          getScript?.list_category?.includes(category.id.toString())
        )
      );
      if (getScript?.from_time) {
        const fromTime = new Date();
        const [fromHours, fromMinutes] = getScript?.from_time.split(":");
        fromTime.setHours(fromHours, fromMinutes);
        setStartDate(fromTime);
      }
      if (getScript?.to_time) {
        const toTime = new Date();
        const [toHours, toMinutes] = getScript?.to_time.split(":");
        toTime.setHours(toHours, toMinutes);
        setEndDate(toTime);
      }
    } else {
      setContents([]);
    }
  }, [getScript]);
  useEffect(() => {
    if (fileChanged) {
      linkImage(indexImg);
      setFileChanged(false);
    }
  }, [fileChanged, contents]);

  const handleImageChange = (e, id, index) => {
    const file = e.target.files[0];
    const fileType = file.type;
    if (fileType?.startsWith("video/")) {
      alert("Vui lòng chọn một tệp hình ảnh, không phải video.");
      return;
    }
    setIndexImg(index);
    const updatedContents = contents.map((content) =>
      content.id === id ? { ...content, imageURL: file } : content
    );
    setContents(updatedContents);
    setFileChanged(true);
  };
  const getAllCateInbox = async () => {
    if (!getPageAtib) {
      return;
    }
    const body = { id_account: Number(getPageAtib) };
    try {
      const res = await axios.post(getAllCate, body, headers);
      setLabelAcc(res.data);
    } catch (err) {}
  };
  useEffect(() => {
    getAllCateInbox();
  }, [getPageAtib, isAutoInbox]);
  const showFileInput = (id) => {
    document.getElementById(`fileInput_${id}`).click();
  };
  useEffect(() => {
    const element = document.getElementById("select-eight");
    const element3 = document.getElementById("buttonheaders");
    if (element) {
      setElementHeight(element?.offsetHeight);
      setElementButton(element3?.offsetHeight);
    }
  }, []);
  const removeImage = (index, content) => {
    deleteFileOnServer(content?.imageURL);
    setContents((prevItems) => {
      const newItems = [...prevItems];
      newItems[index].images = null;
      newItems[index].imageURL = null;
      return newItems;
    });
  };
  const handleAddSplitMarker = (index) => {
    const updatedContents = [...contents];
    updatedContents[
      index
    ].value = `${updatedContents[index]?.value}Xin chào [gender] [name] ! bên shop em có sản phẩm [gender] cần`;
    setContents(updatedContents);
    inputRef.current.focus();
  };
  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Remarketing Theo Nhãn"
        visible={isAutoInbox}
        onHide={() => setIsAutoInbox(false)}
        style={{ width: "70%" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="mb-2 ">
          <OverlayTrigger
            key="tooltip"
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-right" className="custom-tooltip">
                <div className="text-start">
                  Vui lòng nhập nhiều nội dung. Hệ thống sẽ tự động lấy ngẫu
                  nhiên 1 nội dung để gửi tin nhắn. Giúp tài khoản giảm bị hạn
                  chế tính năng do cơ chế chống spam của Facebook
                </div>
              </Tooltip>
            }
          >
            <span
              style={{
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              <IoInformationCircle size={15} />
            </span>
          </OverlayTrigger>
        </div>

        <nav
          id="heightTwo"
          className="w-100 d-flex p-2 gap-2 align-items-center"
          style={{ height: "60px", background: "#545454" }}
        >
          <nav
            className="d-flex gap-2 align-items-center"
            style={{ width: "50%" }}
          >
            <p className="mb-0 text-light" style={{ fontSize: "12px" }}>
              Gửi tin nhắn đến khách hàng theo nhãn đã gán. Khuyến khích nhập
              nhiều nội dung. Hệ thông sẽ lấy ngẫu nhiên 1 nội dung để gửi. Sẽ
              giúp giảm bị hạn chế tính năng spam trùng nội dung !
            </p>
            <Button
              className="button-blue radius border border-light "
              onClick={postContent}
              style={{ height: "max-content" }}
            >
              Lưu
            </Button>
          </nav>
          <nav className="d-flex gap-4" style={{ width: "50%" }}>
            <Button
              className="border border-light w-100"
              onClick={() => startAutoInbox("new")}
              id="buttonheaders"
              style={{
                background: `${changeStatus === true ? "#000" : "#054ddb"}`,
                border: `${changeStatus === true ? "#000" : "#054ddb"}`,
              }}
              disabled={changeStatus}
            >
              {changeStatus === true ? "ĐANG CHẠY" : "CHẠY"}
            </Button>
            <Button
              onClick={() => startAutoInbox("")}
              style={{
                background: `${changeStatus === true ? "#000" : "#bf9411"}`,
                border: `${changeStatus === true ? "#000" : "#054ddb"}`,
              }}
              className=" border border-light w-100"
              disabled={changeStatus}
            >
              {changeStatus === true ? "ĐANG CHẠY" : "CHẠY TIẾP"}
            </Button>
            <Button
              onClick={() => stopAutoInbox()}
              className={`${
                changeStatus ? "bg-danger" : "bg-secondary"
              } border border-light w-100`}
              disabled={!changeStatus}
            >
              DỪNG
            </Button>
          </nav>
        </nav>
        <div
          className="d-flex "
          style={{
            height: "60vh",
            overflow: "auto",
            border: "1px solid",
          }}
        >
          <div
            className="border-dark p-2"
            style={{
              width: "50%",
              borderRight: "1px solid",
              overflow: "auto",
            }}
          >
            {contents?.map((content, index) => (
              <div
                key={content.id}
                className="card flex justify-content-center "
                style={{ border: "0", marginBottom: "10px" }}
              >
                <nav className="d-flex justify-content-between mt-3">
                  <div className="d-flex align-items-center">
                    <p className="mb-0 mr-2 fw-bold">Nội dung {index + 1}</p>
                    <OverlayTrigger
                      key="tooltip"
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-right" className="custom-tooltip">
                          <div className="text-start">
                            Nội dung viết xuống dòng bằng cách bấm phím enter
                            hoặc tổ hợp phím shift+enter
                            <br />
                            Lưu ý
                            <br />
                            Mẫu trả lời tự động bắt buộc phải có [gender] và
                            [name]
                            <br />
                            [gender] - Chèn giới tính của khách hàng
                            <br />
                            [name] - Chèn tên của khách hàng
                            <br />
                            - Ví dụ: xin chào [gender] [name] ! bên shop em có
                            sản phẩm [gender] cần.
                            <br />
                            sẽ là: xin chào anh Vũ ! bên shop em có sản phẩm anh
                            cần.
                          </div>
                        </Tooltip>
                      }
                    >
                      <span
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        <IoInformationCircle size={15} />
                      </span>
                    </OverlayTrigger>
                    <Button
                      className="button-blue radius"
                      style={{ padding: "6px 8px", marginLeft: "10px" }}
                      onClick={() => handleAddSplitMarker(index)}
                    >
                      Mẫu
                    </Button>
                    <Button
                      className="button-blue radius"
                      style={{ padding: "6px 8px", marginLeft: "10px" }}
                      onClick={() => {
                        setContents((pre) => [
                          ...pre,
                          {
                            id: contents.length + 1,
                            value: content?.value,
                            imageURL: content?.imageURL,
                          },
                        ]);
                      }}
                    >
                      Sao chép
                    </Button>
                  </div>
                  <MdDeleteForever
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDeleteContent(content.id)}
                    size={20}
                  />
                </nav>
                <InputTextarea
                  placeholder="Nhập nội dung tin nhắn"
                  value={content.value}
                  onChange={(e) => {
                    const newContents = contents?.map((c) =>
                      c.id === content.id ? { ...c, value: e.target.value } : c
                    );
                    setContents(newContents);
                  }}
                  rows={5}
                  cols={30}
                  ref={inputRef}
                />
                <div className="d-flex gap-2 mt-2">
                  <label htmlFor="fileInput">
                    <div className="d-flex">
                      {content?.imageURL || content?.images ? (
                        <></>
                      ) : (
                        <button
                          className="d-flex flex-column align-items-center gap-4 p-2 border border-secondary justify-content-between rounded mr-2"
                          onClick={() => showFileInput(content.id)}
                        >
                          <IoMdAdd />
                          <p className="mb-0">Chọn ảnh</p>
                        </button>
                      )}
                      <OverlayTrigger
                        key="tooltip"
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id="tooltip-right"
                            className="custom-tooltip"
                          >
                            <div className="text-start">
                              Hạn chế gửi kèm ảnh. Tính năng gửi kèm ảnh dễ bị
                              hạn chế tính năng trên tài khoản do cơ chế chống
                              spam của Facebook
                            </div>
                          </Tooltip>
                        }
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        >
                          <IoInformationCircle size={15} />
                        </span>
                      </OverlayTrigger>
                    </div>
                  </label>
                  <input
                    id={`fileInput_${content?.id}`}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => handleImageChange(e, content?.id, index)}
                  />
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    {content?.imageURL ? (
                      <img
                        style={{ width: "100px", height: "100%" }}
                        src={
                          content?.imageURL instanceof File
                            ? URL.createObjectURL(content?.imageURL)
                            : `${API_URL}/${content?.imageURL}`
                        }
                        alt="Selected"
                      />
                    ) : (
                      <img
                        style={{
                          width: "100px",
                          height: "100%",
                          display: content?.imageURL ? "block" : "none",
                        }}
                        src={
                          getScript?.id
                            ? `${API_URL}/${getScript?.contents[index]?.image}`
                            : ""
                        }
                        alt="Selectedsss"
                      />
                    )}
                    <FaTimes
                      onClick={() => removeImage(index, content)}
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        padding: "2px",
                        fontSize: "18px",
                        display:
                          content?.imageURL || content?.images
                            ? "block"
                            : "none",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
            <nav
              className="p-2 w-100 d-flex gap-2"
              style={{ marginBottom: "20px" }}
            >
              <Button
                className="button-blue radius mb-4 w-100"
                onClick={addContent}
              >
                Thêm nội dung
              </Button>
            </nav>
          </div>
          <div className="p-2" style={{ width: "50%" }}>
            <div className="d-flex flex-column gap-4 justify-content-between ">
              <div className="d-flex justify-content-between ">
                <nav
                  className="d-flex flex-column gap-2 justify-content-between"
                  style={{ width: "45%" }}
                >
                  <p className="mb-0 text-dark fw-bold">
                    *(1): Thời gian delay gửi tin nhắn ?
                  </p>
                  <OverlayTrigger
                    key="tooltip"
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-right" className="custom-tooltip">
                        <div className="text-start">
                          Thời gian delay tối thiểu 60s. Gửi tin nhắn quá nhanh
                          sẽ dễ bị hạn chế tài khoản do cơ chế chống spam của
                          Facebook
                          <br />- Khuyến khích để 300s để an toàn cho tài khoản.
                          Hành động quá nhanh sẽ dễ bị hạn chế tính năng trên
                          tài khoản do cơ chế chống spam của Facebook
                        </div>
                      </Tooltip>
                    }
                  >
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                    >
                      <IoInformationCircle size={15} />
                    </span>
                  </OverlayTrigger>
                  <input
                    className=" w-100   border-dark"
                    style={{
                      height: `${elementHeight}px`,
                      fontSize: "1rem",
                      border: "2px solid #ccc",
                      borderRadius: "6px",
                      paddingTop: "9px",
                      paddingBottom: "9px",
                    }}
                    type="number"
                    min={60}
                    name="delay_time"
                    value={delayTime}
                    onChange={handleChangess}
                    placeholder="Chọn thời gian (giây)"
                  />
                </nav>
                <nav
                  className="d-flex flex-column gap-2 justify-content-between"
                  style={{ width: "45%" }}
                >
                  <p className="mb-0 text-dark fw-bold">
                    *(2): Khung giờ gửi ?
                  </p>
                  <OverlayTrigger
                    key="tooltip"
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-right" className="custom-tooltip">
                        <div className="text-start">
                          Chức năng này giúp tài khoản của bạn chỉ gửi tin nhắn
                          cho khách hàng trong khung giờ đã được cài đặt.
                          <br />- Trong trường hợp số lượng khách hàng trong
                          nhãn của bạn lớn và trong khung giờ được cài đặt tài
                          khoản của bạn gửi tin nhắn chưa hết số lượng khách
                          hàng trong nhãn thì hệ thống sẽ tự động tạm dừng khi
                          hết khung giờ và tiếp tục gửi tiếp vào ngày hôm sau
                          khi đến khung giờ
                        </div>
                      </Tooltip>
                    }
                  >
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                    >
                      <IoInformationCircle size={15} />
                    </span>
                  </OverlayTrigger>
                  <div className="d-flex gap-2">
                    <nav className="d-flex gap-2 align-items-center">
                      <p className="mb-0 text-dark">Từ</p>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => handleStartDateChange(date)}
                        customInput={<ExampleCustomInput />}
                        locale={vi}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Giờ"
                        dateFormat="HH:mm"
                        placeholderText="Chọn giờ"
                        showPopperArrow={false}
                      />
                    </nav>

                    <nav className="d-flex gap-2 align-items-center">
                      <p className="mb-0 text-dark">Đến</p>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => handleEndDateChange(date)}
                        customInput={<ExampleCustomInput />}
                        locale={vi}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Giờ"
                        dateFormat="HH:mm"
                        placeholderText="Chọn giờ"
                        showPopperArrow={false}
                      />
                    </nav>
                  </div>
                </nav>
              </div>
              <div className="">
                <p className="mb-2 text-dark fw-bold">Chọn nhãn để gửi</p>
                <div
                  className="d-flex flex-wrap gap-4"
                  style={{ border: "1px solid", borderRadius: "6px" }}
                >
                  {labelAcc?.map((item, index) => (
                    <nav
                      key={index}
                      onClick={() => handleItemClick(item)}
                      style={{
                        cursor: "pointer",
                      }}
                      className="d-flex align-items-center"
                    >
                      <MdLabel size={30} style={{ color: `${item?.color}` }} />
                      <p
                        className="mb-0 text-dark"
                        style={{ fontSize: "12px" }}
                      >
                        {item?.name}
                      </p>
                    </nav>
                  ))}
                </div>
              </div>
              <div className="">
                <p className="mb-2 text-dark fw-bold">Nhãn được chọn</p>
                {getNhan?.length === 0 ? (
                  <div></div>
                ) : (
                  <div
                    className="d-flex flex-wrap gap-4"
                    style={{ border: "1px solid", borderRadius: "6px" }}
                  >
                    {getNhan?.map((item, index) => (
                      <nav
                        key={index}
                        onClick={() => handleItemClick(item)}
                        style={{
                          cursor: "pointer",
                        }}
                        className="d-flex align-items-center"
                      >
                        <MdLabel
                          size={30}
                          style={{ color: `${item?.color}` }}
                        />
                        <p
                          className="mb-0 text-dark"
                          style={{ fontSize: "12px" }}
                        >
                          {item?.name}
                        </p>
                      </nav>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {getResults?.length === 0 ? (
              <div></div>
            ) : (
              <div
                className="d-flex align-items-center p-2 gap-4"
                style={{ background: "#CDC4C5" }}
              >
                <h6 className="mb-0">
                  Tổng số tin đã gửi: {getResults?.length}
                </h6>
                <h6 className="mb-0">Gửi thành công: {totalResult?.length}</h6>
                <h6 className="mb-0">
                  Không gửi được: {getResults?.length - totalResult?.length}
                </h6>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};
