import React from "react";
import axios from "axios";
import { API_URL, API_ROUTES } from "../api";
export const downloadImage = async (url, filename, setDataFile) => {
  const proxyUrl = "https://cors-anywhere.herokuapp.com/";
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], filename, {
      lastModified: new Date().getTime(),
      type: blob.type,
    });
    setDataFile(file);
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Có lỗi xảy ra trong quá trình fetch:", error);
  }
};
