import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
import axios, { all } from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_NTD, API_ROUTES_ZALO } from "../../../api";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import noRequest from "../../container/client/no_request.png";
export const TableAddFrOfAccount = ({
  toast,
  setListFr,
  listFr,
  setRefresh,
  refresh,
  allAddFriend,
  dataScanRs,
  statusRun,
  setDataScanRs,
  selectedItems,
}) => {
  const headers = useHeaders();
  const acceptAddFriend = API_URL + API_ROUTES_ZALO.ACCEPT_ADD_FRIEND;
  const resultAcceptAddFriend =
    API_URL + API_ROUTES_ZALO.ACCEPT_ADD_FRIEND_RESULT;
  const deleteAddFriend = API_URL + API_ROUTES_ZALO.DELETE_ADD_FRIEND;
  const resultDeleteAddFriend =
    API_URL + API_ROUTES_ZALO.DELETE_ADD_FRIEND_RESULT;
  const getDataAllFr = () => {};
  const [acceptFriend, setAcceptFriend] = useState("");
  const [rejectFriend, setRejectFriend] = useState("");
  const [getTask, setGetTask] = useState([]);
  const [getTaskDele, setGetTaskDele] = useState([]);
  const [rowDataReject, setRowDataReject] = useState([]);
  const [rowDataAccept, setRowDataAccept] = useState([]);

  const getAcceptAddFriend = async (rowData) => {
    const body = {
      id_account: dataScanRs?.data?.id,
      fid: rowData?.userId,
    };
    setRowDataAccept(rowData);
    try {
      const res = await axios.post(acceptAddFriend, body, headers);
      setGetTask(res.data);
      getRsAcceptAddFriend(res.data);
      toast.info("Đang chấp nhận lời mời kết bạn");
    } catch (err) {}
  };
  const getRsAcceptAddFriend = async (taskid) => {
    const body = { id_task: taskid.id_task };
    try {
      const res = await axios.post(resultAcceptAddFriend, body, headers);
      if (res.data.status === "SUCCESS") {
        toast.success("Kết bạn thành công");
        const updatedDataScanRs = {
          ...dataScanRs,
          data: {
            ...dataScanRs.data,
            result: dataScanRs?.data?.result?.filter(
              (item) => item.userId !== rowDataAccept.userId
            ),
          },
        };
        setDataScanRs(updatedDataScanRs);
        setRowDataAccept([]);
      } else if (res.data.status === "FAILURE") {
        toast.error("Kết bạn thất bại");
      }
      setAcceptFriend(res.data.status);
    } catch (err) {
      setAcceptFriend("FAILURE");
      toast.error("Đã xảy ra lỗi khi kết bạn");
    }
  };
  useEffect(() => {
    if (getTask.id_task) {
      const interval = setInterval(() => {
        getRsAcceptAddFriend(getTask);
      }, 3000);
      if (acceptFriend === "SUCCESS" || acceptFriend === "FAILURE") {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [acceptFriend, getTask]);
  const getDeleteAddFriend = async (rowData) => {
    const body = {
      id_account: dataScanRs?.data?.id,
      fid: rowData?.userId,
    };

    try {
      const res = await axios.post(deleteAddFriend, body, headers);
      setGetTaskDele(res.data);
      getRejectAddFriend(res.data);
      toast.info("Đang xoá lời mời kết bạn");
    } catch (err) {}
  };
  const getRejectAddFriend = async (taskidRj) => {
    const body = { id_task: taskidRj.id_task };
    try {
      const res = await axios.post(resultDeleteAddFriend, body, headers);
      if (res.data && res.data.status === "SUCCESS") {
        toast.success("Xóa lời mời kết bạn thành công");
      } else if (res.data && res.data.status === "FAILURE") {
        toast.error("Xóa lời mời kết bạn thất bại");
      }
      setRejectFriend(res.data ? res.data.status : "UNKNOWN");
    } catch (err) {
      setRejectFriend("FAILURE");
      toast.error("Đã xảy ra lỗi khi xoá lời mời kết bạn");
    }
  };
  useEffect(() => {
    if (getTaskDele.id_task) {
      const interval = setInterval(() => {
        getRejectAddFriend(getTaskDele);
      }, 3000);
      if (rejectFriend === "SUCCESS" || rejectFriend === "FAILURE") {
        const updatedDataScanRs = {
          ...dataScanRs,
          data: {
            ...dataScanRs.data,
            result: dataScanRs?.data?.result?.filter(
              (item) => item.userId !== rowDataReject.userId
            ),
          },
        };
        setDataScanRs(updatedDataScanRs);
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [rejectFriend, getTaskDele]);
  const nameAndAvt = (rowData) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <img
          className="rounded-circle"
          style={{ width: "20px", height: "20px" }}
          src={rowData?.avatar}
        />
        <p>{rowData?.zaloName}</p>
      </div>
    );
  };
  const buttonOption = (rowData) => {
    return (
      <div style={{ width: "150px" }} className="d-flex gap-2">
        <Button
          className=" button-blue radius"
          style={{
            padding: "4px 6px",
            width: "50%",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => {
            getAcceptAddFriend(rowData);
          }}
        >
          Gửi kết bạn
        </Button>
        <Button
          className=" button-red radius"
          severity="danger"
          style={{
            padding: "4px 6px",
            width: "50%",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => {
            getDeleteAddFriend(rowData);
            setRowDataReject(rowData);
          }}
        >
          Xoá gợi ý
        </Button>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  return (
    <div style={{ border: "1px solid", width: "100%", height: "400px" }}>
      {selectedItems[0]?.length === 0 ? (
        <div className="d-flex flex-column h-100 justify-content-center align-items-center gap-2">
          <img src={noRequest} alt="" />
          Bạn chưa gửi lời mời kết bạn nào
        </div>
      ) : statusRun ? (
        <div className="d-flex flex-column h-100 justify-content-center align-items-center gap-2">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2em" }}></i>
        </div>
      ) : dataScanRs?.data?.result && dataScanRs?.data?.result.length > 0 ? (
        <DataTable
          size="small"
          value={dataScanRs?.data?.result}
          className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect custom-scrollbar"
          tableStyle={{ minWidth: "100%" }}
          rowClassName={(rowData) => rowClassName(rowData, listFr)}
          onRowClick={(e) => onRowClick(e.data, setListFr, listFr)}
        >
          {/* <Column
            header={headerCheckbox(
              listFr,
              dataScanRs?.data?.result,
              toggleSelectAll,
              setListFr
            )}
            body={(rowData) =>
              bodyCheckbox(rowData, listFr, setListFr, getDataAllFr)
            }
          /> */}
          <Column body={indexTemplate} header="STT"></Column>
          <Column
            body={(rowData) => nameAndAvt(rowData)}
            field="zaloName"
            header="Tên tài khoản"
            sortable
          ></Column>
          <Column body={(rowData) => buttonOption(rowData)} header="Tuỳ chọn" />
        </DataTable>
      ) : (
        <div className="d-flex flex-column h-100 justify-content-center align-items-center gap-2">
          <img src={noRequest} alt="" />
          Bạn không có lời mời kết bạn nào
        </div>
      )}
    </div>
  );
};
