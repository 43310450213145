import { useState, useEffect, useRef } from "react";
import { FaAngleDown } from "react-icons/fa";
import { MdLabel } from "react-icons/md";
import { getAxios } from "../../../const/getAxios";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { Checkbox } from "primereact/checkbox";
import { CreateLabels } from "../labelModal/CreateLable";
import { LuUserPlus2 } from "react-icons/lu";
import { IoReload } from "react-icons/io5";
import { MdGroupAdd } from "react-icons/md";
import { CreateGroupMember } from "./CreateGroupMember";
import { useHeaders } from "../../../const/headers";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
export const Classify = ({
  conversation,
  setConversation,
  setRfFiend,
  rfFriend,
  setModalSendStranger,
  categoryClassify,
  setCategoryClassify,
  rfClassify,
  setRfClassify,
  filterLabel,
  setIsAutoInbox,
  TbMessageChatbot,
  idAccout,
  toast,
  setReloadConver,
  reloadConver,
  allAccount,
}) => {
  const headers = useHeaders();
  const apiGetAllCate = API_URL + API_ROUTES_ZALO.GET_ALL_CATEGORY_MESS;
  const apiReload = API_URL + API_ROUTES_ZALO.RELOAD_CONVERSATIONS_ZALO;
  const apiResults = API_URL + API_ROUTES_ZALO.RESULTS_RELOAD_CONVER_ZALO;
  const [type, setType] = useState("all");
  const [isClassify, setIsClassify] = useState(false);
  const [checked, setChecked] = useState([]);
  const [openLabel, setOpenLabel] = useState(false);
  const [isCreateGr, setIsCreateGr] = useState(false);
  const [reloadStatus, setReloadStatus] = useState(false);
  const classifyRef = useRef();
  const intervalRef = useRef();
  const [taskRl, setTaskRl] = useState([]);
  const [statusReload, setStatusReload] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (classifyRef.current && !classifyRef.current.contains(event.target)) {
        setIsClassify(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [classifyRef]);
  useEffect(() => {
    getAxios(apiGetAllCate, setCategoryClassify);
  }, [rfClassify]);
  const handleSetConver = (types) => {
    setType(types);
    types === "all"
      ? setRfFiend(!rfFriend)
      : setConversation({
          results: conversation?.results?.filter(
            (item) => item.new_message === true
          ),
        });
  };
  const handleFilterLabel = (item) => {
    setChecked(item);
    if (item.id === "0") {
      setConversation(filterLabel);
      return;
    }
    let itemFilter = [];
    for (let i = 0; i < item.conversation?.length; i++) {
      itemFilter.push(
        filterLabel?.results.filter(
          (conver) => conver?.id === item.conversation[i]
        )[0]
      );
    }
    setConversation({
      results: itemFilter,
    });
  };
  const handleReload = async () => {
    const body = {
      id_account: Number(idAccout),
    };
    try {
      const res = await axios.post(apiReload, body, headers);
      setTaskRl(res.data.id_task);
      resultsQuitGr(res.data.id_task);
      toast.success("Đang quét");
      setReloadConver(!reloadConver);
      setStatusReload(true);
    } catch (err) {}
  };
  const resultsQuitGr = async (idtask) => {
    const body = {
      id_task: idtask,
    };
    if (idtask.length > 0) {
      try {
        const res = await axios.post(apiResults, body, headers);
        if (res.data.status === "SUCCESS") {
          toast.success("Quét thành công");
          setRfFiend(!rfFriend);
          setReloadConver(!reloadConver);
          setReloadStatus(true);
          setStatusReload(false);
          setTaskRl([]);
        } else if (res.data.status === "FAILURE") {
          toast.error("Quét thất bại");
          setStatusReload(false);
          setReloadStatus(true);
          setReloadConver(!reloadConver);
          setTaskRl([]);
        }
      } catch (error) {
        toast.error("Đã xảy ra lỗi khi Quét");
        setReloadStatus(true);
        setStatusReload(false);
        setTaskRl([]);
      }
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      resultsQuitGr(taskRl);
    }, 3000);
    if (reloadStatus) {
      setReloadStatus(false);
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [taskRl]);
  return (
    <div
      ref={classifyRef}
      className="d-flex flex-column w-100 h-100 justify-content-between p-2"
    >
      {idAccout && (
        <div className="d-flex gap-2 align-items-center">
          <LuUserPlus2
            onClick={() => setModalSendStranger(true)}
            cursor="pointer"
            size={30}
          />
          <TbMessageChatbot
            onClick={() => setIsAutoInbox(true)}
            cursor="pointer"
            size={30}
          />
          <MdGroupAdd
            cursor="pointer"
            onClick={() => setIsCreateGr(true)}
            size={30}
          />
          {allAccount?.filter((acc) => acc.id === Number(idAccout))[0]
            ?.reload_message ? (
            <ClipLoader size={30} />
          ) : (
            <IoReload onClick={handleReload} cursor="pointer" size={30} />
          )}
          <CreateLabels
            categoryClassify={categoryClassify}
            openLabel={openLabel}
            setOpenLabel={setOpenLabel}
            rfClassify={rfClassify}
            setRfClassify={setRfClassify}
          />
          <CreateGroupMember
            setRfFiend={setRfFiend}
            rfFriend={rfFriend}
            toast={toast}
            idAccout={idAccout}
            isCreateGr={isCreateGr}
            setIsCreateGr={setIsCreateGr}
          />
        </div>
      )}

      <div className="d-flex w-100 justify-content-between ">
        <div className="d-flex gap-2">
          <p
            className={`${type === "all" ? "border-classify" : ""}`}
            onClick={() => handleSetConver("all")}
            style={{ fontWeight: "700", cursor: "pointer" }}
          >
            Tất cả
          </p>
          <p
            className={`${type === "read" ? "border-classify" : ""}`}
            onClick={() => handleSetConver("read")}
            style={{ fontWeight: "700", cursor: "pointer" }}
          >
            Chưa đọc
          </p>
        </div>
        <div
          style={{
            cursor: "pointer",
            position: "relative",
            borderBottom: "3px solid #fff",
          }}
          className="d-flex gap-2 align-items-center"
        >
          <p
            onClick={() => setIsClassify(!isClassify)}
            style={{ fontWeight: "700" }}
          >
            Phân loại
          </p>
          <FaAngleDown onClick={() => setIsClassify(!isClassify)} />
          {isClassify && (
            <nav
              style={{
                height: "40vh",
                width: "15vw",
                background: "#ffffff",
                position: "absolute",
                top: "100%",
                left: "0",
                zIndex: "100",
                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",
              }}
              className="d-flex justify-content-between flex-column"
            >
              <nav
                style={{ borderBottom: "1px solid" }}
                className="h-100 d-flex flex-column gap-2 p-2 overflow-auto"
              >
                <p
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: "500",
                    lineHeight: "1.5",
                  }}
                >
                  Theo thẻ phân loại
                </p>
                {[...categoryClassify, { name: "Tất cả", id: "0" }]?.map(
                  (item, index) => (
                    <nav className="d-flex gap-2 align-items-center">
                      <Checkbox
                        onChange={(e) => handleFilterLabel(item)}
                        checked={checked.id === item.id}
                      ></Checkbox>
                      <nav className="h-100" style={{ width: "max-content" }}>
                        <MdLabel color={item.color} size={25} />
                      </nav>
                      <p
                        style={{
                          fontSize: "0.875rem",
                          fontWeight: "500",
                          lineHeight: "1.5",
                        }}
                        className="text-over"
                      >
                        {item.name}
                      </p>
                    </nav>
                  )
                )}
              </nav>
              <p
                onClick={() => setOpenLabel(true)}
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "18px",
                }}
                className="p-2 w-100 text-center cursor-pointer"
              >
                Quản lý thẻ phân loại
              </p>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
};
