import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
import { EditScriptClient } from "./EditScriptClient";
import { API_URL, API_ROUTES_NTD, API_ROUTES_ZALO } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { statusRunTools } from "../../../const/updateTitle";
import axios from "axios";
import { useTranslation } from "react-i18next";
export const ScriptAccount = ({
  allCateClient,
  setRefresh,
  refresh,
  setListScript,
  listScript,
  setManagerScript,
  setItemCate,
}) => {
  const deleteScript = API_URL + API_ROUTES_ZALO.DELETE_CATEGORY_FRIEND;
  const copyCate = API_URL + API_ROUTES_ZALO.COPY_CATEGORY_ADD_FRIEND;
  const headers = useHeaders();
  const [editCate, setEditCate] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const getDataAllFr = () => { };
  const { t } = useTranslation()
  const handleDeleteScript = async (item) => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá !");
    if (confirm) {
      const body = { id_category: item.id };
      try {
        await axios.post(deleteScript, body, headers);
        setRefresh(!refresh);
        setListScript([]);
      } catch (err) { }
    }
  };
  const handleCopyCate = async (item) => {
    const userName = prompt("Nhập tên kịch bản mới");
    if (userName === null) {
      return;
    }
    if (userName.trim() === "") {
      alert("Tên kịch bản không được để trống");
      return;
    }
    const body = { id_category: item.id, name: userName };
    try {
      await axios.post(copyCate, body, headers);
      setRefresh(!refresh);
      setListScript([]);
    } catch (err) { }
  };
  const optionAcc = (rowData) => {
    return (
      <div className="d-flex gap-1 btn-option-wrapper">
        <Button
          onClick={() => {
            setManagerScript(true);
            setItemCate(rowData);
          }}
          style={{ padding: "6px 8px" }}
          className="button-blue radius"
        >
          {t("Quản lý kịch bản")}
        </Button>
        <Button
          onClick={() => {
            handleCopyCate(rowData);
          }}
          style={{ padding: "6px 8px" }}
          className="button-blue radius"
        >
          {t("Sao chép kịch bản")}
        </Button>
        <Button
          onClick={() => {
            setEditCate(rowData);
            setModalEdit(true);
          }}
          style={{ padding: "6px 8px" }}
          className="button-blue radius"
        >
          {t("Chi tiết")}
        </Button>
        <Button
          severity="danger"
          onClick={() => handleDeleteScript(rowData)}
          style={{ padding: "6px 8px" }}
          className="button-red radius"
        >
          {t("Xoá")}
        </Button>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  const viewFr = (rowData) => {
    return (
      <div className="w-100 d-flex flex-wrap gap-2 align-items-center">
        <p>
          {rowData?.account?.length === 0
            ? "Chưa có tài khoản nào được gán"
            : `Có ${rowData?.account?.length} tài khoản trong kịch bản`}
        </p>
      </div>
    );
  };
  return (
    <div className="h-100" style={{ border: "1px solid", width: "100%" }}>
      <DataTable
        size="small"
        className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ width: "100%" }}
        value={allCateClient}
        rowClassName={(rowData) => rowClassName(rowData, listScript)}
        onRowClick={(e) => onRowClick(e.data, setListScript, listScript)}
      >
        <Column
          header={headerCheckbox(
            listScript,
            allCateClient,
            toggleSelectAll,
            setListScript
          )}
          body={(rowData) =>
            bodyCheckbox(rowData, listScript, setListScript, getDataAllFr)
          }
        />
        <Column body={indexTemplate} header={t("STT")}></Column>
        <Column field="name" sortable header={t("Tên kịch bản")}></Column>
        <Column
          body={(rowData) => viewFr(rowData)}
          header={t("Khách hàng được gán")}
        ></Column>
        <Column
          field="status"
          body={(rowData) => statusRunTools(rowData, t)}
          sortable
          header={t("Trạng thái")}
        ></Column>
        <Column
          body={(rowData) => optionAcc(rowData)}
          header={t("Tuỳ chọn")}
          style={{ width: "400px" }}
          className="options-category"
          headerClassName="option-header"
        ></Column>
      </DataTable>
      <EditScriptClient
        setRefresh={setRefresh}
        refresh={refresh}
        editCate={editCate}
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
      />
    </div>
  );
};
