import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
  rowClassName,
  onRowClick,
} from "../../../const/checkboxPrimeReactjs";
import { Chip } from "primereact/chip";
import { ModalCateMarket } from "./ModalCateMarket";
import { MultiSelect } from "primereact/multiselect";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TiTick } from "react-icons/ti";

export const ListAccMarketplace = ({
  dataFb,
  setListAccMkt,
  listAccMkt,
  setRefresh,
  refresh,
  toast,
  selecCate,
}) => {
  const getDataAllFr = () => {};
  const navigate = useNavigate();
  const headers = useHeaders();
  const [modalManage, setModalManage] = useState(false);
  const addCateInMkt = API_URL + API_ROUTES_NTD.ADD_CATEGORY_IN_ACCOUNT;

  const [itemManage, setItemManage] = useState([]);
  const publicCate = (rowData) => {
    const items = rowData?.category_marketplace || [];
    const displayItems = items.slice(0, 2);
    const hasMoreItems = items.length > 2;
    return (
      <div className="d-flex">
        <p
          className="rounded-pill mb-0 css-chip"
          style={{
            width: "max-content",
            display: items.length === 0 ? "none" : "block",
          }}
        >
          {displayItems.map((item, index) => (
            <Chip
              key={index}
              style={{ margin: "4px" }}
              label={
                item.name.length > 6 ? item.name.slice(0, 6) + "..." : item.name
              }
            />
          ))}
          {hasMoreItems && <span className="position-relative"> ......</span>}
        </p>
      </div>
    );
  };

  const manageCate = (rowData) => {
    return (
      <div className="d-flex justify-content-center align-items-center gap-2">
        <p>
          {selecCate?.account?.some((item) => item === rowData.id)
            ? "Đã đăng"
            : ""}
        </p>
        {selecCate?.account?.some((item) => item === rowData.id) ? (
          <TiTick size={15} className="text-primary" />
        ) : (
          ""
        )}
      </div>
    );
  };
  const nameAndAvt = (rowData) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <img
          className="rounded-circle"
          style={{ width: "20px", height: "20px" }}
          src={rowData?.avatar}
        />
        <p>{rowData?.name}</p>
      </div>
    );
  };
  return (
    <div className=" " style={{ border: "1px solid", width: "50%" }}>
      <DataTable
        size="small"
        value={
          selecCate.id
            ? dataFb?.filter(
                (item) =>
                  item.checkpoint === false &&
                  item.status_proxy === true &&
                  item.limit_market === false  
              )
            : []
        }
        className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ minWidth: "100%" }}
        rowClassName={(rowData) => rowClassName(rowData, listAccMkt)}
        onRowClick={(e) => onRowClick(e.data, setListAccMkt, listAccMkt)}
      >
        <Column
          header={headerCheckbox(
            listAccMkt,
            dataFb,
            toggleSelectAll,
            setListAccMkt
          )}
          body={(rowData) =>
            bodyCheckbox(rowData, listAccMkt, setListAccMkt, getDataAllFr)
          }
        />
        <Column field="UID" header="UID" sortable></Column>
        <Column
          body={(rowData) => nameAndAvt(rowData)}
          field="name"
          header="Tên tài khoản"
          sortable
        ></Column>
        {/* <Column
          field="category_marketplace"
          body={(rowData) => publicCate(rowData)}
          header="Kịch bản"
          sortable
        ></Column> */}
        <Column
          body={(rowData) => manageCate(rowData)}
          header="Trạng thái"
        ></Column>
      </DataTable>
      <ModalCateMarket
        refresh={refresh}
        setRefresh={setRefresh}
        modalManage={modalManage}
        setModalManage={setModalManage}
        itemManage={itemManage}
      />
    </div>
  );
};
