import { useState, useEffect } from "react";
import { postNoHeaders } from "../../../const/postAxios";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { MdOutlineGridView } from "react-icons/md";
import { FaListAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaChevronCircleUp } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { FaChevronCircleDown } from "react-icons/fa";
import { getNoheaders } from "../../../const/getAxios";
import { sortMinPrice, sortMaxPrice, sortByAz, sortByZa } from "./useSort";
export const ProductMiniShop = ({
  allCart,
  idAccountInloaction,
  idCategoryInlocation,
  allCateShop,
  allProduct,
  setAllProduct,
  setViewCart,
}) => {
  const navigate = useNavigate();
  const apiGetProduct = API_URL + API_ROUTES_ZALO.GET_ALL_PRODUCT_SHOP;
  const [listGrid, setListGrid] = useState(true);
  const [menuHidden, setMenuHidden] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [titleHidden, setTitleHidden] = useState(false);
  const handleHiddenTitle = (item) => {
    if (titleHidden.id) {
      setTitleHidden([]);
    } else {
      setTitleHidden(item);

    }
  };
  useEffect(() => {
    getNoheaders(
      `${apiGetProduct}?id_employee=${idAccountInloaction}&id_category=${idCategoryInlocation}`,
      setAllProduct
    );
    setFilteredProduct(allProduct);

  }, [idCategoryInlocation]);
  const handleGetValueSelect = (event) => {
    const value = event.target.value;
    let sortedProducts = [...allProduct];
    if (value === "1") {
      sortedProducts = sortMinPrice(sortedProducts);
    } else if (value === "2") {
      sortedProducts = sortMaxPrice(sortedProducts);
    } else if (value === "3") {
      sortedProducts = sortByAz(sortedProducts);
    } else {
      sortedProducts = sortByZa(sortedProducts);
    }
    setAllProduct(sortedProducts);
  };
  useEffect(() => {
    if (search.length === 0) {
      setAllProduct(filteredProduct);
    } else {
      const results = allProduct.filter((product) =>
        product.title.toLowerCase().includes(search.toLowerCase())
      );
      setAllProduct(results);
    }
  }, [search]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMenuHidden(false);
      } else {
        setMenuHidden(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="product-grids section">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 product-sidebar">
            <div className="single-widget search">
              <h3 className="respon-d-none">Tìm kiếm sản phẩm</h3>
              <form action="#">
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  placeholder="Tên sản phẩm..."
                />
                <button type="submit">
                  <i class="lni lni-search-alt"></i>
                </button>
              </form>
            </div>
            <div className="single-widget">
              <nav className="d-flex w-100 align-items-center justify-content-between">
                <h3 className="w-100">Thể loại</h3>
                <FaChevronCircleUp
                  onClick={() => setMenuHidden(!menuHidden)}
                  style={{
                    marginBottom: "34px",
                    transform: menuHidden ? "none" : "rotate(180deg)",
                  }}
                  size={25}
                />
              </nav>
              {menuHidden && (
                <ul className="list">
                  {allCateShop?.map((item, index) => (
                    item?.status !== 0 && (
                      <li key={index}>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(
                              `/shoplinkhome/${idAccountInloaction}/${item.id}`
                            )
                          }
                        >
                          <a
                            style={{
                              color:
                                item.id === Number(idCategoryInlocation)
                                  ? "#0000ef"
                                  : "",
                              fontWeight:
                                item.id === Number(idCategoryInlocation)
                                  ? "700"
                                  : "",
                            }}
                          >
                            {item?.name}
                          </a>
                        </li>
                      </li>
                    )

                  ))}
                </ul>
              )}
            </div>

          </div>
          <div className="col-lg-9 ">
            <div className="product-grids-head">
              <div className="product-grid-topbar">
                <div className="row align-items-center">
                  <div className="col-lg-7 col-md-8 ">
                    <div className="product-sorting respon-text-center">
                      <label for="sorting">Sắp xếp theo:</label>
                      <select
                        className="form-control"
                        id="sorting"
                        onChange={handleGetValueSelect}
                      >
                        <option value="2">Giá Cao - Thấp</option>
                        <option value="1">Giá Thấp - Cao</option>
                        <option value="3">Thứ tự A - Z</option>
                        <option value="4">Thứ tự Z - A</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-4 d-flex align-items-center justify-content-end gap-4">
                    <div className="d-flex flex-column align-items-center cart-items-shop position-relative respon-just-center">
                      <FaShoppingCart
                        onClick={() => setViewCart(true)}
                        cursor="pointer"
                        size={30}
                      />
                      {/* <p>Giỏ hàng</p> */}
                      {allCart?.items?.length > 0 && (
                        <p
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "-10px",
                            color: "#fff",
                            background: "red",
                            padding: "2px 8px",
                            borderRadius: "50%",
                          }}
                        >
                          {allCart?.items?.length}
                        </p>
                      )}
                    </div>
                    <nav>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                          className={`nav-link ${listGrid ? "active" : ""}`}
                          id="nav-grid-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-grid"
                          type="button"
                          role="tab"
                          aria-controls="nav-grid"
                          aria-selected="true"
                          onClick={() => setListGrid(true)}
                        >
                          <MdOutlineGridView />
                        </button>
                        <button
                          className={`nav-link ${listGrid ? "" : "active"}`}
                          id="nav-list-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-list"
                          type="button"
                          role="tab"
                          aria-controls="nav-list"
                          aria-selected="false"
                          onClick={() => setListGrid(false)}
                        >
                          <FaListAlt />
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="nav-grid"
                  role="tabpanel"
                  aria-labelledby="nav-grid-tab"
                >
                  <div className="row">
                    {allProduct
                      ?.filter((item) => item.status === 1)
                      ?.map((item, index) => (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(
                              `/showproduct/${idAccountInloaction}/${item.category}/${item.id}`
                            )
                          }
                          key={index}
                          className={
                            listGrid
                              ? "lg:col-4 col-md-6 col-6"
                              : "  col-lg-12 col-md-12 col-12"
                          }
                        >
                          <div
                            className={`single-product  ${listGrid ? "" : "singer-product-list"
                              }`}
                          >
                            <div className="product-image">
                              <img src={`${API_URL}/${item.images[0]}`} />
                              <div className="button-shop">
                                <button>Thêm vào giỏ hàng</button>
                              </div>
                            </div>
                            <div className="product-info">
                              <span className="category">
                                {
                                  allCateShop?.filter(
                                    (i) => i.id === item.category
                                  )[0]?.name
                                }
                              </span>
                              <h4
                                className={`title ${titleHidden?.id === item.id ? "" : "text-over"
                                  }`}
                              >
                                <a
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleHiddenTitle(item);
                                  }}
                                >
                                  {item.title}
                                </a>
                              </h4>
                              <div className="price">
                                <span>
                                  {item.variants
                                    ?.map(
                                      (option) =>
                                        `${option.price.toLocaleString(
                                          "vi-VN",
                                          {
                                            style: "currency",
                                            currency: "VND",
                                          }
                                        )}`
                                    )
                                    .join(" - ")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
