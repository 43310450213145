import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Image } from "primereact/image";
import { LuImagePlus } from "react-icons/lu";
import { getLinkOneFile } from "../../../../const/getLinkFile";
import { API_URL, API_ROUTES_ZALO, API_ROUTES } from "../../../../api";
export const ViewAvatarGr = ({
  idAcc,
  Button,
  headers,
  intervalRef,
  memberForcus,
  setRfChangeGr,
  rfChangeGr,
  toast,
}) => {
  const apiChangeAvt = API_URL + API_ROUTES_ZALO.CHANGE_AVATAR;
  const apiRsChangeAvt = API_URL + API_ROUTES_ZALO.RESULTS_CHANGE_AVATAR;
  const apiGetLink = API_URL + API_ROUTES.GET_LINK_FILE;
  const [taskCre, setTaskCre] = useState([]);
  const [nameGr, setNameGr] = useState(false);
  const inputFileRef = useRef(null);
  const [link, setLink] = useState(null);
  const handleClickGetLink = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleChangeAvt = async () => {
    if (idAcc) {
      const body = {
        for_group: true,
        file: link,
        id_account: Number(idAcc),
        id_group: memberForcus?.group_id,
      };

      try {
        const res = await axios.post(apiChangeAvt, body, headers);
        setTaskCre(res.data.id_task);
        resultsChangeAvt(res.data.id_task);
        toast.success("Đang đổi ảnh đại diện");
      } catch (err) {}
    }
  };
  const resultsChangeAvt = async (idtask) => {
    const body = {
      id_task: idtask,
    };
    if (idtask.length > 0) {
      try {
        const res = await axios.post(apiRsChangeAvt, body, headers);
        if (res.data.status === "SUCCESS") {
          setNameGr(true);
          setTaskCre([]);
        } else if (res.data.status === "FAILURE") {
          setNameGr(true);
          setTaskCre([]);
        }
      } catch (error) {
        setNameGr(true);
        setTaskCre([]);
      }
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      resultsChangeAvt(taskCre);
    }, 3000);
    if (nameGr) {
      setNameGr(false);
      setRfChangeGr(!rfChangeGr);
      toast.success("Thành công");
      setLink("");
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [taskCre]);
  return (
    <div
      className="d-flex gap-4 flex-column justify-content-center align-items-center"
      style={{ width: "50%" }}
    >
      <nav className="d-flex gap-4 w-100 justify-content-center align-items-center">
        <nav className="d-flex gap-2 flex-column justify-content-center align-items-center">
          <LuImagePlus
            onClick={handleClickGetLink}
            cursor="pointer"
            size={30}
          />
          <p>Chọn ảnh đại diện mới</p>
          <input
            type="file"
            ref={inputFileRef}
            style={{ display: "none" }}
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                getLinkOneFile(file, setLink, apiGetLink, headers);
              }
            }}
          />
        </nav>
        {link && (
          <div className=" flex justify-content-center">
            <Image src={`${API_URL}${link}`} alt="Image" width="250" preview />
          </div>
        )}
      </nav>
      <Button
        onClick={() => handleChangeAvt()}
        className="button-blue radius "
        style={{ width: "max-content" }}
        label="Đổi ảnh"
      />
    </div>
  );
};
