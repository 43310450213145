import { ToastContainer } from "react-toastify";
import { Chart } from "primereact/chart";
import { API_URL, API_ROUTES, API_ROUTES_ZALO } from "../../api";
import axios from "axios";
import { useHeaders } from "../../const/headers";
import Navigate from "../container/Navigate";
import { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { Footer } from "./Footer";
import { updateTitle } from "../../const/updateTitle";
import { getAxiosStatis } from "../../const/getAxios";
import "./Statistics.css";
import { useTranslation } from "react-i18next";
const Statistics = () => {
  updateTitle("Chốt Care - Thống kê");
  const [chartData, setChartData] = useState({});
  const [chartDataPost, setChartDataPost] = useState({});
  const [chartDataPostGroup, setChartDataPostGroup] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [chartOptionsPost, setChartOptionsPost] = useState({});
  const [chartOptionsPostGroup, setChartOptionsPostGroup] = useState({});
  const apiResultMkt = API_URL + API_ROUTES_ZALO.STATS_ADD_FRIEND;
  const apiStatsMessFriend =
    API_URL + API_ROUTES_ZALO.STATS_CAMPAIGN.MESSAGE_FRIEND;
  const apiStatsMessGroup =
    API_URL + API_ROUTES_ZALO.STATS_CAMPAIGN.MESSAGE_GROUP;
  const apiStatsMessPhone =
    API_URL + API_ROUTES_ZALO.STATS_CAMPAIGN.MESSAGE_PHONE;
  const apiStatsMessMember =
    API_URL + API_ROUTES_ZALO.STATS_CAMPAIGN.MESSAGE_MEMBER;
  const apiResultFriend = API_URL + API_ROUTES.STATUS_FRIEND;
  const apiResultPost = API_URL + API_ROUTES.STATUS_POST;
  const apiResultPostGroup = API_URL + API_ROUTES.GET_STATS_GROUP;
  const apiResultJoinGroup = API_URL + API_ROUTES.STATS_JOIN_GROUP;
  const [statsMessFriend, setStatsMessFriend] = useState([]);
  const [statsMessGroup, setStatsMessGroup] = useState([]);
  const [statsMessPhone, setStatsMessPhone] = useState([]);
  const [statsMessMember, setStatsMessMember] = useState([]);
  const [dataStats, setDataStats] = useState([]);
  const [dataStatsFriend, setDataStatsFriend] = useState([]);
  const [dataStatsPost, setDataStatsPost] = useState([]);
  const [dataStatsPostGroup, setDataStatsPostGroup] = useState([]);
  const [dataStatsJoinGroup, setDataStatsJoinGroup] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { t } = useTranslation();
  var startTime = new Date(startDate);
  var endTime = new Date(endDate);
  const startTimeConver = `${startTime.getFullYear()}-${startTime.getMonth() + 1
    }-${startTime.getDate()}`;
  const endTimeConver = `${endTime.getFullYear()}-${endTime.getMonth() + 1
    }-${endTime.getDate()}`;
  useEffect(() => {
    getAxiosStatis(
      apiResultJoinGroup,
      setDataStatsJoinGroup,
      startTimeConver,
      endTimeConver
    );
    getAxiosStatis(
      apiResultPostGroup,
      setDataStatsPostGroup,
      startTimeConver,
      endTimeConver
    );
    getAxiosStatis(
      apiResultPost,
      setDataStatsPost,
      startTimeConver,
      endTimeConver
    );
    getAxiosStatis(
      apiResultFriend,
      setDataStatsFriend,
      startTimeConver,
      endTimeConver
    );
    getAxiosStatis(apiResultMkt, setDataStats, startTimeConver, endTimeConver);
    getAxiosStatis(
      apiStatsMessFriend,
      setStatsMessFriend,
      startTimeConver,
      endTimeConver
    );
    getAxiosStatis(
      apiStatsMessGroup,
      setStatsMessGroup,
      startTimeConver,
      endTimeConver
    );
    getAxiosStatis(
      apiStatsMessPhone,
      setStatsMessPhone,
      startTimeConver,
      endTimeConver
    );
    getAxiosStatis(
      apiStatsMessMember,
      setStatsMessMember,
      startTimeConver,
      endTimeConver
    );
  }, [startDate, endDate]);
  const styles = {
    mb0ml2: {
      width: "50px",
      justifyContent: "flex-start",
    },
  };
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: [t("Kết bạn")],
      datasets: [
        {
          label: t("Thành công"),
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [dataStats?.success],
        },
        {
          label: t("Thất bại"),
          backgroundColor: "#808080",
          borderColor: "#808080",
          data: [dataStats?.fail],
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const successData = data.datasets[0].data;
              const failData = data.datasets[1].data;
              const totalData =
                successData[context.dataIndex] + failData[context.dataIndex];
              const successCount = successData[context.dataIndex];
              const failCount = failData[context.dataIndex];
              const label = context.dataset.label || "";
              if (label === "Thành công") {
                return `${label}: ${successCount} / ${totalData}`;
              } else if (label === "Thất bại") {
                return `${label}: ${failCount} / ${totalData}`;
              }
              return `${label}: ${context.raw}`;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [startDate, endDate, dataStats]);

  // useEffect(() => {
  //   const documentStyle = getComputedStyle(document.documentElement);
  //   const textColor = documentStyle.getPropertyValue("--text-color");
  //   const textColorSecondary = documentStyle.getPropertyValue(
  //     "--text-color-secondary"
  //   );
  //   const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
  //   const dataFriend = {
  //     labels: [
  //       "Nhắn tin cho bạn bè",
  //       "Nhắn tin đến số điện thoại",
  //       "Gửi tin nhắn vào nhóm",
  //       "Nhắn tin cho thành viên nhóm",
  //     ],
  //     datasets: [
  //       {
  //         label: "Thành công",
  //         backgroundColor: documentStyle.getPropertyValue("--blue-500"),
  //         borderColor: documentStyle.getPropertyValue("--blue-500"),
  //         data: [
  //           statsMessFriend?.success,
  //           statsMessPhone?.success,
  //           statsMessGroup?.success,
  //           statsMessMember?.success,
  //         ],
  //       },
  //       {
  //         label: "Thất bại",
  //         backgroundColor: "#808080", // Change this to your preferred gray color
  //         borderColor: "#808080",
  //         data: [
  //           statsMessFriend?.fail,
  //           statsMessPhone?.fail,
  //           statsMessGroup?.fail,
  //           statsMessMember?.fail,
  //         ],
  //       },
  //     ],
  //   };
  //   const optionsFriend = {
  //     maintainAspectRatio: false,
  //     aspectRatio: 0.8,
  //     plugins: {
  //       legend: {
  //         labels: {
  //           color: textColor,
  //         },
  //       },
  //       tooltip: {
  //         callbacks: {
  //           label: function (context) {
  //             const successData = dataFriend.datasets[0].data;
  //             const failData = dataFriend.datasets[1].data;
  //             const totalData =
  //               successData[context.dataIndex] + failData[context.dataIndex];
  //             const successCount = successData[context.dataIndex];
  //             const failCount = failData[context.dataIndex];
  //             const label = context.dataset.label || "";
  //             if (label === "Thành công") {
  //               return `${label}: ${successCount} / ${totalData}`;
  //             } else if (label === "Thất bại") {
  //               return `${label}: ${failCount} / ${totalData}`;
  //             }
  //             return `${label}: ${context.raw}`;
  //           },
  //         },
  //       },
  //     },
  //     scales: {
  //       x: {
  //         ticks: {
  //           color: textColorSecondary,
  //           font: {
  //             weight: 500,
  //           },
  //         },
  //         grid: {
  //           display: false,
  //           drawBorder: false,
  //         },
  //       },
  //       y: {
  //         ticks: {
  //           color: textColorSecondary,
  //         },
  //         grid: {
  //           display: false,
  //           color: surfaceBorder,
  //           drawBorder: false,
  //         },
  //       },
  //     },
  //   };

  //   setChartDataFr(dataFriend);
  //   setChartOptionsFr(optionsFriend);
  // }, [startDate, endDate, dataStatsFriend]);
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const dataPostGroup = {
      labels: [t("Đăng bài"), t("Tham gia nhóm")],
      datasets: [
        {
          label: t("Thành công"),
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [
            dataStatsPostGroup.status_success,
            dataStatsJoinGroup.status_success,
          ],
        },
        {
          label: t("Thất bại"),
          backgroundColor: "#808080", // Change this to your preferred gray color
          borderColor: "#808080",
          data: [
            dataStatsPostGroup.status_fail,
            dataStatsJoinGroup.status_fail,
          ],
        },
      ],
    };
    const optionsPostGroup = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const successData = dataPostGroup.datasets[0].data;
              const failData = dataPostGroup.datasets[1].data;
              const totalData =
                successData[context.dataIndex] + failData[context.dataIndex];
              const successCount = successData[context.dataIndex];
              const failCount = failData[context.dataIndex];
              const label = context.dataset.label || "";
              if (label === "Thành công") {
                return `${label}: ${successCount} / ${totalData}`;
              } else if (label === "Thất bại") {
                return `${label}: ${failCount} / ${totalData}`;
              }
              return `${label}: ${context.raw}`;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartDataPostGroup(dataPostGroup);
    setChartOptionsPostGroup(optionsPostGroup);
  }, [startDate, endDate, dataStatsPostGroup, dataStatsJoinGroup]);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const dataPost = {
      labels: [
        t("Nhắn tin cho bạn bè"),
        t("Nhắn tin đến số điện thoại"),
        t("Gửi tin nhắn vào nhóm"),
        t("Nhắn tin cho thành viên nhóm"),
      ],
      datasets: [
        {
          label: t("Thành công"),
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [
            statsMessFriend?.success,
            statsMessPhone?.success,
            statsMessGroup?.success,
            statsMessMember?.success,
          ],
        },
        {
          label: t("Thất bại"),
          backgroundColor: "#808080", // Change this to your preferred gray color
          borderColor: "#808080",
          data: [
            statsMessFriend?.fail,
            statsMessPhone?.fail,
            statsMessGroup?.fail,
            statsMessMember?.fail,
          ],
        },
      ],
    };
    const optionsPost = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const successData = dataPost.datasets[0].data;
              const failData = dataPost.datasets[1].data;
              const totalData =
                successData[context.dataIndex] + failData[context.dataIndex];
              const successCount = successData[context.dataIndex];
              const failCount = failData[context.dataIndex];
              const label = context.dataset.label || "";
              if (label === "Thành công") {
                return `${label}: ${successCount} / ${totalData}`;
              } else if (label === "Thất bại") {
                return `${label}: ${failCount} / ${totalData}`;
              }
              return `${label}: ${context.raw}`;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartDataPost(dataPost);
    setChartOptionsPost(optionsPost);
  }, [
    startDate,
    endDate,
    statsMessFriend,
    statsMessPhone,
    statsMessGroup,
    statsMessMember,
  ]);

  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <div
          style={{ backgroundColor: "fce0bd" }}
          className="dashboard w-100 d-flex flex-column h-100 mgtop-respon-47"
        >
          <div
            style={{ backgroundColor: "#1976d2" }}
            className="top-dash d-flex justify-content-between"
          >
            <div className="header-mobile p-3 d-flex justify-content-between w-100">
              <h4 style={{ color: "#fff" }} className="fs-2 mb-0 uppercase-respon">
                {t("Báo cáo")}
              </h4>
              <div className="d-flex gap-2">
                <div className="p-inputgroup w-280 mr-25">
                  <span className="p-inputgroup-addon" style={styles.mb0ml2}>
                    <p className="mb-0 ml-2">{t("Từ")}</p>
                  </span>
                  <Calendar
                    placeholder=""
                    locale="vi"
                    id="buttondisplay"
                    value={startDate}
                    onChange={(e) => {
                      if (endDate && e.value && e.value > endDate) {
                        alert("Ngày kết thúc không thể nhỏ hơn ngày bắt đầu");
                        return;
                      }
                      setStartDate(e.value);
                    }}
                    maxDate={endDate}
                    readOnlyInput
                    disabledDates={[]}
                  />
                </div>
                <div className="p-inputgroup ">
                  <span className="p-inputgroup-addon" style={styles.mb0ml2}>
                    <p className="mb-0 ml-2">{t("Đến")}</p>
                  </span>
                  <Calendar
                    placeholder=""
                    locale="vi"
                    id="buttondisplay"
                    value={endDate}
                    minDate={startDate}
                    onChange={(e) => {
                      if (startDate && e.value && e.value < startDate) {
                        alert("Ngày kết thúc không thể nhỏ hơn ngày bắt đầu");
                        return;
                      }
                      setEndDate(e.value);
                    }}
                    readOnlyInput
                    disabledDates={[]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="p-mobile d-flex flex-column w-100 gap-4"
            style={{
              maxHeight: "100%",
              overflowY: "scroll",
              backgroundColor: "#dee2e6",
              padding: "50px",
            }}
          >
            <div
              style={{ width: "100%", justifyContent: "space-around" }}
              className="chart-left d-flex gap-4 "
            >
              <div
                style={{ width: "40%", backgroundColor: "#fff" }}
                className="w-moblie d-flex flex-column card"
              >
                <div className="title">
                  <h4
                    className="w-100  p-2"
                    style={{
                      color: "#1976D2",
                    }}
                  >
                    {t("Kết bạn")}
                  </h4>
                </div>
                <div
                  style={{ boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)" }}
                  className="middle-dash h-100 d-flex text-center justify-content-around w-100  "
                >
                  <div className="feature mr-4 fs-6 lh-lg">
                    <h5>{t("Chức năng")}</h5>
                    <div className="text-start detail-dash">
                      <p>{t("Kết bạn")}:</p>
                    </div>
                  </div>
                  <div className="result mr-5 fs-6 lh-lg text-start">
                    <h5>{t("Kết quả")}</h5>
                    <p>{`${dataStats?.success}/${dataStats?.success + dataStats?.fail
                      }`}</p>
                  </div>
                  <div className="rate mr-4 fs-6 lh-lg text-start">
                    <h5>{t("Tỉ lệ")}</h5>
                    <p>
                      {dataStats?.success === 0 ||
                        dataStats?.success + dataStats?.fail === 0
                        ? 0
                        : (
                          (dataStats?.success /
                            (dataStats?.success + dataStats?.fail)) *
                          100
                        ).toFixed(2)}
                      %
                    </p>
                  </div>
                </div>
              </div>

              <div style={{ width: "56%" }} className="bot-dash">
                <div className="card">
                  <Chart
                    type="bar"
                    style={{
                      width: "100%",
                      height: "270px",
                      marginBottom: "15px",
                    }}
                    data={chartData}
                    options={chartOptions}
                  />
                </div>
              </div>
            </div>
            <div
              style={{ width: "100%", justifyContent: "space-around" }}
              className="chart-middle d-flex gap-4"
            >
              <div
                style={{ width: "40%", backgroundColor: "#fff" }}
                className="w-moblie d-flex flex-column card"
              >
                <div className="title">
                  <h4
                    className="w-100  p-2"
                    style={{
                      color: "#1976D2",
                    }}
                  >
                    {t("Chiến dịch")}
                  </h4>
                </div>
                <div
                  style={{ boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.14)" }}
                  className="middle-dash h-100 d-flex text-center justify-content-around w-100 "
                >
                  <div className="feature mr-4 fs-6 lh-lg text-start detail-dash">
                    <h5>{t("Chức năng")}</h5>
                    <div className="text-start detail-dash">
                      <p>{t("Nhắn tin cho bạn bè")}:</p>
                      <p>{t("Nhắn tin đến số điện thoại")}:</p>
                      <p>{t("Gửi tin nhắn vào nhóm")}:</p>
                      <p>{t("Nhắn tin cho thành viên nhóm")}:</p>
                    </div>
                  </div>
                  <div className="result mr-5 fs-6 lh-lg text-start">
                    <h5>{t("Kết quả")}</h5>
                    <p>{`${statsMessFriend?.success}/${statsMessFriend?.success + statsMessFriend?.fail
                      }`}</p>
                    <p>{`${statsMessPhone?.success}/${statsMessPhone?.success + statsMessPhone?.fail
                      }`}</p>
                    <p>{`${statsMessGroup?.success}/${statsMessGroup?.success + statsMessGroup?.fail
                      }`}</p>
                    <p>{`${statsMessMember?.success}/${statsMessMember?.success + statsMessMember?.fail
                      }`}</p>
                  </div>
                  <div className="rate mr-4 fs-6 lh-lg text-start">
                    <h5>{t("Tỉ lệ")}</h5>
                    <p>
                      {statsMessFriend?.success === 0 ||
                        statsMessFriend?.success + statsMessFriend?.fail === 0
                        ? 0
                        : (
                          (statsMessFriend?.success /
                            (statsMessFriend?.success +
                              statsMessFriend?.fail)) *
                          100
                        ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {(statsMessPhone?.success === 0 ||
                        statsMessPhone?.success + statsMessPhone?.fail === 0
                        ? 0
                        : (statsMessPhone?.success /
                          (statsMessPhone?.success + statsMessPhone?.fail)) *
                        100
                      ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {(statsMessGroup?.success === 0 ||
                        statsMessGroup?.success + statsMessGroup?.fail === 0
                        ? 0
                        : (statsMessGroup?.success /
                          (statsMessGroup?.success + statsMessGroup?.fail)) *
                        100
                      ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {(statsMessMember?.success === 0 ||
                        statsMessMember?.success + statsMessMember?.fail === 0
                        ? 0
                        : (statsMessMember?.success /
                          (statsMessMember?.success +
                            statsMessMember?.fail)) *
                        100
                      ).toFixed(2)}
                      %
                    </p>
                  </div>
                </div>
              </div>

              <div style={{ width: "56%" }} className="bot-dash">
                <div className="card">
                  <Chart
                    type="bar"
                    style={{
                      width: "100%",
                      height: "270px",
                      marginBottom: "15px",
                    }}
                    data={chartDataPost}
                    options={chartOptionsPost}
                  />
                </div>
              </div>
            </div>
            {/* <div
              style={{ width: "100%", justifyContent: "space-around" }}
              className="chart-right d-flex gap-4"
            >
              <div
                style={{ width: "40%", backgroundColor: "#fff" }}
                className="w-moblie d-flex flex-column card"
              >
                <div className="title ">
                  <h4
                    className="w-100 p-2"
                    style={{
                      color: "#1976D2",
                    }}
                  >
                    Quản lý khách hàng
                  </h4>
                </div>
                <div
                  style={{ boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)" }}
                  className="middle-dash h-100 d-flex text-center justify-content-around w-100"
                >
                  <div className="feature mr-5 fs-6 lh-lg ">
                    <h5>Chức năng</h5>
                    <div className="text-start">
                      <p>Like:</p>
                      <p>Bình luận:</p>
                      <p>Nhắn tin:</p>
                    </div>
                  </div>
                  <div className="result mr-4 fs-6 lh-lg text-start">
                    <h5>Kết quả</h5>
                    <p>{`${dataStatsFriend?.like_success}/${
                      dataStatsFriend?.like_success + dataStatsFriend?.like_fail
                    }`}</p>
                    <p>{`${dataStatsFriend?.comment_success}/${
                      dataStatsFriend?.comment_success +
                      dataStatsFriend?.comment_fail
                    }`}</p>
                    <p>{`${dataStatsFriend?.message_success}/${
                      dataStatsFriend?.message_success +
                      dataStatsFriend?.message_fail
                    }`}</p>
                  </div>
                  <div className="rate mr-4 fs-6 lh-lg text-start">
                    <h5>Tỉ lệ</h5>
                    <p>
                      {dataStatsFriend?.like_success === 0 ||
                      dataStatsFriend?.like_success +
                        dataStatsFriend?.like_fail ===
                        0
                        ? 0
                        : (
                            (dataStatsFriend?.like_success /
                              (dataStatsFriend?.like_success +
                                dataStatsFriend?.like_fail)) *
                            100
                          ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {dataStatsFriend?.comment_success === 0 ||
                      dataStatsFriend?.comment_success +
                        dataStatsFriend?.comment_fail ===
                        0
                        ? 0
                        : (
                            (dataStatsFriend?.comment_success /
                              (dataStatsFriend?.comment_success +
                                dataStatsFriend?.comment_fail)) *
                            100
                          ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {dataStatsFriend?.message_success === 0 ||
                      dataStatsFriend?.message_success +
                        dataStatsFriend?.message_fail ===
                        0
                        ? 0
                        : (
                            (dataStatsFriend?.message_success /
                              (dataStatsFriend?.message_success +
                                dataStatsFriend?.message_fail)) *
                            100
                          ).toFixed(2)}
                      %
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ width: "56%" }} className="bot-dash">
                <div className="card ">
                  <Chart
                    style={{
                      width: "100%",
                      height: "270px",
                      marginBottom: "15px",
                    }}
                    type="bar"
                    data={chartDataFr}
                    options={chartOptionsFr}
                  />
                </div>
              </div>
            </div> */}
            <div
              style={{ width: "100%", justifyContent: "space-around" }}
              className="chart-Post-Group d-flex gap-4"
            >
              <div
                style={{ width: "40%", backgroundColor: "#fff" }}
                className="w-moblie d-flex flex-column card"
              >
                <div className="title ">
                  <h4
                    className="w-100 p-2"
                    style={{
                      color: "#1976D2",
                    }}
                  >
                    {t("Quản lý nhóm")}
                  </h4>
                </div>
                <div
                  style={{ boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)" }}
                  className="middle-dash h-100 d-flex text-center justify-content-around w-100"
                >
                  <div className="feature mr-5 fs-6 lh-lg text-start ">
                    <h5>{t("Chức năng")}</h5>
                    <div className="text-start">
                      <p>{t("Đăng bài")}:</p>
                      <p>{t("Tham gia nhóm")}:</p>
                    </div>
                  </div>
                  <div className="result mr-4 fs-6 lh-lg text-start">
                    <h5>{t("Kết quả")}</h5>
                    <p>{`${dataStatsPostGroup.status_success}/${dataStatsPostGroup.status_success +
                      dataStatsPostGroup.status_fail
                      }`}</p>
                    <p>{`${dataStatsJoinGroup.status_success}/${dataStatsJoinGroup.status_success +
                      dataStatsJoinGroup.status_fail
                      }`}</p>
                  </div>
                  <div className="rate mr-4 fs-6 lh-lg text-start">
                    <h5>{t("Tỉ lệ")}</h5>
                    <p>
                      {dataStatsPostGroup.status_success === 0 ||
                        dataStatsPostGroup.status_success +
                        dataStatsPostGroup.status_fail ===
                        0
                        ? 0
                        : (
                          (dataStatsPostGroup.status_success /
                            (dataStatsPostGroup.status_success +
                              dataStatsPostGroup.status_fail)) *
                          100
                        ).toFixed(2)}
                      %
                    </p>
                    <p>
                      {dataStatsJoinGroup.status_success === 0 ||
                        dataStatsJoinGroup.status_success +
                        dataStatsJoinGroup.status_fail ===
                        0
                        ? 0
                        : (
                          (dataStatsJoinGroup.status_success /
                            (dataStatsJoinGroup.status_success +
                              dataStatsJoinGroup.status_fail)) *
                          100
                        ).toFixed(2)}
                      %
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ width: "56%" }} className="bot-dash">
                <div className="card ">
                  <Chart
                    style={{
                      width: "100%",
                      height: "270px",
                      marginBottom: "15px",
                    }}
                    type="bar"
                    data={chartDataPostGroup}
                    options={chartOptionsPostGroup}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Statistics;
