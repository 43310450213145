import React, { useEffect, useState } from "react";
import { updateTitle } from "./../../const/updateTitle";
import { toast, ToastContainer } from "react-toastify";
import Navigate from "../container/Navigate";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { API_ROUTES_ZALO, API_URL } from "./../../api";
import axios from "axios";
import "./ManageDomain.css";
import { useHeaders } from "./../../const/headers";
import { useTranslation } from "react-i18next";
import { Footer } from "./Footer";
const ManageDomain = () => {
  updateTitle("Chốt Care - Quản Lý Tên Miền");
  const hearder = useHeaders();
  const apiGetDomain = API_URL + API_ROUTES_ZALO.GET_DOMAIN;
  const apiCreateDomain = API_URL + API_ROUTES_ZALO.CREATE_OR_ADD_DOMAIN;
  const widthNavi = document.getElementById("width-navigate")?.offsetWidth;
  const [domain, setDomain] = useState("");
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();
  const createOrAdd = async () => {
    if (domain === "") {
      alert("Vui lòng nhập tên miền");
      return;
    }
    const body = {
      domain: domain,
    };
    try {
      const res = await axios.post(apiCreateDomain, body, hearder);
      setRefresh(!refresh);
      toast.success("Cập nhật thành công");
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  const getDomain = async () => {
    try {
      const res = await axios.get(apiGetDomain, hearder);
      setDomain(res.data);
    } catch (error) { }
  };
  useEffect(() => {
    getDomain();
  }, [refresh]);
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container" id="width-navigate">
          <Navigate />
        </div>
        <div
          className=" h-100 mobile-width mgtop-respon-47"
          style={{ width: `calc(100vw - ${widthNavi}px)` }}
        >
          <nav style={{ padding: "20px" }}>
            <h2>{t("Quản Lý Tên Miền")}</h2>
          </nav>
          <div style={{ height: "100%" }}>
            <div style={{ margin: "20px", border: "1px dashed" }}>
              <p
                style={{
                  padding: "20px",
                  fontSize: "16px",
                  lineHeight: "22px",
                }}
              >
                {t("Tên miền riêng để sử dụng các tính năng như Mini Shop, đăng hình ảnh trên tường, v.v..")}
                <br />
                {t("Sau khi thêm Tên miền riêng vào hệ thống, các sản phẩm trong Mini Shop sẽ có link dạng: https://[tên miền của bạn .com]/showproduct/xxxx")}
                <br />
                {t("Vui lòng trỏ tên miền của bạn về IP: 116.105.225.194 trước khi thêm vào hệ thống!")}
              </p>
            </div>
            <div style={{ margin: "20px" }}>
              <Card className="d-flex" title={t("Cấu hình tên miền riêng")}>
                <div className="d-flex justify-content-center flex-column gap-2">
                  <p>{t("Tên miền")}</p>
                  <div className="card flex justify-content-center">
                    <InputText
                      value={domain}
                      onChange={(e) => setDomain(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div>
                    <Button
                      onClick={createOrAdd}
                      className="d-flex justify-content-center button-blue radius"
                    >
                      {t("Cập nhật")}
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );
};

export default ManageDomain;
