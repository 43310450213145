import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_ZALO, API_ROUTES } from "../../../api";
import { IoIosAddCircle } from "react-icons/io";
import { Tooltip } from "primereact/tooltip";
import { FaEdit } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import { Paginator } from "primereact/paginator";
import ViewLabelInFr from "./ViewLabelInFr";
export const ModalLabelClient = ({
  openLabel,
  setOpenLabel,
  setRefresh,
  refresh,
  dataZalo,
  toast,
}) => {
  const headers = useHeaders();
  const apiAllFriend = API_URL + API_ROUTES_ZALO.GET_ALL_FRIEND;
  const [allLabelFr, setAllLabelFr] = useState([]);
  const apiGetLabel = API_URL + API_ROUTES_ZALO.GET_ALL_CATEGORY_MESS;
  const apiCreOrEdit = API_URL + API_ROUTES_ZALO.CREATE_OR_EDIT_LABEL_FR;
  const apiDelete = API_URL + API_ROUTES_ZALO.DELETE_LABEL_IN_FR;
  const apiAssign = API_URL + API_ROUTES.ADD_LABEL_CONVERSION;
  const [itemLabel, setItemLabel] = useState([]);
  const [allFriend, setAllFriend] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const [first, setFirst] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [listAccount, setListAccount] = useState([]);
  const [forcusLabel, setForcusLabel] = useState([]);
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
    getDataAllAddFr(itemAccount);
  };
  const [rows, setRows] = useState(100);
  const [itemAccount, setItemAccount] = useState([]);
  const getAllLabel = async () => {
    try {
      const res = await axios.get(apiGetLabel, headers);
      setAllLabelFr(res.data);
    } catch (err) {}
  };
  const handleCreateOrEditLabel = async (type, item) => {
    const promt = window.prompt("Nhập tên nhãn mới");
    const body = {
      id_label: type === "edit" ? item.id : null,
      name: promt,
    };
    if (!promt) {
      alert("Vui lòng nhập tên nhãn");
    }
    try {
      await axios.post(apiCreOrEdit, body, headers);
      toast.success("Thành công");
      getAllLabel();
    } catch (err) {}
  };
  const handleDelete = async (item) => {
    const body = {
      id_label: item.id,
    };
    try {
      await axios.post(apiDelete, body, headers);
      getAllLabel();
      toast.success("Xoá thành công");
    } catch (err) {
      toast.error("Đã có lỗi xảy ra");
    }
  };
  const getDataAllAddFr = async (item) => {
    setItemAccount(item);
    if (item?.id !== undefined) {
      try {
        const res = await axios.get(
          `${apiAllFriend}?number_per_page=${rows}&page=${
            totalPage + 1
          }&id_account=${item.id}`,
          headers
        );
        setAllFriend(res.data.results);
        setGetTotal(res.data);
      } catch (err) {}
    } else {
      setAllFriend([]);
    }
  };
  useEffect(() => {
    getAllLabel();
  }, [openLabel]);
  const handleChangeAcount = (item) => {
    if (listAccount?.includes(item)) {
      setListAccount(listAccount?.filter((acc) => acc.id !== item.id));
    } else {
      setListAccount((pre) => [...pre, item]);
    }
  };
  const handleAssign = async () => {
    if (listAccount?.length === 0 || !itemLabel?.id) {
      alert("Vui lòng chọn kịch bản và tải khoản muốn gán trước");
      return;
    }
    const body = {
      id_category: itemLabel.id,
      id_friends: listAccount.map((item) => item.id),
    };
    try {
      await axios.post(apiAssign, body, headers);
      toast.success("Gán nhãn thành công");
      getDataAllAddFr(itemAccount);
      setListAccount([]);
    } catch (err) {
      toast.error("Có lỗi xảy ra");
    }
  };
  return (
    <Dialog
      header="Quản lý nhãn"
      visible={openLabel}
      style={{ position: "relative", width: "60vw" }}
      onHide={() => setOpenLabel(false)}
      className="overFlowJoinGr"
      resizable={true}
    >
      <div className="d-flex h-100 flex-column gap-4">
        <div
          className="d-flex w-100 h-100 gap-4"
          style={{ overflowX: "auto", overflowY: "hidden" }}
        >
          {dataZalo?.map((item, index) => (
            <div
              onClick={() => getDataAllAddFr(item)}
              key={index}
              className="h-100 p-2 gap-2 d-flex flex-column align-items-center"
              style={{
                width: "max-content",
                cursor: "pointer",
                background: itemAccount?.id === item.id ? "aqua" : "",
              }}
            >
              <img
                style={{
                  height: "30px",
                  height: "30px",
                  borderRadius: "50%",
                }}
                src={item.avatar}
              />
              <p>{item.name}</p>
            </div>
          ))}
        </div>
        <div className="d-flex w-100 gap-2 h-100">
          <div
            className="d-flex flex-column w-100 h-100 gap-2"
            style={{ overflowX: "auto", overflowY: "hidden" }}
          >
            {itemAccount?.id && (
              <>
                <nav className="d-flex gap-2 align-items-center">
                  <p>Danh sách nhãn</p>
                  <>
                    <IoIosAddCircle
                      id={`tooltip-icon-label`}
                      size={30}
                      onClick={() => handleCreateOrEditLabel("")}
                      cursor="pointer"
                    />
                    <Tooltip
                      target={`#tooltip-icon-label`}
                      content={`Thêm nhãn mới`}
                      position="bottom"
                      mouseTrack
                      mouseTrackTop={15}
                    />
                  </>
                </nav>
                {allLabelFr?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => setItemLabel(item)}
                    className="cursor-pointer border border-2 justify-content-between rounded p-2 gap-2 d-flex gap-2 align-items-center"
                    style={{
                      width: "100%",
                      background: itemLabel?.id === item.id ? "aqua" : "",
                    }}
                  >
                    <p>{item.name}</p>
                    <nav className="d-flex gap-2">
                      <FaEdit
                        onClick={() => handleCreateOrEditLabel("edit", item)}
                        size={25}
                        cursor={"pointer"}
                      />
                      <FaDeleteLeft
                        onClick={() => handleDelete(item)}
                        color="red"
                        size={25}
                        cursor={"pointer"}
                      />
                    </nav>
                  </div>
                ))}
              </>
            )}
          </div>
          <div
            className="d-flex flex-column w-100 gap-4"
            style={{ overflowX: "auto", overflowY: "auto", height: "50vh" }}
          >
            {itemLabel?.id && (
              <>
                <p>Danh sách bạn bè</p>
                <div className="d-flex gap-2 h-100 flex-column overflow-auto">
                  {allFriend?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleChangeAcount(item)}
                      className="border border-2 rounded gap-2 w-100 justify-content-between p-2 d-flex justify-content-start align-items-center"
                      style={{
                        width: "max-content",
                        cursor: "pointer",
                        background: listAccount?.includes(item) ? "aqua" : "",
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <img
                          style={{
                            height: "30px",
                            height: "30px",
                            borderRadius: "50%",
                          }}
                          src={item.avatar}
                        />
                        <p>{item.name}</p>
                      </div>
                      <div className="flex justify-content-center">
                        <Button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setForcusLabel(item);
                          }}
                          className=" button-blue radius"
                          label="Nhãn"
                          style={{
                            padding: "8px 12px",
                            fontSize: "12px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div style={{ flexShrink: 0, position: "sticky", bottom: 0 }}>
                  <Paginator
                    style={{ height: "15%" }}
                    className="pagination-markerting"
                    first={first}
                    rows={rows}
                    totalRecords={getTotal?.count}
                    onPageChange={onPageChange}
                    rowsPerPageOptions={rowsPerPageOptions}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <ViewLabelInFr
          allFriend={allFriend}
          headers={headers}
          forcusLabel={forcusLabel}
          setForcusLabel={setForcusLabel}
          getDataAllAddFr={getDataAllAddFr}
          itemAccount={itemAccount}
        />
        <nav className="d-flex gap-2 justify-content-end w-100">
          <Button
            className=" button-blue radius"
            severity="danger"
            style={{
              padding: "8px 12px",
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => handleAssign()}
          >
            Gán nhãn
          </Button>
          <Button
            className=" button-red radius"
            severity="danger"
            style={{
              padding: "8px 12px",
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => setOpenLabel(false)}
          >
            Thoát
          </Button>
        </nav>
      </div>
    </Dialog>
  );
};
