import React, { useEffect, useRef } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES, API_ROUTES_ZALO } from "../../api";
import { Footer } from "./Footer";
import { Button } from "primereact/button";
import { useHeaders } from "../../const/headers";
import { InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { updateTitle } from "../../const/updateTitle";
import { EditProxy } from "../container/proxy/EditProxy";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ModalExpProxy } from "../container/account/ModalExpProxy";
import { useTranslation } from "react-i18next";
import { getAxios } from "../../const/getAxios";
import { CreateProxy } from "../container/proxy/CreateProxy";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
  onRowClick,
  rowClassName,
} from "../../const/checkboxPrimeReactjs";
export const ManagerProxy = () => {
  updateTitle("Chốt Care - Quản Lý Tài Khoản");
  const getListProxy = API_URL + API_ROUTES_ZALO.GET_PROXY;
  const deleteProxy = API_URL + API_ROUTES_ZALO.DELETE_PROXY;
  const checkProxy = API_URL + API_ROUTES_ZALO.CHECK_PROXY;
  const checkReultsProxy = API_URL + API_ROUTES_ZALO.CHECK_RESULTS_PROXY;
  const headers = useHeaders();
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [dataProxy, setDataProxy] = useState([]);
  const [search, SetSearch] = useState("");
  const [showData, setShowData] = useState(false);
  const [selectedItemEdit, setSelectedItemEdit] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [selectedUIDStatus, setSelectedUIDStatus] = useState([]);
  const [selectedUIDProxy, setSelectedUIDProxy] = useState([]);
  const [rfDataFb, setRfDataFb] = useState(false);
  const [taskId, setTaskId] = useState([]);
  const [taskIdProxy, setTaskIdProxy] = useState([]);
  const [dataRsAcc, setDataRsAcc] = useState("");
  const [dataRsProxy, setDataRsProxy] = useState("");
  const intervalRef = useRef();
  const { t } = useTranslation();
  const changeSearch = (event) => {
    const { value } = event.target;
    SetSearch(value);
  };
  const deleteSecletAccount = async () => {
    if (listUidCheck.length <= 0) {
      alert(t("Bạn phải chọn ít nhất 1 tài khoản để xóa"));
      return;
    }
    const confirm = window.confirm(
      t(
        "Lưu ý: Khi xoá tài khoản ra khỏi hệ thống. Tất cả các chiến dịch, kịch bản, nhãn khách hàng..., Dữ liệu liên quan đến tài khoản sẽ bị mất"
      )
    );
    if (confirm) {
      try {
        const body = { id_proxies: listUidCheck.map(Number) };
        await axios.post(deleteProxy, body, headers);
        setRefresh(!refresh);
      } catch (error) {
        toast.error(t("Xóa không thành công"));
      }
    }
  };
  const deleteOneAccount = async (item) => {
    const confirm = window.confirm(
      t(
        "Lưu ý: Khi xoá tài khoản ra khỏi hệ thống. Tất cả các chiến dịch, kịch bản, nhãn khách hàng..., Dữ liệu liên quan đến tài khoản sẽ bị mất"
      )
    );
    if (confirm) {
      try {
        const body = { id_proxies: [item.id] };
        const response = await axios.post(deleteProxy, body, headers);
        setRefresh(!refresh);
      } catch (error) {
        toast.error(t("Xóa không thành công"));
      }
    }
  };
  const listUidCheck = selectedItem?.map((item) => item.id);
  const checkAccountProxy = async () => {
    if (listUidCheck.length <= 0) {
      alert(t("Bạn phải chọn ít nhất 1 tài khoản để kiểm tra"));
    } else {
      setSelectedUIDProxy(listUidCheck);
      try {
        const response = await axios.post(
          checkProxy,
          { id_proxies: listUidCheck.map(Number) },
          headers
        );
        setTaskIdProxy(response.data.id_task);
        return response.data.status;
      } catch (error) {
        setSelectedUIDProxy([]);
        return null;
      }
    }
  };
  const checkResultsProxy = async () => {
    if (taskIdProxy?.length === 0) {
      return;
    }
    const body = { id_task: taskIdProxy };
    try {
      const res = await axios.post(checkReultsProxy, body, headers);
      setDataRsProxy(res.data);
      setRfDataFb(!rfDataFb);
    } catch (err) {
      setDataRsProxy(err.response.data);
      setSelectedUIDProxy([]);
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      checkResultsProxy();
    }, 3000);

    return () => clearInterval(intervalRef.current);
  }, [taskIdProxy]);
  useEffect(() => {
    if (
      dataRsProxy?.status === "SUCCESS" ||
      dataRsProxy?.status === "FAILURE"
    ) {
      setSelectedUIDProxy([]);
      setDataRsProxy([]);
      setTaskIdProxy([]);
      setRfDataFb(!rfDataFb);
      clearInterval(intervalRef.current);
    }
  }, [dataRsProxy]);
  useEffect(() => {
    getAxios(getListProxy, setDataProxy);
  }, [refresh, rfDataFb]);

  const handleEdit = (item) => {
    setShowData(true);
    setSelectedItemEdit(item);
  };
  const getDataAllFr = () => { };
  const statusBodyProxy = (item) => (
    <span
      style={{
        color: selectedUIDProxy.includes(item?.id)
          ? "#000"
          : item?.status === true
            ? "blue"
            : "red",
      }}
    >
      {t(
        selectedUIDProxy.includes(item?.id)
          ? "Đang kiểm tra"
          : item?.status === false
            ? "Không hoạt động"
            : "Hoạt động"
      )}
    </span>
  );
  const expiration = (item) => (
    <p>{`${item?.date_expiration?.split("T")[0].split("-")[2]}-${item?.date_expiration?.split("T")[0].split("-")[1]
      }-${item?.date_expiration?.split("T")[0].split("-")[0]}`}</p>
  );
  const buttonTemplate = (item) => (
    <td>
      <Button
        onClick={() => handleEdit(item)}
        className="button-blue radius"
        style={{
          marginRight: "8px",
          padding: "0.5rem 0.75rem",
        }}
      >
        {t("Sửa")}
      </Button>

      <Button
        className="button-red radius"
        style={{
          marginRight: "8px",
          padding: "0.5rem 0.75rem",
        }}
        onClick={() => {
          deleteOneAccount(item);
        }}
      >
        {t("Xoá")}
      </Button>
    </td>
  );
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };

  function filterByName(filterDataFb, searchKey) {
    return filterDataFb?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchKey.toLowerCase())
      )
    );
  }
  const resultPro = filterByName(dataProxy, search);

  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="d-flex h-100 w-100 mgtop-respon-47">
          <div
            className="h-100 d-flex flex-column"
            style={{
              width: "100%",
              borderRight: "0.5px solid",
              borderLeft: "0.5px solid",
            }}
          >
            <div className="d-flex gap-2 w-100 justify-content-between p-2">
              <Button
                style={{ width: "100%" }}
                onClick={() => setModalShow(true)}
                className="button-blue d-flex justify-content-center radius"
              >
                {t("Thêm Proxy")}
              </Button>
              <Button
                style={{
                  width: "100%",
                }}
                className="button-blue d-flex justify-content-center radius"
                onClick={() => {
                  checkAccountProxy();
                }}
                disabled={
                  selectedUIDStatus?.length !== 0 ||
                    selectedUIDProxy?.length !== 0
                    ? true
                    : false
                }
              >
                {t("Kiểm tra Proxy")}
              </Button>
              <Button
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  deleteSecletAccount();
                }}
                className="button-red d-flex justify-content-center radius"
              >
                {t("Xoá")}
              </Button>
              <InputGroup
                className="responsive-width-acc"
                style={{
                  width: "100%",
                  borderRadius: "0",
                }}
              >
                <Form.Control
                  placeholder={t("Tìm kiếm nhanh")}
                  aria-describedby="basic-addon2"
                  value={search}
                  onChange={changeSearch}
                  style={{
                    borderRadius: "0",
                    textAlign: "center",
                  }}
                />
              </InputGroup>
            </div>
            <div
              className=" h-100"
              style={{
                border: "1px solid",
                overflow: "auto",
                margin: "0 0.5rem 0.5rem 0.5rem",
              }}
            >
              <DataTable
                size="small"
                value={resultPro || dataProxy}
                className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect datatableoverflow"
                tableStyle={{ minWidth: "100%" }}
                rowClassName={(rowData) => rowClassName(rowData, selectedItem)}
                onRowClick={(e) =>
                  onRowClick(e.data, setSelectedItem, selectedItem)
                }
              >
                <Column
                  header={headerCheckbox(
                    selectedItem,
                    resultPro || dataProxy,
                    toggleSelectAll,
                    setSelectedItem
                  )}
                  body={(rowData) =>
                    bodyCheckbox(
                      rowData,
                      selectedItem,
                      setSelectedItem,
                      getDataAllFr
                    )
                  }
                />
                <Column body={indexTemplate} header={t("STT")}></Column>
                <Column className="rowProxy" field="proxy" header={t("Proxy")} sortable></Column>
                <Column field="note" header={t("Ghi chú")} sortable></Column>
                <Column
                  body={statusBodyProxy}
                  header={t("Trạng thái")}
                  field="status_proxy"
                  sortable
                ></Column>
                <Column
                  body={expiration}
                  header={t("Hạn sử dụng")}
                  field="status_proxy"
                  sortable
                ></Column>
                <Column body={buttonTemplate} header={t("Tuỳ chọn")}></Column>
              </DataTable>
              <CreateProxy
                setDataProxy={setDataProxy}
                refresh={refresh}
                t={t}
                headers={headers}
                setRefresh={setRefresh}
                modalShow={modalShow}
                setModalShow={setModalShow}
                toast={toast}
              />
              <EditProxy
                t={t}
                selectedItemEdit={selectedItemEdit}
                setSelectedItemEdit={setSelectedItemEdit}
                headers={headers}
                setRefresh={setRefresh}
                refresh={refresh}
                setShowData={setShowData}
                showData={showData}
                toast={toast}
              />
              <ModalExpProxy dataProxy={dataProxy} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
