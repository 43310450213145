import { Dialog } from "primereact/dialog";
import { IoIosLink } from "react-icons/io";
import { MdContentCopy } from "react-icons/md";
import { useHeaders } from "../../../const/headers";
import { API_ROUTES, API_URL } from "../../../api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useUser } from "../../../contexts/UserContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { InputTextarea } from "primereact/inputtextarea";
import { useNavigate } from "react-router-dom";
export const ContentZalo = () => {
  const navigate = useNavigate();
  const handleNavi = (link) => {
    navigate(link);
  };
  const { userInfo } = useUser();
  const [rf, setRf] = useState(false);
  const [image, setImage] = useState("");
  const [imageFirst, setImageFirst] = useState("");
  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");
  const [visible, setVisible] = useState(false);
  const [landing, setLanding] = useState([]);
  const [file, setFile] = useState(null);
  const headers = useHeaders();
  const uploadEndpoint = API_URL + API_ROUTES.UPLOAD_FILE_SERVER;
  const createUpdateEndpoint = API_URL + API_ROUTES.EDIT_LANDING;
  const apiGetLanding = API_URL + API_ROUTES.GET_ALL_LANDING;

  const fetchData = async () => {
    try {
      const res = await axios.get(apiGetLanding);
      setLanding(res.data);
    } catch (err) { }
  };

  useEffect(() => {
    fetchData();
  }, [rf]);

  const contentFistMain = landing?.filter((item) => item?.id === 1);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataURL = reader.result;
      const imgElement = document.getElementById("previewImage");
      if (imgElement) {
        imgElement.src = imageDataURL;
      }
    };
    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };
    reader.readAsDataURL(file);
  };

  const onChangeContent = (e) => {
    setContent(e.target.value);
  };

  const onChangeLink = (e) => {
    setDescription(e.target.value);
  };

  const uploadToServer = async () => {
    if (file !== null) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await axios.post(uploadEndpoint, formData, headers);
        setImage(response.data.image);
      } catch (error) { }
    }
  };

  useEffect(() => {
    setImage(contentFistMain[0]?.image);
    setDescription(contentFistMain[0]?.content);
    setContent(contentFistMain[0]?.title);
  }, [visible]);

  useEffect(() => {
    uploadToServer();
  }, [file]);

  const createResource = async () => {
    const data = {
      id: contentFistMain[0]?.id,
      title: content,
      content: description,
      image: image === "" ? contentFistMain[0]?.image : image,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription("");
      setContent("");
      setVisible(false);
    } catch (error) { }
  };
  return (
    <>
      <div className="hero section dark-background">
        <div className="container ">
          <div className="row gy-4 content-zalo " id="banner-zalo">
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center aos-init aos-animate">
              <h1>{contentFistMain[0]?.title}</h1>
              <p
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{
                  __html: contentFistMain[0]?.content,
                }}
              />
              <div className="d-flex">
                <button
                  onClick={() => handleNavi("/register")}
                  className="btn-get-started"
                >
                  Dùng ngay miễn phí
                </button>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img aos-init aos-animate" id="img-banner">
              <img
                src={`${API_URL}/${contentFistMain[0]?.image}`}
                className="img-fluid "
              />
            </div>
          </div>
        </div>
      </div>
      {userInfo?.is_admin === true ? (
        <button
          style={{
            width: "100%",
            border: "none",
            backgroundColor: "#0866ff",
            color: "#fff",
            fontSize: "1rem",
            padding: "0.5rem 1rem",
            cursor: "pointer",
            outline: "none",
            transition: "all 0.2s ease-in-out",
          }}
          onClick={() => setVisible(true)}
        >
          Sửa
        </button>
      ) : (
        <></>
      )}
      <Dialog
        header="Quản lý"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="d-flex flex-column gap-2 mr-2 mb-2">
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <MdContentCopy />
            </span>
            <InputText
              onChange={(e) => onChangeContent(e)}
              placeholder="Tiêu đề"
              value={content}
            />
          </div>
          <div className="p-inputgroup flex-1">
            <span className="p-inputgroup-addon">
              <IoIosLink />
            </span>
            <InputTextarea
              onChange={(e) => onChangeLink(e)}
              value={description}
              placeholder="Mô tả"
            />
          </div>
          <h3>Chọn Ảnh</h3>
          <label htmlFor="fileInput">
            <button
              className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
              onClick={() => document.getElementById("fileInput").click()}
            >
              <p className="mb-0">Chọn ảnh</p>
            </button>
          </label>
          <input
            id="fileInput"
            type="file"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          {image === "" && imageFirst === "" ? (
            <div></div>
          ) : (
            <img
              style={{
                width: "100px",
                display: "block",
              }}
              alt="Selected Image"
              src={`${API_URL}/${image === "" ? imageFirst : image}`}
            />
          )}
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className="button-blue radius"
            label="Sửa"
            onClick={() => createResource()}
          />
          <Button
            className="button-red radius"
            label="Hủy"
            onClick={() => setVisible(false)}
            severity="danger"
          />
        </div>
      </Dialog>
    </>
  );
};
