import { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FcAddImage } from "react-icons/fc";
import { FloatLabel } from "primereact/floatlabel";
import { InputTextarea } from "primereact/inputtextarea";
import axios from "axios";
import { Image } from "primereact/image";
import { TiDelete } from "react-icons/ti";
import { Button } from "primereact/button";
export const SendMessNewFriend = ({
  modalSendStranger,
  setModalSendStranger,
  textStranger,
  setTextStranger,
  phoneStranger,
  setPhoneStranger,
  setImageStranger,
  imageStranger,
  getLinkAttack,
  headers,
  API_URL,
  handleSendMessenger,
  messCard,
}) => {
  const inputImageRef = useRef();
  const linkAtack = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await axios.post(getLinkAttack, formData, headers);
      setImageStranger(res.data.file);
    } catch (err) {}
  };
  const handleFileChange = async () => {
    const fileInput = inputImageRef.current;
    const files = fileInput.files;
    await linkAtack(files[0]);
  };
  const handleIconClick = () => {
    inputImageRef.current.click();
  };
  return (
    <div className="flex justify-content-center">
      <Dialog
        header={
          messCard?.id
            ? `Gửi tin nhắn cho ${messCard?.attachments[0]?.title}`
            : "Gửi tin nhắn thủ công"
        }
        visible={modalSendStranger}
        onHide={() => setModalSendStranger(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="d-flex flex-column gap-4 mt-4 p-2">
          {!messCard?.id && (
            <FloatLabel className="w-100">
              <InputText
                value={phoneStranger}
                onChange={(e) => setPhoneStranger(e.target.value)}
                className="w-100"
              />
              <label for="username">Số điện thoại người nhận</label>
            </FloatLabel>
          )}
          <FloatLabel className="w-100">
            <InputTextarea
              value={textStranger}
              onChange={(e) => setTextStranger(e.target.value)}
              className="w-100"
            />
            <label for="username">Nội dung tin nhắn</label>
          </FloatLabel>
          {!messCard?.id && (
            <FcAddImage cursor="pointer" onClick={handleIconClick} size={30} />
          )}
          {imageStranger && (
            <nav style={{ width: "max-content", position: "relative" }}>
              <Image
                src={`${API_URL}${imageStranger}`}
                zoomSrc={`${API_URL}${imageStranger}`}
                width="80"
                height="60"
                preview
              />
              <TiDelete
                onClick={() => setImageStranger(null)}
                cursor="pointer"
                size={30}
                color="red"
                style={{ position: "absolute", top: "-10px", right: "-10px" }}
              />
            </nav>
          )}
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={inputImageRef}
            accept="image/*,video/*"
          />
        </div>
        <nav className="d-flex w-100 justify-content-end">
          <Button
            onClick={handleSendMessenger}
            className="button-blue radius"
            label="Gửi tin nhắn"
          />
        </nav>
      </Dialog>
    </div>
  );
};
