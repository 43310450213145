import { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import axios from "axios";
import { Button } from "primereact/button";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { Checkbox } from "primereact/checkbox";

export const CreateGroupMember = ({
  toast,
  isCreateGr,
  setIsCreateGr,
  idAccout,
  setRfFiend,
  rfFriend,
}) => {
  const apiGetFr = API_URL + API_ROUTES_ZALO.GET_ALL_FRIEND;
  const apiCreateGr = API_URL + API_ROUTES_ZALO.CREATE_GROUP;
  const apiRsCreate = API_URL + API_ROUTES_ZALO.RESULTS_CREATE_GROUP;
  const headers = useHeaders();
  const [scrollTop, setScrollTop] = useState(false);
  const [allFriend, setAllFriend] = useState([]);
  const element = document.getElementById("scorll-height-getFr");
  const [listUid, setListUid] = useState([]);
  const [nameGr, setNameGr] = useState("");
  const [taskGr, setTaskGr] = useState([]);
  const [createGr, setCreateGr] = useState(false);
  const intervalRef = useRef();
  element?.addEventListener("scroll", function () {
    if (element.scrollTop + element.clientHeight + 2 >= element.scrollHeight) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  });
  useEffect(() => {
    const getConver = async () => {
      try {
        const res = await axios.get(
          `${apiGetFr}?id_account=${idAccout}`,
          headers
        );
        setAllFriend(res.data);
      } catch (err) {}
    };
    getConver();
  }, [idAccout, isCreateGr]);
  useEffect(() => {
    const getAccoutScroll = async () => {
      const numberPage = allFriend.next.split("page=")[1];
      try {
        const res = await axios.get(
          `${apiGetFr}?id_account=${idAccout}&page=${numberPage}`,
          headers
        );
        setAllFriend((pre) => ({
          next: res?.data?.next,
          results: [...res?.data.results, ...pre?.results],
        }));
      } catch (err) {}
    };
    if (scrollTop && allFriend.next) {
      getAccoutScroll();
    }
  }, [scrollTop]);
  const handleGetUid = (item) => {
    if (listUid.includes(item.uid)) {
      setListUid(listUid?.filter((i) => i !== item.uid));
    } else {
      setListUid((pre) => [...pre, item.uid]);
    }
  };
  const handleCreateGr = async () => {
    console.log(nameGr);
    const body = {
      name: nameGr,
      id_account: idAccout,
      members: listUid,
    };
    if ((listUid.length > 0, !nameGr)) {
      alert("Vui lòng nhập tên và chọn ít nhất 1 bạn bè để tạo nhóm");
      return;
    }
    try {
      const res = await axios.post(apiCreateGr, body, headers);
      setTaskGr(res.data.id_task);
      resultsCreateGr(res.data.id_task);
      toast.success("Đang tạo nhóm");
    } catch (err) {}
  };
  const resultsCreateGr = async (idtask) => {
    const body = {
      id_task: idtask,
    };
    if (idtask.length > 0) {
      try {
        const res = await axios.post(apiRsCreate, body, headers);
        if (res.data.status === "SUCCESS") {
          toast.success("Tạo nhóm thành công");
          setRfFiend(!rfFriend);
          setCreateGr(true);
          //   navigate(`/homechat/${itemFriend.account}`);
          setTaskGr([]);
          setIsCreateGr(false);
        } else if (res.data.status === "FAILURE") {
          toast.error("Tạo nhóm thất bại");
          setCreateGr(true);
          setTaskGr([]);
        }
      } catch (error) {
        toast.error("Đã xảy ra lỗi khi Tạo nhóm");
        setCreateGr(true);
        setTaskGr([]);
      }
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      resultsCreateGr(taskGr);
    }, 3000);
    if (createGr) {
      setCreateGr(false);
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [taskGr]);
  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Tạo nhóm chat"
        visible={isCreateGr}
        onHide={() => setIsCreateGr(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div
          className="d-flex flex-column  overflow-auto "
          style={{ height: "70vh" }}
        >
          <div className="mt-4 mb-2">
            <FloatLabel className="w-100">
              <InputText
                value={nameGr}
                onChange={(e) => setNameGr(e.target.value)}
                className="w-100"
              />
              <label for="username">Nhập tên nhóm</label>
            </FloatLabel>
          </div>
          <div
            className="overflow-auto h-100 d-flex flex-column gap-2"
            id="scorll-height-getFr"
          >
            {allFriend?.results?.map((item, index) => (
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  onClick={() => handleGetUid(item)}
                  checked={listUid?.includes(item.uid)}
                ></Checkbox>
                <img
                  src={item.avatar}
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                />
                <p>{item?.name}</p>
              </div>
            ))}
          </div>
        </div>
        <nav className="d-flex w-100 justify-content-end gap-2">
          <Button
            onClick={() => setIsCreateGr(false)}
            className="button-red radius"
            label="Huỷ"
          />
          <Button
            onClick={() => handleCreateGr()}
            className="button-blue radius"
            label="Tạo nhóm"
          />
        </nav>
      </Dialog>
    </div>
  );
};
