import React, { useState, useEffect, memo, useRef } from "react";
import axios from "axios";
import Navigate from "../container/Navigate";
import "./style.css";
import _ from "lodash";
import { InputGroup, Form } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Footer } from "./Footer";
import { API_ROUTES, API_URL } from "../../api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoSearch } from "react-icons/io5";
import ShowLastMess from "../container/modal/ShowLastMess";
import EditOrder from "../container/modal/EditOrder";
import { useHeaders } from "../../const/headers";
import CreateOrder from "../container/modal/CreateOrder";
import { updateTitle } from "../../const/updateTitle";
import { ModalDataCsv } from "../container/modal/ModalDateCsv";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../const/checkboxPrimeReactjs";
import { Paginator } from "primereact/paginator";
import { onRowClick } from "../../const/checkboxPrimeReactjs";
import { rowClassName } from "../../const/checkboxPrimeReactjs";
import { getAxiosResults } from "../../const/getAxios";
import { IoInformationCircle } from "react-icons/io5";
import { Sidebar } from "primereact/sidebar";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const OderManager = ({ props }) => {
  updateTitle("Chốt Care - Quản Lý Đơn Hàng");
  const headers = useHeaders();
  const [selectedItems, setSelectedItems] = useState({});
  const [allOrder, setAllOrder] = useState();
  const [search, SetSearch] = useState("");
  const getAllOrder = API_URL + API_ROUTES.GET_ALL_ORDER;
  const deleteOrder = API_URL + API_ROUTES.DELETE_ORDER;
  const accessOrderApi = API_URL + API_ROUTES.ACCESS_ORDER;
  const [textareaValue, setTextareaValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [hiddenSearch, setHiddenSearch] = useState(false);
  const [showLast, setShowLast] = useState(false);
  const [getItem, setGetItem] = useState();
  const [modalEdit, setModalEdit] = useState(false);
  const [itemEdit, setItemEdit] = useState([]);
  const [modalCreate, setModalCreate] = useState(false);
  const [dataCsv, setDataCsv] = useState([]);
  const [openCsv, setOpenCsv] = useState(false);
  const [listOrder, setListOrder] = useState([]);
  const isInitialRender = useRef(true);
  const handleCreate = () => {
    setModalCreate(true);
  };
  const [dataSlier, setDataSlier] = useState([]);
  const [isOpenSlier, setIsOpenSlier] = useState(false);
  const [first, setFirst] = useState(0);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [rows, setRows] = useState(100);
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  const getDataAllFr = () => {};
  const handleOpenCsv = () => {
    setOpenCsv(true);
  };
  const clickEditModal = (item) => {
    setModalEdit(true);
    setItemEdit(item);
  };
  const onClickSearch = () => {
    setHiddenSearch(!hiddenSearch);
  };
  const searchChange = (event) => {
    const { value } = event.target;
    SetSearch(value);
  };

  const getSelectedUIDs = () => {
    return Object.keys(selectedItems).filter((key) => selectedItems[key]);
  };
  function formatDateTime(dateTimeString) {
    const dateObject = new Date(dateTimeString);
    return `${dateObject.getHours()}:${dateObject.getMinutes()}:${dateObject.getSeconds()} - ${dateObject.getDate()}/${
      dateObject.getMonth() + 1
    }/${dateObject.getFullYear()}`;
  }
  const selectedUIDs = getSelectedUIDs();
  function convertData(inputData) {
    const dataArray = inputData?.split("\n");
    const result = [];
    dataArray?.forEach((data) => {
      const [UID, name, cookie, proxy, access_token] = data?.split("|");
      result?.push({
        UID: UID,
        name: name,
        proxy: proxy,
        cookie: cookie,
        access_token: access_token,
      });
    });

    return result;
  }
  const inputData = textareaValue;
  const result = convertData(inputData);
  const deleteData = async () => {
    if (selectedUIDs.length < 0) {
      alert("Bạn phải chọn ít nhất 1 tài khoản để xóa");
    }
    const confirm = window.confirm("Bạn có chắc chắn muốn xóa không?");
    if (confirm) {
      try {
        const body = {
          id_orders: selectedUIDs.map((str) => parseInt(str, 10)),
        };
        const response = await axios.post(deleteOrder, body, headers);
        setRefresh(!refresh);
        setSelectedItems({});
      } catch (error) {
        toast.error("Xóa không thành công");
      }
    }
  };
  const deleteDataOne = async (item) => {
    const confirm = window.confirm("Bạn có chắc chắn muốn xóa không?");
    if (confirm) {
      try {
        const body = { id_orders: [item?.id] };
        const response = await axios.post(deleteOrder, body, headers);
        setRefresh(!refresh);
        setSelectedItems({});
      } catch (error) {
        toast.error("Xóa không thành công");
      }
    }
  };

  useEffect(() => {
    // getOrder();
    getAxiosResults(getAllOrder, setAllOrder, setGetTotal, rows, totalPage + 1);
  }, [refresh, totalPage, rows]);
  const clickAccess = async (getItem) => {
    const body = {
      id_order: getItem?.id,
    };
    try {
      const response = await axios.post(accessOrderApi, body, headers);
      setRefresh(!refresh);
      toast.success("Xác nhận thành công");
    } catch (error) {
      toast.error("Xác nhận không thành công");
    }
  };
  function filterByName(filterDataFb, searchKey) {
    return filterDataFb?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchKey.toLowerCase())
      )
    );
  }
  const resultPro = filterByName(allOrder, search);
  const heightHome =
    document.getElementById("footer")?.offsetHeight +
    document.getElementById("orderheigt")?.offsetHeight;
  const time = (rowData) => {
    return (
      <>
        <p>{formatDateTime(rowData?.created_at)}</p>
      </>
    );
  };
  const detailClient = (rowData) => {
    return (
      <>
        <p>{`${rowData?.name_order}-${rowData?.phone_number}`}</p>
      </>
    );
  };
  const listButton = (rowData) => {
    return (
      <nav className="d-flex gap-2">
        <Button
          style={{ padding: "6px 8px" }}
          className="button-blue radius"
          onClick={() => clickEditModal(rowData)}
        >
          Sửa
        </Button>
        <Button
          style={{
            padding: "6px 8px",
            display: rowData?.status === 1 ? "none" : "",
          }}
          className="button-blue radius"
          onClick={() => {
            clickAccess(rowData);
          }}
          severity="danger"
        >
          Xác nhận
        </Button>
        <Button
          style={{ padding: "6px 8px" }}
          className="button-red radius"
          onClick={() => {
            deleteDataOne(rowData);
          }}
          severity="danger"
        >
          Xóa
        </Button>
      </nav>
    );
  };
  const status = (rowData) => {
    return (
      <>
        <p
          style={{
            color:
              rowData?.status === 0
                ? "#000"
                : rowData?.status === 1
                ? "blue"
                : "red",
          }}
        >
          {rowData?.status === 0
            ? "Chưa xác nhận"
            : rowData?.status === 1
            ? "Đã xác nhận"
            : "Huỷ đơn hàng"}
        </p>
      </>
    );
  };
  const detailsAddress = (rowData) => (
    <div className="d-flex align-items-center justify-content-between">
      <span>{rowData.address}</span>
      <OverlayTrigger
        key="tooltip"
        placement="top"
        overlay={
          <Tooltip id={`tooltip-top-${rowData.id}`}>
            {`${rowData.address} - ${rowData.ward} - ${rowData.district} - ${rowData.city}`}
          </Tooltip>
        }
      >
        <span style={{ cursor: "pointer", marginLeft: "5px" }}>
          <IoInformationCircle size={15} />
        </span>
      </OverlayTrigger>
    </div>
  );
  const widthNavi = document.getElementById("width-navigate")?.offsetWidth;

  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container" id="width-navigate">
          <Navigate />
        </div>
        <div
          className=" d-flex flex-column"
          style={{ width: `calc(100vw - ${widthNavi}px)` }}
        >
          <div
            className="d-flex p-2 gap-2 flite-account-manage"
            id="orderheigt"
          >
            <div
              className="d-flex align-items-center justify-content-end  w-100 box-sizing-border-box "
              style={{ gap: "10px" }}
            >
              <Button
                style={{
                  borderRadius: "0",
                  width: "200px",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
                className="button-blue radius"
                onClick={() => handleCreate()}
              >
                Thêm đơn hàng
              </Button>

              <ModalDataCsv
                dataCsv={dataCsv}
                allOrder={allOrder}
                setOpenCsv={setOpenCsv}
                openCsv={openCsv}
                listOrder={listOrder}
              />
              <CreateOrder
                refresh={refresh}
                setRefresh={setRefresh}
                modalCreate={modalCreate}
                setModalCreate={setModalCreate}
              />
              <Button
                style={{
                  borderRadius: "0",
                  width: "200px",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
                className="button-blue radius"
                onClick={() => {
                  handleOpenCsv();
                }}
              >
                Xuất file Excel
              </Button>
              <Button
                style={{
                  borderRadius: "0",
                  width: "200px",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
                className="button-red radius"
                severity="danger"
                onClick={() => {
                  deleteData();
                }}
              >
                Xóa
              </Button>
            </div>
            <InputGroup
              className="responsive-width-acc"
              style={{
                width: "auto",
                borderRadius: "0",
                width: "200px",
              }}
            >
              <Form.Control
                placeholder="Tìm kiếm nhanh"
                aria-describedby="basic-addon2"
                value={search}
                style={{
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  display: `${hiddenSearch === true ? "none" : ""}`,
                }}
                onChange={searchChange}
              />

              <IoSearch
                onClick={onClickSearch}
                className="responsive-hidden-search"
                style={{ display: "none", fontSize: "42px" }}
              />
            </InputGroup>
          </div>
          {/* <Container className="container-boostrap h-100"> */}
          <div
            className="d-flex"
            style={{
              gap: "20px",
              height: `calc(100vh - ${heightHome}px)`,
              padding: "0.5rem",
            }}
          >
            <div className="h-100 w-100 " style={{ border: "0.5px solid" }}>
              <DataTable
                size="small"
                value={resultPro || allOrder}
                className="small-font-table dataTableWithFixedOrder dataTableWithHoverEffect datatableoverflow css-overflow"
                tableStyle={{ minWidth: "100%" }}
                rowClassName={(rowData) => rowClassName(rowData, listOrder)}
                onRowClick={(e) => onRowClick(e.data, setListOrder, listOrder)}
              >
                <Column
                  header={headerCheckbox(
                    listOrder,
                    resultPro || allOrder,
                    toggleSelectAll,
                    setListOrder
                  )}
                  body={(rowData) =>
                    bodyCheckbox(rowData, listOrder, setListOrder, getDataAllFr)
                  }
                />
                <Column
                  body={(rowData) => detailClient(rowData)}
                  field="name_order"
                  header="Thông tin khách hàng"
                ></Column>
                <Column field="product" header="Sản phẩm"></Column>
                <Column
                  field="quantity"
                  header="Số lượng"
                  style={{ textAlign: "right" }}
                  sortable
                ></Column>
                <Column
                  field="price"
                  header="Thành tiền"
                  style={{ textAlign: "right" }}
                  sortable
                  body={(rowData) =>
                    `${rowData.price.toLocaleString("vi-VN")} đ`
                  }
                ></Column>
                <Column
                  body={detailsAddress}
                  field="address"
                  header="Địa chỉ"
                ></Column>
                {/* <Column
                  field="order_employee_username"
                  header="Nhân viên"
                ></Column> */}
                <Column
                  body={(rowData) => time(rowData)}
                  field="created_at"
                  header="Ngày đặt hàng"
                  sortable
                ></Column>
                <Column field="note" header="Ghi chú"></Column>
                <Column
                  body={(rowData) => status(rowData)}
                  field="status"
                  header="Trạng thái"
                ></Column>
                <Column
                  className="width-option"
                  body={(rowData) => listButton(rowData)}
                  header="Tuỳ chọn"
                ></Column>
              </DataTable>
              <Paginator
                className="pagination-markerting"
                first={first}
                rows={rows}
                totalRecords={getTotal?.count}
                onPageChange={onPageChange}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            </div>
          </div>
        </div>
      </div>
      <EditOrder
        itemEdit={itemEdit}
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <ShowLastMess
        setRefresh={setRefresh}
        refresh={refresh}
        clickAccess={clickAccess}
        getItem={getItem}
        setShowLast={setShowLast}
        showLast={showLast}
      />
      <Footer />
    </div>
  );
};

export default memo(OderManager);
