import React, { useState, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES } from "../../../api";
import { Button } from "primereact/button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "../../../contexts/UserContext";
import ModalRegister from "../register/ModalResgister";
import { useTranslation } from "react-i18next";
export default function AlertRegister() {
  const { setRfRegister, rfRegister, register, setModalRe } = useUser();
  const headers = useHeaders();
  const create = API_URL + API_ROUTES.CREATE_UPDATE_REGISTER;
  const upload = API_URL + API_ROUTES.UPLOAD_FILE_SERVER;
  const [value, setValue] = useState(register[0]?.content || "");
  const [fileImg, setFileImg] = useState(null);
  const [imageLink, setImageLink] = useState(null);
  const { t } = useTranslation()
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileImg(file);
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataURL = reader.result;
      const imgElement = document.getElementById("previewImage");
      if (imgElement) {
        imgElement.src = imageDataURL;
      }
    };
    reader.readAsDataURL(file);
  };
  const upLoadSever = async () => {
    if (fileImg !== null) {
      const formData = new FormData();
      formData.append("image", fileImg);
      try {
        const res = await axios.post(upload, formData, headers);
        setImageLink(res.data.image);
      } catch (err) {
      }
    }
  };
  useEffect(() => {
    upLoadSever();
  }, [fileImg]);
  const createAlert = async () => {
    const body = {
      id: register[0]?.id || null,
      content: value || register[0]?.content,
      image: imageLink || register[0]?.image,
    };
    try {
      const res = await axios.post(create, body, headers);
      toast.success("Thành công");
      setRfRegister(!rfRegister);
    } catch (err) {
      toast.error("Lỗi");
    }
  };
  return (
    <div className="flex-column flex justify-content-center">
      <ToastContainer />
      <ModalRegister />
      <p>{t("Nội dung")}</p>
      <InputTextarea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        rows={5}
        cols={30}
      />

      <label htmlFor="fileInput">
        <button
          className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
          onClick={() => document.getElementById("fileInput").click()}>
          <p className="mb-0">{t("Chọn ảnh")}</p>
        </button>
      </label>
      <input
        id="fileInput"
        type="file"
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
      {imageLink === "" ? (
        <div></div>
      ) : (
        <img
          style={{
            width: "100px",
            display: "block",
          }}
          alt="Selected Image"
          src={`${API_URL}/${imageLink || register[0]?.image}`}
        />
      )}
      <div className="d-flex gap-2 mt-2">
        <Button
          style={{ width: "max-content", height: "100%" }}
          label={t("Lưu")}
          className="mb-2 button-blue radius"
          onClick={createAlert}
        />
        <Button
          style={{ width: "max-content", height: "100%" }}
          label={t("Xem Popup")}
          className="mb-2 button-blue radius"
          onClick={() => setModalRe(true)}
        />
      </div>
    </div>
  );
}
