export const sortMinPrice = (products) => {
  return products.sort((a, b) => {
    const priceA = a.variants[0].price;
    const priceB = b.variants[0].price;
    return priceA - priceB;
  });
};
export const sortMaxPrice = (products) => {
  return products.sort((a, b) => {
    const priceA = a.variants[0].price;
    const priceB = b.variants[0].price;
    return priceB - priceA;
  });
};
export const sortByAz = (products) => {
  return products.sort((a, b) => a.title.localeCompare(b.title));
};
export const sortByZa = (products) => {
  return products.sort((a, b) => b.title.localeCompare(a.title));
};
