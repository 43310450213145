import { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { IoIosLink } from "react-icons/io";
import { MdContentCopy } from "react-icons/md";
import { useHeaders } from "../../../const/headers";
import { API_ROUTES, API_URL } from "../../../api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import { useUser } from "../../../contexts/UserContext";
import { InputTextarea } from "primereact/inputtextarea";
export const Frequently = () => {
  const [hiddenQs, setHiddenQs] = useState([""]);
  const handleOpenQs = (type) => {
    if (hiddenQs.some((item) => item === type)) {
      setHiddenQs(hiddenQs?.filter((item) => item !== type));
    } else setHiddenQs((pre) => [...pre, type]);
  };
  const { userInfo } = useUser();
  const [rf, setRf] = useState(false);
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [imageFirst, setImageFirst] = useState("");
  const [content, setContent] = useState("");
  const [content1, setContent1] = useState("");
  const [content2, setContent2] = useState("");
  const [content3, setContent3] = useState("");
  const [content4, setContent4] = useState("");
  const [description, setDescription] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [description3, setDescription3] = useState("");
  const [description4, setDescription4] = useState("");
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [landing, setLanding] = useState([]);
  const [file, setFile] = useState(null);
  const headers = useHeaders();
  const uploadEndpoint = API_URL + API_ROUTES.UPLOAD_FILE_SERVER;
  const createUpdateEndpoint = API_URL + API_ROUTES.EDIT_LANDING;
  const apiGetLanding = API_URL + API_ROUTES.GET_ALL_LANDING;

  const fetchData = async () => {
    try {
      const res = await axios.get(apiGetLanding);
      setLanding(res.data);
    } catch (err) { }
  };

  useEffect(() => {
    fetchData();
  }, [rf]);

  const contentFistMain = landing?.filter((item) => item?.id === 12);
  const contentSecondMain = landing?.filter((item) => item?.id === 13);
  const contentThirdMain = landing?.filter((item) => item?.id === 14);
  const contentFourMain = landing?.filter((item) => item?.id === 15);
  const contentFiveMain = landing?.filter((item) => item?.id === 34);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataURL = reader.result;
      const imgElement = document.getElementById("previewImage");
      if (imgElement) {
        imgElement.src = imageDataURL;
      }
    };
    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };
    reader.readAsDataURL(file);
  };

  const onChangeContent = (e) => {
    setContent(e.target.value);
  };
  const onChangeContent1 = (e) => {
    setContent1(e.target.value);
  };
  const onChangeContent2 = (e) => {
    setContent2(e.target.value);
  };
  const onChangeContent3 = (e) => {
    setContent3(e.target.value);
  };
  const onChangeContent4 = (e) => {
    setContent4(e.target.value);
  };
  const onChangeLink = (e) => {
    setDescription(e.target.value);
  };
  const onChangeLink1 = (e) => {
    setDescription1(e.target.value);
  };
  const onChangeLink2 = (e) => {
    setDescription2(e.target.value);
  };
  const onChangeLink3 = (e) => {
    setDescription3(e.target.value);
  };
  const onChangeLink4 = (e) => {
    setDescription4(e.target.value);
  };
  const uploadToServer = async () => {
    if (file !== null) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await axios.post(uploadEndpoint, formData, headers);
        setImage(response.data.image);
        setImage1(response.data.image);
        setImage2(response.data.image);
        setImage3(response.data.image);
      } catch (error) { }
    }
  };

  useEffect(() => {
    setImage(contentFistMain[0]?.image);
    setDescription(contentFistMain[0]?.content);
    setContent(contentFistMain[0]?.title);
    setImage1(contentSecondMain[0]?.image);
    setDescription1(contentSecondMain[0]?.content);
    setContent1(contentSecondMain[0]?.title);
    setImage2(contentThirdMain[0]?.image);
    setDescription2(contentThirdMain[0]?.content);
    setContent2(contentThirdMain[0]?.title);
    setImage3(contentFourMain[0]?.image);
    setDescription3(contentFourMain[0]?.content);
    setContent3(contentFourMain[0]?.title);
    setDescription4(contentFiveMain[0]?.content);
    setContent4(contentFiveMain[0]?.title);
  }, [visible, visible1, visible2, visible3, visible4]);

  useEffect(() => {
    uploadToServer();
  }, [file]);

  const createResource = async () => {
    const data = {
      id: contentFistMain[0]?.id,
      title: content,
      content: description,
      image: image === "" ? contentFistMain[0]?.image : image,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription("");
      setContent("");
      setVisible(false);
    } catch (error) { }
  };
  const createResource1 = async () => {
    const data = {
      id: contentSecondMain[0]?.id,
      title: content1,
      content: description1,
      image: image1 === "" ? contentSecondMain[0]?.image : image1,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription1("");
      setContent1("");
      setVisible1(false);
    } catch (error) { }
  };
  const createResource2 = async () => {
    const data = {
      id: contentThirdMain[0]?.id,
      title: content2,
      content: description2,
      image: image2 === "" ? contentThirdMain[0]?.image : image2,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription2("");
      setContent2("");
      setVisible2(false);
    } catch (error) { }
  };
  const createResource3 = async () => {
    const data = {
      id: contentFourMain[0]?.id,
      title: content3,
      content: description3,
      image: image3 === "" ? contentFourMain[0]?.image : image3,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription3("");
      setContent3("");
      setVisible3(false);
    } catch (error) { }
  };
  const createResource4 = async () => {
    const data = {
      id: contentFiveMain[0]?.id,
      title: content4,
      content: description4,
      image: image4 === "" ? contentFiveMain[0]?.image : image3,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription4("");
      setContent4("");
      setVisible4(false);
    } catch (error) { }
  };
  return (
    <div className="faq-2 section light-background" id="question">
      <div
        className="container section-title aos-init aos-animate"
        data-aos="fade-up"
      >
        <h2>Các Câu Hỏi Thường Gặp</h2>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="faq-container">
                <div
                  className="faq-item gap-2 faq-active aos-init aos-animate align-items-center d-flex flex-column justify-content-between cursor-pointer"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  onClick={() => handleOpenQs("one")}
                >
                  <nav className="w-100 flex justify-content-between">
                    <nav className="d-flex w-100 justify-content-start">
                      <i className="faq-icon bi bi-question-circle"></i>
                      <h3 className="text-start">
                        {contentFistMain[0]?.title}
                      </h3>
                    </nav>
                    <i className="faq-toggle bi bi-chevron-right"></i>
                    {/* <i class="faq-toggle bi bi-chevron-right"></i> */}
                  </nav>
                  {hiddenQs?.some((item) => item === "one") && (
                    <div className="faq-content">
                      <p
                        className="text-start"
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: contentFistMain[0]?.content,
                        }}
                      />
                    </div>
                  )}
                  {userInfo?.is_admin === true ? (
                    <button
                      style={{
                        width: "100%",
                        border: "none",
                        backgroundColor: "#0866ff",
                        color: "#fff",
                        fontSize: "1rem",
                        padding: "0.5rem 1rem",
                        cursor: "pointer",
                        outline: "none",
                        transition: "all 0.2s ease-in-out",
                      }}
                      onClick={() => setVisible1(true)}
                    >
                      Sửa
                    </button>
                  ) : (
                    <></>
                  )}

                  <Dialog
                    header="Quản lý"
                    visible={visible1}
                    style={{ width: "50vw" }}
                    onHide={() => {
                      if (!visible1) return;
                      setVisible1(false);
                    }}
                  >
                    <div className="d-flex flex-column gap-2 mr-2 mb-2">
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <MdContentCopy />
                        </span>
                        <InputText
                          onChange={(e) => onChangeContent1(e)}
                          placeholder="Tiêu đề"
                          value={content1}
                        />
                      </div>
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <IoIosLink />
                        </span>
                        <InputTextarea
                          onChange={(e) => onChangeLink1(e)}
                          value={description1}
                          placeholder="Mô tả"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2 mt-2">
                      <Button
                        className="button-blue radius"
                        label="Sửa"
                        onClick={() => createResource1()}
                      />
                      <Button
                        className="button-red radius"
                        label="Hủy"
                        onClick={() => setVisible1(false)}
                        severity="danger"
                      />
                    </div>
                  </Dialog>
                </div>
              </div>
              <div className="faq-container">
                <div
                  className="faq-item gap-2 faq-active aos-init aos-animate align-items-center d-flex flex-column justify-content-between cursor-pointer"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  onClick={() => handleOpenQs("two")}
                >
                  <nav className="w-100 flex justify-content-between">
                    <nav className="d-flex w-100 justify-content-start">
                      <i className="faq-icon bi bi-question-circle"></i>
                      <h3 className="text-start">
                        {contentSecondMain[0]?.title}
                      </h3>
                    </nav>
                    <i className="faq-toggle bi bi-chevron-right"></i>
                    {/* <i class="faq-toggle bi bi-chevron-right"></i> */}
                  </nav>
                  {hiddenQs?.some((item) => item === "two") && (
                    <div className="faq-content">
                      <p
                        className="text-start"
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: contentSecondMain[0]?.content,
                        }}
                      />
                    </div>
                  )}
                  {userInfo?.is_admin === true ? (
                    <button
                      style={{
                        width: "100%",
                        border: "none",
                        backgroundColor: "#0866ff",
                        color: "#fff",
                        fontSize: "1rem",
                        padding: "0.5rem 1rem",
                        cursor: "pointer",
                        outline: "none",
                        transition: "all 0.2s ease-in-out",
                      }}
                      onClick={() => setVisible1(true)}
                    >
                      Sửa
                    </button>
                  ) : (
                    <></>
                  )}

                  <Dialog
                    header="Quản lý"
                    visible={visible1}
                    style={{ width: "50vw" }}
                    onHide={() => {
                      if (!visible1) return;
                      setVisible1(false);
                    }}
                  >
                    <div className="d-flex flex-column gap-2 mr-2 mb-2">
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <MdContentCopy />
                        </span>
                        <InputText
                          onChange={(e) => onChangeContent1(e)}
                          placeholder="Tiêu đề"
                          value={content1}
                        />
                      </div>
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <IoIosLink />
                        </span>
                        <InputTextarea
                          onChange={(e) => onChangeLink1(e)}
                          value={description1}
                          placeholder="Mô tả"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2 mt-2">
                      <Button
                        className="button-blue radius"
                        label="Sửa"
                        onClick={() => createResource1()}
                      />
                      <Button
                        className="button-red radius"
                        label="Hủy"
                        onClick={() => setVisible1(false)}
                        severity="danger"
                      />
                    </div>
                  </Dialog>
                </div>
              </div>
              <div className="faq-container">
                <div
                  className="faq-item gap-2 faq-active aos-init aos-animate align-items-center d-flex flex-column justify-content-between cursor-pointer"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  onClick={() => handleOpenQs("three")}
                >
                  <nav className="w-100 flex justify-content-between">
                    <nav className="d-flex w-100 justify-content-start">
                      <i className="faq-icon bi bi-question-circle"></i>
                      <h3 className="text-start">
                        {contentThirdMain[0]?.title}
                      </h3>
                    </nav>
                    <i className="faq-toggle bi bi-chevron-right"></i>
                    {/* <i class="faq-toggle bi bi-chevron-right"></i> */}
                  </nav>
                  {hiddenQs?.some((item) => item === "three") && (
                    <div className="faq-content">
                      <p
                        className="text-start"
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: contentThirdMain[0]?.content,
                        }}
                      />
                    </div>
                  )}
                  {userInfo?.is_admin === true ? (
                    <button
                      style={{
                        width: "100%",
                        border: "none",
                        backgroundColor: "#0866ff",
                        color: "#fff",
                        fontSize: "1rem",
                        padding: "0.5rem 1rem",
                        cursor: "pointer",
                        outline: "none",
                        transition: "all 0.2s ease-in-out",
                      }}
                      onClick={() => setVisible2(true)}
                    >
                      Sửa
                    </button>
                  ) : (
                    <></>
                  )}
                  <Dialog
                    header="Quản lý"
                    visible={visible2}
                    style={{ width: "50vw" }}
                    onHide={() => {
                      if (!visible2) return;
                      setVisible2(false);
                    }}
                  >
                    <div className="d-flex flex-column gap-2 mr-2 mb-2">
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <MdContentCopy />
                        </span>
                        <InputText
                          onChange={(e) => onChangeContent2(e)}
                          placeholder="Tiêu đề"
                          value={content2}
                        />
                      </div>
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <IoIosLink />
                        </span>
                        <InputTextarea
                          onChange={(e) => onChangeLink2(e)}
                          value={description2}
                          placeholder="Mô tả"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2 mt-2">
                      <Button
                        className="button-blue radius"
                        label="Sửa"
                        onClick={() => createResource2()}
                      />
                      <Button
                        className="button-red radius"
                        label="Hủy"
                        onClick={() => setVisible2(false)}
                        severity="danger"
                      />
                    </div>
                  </Dialog>
                </div>
              </div>
              <div className="faq-container">
                <div
                  className="faq-item gap-2 faq-active aos-init aos-animate align-items-center d-flex flex-column justify-content-between cursor-pointer"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  onClick={() => handleOpenQs("four")}
                >
                  <nav className="w-100 flex justify-content-between">
                    <nav className="d-flex w-100 justify-content-start">
                      <i className="faq-icon bi bi-question-circle"></i>
                      <h3 className="text-start">
                        {contentFourMain[0]?.title}
                      </h3>
                    </nav>
                    <i className="faq-toggle bi bi-chevron-right"></i>
                    {/* <i class="faq-toggle bi bi-chevron-right"></i> */}
                  </nav>
                  {hiddenQs?.some((item) => item === "four") && (
                    <div className="faq-content">
                      <p
                        className="text-start"
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: contentFourMain[0]?.content,
                        }}
                      />
                    </div>
                  )}
                  {userInfo?.is_admin === true ? (
                    <button
                      style={{
                        width: "100%",
                        border: "none",
                        backgroundColor: "#0866ff",
                        color: "#fff",
                        fontSize: "1rem",
                        padding: "0.5rem 1rem",
                        cursor: "pointer",
                        outline: "none",
                        transition: "all 0.2s ease-in-out",
                      }}
                      onClick={() => setVisible3(true)}
                    >
                      Sửa
                    </button>
                  ) : (
                    <></>
                  )}

                  <Dialog
                    header="Quản lý"
                    visible={visible3}
                    style={{ width: "50vw" }}
                    onHide={() => {
                      if (!visible3) return;
                      setVisible3(false);
                    }}
                  >
                    <div className="d-flex flex-column gap-2 mr-2 mb-2">
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <MdContentCopy />
                        </span>
                        <InputText
                          onChange={(e) => onChangeContent3(e)}
                          placeholder="Tiêu đề"
                          value={content3}
                        />
                      </div>
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <IoIosLink />
                        </span>
                        <InputTextarea
                          onChange={(e) => onChangeLink3(e)}
                          value={description3}
                          placeholder="Mô tả"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2 mt-2">
                      <Button
                        className="button-blue radius"
                        label="Sửa"
                        onClick={() => createResource3()}
                      />
                      <Button
                        className="button-red radius"
                        label="Hủy"
                        onClick={() => setVisible3(false)}
                        severity="danger"
                      />
                    </div>
                  </Dialog>
                </div>
              </div>
              <div className="faq-container">
                <div
                  className="faq-item gap-2 faq-active aos-init aos-animate align-items-center d-flex flex-column justify-content-between cursor-pointer"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  onClick={() => handleOpenQs("five")}
                >
                  <nav className="w-100 flex justify-content-between">
                    <nav className="d-flex w-100 justify-content-start">
                      <i className="faq-icon bi bi-question-circle"></i>
                      <h3 className="text-start">
                        {contentFiveMain[0]?.title}
                      </h3>
                    </nav>
                    <i className="faq-toggle bi bi-chevron-right"></i>
                    {/* <i class="faq-toggle bi bi-chevron-right"></i> */}
                  </nav>
                  {hiddenQs?.some((item) => item === "five") && (
                    <div className="faq-content">
                      <p
                        className="text-start"
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: contentFiveMain[0]?.content,
                        }}
                      />
                    </div>
                  )}
                  {userInfo?.is_admin === true ? (
                    <button
                      style={{
                        width: "100%",
                        border: "none",
                        backgroundColor: "#0866ff",
                        color: "#fff",
                        fontSize: "1rem",
                        padding: "0.5rem 1rem",
                        cursor: "pointer",
                        outline: "none",
                        transition: "all 0.2s ease-in-out",
                      }}
                      onClick={() => setVisible4(true)}
                    >
                      Sửa
                    </button>
                  ) : (
                    <></>
                  )}

                  <Dialog
                    header="Quản lý"
                    visible={visible4}
                    style={{ width: "50vw" }}
                    onHide={() => {
                      if (!visible4) return;
                      setVisible4(false);
                    }}
                  >
                    <div className="d-flex flex-column gap-2 mr-2 mb-2">
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <MdContentCopy />
                        </span>
                        <InputText
                          onChange={(e) => onChangeContent4(e)}
                          placeholder="Tiêu đề"
                          value={content4}
                        />
                      </div>
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                          <IoIosLink />
                        </span>
                        <InputTextarea
                          onChange={(e) => onChangeLink4(e)}
                          value={description4}
                          placeholder="Mô tả"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2 mt-2">
                      <Button
                        className="button-blue radius"
                        label="Sửa"
                        onClick={() => createResource4()}
                      />
                      <Button
                        className="button-red radius"
                        label="Hủy"
                        onClick={() => setVisible4(false)}
                        severity="danger"
                      />
                    </div>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
