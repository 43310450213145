export const FooterZalo = () => {
  return (
    <footer className="footer">
      <a
        href="https://www.chotnhanh.vn/"
        target="_blank"
        className="footer-content"
      >
        BY CHOT NHANH
      </a>
    </footer>
  );
};
