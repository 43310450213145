import { useState, useEffect } from "react";
import Navigate from "../container/Navigate";
import { Footer } from "./Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES_ZALO } from "../../api";
import { CategoryShopInAccount } from "../container/minishop/CategoryShopInAccount";
import axios from "axios";
import "../container/minishop/minishop.css";
import { useHeaders } from "../../const/headers";
import { useLocation } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import { CreateProductShop } from "../container/minishop/CreateProductShop";
import { ListProductShop } from "../container/minishop/ListProductShop";
import { FaCaretSquareLeft, FaCaretSquareRight } from "react-icons/fa";
export const ManagerMiniShop = () => {
  const headers = useHeaders();
  const location = useLocation();
  const apiGetAllProduct = API_URL + API_ROUTES_ZALO.GET_ALL_PRODUCT_SHOP;
  const apiGetCategoryShop = API_URL + API_ROUTES_ZALO.GET_CATEGORY_SHOP;
  const [idCate, setIdCate] = useState(false);
  const [listCateShop, setListCateShop] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const [editProduct, setEditProduct] = useState([]);
  const [rfShop, setRfShop] = useState(false);
  const { userInfo } = useUser();
  const [itemCover, setItemCover] = useState([]);

  const handleGetCateInAccount = async () => {
    try {
      const res = await axios.get(
        `${apiGetCategoryShop}?id_employee=${userInfo?.id}`,
        headers
      );
      setListCateShop(res.data);
    } catch (err) { }
  };
  const handleGetAllProduct = async (idAcc, idCate) => {
    if (idCate || idAcc) {
      try {
        const res = await axios.get(
          `${apiGetAllProduct}?id_employee=${userInfo?.id}&id_category=${idCate}`
        );
        setAllProduct(res.data);
      } catch (err) { }
    }
  };

  useEffect(() => {
    handleGetCateInAccount();
    setEditProduct([]);
  }, [rfShop]);
  useEffect(() => {
    handleGetAllProduct(
      location.pathname.split("/")[2],
      location.pathname.split("/")[3]
    );
  }, [idCate]);
  const [isOpen, setIsOpen] = useState(true);
  const [showToggle, setShowToggle] = useState(true);

  const toggleMinishop = () => {
    setIsOpen(prev => !prev);
  };
  const handleResize = () => {
    if (window.innerWidth < 500) {
      setIsOpen(false);
      setShowToggle(true);
    }
    if (window.innerWidth > 900) {
      setIsOpen(true);
      setShowToggle(false);
    } else {
      setShowToggle(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="d-flex flex-column w-100" style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div id="navigate-contair" className="navigate-container">
          <Navigate />
        </div>
        {location.pathname.split("/").includes("create") ||
          location.pathname.split("/").includes("edit") ? (
          <CreateProductShop
            setEditProduct={setEditProduct}
            editProduct={editProduct}
            handleGetAllProduct={handleGetAllProduct}
            toast={toast}
            idAccount={location.pathname.split("/")[2]}
            idCategory={location.pathname.split("/")[3]}
          />
        ) : (
          <div
            className="d-flex flex-column h-100 w-100 overflow-auto mgtop-respon-47"
          //   style={{ width: `calc(100vw - ${widtNavigate}px)` }}
          >
            <div className="d-flex w-100 h-100 overflow-auto">
              {showToggle && (
                isOpen ? (
                  <FaCaretSquareLeft
                    className="toggle-minishop"
                    onClick={toggleMinishop}
                    style={{
                      width: "40px",
                      height: "40px",
                      color: "#fff",
                      top: "0",
                      right: "0",
                      zIndex: "100000",
                      position: "fixed"
                    }}
                    size={30}
                  />
                ) : (
                  <FaCaretSquareRight
                    className="toggle-minishop"
                    onClick={toggleMinishop}
                    size={30}
                    style={{
                      width: "40px",
                      height: "40px",
                      color: "#fff",
                      top: "0",
                      right: "0",
                      zIndex: "100000",
                      position: "fixed"
                    }}
                  />
                )
              )}

              {isOpen && (
                <CategoryShopInAccount
                  itemCover={itemCover}
                  setItemCover={setItemCover}
                  setIdCate={setIdCate}
                  idCate={idCate}
                  iduser={userInfo?.id}
                  idCategory={location.pathname.split("/")[3]}
                  listCateShop={listCateShop}
                  setRfShop={setRfShop}
                  rfShop={rfShop}
                  toast={toast}
                />
              )}

              {location.pathname.split("/")[3] && (
                <ListProductShop
                  setEditProduct={setEditProduct}
                  headers={headers}
                  API_URL={API_URL}
                  API_ROUTES_ZALO={API_ROUTES_ZALO}
                  idAccount={location.pathname.split("/")[2]}
                  idCategory={location.pathname.split("/")[3]}
                  allProduct={allProduct}
                  handleGetAllProduct={handleGetAllProduct}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};
