import React from "react";
import { NavZalo } from "./NavZalo";
import "./homezalo.css";
import { ContentZalo } from "./ContentZalo";
import { AboutZalo } from "./AboutZalo";
import { WhyUs } from "./WhyUs";
import { FeatureZalo } from "./FeatureZalo";
import { Services } from "./Services";
import { ActionImage } from "./ActionImage";
import "aos/dist/aos.css";
import { Portfolio } from "./Portfolio";
import { Testimonials } from "./Testimonials";
import AOS from "aos";
import { Frequently } from "./Frequently";
import { Contact } from "./Contact";
import { FooterZalo } from "./FooterZalo";
import lienheimg from "../../container/redue/contact.png";
import { FeatureZalo2 } from "./FeatureZalo2";

export const HomeZalo = () => {
  React.useEffect(() => {
    AOS.init();
  }, []);
  {
    /* https://bootstrapmade.com/demo/Arsha/ */
  }
  return (
    <div className="index-page scrolled">
      <NavZalo />
      <ContentZalo />
      <AboutZalo />
      <WhyUs />
      <FeatureZalo />
      <FeatureZalo2 />
      <Services />
      <Portfolio />
      <Testimonials />
      <Frequently />
      <Contact />
      <FooterZalo />
      <div className="fixed-contact">
        {/* <p>
      Bạn đang cần liên hệ.
      <br />
      Chúng tôi luôn sẵn sàng tại đây:
    </p> */}
        <a
          href="https://www.facebook.com/messages/t/100095368608961"
          target="_blank"
        >
          <img
            className="contact-mobile"
            src={lienheimg}
            style={{ width: "300px" }}
            alt=""
          />
        </a>
      </div>
    </div>
  );
};
