import { useState, useEffect } from "react";
import { FaShopify } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { Galleria } from "primereact/galleria";
import { useLocation } from "react-router-dom";
import { postNoHeaders } from "../../../const/postAxios";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import axios from "axios";
import ModalAddCart from "./ModalAddCart";
import { formatTextWithLineBreaks } from "../../../const/splittext";
import { BiCategory } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { getNoheaders } from "../../../const/getAxios";
import { useUser } from "../../../contexts/UserContext";
import { Image } from "primereact/image";
import { useHeaders } from "../../../const/headers";
import { Slider } from "primereact/slider";
export const ShowProduct = ({
  allCart,
  setAllCart,
  allCateShop,
  openCart,
  setOpenCart,
  viewCart,
  setViewCart,
  rfCart,
  setRfCart,
  cover,
  API_URL,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const session_key = localStorage.getItem("session_key");
  const apiGetProduct = API_URL + API_ROUTES_ZALO.GET_ALL_PRODUCT_SHOP;
  const apiGetAllProduct = API_URL + API_ROUTES_ZALO.GET_ALL_PRODUCT_SHOP;
  const apiGetDomain = API_URL + API_ROUTES_ZALO.GET_DOMAIN;
  const idCate = location.pathname.split("/")[3];
  const idProduct = location.pathname.split("/")[4];
  const iduser = location.pathname.split("/")[2];
  const [allProduct, setAllProduct] = useState([]);
  const { userInfo } = useUser();
  const [forcusProduct, setForcusProduct] = useState([]);
  const headers = useHeaders();
  const [domain, setDomain] = useState(null);
  const getDomainShop = async () => {
    try {
      const res = await axios.get(apiGetDomain, headers);
      setDomain(res.data);
    } catch (err) { }
  };
  useEffect(() => {
    getDomainShop();
  }, []);
  useEffect(() => {
    getNoheaders(
      `${apiGetProduct}?id_employee=${iduser}&id_category=${idCate}`,
      setAllProduct
    );
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [idProduct, idCate]);
  useEffect(() => {
    if (allProduct) {
      const filteredProduct = allProduct?.filter(
        (item) => item.id === Number(idProduct)
      );
      setForcusProduct(filteredProduct);
    }

  }, [idProduct, allProduct])
  // const forcusProduct = allProduct?.filter(
  //   (item) => item.id === Number(idProduct)
  // );
  const forcusCategory = allCateShop?.filter(
    (item) => item.id === forcusProduct[0]?.category
  );

  const [inforVarians, setInforVarians] = useState([]);
  useEffect(() => {
    setInforVarians(
      allProduct?.filter((item) => item.id === Number(idProduct))[0]
        ?.variants[0]
    );
  }, [allProduct]);
  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
    },
  ];

  const relatedProductList = [...allProduct]
    .filter((product) => forcusProduct && parseFloat(forcusProduct[0]?.id) !== product.id)
    .slice(0, 4);
  const renderRelatedProductList = (relatedProductList) => {
    return relatedProductList
      ?.filter((item) => item.status === 1)
      ?.map((item, index) => (
        <div
          key={index}
          style={{
            pointerEvents: item.status === 0 ? "none" : "",
            opacity: item.status === 0 ? "0.5" : "",
          }}
          className="col "
        >
          <nav
            className="mini-shop-container d-flex flex-column align-items-center"
            onClick={() => {
              navigate(
                `/showproduct/${iduser}/${idCate}/${item.id}`
              )
            }

            }
          >
            {item.images.length > 0 && (
              <Image
                className="mini-shop-image"
                width="80px"
                height="80px"
                src={`${API_URL}${item.images[0]}`}
                preview
                alt="sanpham"
              />
            )}
            <nav
              style={{ width: "-webkit-fill-available" }}
              className="mini-shop-info"

            >
              <p className="mini-shop-description">
                {formatTextWithLineBreaks(item.title.length > 70 ? item.title.slice(0, 70) + "..." : item.title)}
              </p>
              <nav className="mini-shop-variants">
                <div className="price">
                  <p>
                    {item.variants
                      ?.map(
                        (option) =>
                          `${option.price.toLocaleString(
                            "vi-VN",
                            {
                              style: "currency",
                              currency: "VND",
                            }
                          )}`
                      )
                      .join(" - ")}
                  </p>
                </div>
              </nav>

            </nav>

          </nav>
        </div>
      ))

  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={`${API_URL}/${item}`}
        alt={item.alt}
        style={{ display: "block", height: "40px" }}
      />
    );
  };

  const images = forcusProduct[0]?.images || [];
  const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const handlePrevious = () => {
      setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
    };

    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
    };
    const handleChange = (e) => {
      setCurrentIndex(e.value);
    };
    if (images.length === 0) {
      return <p>No images available</p>;
    }

    return (
      <div className="image-slider">
        <Slider
          value={currentIndex}
          onChange={handleChange}
          min={0}
          max={images.length - 1}
          step={1}
          orientation="horizontal"
          style={{ width: '100%' }}
          className="image-slider"
        />
        <div className="slider-image">
          <img
            src={`${API_URL}${images[currentIndex]}`}
            alt={`${API_URL} ${currentIndex}`}
            style={{ width: '100%' }}
          />
          <button
            onClick={handlePrevious}
            className="button-prev"
          >
            &lt;
          </button>
          <button
            onClick={handleNext}
            className="button-next"
          >
            &gt;
          </button>
          <div className="slide-number">
            {currentIndex + 1} / {images.length}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const tiktokScript = document.createElement("script");
    tiktokScript.src = "https://www.tiktok.com/embed.js";
    tiktokScript.async = true;
    document.body.appendChild(tiktokScript);

    const facebookScript = document.createElement("script");
    facebookScript.src =
      "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0";
    facebookScript.async = true;
    facebookScript.defer = true;
    facebookScript.crossOrigin = "anonymous";
    document.body.appendChild(facebookScript);

    facebookScript.onload = () => {
      if (window.FB) {
        window.FB.XFBML.parse();
      }
    };

    return () => {
      document.body.removeChild(tiktokScript);
      document.body.removeChild(facebookScript);
    };
  }, [forcusProduct]);
  return forcusProduct?.length === 0 ? (
    <h2 className="bg-dark text-light w-100 text-center mt-4">
      Không có thông tin về sản phẩm này
    </h2>
  ) : (
    <div
      className="showProduct-wrapper"

    >

      <ModalAddCart
        iduser={iduser}
        rfCart={rfCart}
        setRfCart={setRfCart}
        session_key={session_key}
        forcusProduct={forcusProduct}
        openCart={openCart}
        setOpenCart={setOpenCart}
      />
      <div className="showProduct-container item-details  flex-column">
        <div className=" d-flex flex-column gap-3">
          <div className="nav_bar nav_bar_fixed d-flex align-items-center">
            <div
              onClick={() => navigate(`/shoplinkhome/${iduser}`)}
              className="cursor-pointer d-flex align-items-center"
            >
              <BiCategory size={30} />
              <p>Danh mục</p>

            </div>
            <div className="text-dark fs-4">
              {cover?.name}
            </div>
            <div className="d-flex align-items-center gap-1 cart-items-shop postion-relative">
              <FaShoppingCart
                onClick={() => setViewCart(true)}
                cursor="pointer"
                size={30}
              />
              Giỏ hàng
              {allCart?.items?.length > 0 && (
                <span className="cart-count" >
                  {allCart?.items?.length}
                </span>
              )}
            </div>

          </div>

          <div className="product-details-info">
            <div className="row align-items-center" style={{ marginTop: "25px" }}>
              <div className="top-banner w-100 d-flex justify-content-center">
                <img
                  src={`${API_URL}${cover.image}`}
                  alt=""
                  style={{
                    width: "90%",
                    maxWidth: "1000px",
                    height: "50%",
                  }}
                />
              </div>
              <div className="col-lg-12 col-md-12 ">
                <div className="product-images">
                  <div className="d-flex w-100 justify-content-center">
                    {/* <Galleria
                      value={forcusProduct[0]?.images}
                      responsiveOptions={responsiveOptions}
                      numVisible={5}
                      circular
                      style={{
                        // maxWidth: "640px",
                        display: "flex",
                        width: "100%",
                      }}
                      className="galleria-show-product"
                      showItemNavigators
                      item={itemTemplate}
                      thumbnail={thumbnailTemplate}
                    /> */}
                    <ImageSlider images={images} />.
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mt-4">
                <div className="product-info">
                  <h2 className="title-minishop">{forcusProduct[0]?.title}</h2>
                  <p
                    className="category"
                    onClick={() =>
                      navigate(
                        `/shoplinkhome/${iduser}/${forcusProduct[0]?.category}`
                      )
                    }
                  >
                    <i className="lni lni-tag"></i> Thể loại:
                    <a className="a-blue" href="javascript:void(0)">{forcusCategory[0]?.name}</a>
                  </p>
                  <div className="info-body custom-responsive-margin mb-2">
                    <h4>Thông tin liên hệ</h4>
                    {forcusProduct[0]?.creator_name && (
                      <p>Người bán: <br />
                        <span> {forcusProduct[0]?.creator_name}</span>
                      </p>
                    )}
                    <b>
                      SDT:
                      <a className="a-blue" href={`tel:${forcusProduct[0]?.phone_number}`}>
                        {forcusProduct[0]?.phone_number}
                      </a>
                    </b>
                  </div>
                  <h3 class="price">
                    {`Giá: ${inforVarians?.price?.toLocaleString("vi-VN")} VND`}
                  </h3>
                  <nav className="d-flex gap-2 mb-2">
                    <p>{`Số lượng: ${inforVarians?.total_quantity}`}</p>
                    <p>{`Đã bán: ${inforVarians?.sold_quantity}`}</p>
                  </nav>
                  <div className="d-flex flex-wrap gap-2 align-items-center">
                    <p>Phân loại:</p>
                    {forcusProduct[0]?.variants?.map((item, index) => (
                      <div
                        onClick={() => setInforVarians(item)}
                        className={`category-container cursor-pointer ${inforVarians?.id === item.id
                          ? "styles-use-varians"
                          : ""
                          }`}
                        key={index}
                      >
                        <p className="category-shop">{`${item.classify}:`}</p>
                        <nav className="d-flex gap-2">
                          {item.options?.map((option, num) => (
                            <p
                              key={num}
                            >{`${option?.name} - ${option.value}`}</p>
                          ))}
                        </nav>
                      </div>
                    ))}
                  </div>

                </div>
              </div>
            </div>
            <div className="single-block">
              <div className="row">
                {forcusProduct[0]?.video && (
                  <div className="col-lg-12 text-align-center">
                    {forcusProduct[0]?.platform_type === "tiktok" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<blockquote class="tiktok-embed" cite="" data-video-id="${forcusProduct[0]?.video}" style="max-width: 605px;min-width: 325px;"> <section> </section></blockquote>`,
                        }}
                      />
                    ) : forcusProduct[0]?.platform_type === "facebook" ? (
                      <div
                        className="fb-video"
                        data-href={forcusProduct[0]?.video}
                        data-width="500"
                        data-show-text="false"
                      >
                        <blockquote
                          cite={forcusProduct[0]?.video}
                          className="fb-xfbml-parse-ignore"
                        >
                          <a href={forcusProduct[0]?.video}></a>
                        </blockquote>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ReactPlayer
                          url={forcusProduct[0]?.video}
                          controls={true}
                          width="auto"
                          height="auto"
                          config={{
                            youtube: {
                              playerVars: {
                                quality: "highres",
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div className="col-lg-12 mt-2">
                  <div className="info-body custom-responsive-margin">
                    <h4>Chi tiết</h4>
                    <p>
                      {formatTextWithLineBreaks(forcusProduct[0]?.description)}
                    </p>
                  </div>
                  <div className="">
                    <h3>Sản phẩm liên quan</h3>
                    <div className="row">
                      {renderRelatedProductList(relatedProductList)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_button">
            <button className="left_button radius"
              onClick={() => setViewCart(true)}>

              <p>Xem giỏ hàng</p>
              {allCart?.items?.length > 0 && (
                <p
                  style={{
                    position: "absolute",
                    top: "-6px",
                    right: "0",
                    color: "#fff",
                    background: "red",
                    padding: "2px 8px",
                    borderRadius: "50%",
                  }}
                >
                  {allCart?.items?.length}
                </p>
              )}</button>
            <button
              onClick={() =>
                inforVarians?.stock_status === "Hết hàng" ||
                  forcusProduct[0]?.sell_option === 1
                  ? null
                  : setOpenCart(true)
              }
              className="right_button"
              style={{
                opacity:
                  inforVarians?.stock_status === "Hết hàng" ? "0.5" : "",
                pointerEvents:
                  inforVarians?.stock_status === "Hết hàng" ? "none" : "",
                color:
                  inforVarians?.stock_status === "Hết hàng" ? "red" : "",
                background:
                  inforVarians?.stock_status === "Hết hàng" ? "#000" : "",
              }}
            >
              <FaShoppingCart
                onClick={() => setViewCart(true)}
                cursor="pointer"
                size={20}
              />
              {inforVarians?.stock_status === "Hết hàng" ? (
                "Hết hàng"
              ) : forcusProduct[0]?.sell_option === 1 ? (
                <a
                  className="text-light"
                  target="_blank"
                  href={forcusProduct[0]?.link_zalo}
                >
                  Liên hệ người bán
                </a>
              ) : (
                "Thêm vào giỏ hàng"
              )}
            </button>
          </div>
        </div>
      </div>

    </div>
  );
};
