import React, { useEffect, useState } from "react";
import Navigate from "../container/Navigate";
import "./style.css";
import ReactPlayer from "react-player";
import { AiFillAppstore } from "react-icons/ai";
import { Carousel } from "primereact/carousel";
import { updateTitle } from "../../const/updateTitle";
import { Button } from "primereact/button";
import { Footer } from "./Footer";
import { API_URL, API_ROUTES } from "../../api";
import axios from "axios";
import { useHeaders } from "../../const/headers";
import { ModalEditHd } from "../container/huongdan/ModalEditHd";
import { ModalCreateHd } from "../container/huongdan/ModalCreateHd";
import { useUser } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";

const ResponsiveCarousel = ({
  resources,
  setOpModalHd,
  setItemEdit,
  deleteTuto,
  userInfo,
}) => {
  const productTemplate = (item) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        className="border-1 surface-border border-round m-2 text-center "
      >
        <div
          className="d-flex flex-column justify-content-end align-items-center"
          onClick={() => scrollToHdsd(item)}
        >
          <h5
            className="mb-1 mt-2"
            style={{ height: "70px", overflow: "hidden", fontSize: "16px" }}
          >
            {item.title}
          </h5>
          <img
            className="mt-2 mb-2"
            style={{ width: "200px", height: "100px" }}
            src={`${API_URL}/${item?.image}`}
            alt="tutorial-thumbnail"
          />
        </div>
        {userInfo.is_admin === true ? (
          <div className="mb-2">
            <Button
              label="Sửa"
              onClick={() => {
                setOpModalHd(true);
                setItemEdit(item);
              }}
              className=" button-blue radius"
            />
            <Button
              label="Xoá"
              severity="danger"
              onClick={() => {
                deleteTuto(item);
              }}
              className=" button-red radius"
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const scrollToHdsd = (item) => {
    const hdsdSection = document.getElementById(`huongdanaccount-${item?.id}`);
    hdsdSection?.scrollIntoView({ behavior: "smooth" });
  };

  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  return (
    <Carousel
      value={resources}
      numVisible={4}
      numScroll={1}
      responsiveOptions={responsiveOptions}
      itemTemplate={productTemplate}
    />
  );
};

const Huongdan = () => {
  updateTitle("Chốt Care - Hướng Dẫn");
  const { userInfo } = useUser();
  const headers = useHeaders();
  const getAllTuto = API_URL + API_ROUTES.GET_ALL_TUTORIAL;
  const apiDele = API_URL + API_ROUTES.DELETE_TUTORIAL;

  const [allTuto, setAllTuto] = useState([]);
  const [opModalHd, setOpModalHd] = useState(false);
  const [opCreateHd, setOpCreateHd] = useState(false);
  const [itemEdit, setItemEdit] = useState(null);
  const [rf, setRf] = useState(false);
  const { t } = useTranslation()
  const fetchData = async () => {
    try {
      const res = await axios.get(getAllTuto, headers);
      setAllTuto(res.data);
    } catch (err) { }
  };

  const deleteTuto = async (item) => {
    const confirm = window.confirm("Bạn có chắc chắn muốn xoá không ?");
    if (confirm) {
      const body = { id: item.id };
      try {
        await axios.post(apiDele, body, headers);
        setRf(!rf);
      } catch (err) { }
    }
  };

  useEffect(() => {
    fetchData();
  }, [rf]);
  const resources = allTuto;
  return (
    <div className="d-flex flex-column" style={{ height: "100vh" }}>
      <div className="notifica-container">
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="w-100 h-100 p-2 mgtop-respon-47" style={{ overflow: "auto" }}>
          <div className="mt-4">
            <ResponsiveCarousel
              setOpModalHd={setOpModalHd}
              setItemEdit={setItemEdit}
              opModalHd={opModalHd}
              resources={resources}
              deleteTuto={deleteTuto}
              userInfo={userInfo}
            />
            {userInfo.is_admin && (
              <div>
                <Button
                  label={t("Thêm")}
                  onClick={() => setOpCreateHd(true)}
                  className="mr-2 w-100  button-blue radius"
                />
              </div>
            )}
            <div className="d-flex justify-content-center">
              {[...Array(7)].map((_, index) => (
                <nav key={index}>
                  <AiFillAppstore style={{ fontSize: "55px" }} />
                </nav>
              ))}
            </div>
          </div>
          <div className="mt-4 mb-4 d-flex flex-column gap-4 w-100">
            {allTuto?.map((item) => (
              <div
                key={item?.id}
                className="text-center d-flex flex-column align-items-center"
                id={`huongdanaccount-${item?.id}`}
              >
                <nav>
                  <h2
                    className="text-center"
                    style={{ fontWeight: "700", lineHeight: "1.28" }}
                  >
                    HƯỚNG DẪN SỬ DỤNG
                  </h2>
                  <p className="mb-3" style={{ fontSize: "22px" }}>
                    {item?.title}
                  </p>
                </nav>
                <ReactPlayer
                  className="reactplay-response"
                  url={item?.link}
                  controls={true}
                  config={{
                    youtube: {
                      playerVars: {
                        quality: "highres",
                      },
                    },
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
      <ModalEditHd
        setOpModalHd={setOpModalHd}
        opModalHd={opModalHd}
        itemEdit={itemEdit}
        setRf={setRf}
        rf={rf}
      />
      <ModalCreateHd
        setOpCreateHd={setOpCreateHd}
        opCreateHd={opCreateHd}
        setRf={setRf}
        rf={rf}
      />
    </div>
  );
};

export default Huongdan;
