import React from "react";
import { MdLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useStatus } from "../../contexts/StatusContext";
export const Footer = () => {
  const { i18n, t } = useTranslation();
  const { language, setLanguage } = useStatus();
  const handleLaguage = () => {
    if (language === "vi") {
      i18n.changeLanguage("en");
      setLanguage("en");
    } else {
      i18n.changeLanguage("vi");
      setLanguage("vi");
    }
  };
  return (
    <div
      id="footer"
      className="d-flex w-100  text justify-content-center"
      style={{
        background: "#2c343f",
        gap: "80px",
        padding: "10px 20px",
      }}
    >
      <div className=" d-flex justify-content-between w-100">
        <a
          href="https://www.facebook.com/chotnhanh.vn"
          target="blank"
          style={{
            width: "max-content",
            color: "#fff",
            background: "#000",
            padding: "6px 12px",
            textDecoration: "none",
            fontSize: "14px",
          }}
        >
          {t("LIÊN HỆ CHỐT NHANH")}
        </a>
        <MdLanguage
          cursor="pointer"
          onClick={() => handleLaguage()}
          size={25}
          color="#fff"
        />
      </div>
    </div>
  );
};
