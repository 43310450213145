import React from "react";
import { Dialog } from "primereact/dialog";
import { API_URL } from "../../../api";
export const ModalDetailsClient = ({
  setOpenDetails,
  openDetails,
  itemResults,
  dataZalo,
}) => {
  const nameAccount = dataZalo?.find(
    (item) => item.id === itemResults?.account
  );
  return (
    <Dialog
      header="Chi tiết"
      visible={openDetails}
      style={{ width: "20vw" }}
      onHide={() => setOpenDetails(false)}
    >
      <div className="f-flex flex-column gap-2">
        <nav
          style={{
            paddingBottom: "0",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2 flex-column"
        >
          <h5>Tên tài khoản: {nameAccount?.name}</h5>
        </nav>

        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <p>
            {itemResults?.status === 0
              ? "Kết bạn không thành công."
              : itemResults?.status === 1
              ? "Kết bạn thành công."
              : itemResults?.status === 2
              ? "Kết bạn: Không chạy."
              : "Kết bạn: Bị hạn chế."}
          </p>
        </nav>
        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <p>Số điện thoại kết bạn: {itemResults?.phone_number}</p>
        </nav>
        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <p>Tin nhắn kết bạn: {itemResults?.message}</p>
        </nav>
      </div>
    </Dialog>
  );
};
