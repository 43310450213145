import { useState, useEffect } from "react";
import "./homezalo.css";
import { IoMenu } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import { useAuth } from "../../../AuthContext";
import { useNavigate } from "react-router-dom";
export const NavZalo = () => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [hidden, setHidden] = useState(false);
  const [hiddenNav, setHiddenNav] = useState(false);
  const { userLoggedIn } = useAuth();
  const navigate = useNavigate();
  const handleClick = (event, href) => {
    event.preventDefault();
    navigate(href);
  };
  const handleNavi = (event, link) => {
    event.preventDefault();
    navigate(link);
  };

  const handleScroll = () => {
    const currentScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;

    if (currentScrollTop > lastScrollTop) {
      setHidden(true);
    } else {
      setHidden(false);
    }
    setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);
  function toggleScrolled() {
    const selectBody = document.querySelector("body");
    const selectHeader = document.querySelector("#header");
    if (
      !selectHeader?.classList.contains("scroll-up-sticky") &&
      !selectHeader?.classList.contains("sticky-top") &&
      !selectHeader?.classList.contains("fixed-top")
    )
      return;
    window.scrollY > 100
      ? selectBody?.classList.add("scrolled")
      : selectBody?.classList.remove("scrolled");
  }
  document.addEventListener("scroll", toggleScrolled);
  window.addEventListener("load", toggleScrolled);
  return (
    <div
      id="header"
      className={`header d-flex align-items-center fixed-top navmenu ${hidden ? "navmenu-hidden" : ""
        }`}
    >
      <div className="container-fluid container-xl position-relative d-flex align-items-center">
        <a
          onClick={(e) => handleNavi(e, "/")}
          className="logo d-flex align-items-center me-auto"
          href=""
        >
          <img src="https://care.chotnhanh.vn/media/server/79bc45b9-8c46-43d8-8275-69e714acb3b9.jpg" alt="" style={{ height: "60px" }} />
        </a>
        <nav className="navmenu hidden-nav">
          <ul>
            <li>
              <a href="#about">Về chúng tôi</a>
            </li>
            <li>
              <a href="#feature">Tính năng</a>
            </li>
            <li>
              <a href="#question">Câu hỏi</a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/messages/t/100095368608961"
                target="blank"
              >
                Liên hệ
              </a>
            </li>
            <li>
              <a href="" onClick={(e) => handleNavi(e, "/redue")}>
                Bảng giá
              </a>
            </li>
            {userLoggedIn ? (
              <li>
                <a href="" onClick={(event) => handleClick(event, "/account")}>
                  Chức năng
                </a>
              </li>
            ) : (
              <>
                <li>
                  <a onClick={(event) => handleClick(event, "/login")} href="">
                    Đăng nhập
                  </a>
                </li>
                <li>
                  <a
                    onClick={(event) => handleClick(event, "/register")}
                    href="/"
                  >
                    Đăng ký
                  </a>
                </li>
              </>
            )}
          </ul>
        </nav>
        <a
          className="btn-getstarted"
          href="https://www.chotnhanh.vn/"
          target="blank"
        >
          Trang chủ
        </a>
        <IoMenu
          className="icon-hidden"
          onClick={() => setHiddenNav(true)}
          size={40}
          style={{ color: "#fff", marginLeft: "0.5rem", cursor: "pointer" }}
        />
        {hiddenNav && (
          <nav className="navmenus ">
            <ul>
              <IoCloseSharp onClick={() => setHiddenNav(false)} size={40} />
              <li>
                <a href="#about">Về chúng tôi</a>
              </li>
              <li>
                <a href="">Tính năng</a>
              </li>
              <li>
                <a href="#question">Câu hỏi</a>
              </li>
              <li>
                <a href="#contact">Liên hệ</a>
              </li>
              {userLoggedIn ? (
                <li>
                  <a
                    href=""
                    onClick={(event) => handleClick(event, "/account")}
                  >
                    Chức năng
                  </a>
                </li>
              ) : (
                <>
                  <li>
                    <a
                      onClick={(event) => handleClick(event, "/login")}
                      href=""
                    >
                      Đăng nhập
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(event) => handleClick(event, "/register")}
                      href="/"
                    >
                      Đăng ký
                    </a>
                  </li>
                </>
              )}
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};
