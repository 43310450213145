import React from "react";
import { Dialog } from "primereact/dialog";
import { API_URL } from "../../../api";
export const ModalDetailsGroup = ({
  setOpenDetails,
  openDetails,
  itemResults,
  dataZalo,
}) => {
  const nameAccount = dataZalo?.find(
    (item) => item.id === itemResults?.account
  );
  return (
    <Dialog
      header="Chi tiết"
      visible={openDetails}
      style={{ width: "20vw" }}
      onHide={() => setOpenDetails(false)}
    >
      <div className="f-flex flex-column gap-2">
        <nav
          style={{
            paddingBottom: "0",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2 flex-column"
        >
          <h5>Tên tài khoản: {nameAccount?.name}</h5>
        </nav>

        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <p>
            {itemResults?.status === 0
              ? "Tham gia nhóm không thành công."
              : itemResults?.status === 1
              ? "Tham gia nhóm thành công."
              : itemResults?.status === 2
              ? "Tham gia nhóm: Không chạy."
              : "Tham gia nhóm: Đang chờ duyệt."}
          </p>
        </nav>
        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2 flex-column"
        >
          <p>link nhóm tham gia: </p>
          <a href={itemResults?.link_group}>{itemResults?.link_group}</a>
        </nav>
      </div>
    </Dialog>
  );
};
