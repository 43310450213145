import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { API_ROUTES_ZALO, API_ROUTES, API_URL } from "../../../api";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { useHeaders } from "../../../const/headers";
import { FcAddImage } from "react-icons/fc";
import { getAxios } from "../../../const/getAxios";
import { Image } from "primereact/image";
import { Dropdown } from "primereact/dropdown";
import { useUser } from "../../../contexts/UserContext";
import { InputTextarea } from "primereact/inputtextarea";
export const ModalSettingCoverShop = ({
  setOpenSetting,
  openSetting,
  iduser,
  itemCover,
  setItemCover,
  getCoverShop,
  toast,
}) => {
  const inputImageRef = useRef();
  const headers = useHeaders();
  const { setRfMe, rfMe, userInfo } = useUser();
  const apiCreateEditCover = API_URL + API_ROUTES_ZALO.CREATE_UPDATE_COVER;
  const getLinkAttack = API_URL + API_ROUTES.GET_LINK_FILE;
  const apiNotification = API_URL + API_ROUTES_ZALO.NOTIFICATION_ACOUNT;
  const getAccount = API_URL + API_ROUTES.GET_ACC_FB;
  const apiSuccessOrder = API_URL + API_ROUTES_ZALO.ORDER_SUCCESS_MESSENGER;
  const apiConfirmOrder = API_URL + API_ROUTES_ZALO.CONFIRM_MESS_ORDER;
  const [allAccount, setAllAccount] = useState([]);
  const [notification, setNotification] = useState([]);
  const [messengerOrder, setMessengerOrder] = useState("");
  const [messengerConfirm, setMessengerConfirm] = useState("");
  useEffect(() => {
    getAxios(getAccount, setAllAccount);
    if (userInfo?.id) {
      setMessengerConfirm(userInfo.confirm_message);
      setMessengerOrder(userInfo.order_successful_message);
    }
  }, [rfMe]);
  useEffect(() => {
    setNotification();
  }, [allAccount, rfMe]);
  const [linkImage, setLinkImage] = useState(null);
  const [name, setName] = useState("");
  const [accoutDown, setAccountDown] = useState(null);
  useEffect(() => {
    if (itemCover?.id) {
      setLinkImage(itemCover.image);
      setName(itemCover.name);
    }
  }, [itemCover]);
  const handleGetLink = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await axios.post(getLinkAttack, formData, headers);
      setLinkImage(res.data.file);
    } catch (err) {}
  };
  const handleIconClick = () => {
    inputImageRef.current.click();
  };
  const handleFileChange = () => {
    const fileInput = inputImageRef.current;
    const files = fileInput.files;
    handleGetLink(files[0]);
  };
  const handleCreateOrEdit = async () => {
    const body = itemCover?.id
      ? {
          id_user: iduser,
          id_cover: itemCover?.id,
          image: linkImage,
          name: name,
        }
      : {
          id_user: iduser,
          image: linkImage,
          name: name,
        };
    try {
      await axios.post(apiCreateEditCover, body, headers);
      getCoverShop();
      if (itemCover.user !== Number(iduser)) {
        toast.success("Tạo danh mục thành công, Vui lòng đợi phê duyệt");
      }
      setOpenSetting(false);
    } catch (err) {}
  };
  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        {option.avatar && (
          <img
            alt={option.name}
            src={option.avatar}
            style={{ width: "25px", borderRadius: "50%" }}
          />
        )}
        <div>{option.name}</div>
      </div>
    );
  };
  const handleNotification = async (item) => {
    const body = {
      id_account: item.id,
    };
    try {
      const res = await axios.post(apiNotification, body, headers);
      toast.success(res.data.message);
      setRfMe(!rfMe);
    } catch (err) {}
  };
  const handleSendSuccessOrder = async () => {
    const body = {
      order_successful_message: messengerOrder,
    };
    try {
      await axios.post(apiSuccessOrder, body, headers);
      toast.success("Thiết lập thành công");
    } catch (err) {}
  };
  const handleConfirmOrder = async () => {
    const body = {
      confirm_message: messengerConfirm,
    };
    try {
      await axios.post(apiConfirmOrder, body, headers);
      toast.success("Thiết lập thành công");
    } catch (err) {}
  };
  const defaultSuccess =
    "📣 📣 📣 Thông báo đặt hàng thành công:\n-Thông tin đơn hàng:\n -Người nhận: {full_name}\n -Số ĐT: {phone_number}\n -Địa chỉ: {address}, {ward}, {district}, {city}\n -Sản phẩm: {description}\n -Thành tiền: {total_amount}₫\n -Ghi chú: {note}\n -Chúng tôi sẽ liên hệ quý khách để xác nhận đơn hàng. Chân thành cảm ơn!";
  const defaultConfirm =
    "Cảm ơn quý khách {full_name} đã đặt mua sản phẩm.\nChúng tôi đã xác nhận đơn đặt hàng của quý khách và sẽ gửi hàng cho quý khách trong hôm nay.\nKính chúc quý khách sức khỏe!";

  return (
    <div className="flex justify-content-center">
      <Dialog
        header="Thiết lập thông tin Shop"
        visible={openSetting}
        onHide={() => setOpenSetting(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="mb-2 border-2 rounded p-2 pt-4 d-flex flex-column gap-4 mt-4 p-2 w-100">
          <FloatLabel>
            <InputText
              id="username"
              className="w-100"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <label htmlFor="Tên Shop">Tên Shop</label>
          </FloatLabel>
          {itemCover?.id ? (
            <nav>
              <p>Ảnh Banner</p>
            </nav>
          ) : (
            <></>
          )}
          <nav className="d-flex gap-2">
            <nav>
              <FcAddImage
                size={40}
                onClick={handleIconClick}
                cursor="pointer"
              />
            </nav>
            {linkImage && (
              <Image src={`${API_URL}${linkImage}`} width="50%" height="100%" />
            )}
          </nav>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={inputImageRef}
            accept="image/*,video/*"
          />
          <nav className="w-100 text-end">
            <Button
              className="button-blue
             radius"
              style={{ marginTop: "1rem" }}
              onClick={handleCreateOrEdit}
              label={itemCover?.id ? "Cập nhật" : "Tạo mới"}
              text
            />
          </nav>
        </div>
        <div className="mb-2 border-2 gap-2 rounded p-2 d-flex justify-content-between ">
          <nav className="d-flex gap-2 flex-column">
            <p>Chọn tài khoản gửi tin nhắn:</p>
            <Dropdown
              value={accoutDown}
              onChange={(e) => {
                setAccountDown(e.value);
                handleNotification(e.value);
              }}
              options={[
                {
                  avatar: "",
                  name: "Tắt chức năng gửi tin nhắn",
                  id: 0,
                  checkpoint: false,
                  proxy: {
                    status: true,
                  },
                },
                ...allAccount,
              ]?.filter(
                (item) => item.checkpoint === false && item.proxy.status
              )}
              itemTemplate={countryOptionTemplate}
              optionLabel="name"
              placeholder="Chọn tài khoản"
              className="w-full md:w-14rem"
            />
          </nav>
          <nav className="d-flex gap-2 flex-column">
            <p>Tài khoản đã được chọn:</p>
            {userInfo.id_order_notification_account ? (
              <div className="flex align-items-center gap-2">
                <img
                  alt={
                    allAccount?.filter(
                      (item) =>
                        item.id === userInfo.id_order_notification_account &&
                        item.checkpoint === false &&
                        item.proxy.status
                    )[0]?.name
                  }
                  src={
                    allAccount?.filter(
                      (item) =>
                        item.id === userInfo.id_order_notification_account &&
                        item.checkpoint === false &&
                        item.proxy.status
                    )[0]?.avatar
                  }
                  style={{ width: "25px", borderRadius: "50%" }}
                />
                <div>
                  {
                    allAccount?.filter(
                      (item) =>
                        item.id === userInfo.id_order_notification_account &&
                        item.checkpoint === false &&
                        item.proxy.status
                    )[0]?.name
                  }
                </div>
              </div>
            ) : (
              <p>Bạn chưa chọn tài khoản nào</p>
            )}
          </nav>
        </div>
        <div className="mb-2 d-flex gap-2 flex-column border-2 rounded p-2 ">
          <nav className="d-flex align-items-center gap-2">
            <p>Thiết lập tin nhắn xác nhận đặt hàng thành công</p>
            <Button
              className="button-blue
             radius"
              style={{ width: "max-content" }}
              onClick={() => setMessengerOrder(defaultSuccess)}
              label="Mẫu"
              text
            />
          </nav>
          <InputTextarea
            value={messengerOrder}
            onChange={(e) => setMessengerOrder(e.target.value)}
          />
          <nav className="text-end w-100">
            <Button
              className="button-blue
             radius"
              style={{ width: "max-content" }}
              onClick={handleSendSuccessOrder}
              label="Cập nhật"
              text
            />
          </nav>
        </div>
        <div className="mb-2 d-flex gap-2 flex-column border-2 rounded p-2 ">
          <nav className="d-flex align-items-center gap-2">
            <p>Thiết lập tin nhắn xác nhận đơn hàng</p>
            <Button
              className="button-blue
             radius"
              style={{ width: "max-content" }}
              onClick={() => setMessengerConfirm(defaultConfirm)}
              label="Mẫu"
              text
            />
          </nav>
          <InputTextarea
            value={messengerConfirm}
            onChange={(e) => setMessengerConfirm(e.target.value)}
          />
          <nav className="text-end w-100">
            <Button
              className="button-blue
             radius"
              style={{ marginTop: "0.5rem", width: "max-content" }}
              onClick={handleConfirmOrder}
              label="Cập nhật"
              text
            />
          </nav>
        </div>
        <nav className="d-flex justify-content-end">
          <Button
            className="button-red radius"
            style={{ marginTop: "1rem" }}
            onClick={() => setOpenSetting(false)}
            label="Thoát"
            text
          />
        </nav>
      </Dialog>
    </div>
  );
};
