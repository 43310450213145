import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
import axios, { all } from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_NTD, API_ROUTES_ZALO } from "../../../api";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import noRequest from "../../container/client/no_request.png";
import { set } from "lodash";
import { type } from "@testing-library/user-event/dist/type";
export const TableSentFrOfAccount = ({
  toast,
  setListFr,
  listFr,
  setRefresh,
  refresh,
  allAddFriend,
  dataScanRs,
  statusRun,
  selectedItems,
  setDataScanRs,
  getDeleteAddFriend,
  selectedRows,
  setSelectedRows,
}) => {
  const headers = useHeaders();
  const acceptAddFriend = API_URL + API_ROUTES_ZALO.ACCEPT_ADD_FRIEND;
  const resultAcceptAddFriend =
    API_URL + API_ROUTES_ZALO.ACCEPT_ADD_FRIEND_RESULT;

  const getDataAllFr = () => {};
  const [acceptFriend, setAcceptFriend] = useState("");
  const [getTask, setGetTask] = useState([]);

  const getAcceptAddFriend = async () => {
    const body = {
      id_account: dataScanRs?.data?.id,
      fid: dataScanRs?.data?.result[0]?.userId,
    };
    try {
      const res = await axios.post(acceptAddFriend, body, headers);
      setGetTask(res.data);
      getRsAcceptAddFriend(res.data);
      toast.info("Đang chấp nhận lời mời kết bạn");
      setRefresh(!refresh);
    } catch (err) {}
  };
  const getRsAcceptAddFriend = async (taskid) => {
    const body = { id_task: taskid.id_task };
    try {
      const res = await axios.post(resultAcceptAddFriend, body, headers);
      if (res.data.status === "SUCCESS") {
        toast.success("Kết bạn thành công");
        setRefresh(!refresh);
      } else if (res.data.status === "FAILURE") {
        toast.error("Kết bạn thất bại");
      }
      setRefresh(!refresh);
      setAcceptFriend(res.data.status);
    } catch (err) {
      setAcceptFriend(err.res.data.status);
      toast.error("Đã xảy ra lỗi khi kết bạn");
    }
  };
  useEffect(() => {
    if (getTask.id_task) {
      const interval = setInterval(() => {
        getRsAcceptAddFriend(getTask);
      }, 3000);
      if (acceptFriend === "SUCCESS" || acceptFriend === "FAILURE") {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [acceptFriend, getTask]);
  const [userId, setUserId] = useState([]);

  const nameAndAvt = (rowData) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <img
          className="rounded-circle"
          style={{ width: "20px", height: "20px" }}
          src={rowData?.avatar}
        />
        <p>{rowData?.zaloName}</p>
      </div>
    );
  };
  const messageSent = (rowData) => {
    return <p>{rowData?.fReqInfo?.message}</p>;
  };
  const timeSent = (rowData) => {
    const timestamp = rowData?.fReqInfo?.time;
    const date = new Date(timestamp * 1000); // Chuyển đổi từ giây sang mili giây

    const formattedDate = date.toLocaleDateString("vi-VN").replace(/\//g, "/"); // Định dạng ngày với dấu gạch ngang
    const formattedTime = date.toLocaleTimeString("vi-VN"); // Định dạng giờ

    return <p>{`${formattedDate} - ${formattedTime}`}</p>;
  };
  const buttonOption = (rowData) => {
    return (
      <div style={{ width: "150px" }} className="d-flex gap-2">
        <Button
          className=" button-red radius"
          severity="danger"
          style={{
            padding: "4px 6px",
            width: "100%",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => getDeleteAddFriend(rowData, "one")}
        >
          Thu hồi lời mời
        </Button>
      </div>
    );
  };

  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  return (
    <div style={{ border: "1px solid", width: "100%", height: "400px" }}>
      {selectedItems[0]?.length === 0 ? (
        <div className="d-flex flex-column h-100 justify-content-center align-items-center gap-2">
          <img src={noRequest} alt="" />
          Bạn chưa gửi lời mời kết bạn nào
        </div>
      ) : statusRun ? (
        <div className="d-flex flex-column h-100 justify-content-center align-items-center gap-2">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2em" }}></i>
        </div>
      ) : dataScanRs && dataScanRs.length > 0 ? (
        <DataTable
          size="small"
          value={dataScanRs}
          className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect custom-scrollbar"
          tableStyle={{ minWidth: "100%" }}
          rowClassName={(rowData) => rowClassName(rowData, listFr)}
          onRowClick={(e) => onRowClick(e.data, setListFr, listFr)}
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3em" }}
          ></Column>
          <Column body={indexTemplate} header="STT"></Column>
          <Column
            body={(rowData) => nameAndAvt(rowData)}
            field="zaloName"
            header="Tên tài khoản"
            sortable
          ></Column>
          <Column body={messageSent} header="Tin nhắn đã gửi"></Column>
          <Column body={timeSent} header="Thời gian gửi" sortable></Column>
          <Column body={(rowData) => buttonOption(rowData)} header="Tuỳ chọn" />
        </DataTable>
      ) : (
        <div className="d-flex flex-column h-100 justify-content-center align-items-center gap-2">
          <img src={noRequest} alt="" />
          Bạn chưa gửi lời mời kết bạn nào
        </div>
      )}
    </div>
  );
};
