import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_URL, API_ROUTES, WS_URL, API_ROUTES_ZALO } from "../../../api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useUser } from "../../../contexts/UserContext";
import { Image } from "primereact/image";
import { getAxios } from "../../../const/getAxios";
import { FadeLoader } from "react-spinners";
import { Dropdown } from "primereact/dropdown";

export const CreateAccount = ({
  setModalShow,
  modalShow,
  toast,
  headers,
  setRefresh,
  props,
  t,
  refresh,
  setDataFb,
}) => {
  const access = localStorage.getItem("access");
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const getListProxy = API_URL + API_ROUTES_ZALO.GET_PROXY;
  const [listProxy, setListProxy] = useState([]);
  const [proxy, setProxy] = useState(null);
  const { userInfo } = useUser();
  const socketRef = useRef(null);
  const [resultQr, setResultQr] = useState([]);
  const [countdown, setCountdown] = useState(60);
  useEffect(() => {
    if (userInfo?.id && modalShow) {
      if (!socketRef.current) {
        const client = new W3CWebSocket(
          `wss://${WS_URL}/ws/account/${userInfo?.id}/?token=${access}`
        );
        socketRef.current = client;

        client.onopen = function () {};
        client.onerror = function () {};
        client.onmessage = function (message) {
          const parsedData = JSON.parse(message.data);
          if (parsedData.result) {
            if (
              parsedData.result === "Tài khoản Zalo đã tồn tại trên hệ thống"
            ) {
              toast.error(parsedData.result);
              setResultQr([]);
              setModalShow(false);
              return;
            }
            setResultQr(parsedData.result);
            if (parsedData.result === true) {
              getAxios(getDataFb, setDataFb);
              setModalShow(false);
            }
          }
        };
        return () => {
          if (client) {
            client.close();
            socketRef.current = null;
          }
        };
      }
    }
  }, [userInfo, modalShow]);
  useEffect(() => {
    getAxios(getListProxy, setListProxy);
    if (
      proxy?.id &&
      modalShow &&
      socketRef.current?.readyState === WebSocket.OPEN
    ) {
      const sendCommand = () => {
        socketRef.current.send(
          JSON.stringify({
            command: "login_qr",
            proxy: proxy?.proxy,
          })
        );
        setCountdown(60);
      };
      sendCommand();
      const interval = setInterval(() => {
        sendCommand();
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [modalShow, proxy]);
  useEffect(() => {
    if (modalShow && resultQr.length > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => (prev > 0 ? prev - 1 : 62));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [modalShow, resultQr]);
  const itemTemplate = (option) => {
    return (
      <div className="d-flex flex-column">
        <span>{option.proxy}</span>
        {option.note && <span>{`Ghi chú: ${option.note}`}</span>}
      </div>
    );
  };
  return (
    <div className="flex justify-content-center">
      <Dialog
        header={t("Quét Mã QR CODE")}
        visible={modalShow}
        style={{ width: "max-content" }}
        onHide={() => setModalShow(false)}
      >
        <div
          className="card flex flex-column flex-wrap gap-3"
          style={{ border: "0" }}
        >
          <Dropdown
            value={proxy}
            onChange={(e) => setProxy(e.value)}
            options={listProxy}
            optionLabel="proxy"
            itemTemplate={itemTemplate}
            placeholder="Chọn Proxy"
            className="w-100"
          />
          <div className="flex justify-content-center align-items-center flex-column w-100">
            {resultQr?.length > 0 && proxy ? (
              <>
                <Image src={resultQr} alt="Image" width="250" preview />
                <nav className="d-flex gap-2 justify-content-center">
                  <p>Mã Qr sẻ được làm mới sau:</p>
                  <p style={{ color: countdown > 10 ? "blue" : "red" }}>
                    {countdown}
                  </p>
                </nav>
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center w-100">
                {proxy && <FadeLoader />}
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2"></div>
      </Dialog>
    </div>
  );
};
