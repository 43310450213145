import React, { useEffect, useMemo } from "react";
import Navigate from "../../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES_ZALO, API_ROUTES } from "../../../api";
import { Footer } from "../Footer";
import { Button } from "primereact/button";
import { useHeaders } from "../../../const/headers";
import { InputGroup } from "react-bootstrap";
import { updateTitle } from "../../../const/updateTitle";
import "./product.css";
import { getAxiosResults, getAxiosFilter } from "../../../const/getAxios";
import { formatDateTime, formatDateArticle } from "../../../const/convertDate";
import ModalEditProduct from "./ModalEditProduct";
import { Paginator } from "primereact/paginator";
import { useUser } from "../../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Image } from "primereact/image";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
export const ManagerProducts = () => {
  updateTitle("Chốt Care - Đơn hàng");
  const [rows, setRows] = useState(100);
  const headers = useHeaders();
  const { userInfo } = useUser();
  const apiAllOrder = API_URL + API_ROUTES_ZALO.ALL_ORDER_SHOP;
  const apiConfirmOrder = API_URL + API_ROUTES_ZALO.CONFIRM_ORDER_PRODUCT;
  const apiCancelOrder = API_URL + API_ROUTES_ZALO.CANCEL_ORDER_PRODUCT;
  const apiDeleteOrder = API_URL + API_ROUTES_ZALO.DELETE_ORDER_PRODUCT;
  const getEmployee = API_URL + API_ROUTES.GET_LIST_EMPLOYEE;
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [orders, setOrders] = useState([]);
  const [first, setFirst] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const [refes, setRefes] = useState(false);
  const { t } = useTranslation();
  const [productEdit, setProductEdit] = useState([]);
  const [productDetail, setProductDetail] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [keywords, setKeywords] = useState("");
  const [filterButton, setFilterButton] = useState(false);
  const [listEmpl, setListEmpl] = useState([]);
  const [valueEpl, setValueEpl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  useEffect(() => {
    getAxiosFilter(
      apiAllOrder,
      setOrders,
      setGetTotal,
      rows,
      totalPage + 1,
      keywords,
      startDate ? formatDateArticle(startDate).split(" ")[1] : "",
      endDate ? formatDateArticle(endDate).split(" ")[1] : "",
      valueEpl?.id || "",
      selectedStatus?.status === undefined ? "" : selectedStatus?.status
    );
  }, [
    refes,
    totalPage,
    rows,
    startDate,
    endDate,
    filterButton,
    valueEpl,
    selectedStatus,
  ]);
  useEffect(() => {
    getDataEmpl();
  }, []);
  useEffect(() => {
    if (productEdit?.id) {
      setProductEdit(orders?.filter((item) => item.id === productEdit.id)[0]);
    }
  }, [orders]);
  useEffect(() => {
    if (productDetail?.id) {
      setProductDetail(orders?.filter((item) => item.id === productDetail.id)[0]);
    }
  }, [orders]);
  const handleConfirmOrder = async (item) => {
    const body = {
      id_order: item.id,
    };
    try {
      await axios.post(apiConfirmOrder, body, headers);
      getAxiosResults(apiAllOrder, setOrders, setGetTotal, rows, totalPage + 1);
    } catch (err) { }
  };
  const getDataEmpl = async () => {
    try {
      const response = await axios.get(getEmployee, headers);
      setListEmpl(response.data);
    } catch (err) { }
  };
  const handleCancelOrder = async (item) => {
    const body = {
      id_order: item.id,
    };
    try {
      await axios.post(apiCancelOrder, body, headers);
      getAxiosResults(apiAllOrder, setOrders, setGetTotal, rows, totalPage + 1);
    } catch (err) { }
  };
  const handleDeleteOrders = async (item) => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá đơn hàng này !");
    if (confirm) {
      const body = {
        id_order: item.id,
      };
      try {
        await axios.post(apiDeleteOrder, body, headers);
        getAxiosResults(
          apiAllOrder,
          setOrders,
          setGetTotal,
          rows,
          totalPage + 1
        );
      } catch (err) { }
    }
  };
  const TotalMoney = (item) => {
    let totalMoney = 0;
    if (item && Array.isArray(item.items)) {
      totalMoney = item.items.reduce((acc, curr) => acc + (curr.price * curr.quantity), 0);
    }
    return totalMoney;
  };
  useEffect(() => {
    if (productEdit?.id) {
      setProductEdit(orders.filter((item) => item?.id === productEdit.id)[0]);
    }
  }, [orders]);
  useEffect(() => {
    if (productDetail?.id) {
      setProductDetail(orders.filter((item) => item?.id === productDetail.id)[0]);
    }
  }, [orders]);

  const employeeView = (rowData) => {
    return (
      <div>
        {listEmpl?.filter((item) => item.id === rowData?.employee)[0]?.fullname}
      </div>
    );
  };

  const renderActions = (rowData) => {
    return (
      <div className="d-flex flex-column gap-2">
        <Button
          label={t("Chi tiết")}
          onClick={() => {
            setOpenDetail(true);
            setProductDetail(rowData);
          }}
          className="button-blue radius"
        />
        {rowData.status !== 0 && (
          <Button
            label={
              rowData.status === 1
                ? t("Huỷ")
                : rowData.status === 0
                  ? ""
                  : rowData.status === 2
                    ? t("Nhận đơn")
                    : ""
            }

            className={`${rowData.status === 1 ? "button-red" : "button-blue"
              } radius`}
            onClick={() =>
              rowData.status === 1
                ? handleCancelOrder(rowData)
                : handleConfirmOrder(rowData)
            }
          />
        )}

        {rowData.status !== 1 && rowData.status !== 0 && (
          <Button
            label={t("Sửa")}
            onClick={() => {
              setOpenEdit(true);
              setProductEdit(rowData);
            }}
            className="button-blue radius"
          />
        )}
        {rowData.status !== 1 && userInfo?.id === rowData.user && (
          <Button
            label={t("Xoá")}
            className="button-red radius"
            onClick={() => handleDeleteOrders(rowData)}
          />
        )}
      </div>
    );
  };
  const renderStatus = (rowData) => {
    return t(
      rowData.status === 0
        ? "Đã huỷ"
        : rowData.status === 1
          ? "Đã xác nhận"
          : "Chờ xác nhận"
    );
  };
  const statusOptions = useMemo(() => {
    const statusSet = new Set(orders.map((item) => item.status));
    return Array.from(statusSet).map((status) => ({
      label: t(
        status === 0 ? "Đã huỷ" : status === 1 ? "Đã xác nhận" : "Chờ xác nhận"
      ),
      value: status,
    }));
  }, [orders]);

  // useEffect(() => {
  //   if (selectedStatus === null) {
  //     setFilteredData(orders);
  //   } else {
  //     setFilteredData(orders.filter((item) => item.status === selectedStatus));
  //   }
  // }, [selectedStatus, orders]);

  const handleStatusFilter = (event) => {
    setSelectedStatus(event.value);
  };

  const clearFilter = () => {
    setSelectedStatus(null); // Xóa giá trị bộ lọc
  };
  const apiGetDomain = API_URL + API_ROUTES_ZALO.GET_DOMAIN;
  const [domain, setDomain] = useState(null);
  const getDomainShop = async () => {
    try {
      const res = await axios.get(apiGetDomain, headers);
      setDomain(res.data);
    } catch (err) { }
  };
  useEffect(() => {
    getDomainShop();
  }, []);
  const renderProducts = (items) => {
    return items.map((pro, i) => (
      <tr key={i}>
        <td>
          <div className="d-flex flex-column" style={{ textAlign: "-webkit-center" }}>
            <Image
              src={`${API_URL}${pro.image}`}
              alt={pro.title}
              width="40"
              height="40"
              preview
            />
            <a target="blank"
              href={`https://${domain}/showproduct/${productDetail.user}/${pro.id_category}/${pro.id_product}`}
            >
              {`${pro.title} - ${pro?.options
                ?.map((option) => `${option.name}: ${option.value}`)
                .join(", ")}`}</a>

          </div>
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>{pro.quantity}</td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>{pro.price.toLocaleString("vi-VN")} VND</td>
      </tr>
    ));
  };

  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="p-4 w-100 h-100 d-flex flex-column mgtop-respon-47">
          <ModalEditProduct
            toast={toast}
            refes={refes}
            setRefes={setRefes}
            orders={orders}
            productEdit={productEdit}
            setProductEdit={setProductEdit}
            openEdit={openEdit}
            setOpenEdit={setOpenEdit}
          />
          <div className="d-flex justify-content-between mb-2 align-items-center">
            <h3 className="title-product-minishop mb-0 w-100">
              {t("Quản lý đơn hàng MiniShop")}
            </h3>
            <div className="navigate-product d-flex gap-2 w-100 justify-content-end">
              <div className="calendar-product d-flex">
                <nav className="d-flex hover-pointer">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      width: "50px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <p className="mb-0 ml-2">{t("Từ")}</p>
                  </span>
                  <Calendar
                    style={{ fontSize: "12px" }}
                    placeholder={t("Ngày bắt đầu")}
                    locale="vi"
                    id="buttondisplay"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.value);
                    }}
                    readOnlyInput
                    maxDate={endDate}
                    disabledDates={[]}
                  />
                </nav>
                <nav className="d-flex hover-pointer">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      width: "50px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <p className="mb-0 ml-2">{t("Đến")}</p>
                  </span>
                  <Calendar
                    style={{ fontSize: "12px" }}
                    placeholder={t("Ngày kết thúc")}
                    locale="vi"
                    id="buttondisplay"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.value);
                    }}
                    minDate={startDate}
                    readOnlyInput
                    disabledDates={[]}
                  />
                </nav>
              </div>
              <div className="p-inputgroup flex-1 w-100">
                <InputText
                  placeholder="Tìm kiếm"
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  style={{ width: "max-content" }}
                />
                <Button
                  onClick={() => setFilterButton(!filterButton)}
                  icon="pi pi-search"
                  className="p-button-warning"
                />
              </div>
            </div>
          </div>
          <div className="w-100 h-100 d-flex flex-column overflow-auto">
            <div className="product-management d-flex h-100 flex-column">
              <DataTable
                value={orders}
                scrollable
                scrollHeight="auto"
                ableStyle={{ minWidth: "100%" }}
                className="small-font-table h-100 text-align-center"
              >
                <Column
                  header={t("STT")}
                  body={(data, options) => options.rowIndex + 1}
                />
                <Column
                  style={{ textAlign: "center", maxWidth: "120px", verticalAlign: "middle" }}
                  field="full_name"
                  header={t("Nhân viên")}
                  body={employeeView}
                  filter
                  filterElement={
                    <div className="filter-container">
                      <Dropdown
                        options={[{ id: "", fullname: "Tất cả" }, ...listEmpl]}
                        value={valueEpl}
                        onChange={(e) => setValueEpl(e.value)}
                        optionLabel="fullname"
                        placeholder={t("Chọn nhân viên")}
                      />
                      <Button
                        label={t("Clear Filter")}
                        onClick={clearFilter}
                        className="button-blue radius"
                      />
                    </div>
                  }
                />
                <Column
                  style={{ textAlign: "center" }}
                  field="full_name"
                  header={t("Người nhận")}
                  body={(rowData) =>
                  (
                    <div id="full_name-detail"
                      onClick={() => {
                        setOpenDetail(true);
                        setProductDetail(rowData);
                      }}
                    >
                      {rowData.full_name}
                    </div>
                  )
                  }
                />

                <Column header={t("Thành tiền")}
                  field="totalMonney"
                  sortable
                  body={(rowData) => (
                    <p>{`${TotalMoney(rowData).toLocaleString("vi-VN")} VND`}</p>
                  )}

                />
                <Column
                  sortable
                  field="created_at"
                  header={t("Ngày đặt")}
                  body={(rowData) => formatDateTime(rowData.created_at)}
                />
                <Column
                  header={t("Trạng thái")}
                  body={renderStatus}
                  filter
                  filterMenuStyle={{ fontSize: "12px" }}
                  filterElement={
                    <div className="filter-container">
                      <Dropdown
                        options={[
                          { label: "Đã huỷ", status: 0 },
                          { label: "Đã xác nhận", status: 1 },
                          { label: "Chờ xác nhận", status: 2 },
                        ]}
                        value={selectedStatus}
                        onChange={handleStatusFilter}
                        optionLabel="label"
                        placeholder={t("Chọn trạng thái")}
                      />
                      <Button
                        label={t("Clear Filter")}
                        onClick={clearFilter}
                        className="button-blue radius"
                      />
                    </div>
                  }
                />
                <Column
                  headerClassName="btnAction-product"
                  header={t("Tuỳ chọn")}
                  body={renderActions}
                />
              </DataTable>
            </div>
            <Dialog
              header="Thông tin đơn hàng"
              className="dialog-shop"
              visible={openDetail}
              style={{ width: "90%" }}
              onHide={() => {
                if (!openDetail) return;
                setOpenDetail(false);
              }}
            >
              <div>
                <label>Họ tên</label>
                <p>{productDetail.full_name}</p>
                <label>Sổ điện thoại</label>
                <p>{productDetail.phone_number}</p>
                <label>Địa chỉ</label>
                <p>{`${productDetail.address}  ${productDetail.ward} - ${productDetail.district} - ${productDetail.city}`}</p>
              </div>
              <table className="table table-striped mt-3 modal-detail-product">
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th>{t("Tên sản phẩm")}</th>
                    <th>{t("Số lượng")}</th>
                    <th>{t("Giá tiền")}</th>
                  </tr>
                </thead>
                <tbody>
                  {productDetail.items && renderProducts(productDetail.items)}
                </tbody>
              </table>
            </Dialog>
            <Paginator
              className="pagination-markerting"
              first={first}
              rows={rows}
              totalRecords={getTotal?.count}
              onPageChange={onPageChange}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
