import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { MdAccountCircle } from "react-icons/md";
import { MdOutlinePassword } from "react-icons/md";
import { Calendar } from "primereact/calendar";
import { FaCalendarAlt } from "react-icons/fa";
import { Button } from "primereact/button";
import moment from "moment-timezone";
import axios from "axios";
import { API_URL, API_ROUTES } from "../../../api";
import { useUser } from "../../../contexts/UserContext";
import { Password } from "primereact/password";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineTimeline } from "react-icons/md";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { CiViewTimeline } from "react-icons/ci";
import { useHeaders } from "../../../const/headers";
import { Dropdown } from "primereact/dropdown";
import { FaUserGroup } from "react-icons/fa6";
import { formatDateArticle } from "../../../const/convertDate";
import { toast } from "react-toastify";
export const ModalEditSep = ({
  setModalEdit,
  modalEdit,
  setRefresh,
  refresh,
  getItemEdit,
  managerFilter,
}) => {
  const currentDate = moment().tz("Asia/Ho_Chi_Minh").toDate();
  const { userInfo, setUserInfo } = useUser();
  const headers = useHeaders();
  const [date, setDate] = useState(null);
  const editAcc = API_URL + API_ROUTES.EDIT_ACCOUNT_MANAGER;
  const { t } = useTranslation();
  const today = new Date();
  const formatDate = (date) => {
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }
    return date.toISOString().split("T")[0];
  };
  const [form, setForm] = useState({
    facebook_link: "",
    username: "",
    fullname: "",
    password: "",
    phone_number: "",
    employee_limit: "",
    expiration_date: formatDate(today),
    mail: "",
    account_limit: "",
    permission: "",
  });
  const [errors, setErrors] = useState({});

  const styles = {
    mb0ml2: {
      width: "205px",
      justifyContent: "flex-start",
    },
  };

  const handleDateChange = (e) => {
    setForm({
      ...form,
      expiration_date: e.target.value,
    });
  };

  const permissionsList = [
    { name: "Quản trị viên", value: "is_admin" },
    { name: "Khách hàng", value: "is_manager" },
    { name: "Lập trình viên", value: "is_developer" },
    { name: "Chăm sóc khách hàng", value: "is_saler" },
  ];

  const selectedPermissionTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder || "Vui lòng chọn quyền"}</span>;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handlePermissionChange = (e) => {
    setForm({ ...form, permission: e.value });
  };
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  }
  const validatePhoneNumber = (phone_number) => {
    const re = /^(0[3|5|7|8|9])+([0-9]{8})$/;
    return re.test(String(phone_number));
  }
  const validateForm = () => {
    const newErrors = {};

    if (!form.fullname.trim()) {
      newErrors.fullname = "Họ tên không được để trống.";
    }
    if (!form.username.trim()) {
      newErrors.username = "Tên đăng nhập không được để trống";
    }
    if (form.password.length < 6) {
      newErrors.password = "Mật khẩu phải ít nhất 6 ký tự.";
    }
    if (!form.permission) {
      newErrors.permission = "Bạn phải chọn ít nhất một quyền.";
    }
    if (!form.mail) {
      newErrors.mail = "Email không được để trống";
    }
    if (!validateEmail(form.mail)) {
      newErrors.mail = "Email không hợp lệ";
    }
    if (!validatePhoneNumber(form.phone_number)) {
      newErrors.phone_number = "Số điện thoại không hợp lệ"
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (getItemEdit) {
      const expirationDate = new Date(getItemEdit.expiration_date);
      setForm({
        facebook_link: getItemEdit.facebook_link || "",
        username: getItemEdit.username || "",
        fullname: getItemEdit.fullname || "",
        password: getItemEdit.raw_password || "",
        phone_number: getItemEdit.phone_number || "",
        employee_limit: getItemEdit.employee_limit || "",
        expiration_date: expirationDate,
        mail: getItemEdit.mail || "",
        account_limit: getItemEdit.account_limit || "",
        permission: getItemEdit.permission || "",
      });
    }
  }, [getItemEdit]);

  const editManager = async () => {
    if (validateForm()) {
      const body = {
        facebook_link: form.facebook_link,
        username: form.username,
        fullname: form.fullname,
        password: form.password,
        phone_number: form.phone_number,
        employee_limit: form.employee_limit,
        account_limit: form.account_limit,
        expiration_date: `${formatDateArticle(form.expiration_date).split(" ")[1].split("-")[2]
          }-${formatDateArticle(form.expiration_date).split(" ")[1].split("-")[1]}-${formatDateArticle(form.expiration_date).split(" ")[1].split("-")[0]
          } 23:59:59`,
        mail: form.mail,
        account_limt: form.account_limt,
        permission: form.permission,
        id_manager: getItemEdit.id,
      };
      try {
        await axios.post(editAcc, body, headers);
        setRefresh(!refresh);
        setModalEdit(false);
        setForm("");
      } catch (err) {
        console.error(err);
        if (
          err.response &&
          err.response.data.error ===
            "Tài khoản đăng nhập đã tồn tại trên hệ thống"
        ) {
          toast.error("Tài khoản đăng nhập đã tồn tại trên hệ thống");
        }
      }
    }
  };
  return (
    <div className=" flex justify-content-center ">
      <Dialog
        header="Chỉnh sửa tài khoản"
        visible={modalEdit}
        style={{ width: "50vw" }}
        onHide={() => setModalEdit(false)}
      >
        <div
          className="card flex flex-column flex-wrap md:flex-row gap-3"
          style={{ border: "0" }}
        >
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdAccountCircle />
              <p className="mb-0 ml-2">Tên đăng nhập</p>
            </span>
            <InputText
              name="username"
              onChange={handleInputChange}
              value={form.username}
              placeholder="Tên đăng nhập"
              disabled
            />
            {errors.username && (
              <small className="p-error">{errors.username}</small>
            )}
          </div>

          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdOutlinePassword />
              <p className="mb-0 ml-2">Mật khẩu</p>
            </span>
            <Password
              name="password"
              toggleMask
              feedback={t("")}
              onChange={handleInputChange}
              value={form.password}
              placeholder="Mật khẩu đăng nhập"
            />
            {errors.password && (
              <small className="p-error">{errors.password}</small>
            )}
          </div>

          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdDriveFileRenameOutline />
              <p className="mb-0 ml-2">Họ tên</p>
            </span>
            <InputText
              name="fullname"
              onChange={handleInputChange}
              value={form.fullname}
              placeholder="Họ Tên"
            />
            {errors.fullname && (
              <small className="p-error">{errors.fullname}</small>
            )}
          </div>

          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon " style={styles.mb0ml2}>
              <FaPhoneAlt />
              <p className="mb-0 ml-2">Số điện thoại</p>
            </span>
            <InputText
              name="phone_number"
              onChange={handleInputChange}
              value={form.phone_number}
              placeholder="Số điện thoại"
              keyfilter={/[0-9]/}
            />
            {errors.phone_number && (
              <small className="p-error">{errors.phone_number}</small>
            )}

          </div>

          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdDriveFileRenameOutline />
              <p className="mb-0 ml-2">Link Facebook</p>
            </span>
            <InputText
              name="facebook_link"
              onChange={handleInputChange}
              value={form.facebook_link}
              placeholder="Link Facebook"
            />
          </div>

          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdDriveFileRenameOutline />
              <p className="mb-0 ml-2">Mail</p>
            </span>
            <InputText
              name="mail"
              onChange={handleInputChange}
              value={form.mail}
              placeholder="Mail"
            />
          </div>
          {errors.mail && (
            <small className="p-error">{errors.mail}</small>
          )}
          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <FaUserGroup />
              <p className="mb-0 ml-2">Phân quyền</p>
            </span>
            <Dropdown
              options={permissionsList}
              optionLabel="name"
              optionValue="value"
              value={form.permission}
              onChange={handlePermissionChange}
              placeholder="Phân quyền"
              valueTemplate={selectedPermissionTemplate}
            />
            {errors.permission && (
              <small className="p-error">{errors.permission}</small>
            )}
          </div>

          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdOutlineTimeline />
              <p className="mb-0 ml-2">Số lượng nhân viên</p>
            </span>
            <InputText
              name="employee_limit"
              onChange={handleInputChange}
              value={form.employee_limit}
              type="number"
              min={0}
            />
          </div>

          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <MdOutlineTimeline />
              <p className="mb-0 ml-2">Số lượng tài khoản</p>
            </span>
            <InputText
              name="account_limt"
              onChange={handleInputChange}
              value={form.account_limit}
              type="number"
              min={0}
            />
          </div>

          <div className="p-inputgroup ">
            <span className="p-inputgroup-addon" style={styles.mb0ml2}>
              <FaCalendarAlt />
              <p className="mb-0 ml-2">Hạn sử dụng</p>
            </span>
            <Calendar
              placeholder="Hạn sử dụng"
              locale="vi"
              id="buttondisplay"
              value={
                form.expiration_date ? new Date(form.expiration_date) : null
              }
              onChange={handleDateChange}
              minDate={currentDate}
              readOnlyInput
              disabledDates={[]}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          {getItemEdit?.is_manager === true ? (
            <Button
              className=" button-blue radius"
              label="Sửa"
              onClick={() => editManager()}
            />
          ) : (
            <></>
          )}
          <Button
            className=" button-red radius"
            label="Hủy"
            onClick={() => setModalEdit(false)}
            severity="danger"
          />
        </div>
      </Dialog>
    </div>
  );
};
