import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL, API_ROUTES, API_ROUTES_ZALO } from "../../../api";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { getAxios } from "../../../const/getAxios";
import { Dropdown } from "primereact/dropdown";

export const EditAccount = ({
  setShowData,
  showData,
  toast,
  props,
  setRefresh,
  headers,
  setSelectedItemEdit,
  selectedItemEdit,
  refresh,
  t,
}) => {
  const getListProxy = API_URL + API_ROUTES_ZALO.GET_PROXY;
  const editData = API_URL + API_ROUTES.CHECK_ACC_EDIT;
  const [listProxy, setListProxy] = useState([]);
  const [proxy, setProxy] = useState(null);
  useEffect(() => {
    getAxios(getListProxy, setListProxy);
    setProxy(
      listProxy.filter((item) => item.id === selectedItemEdit?.proxy?.id)[0]
    );
  }, [showData]);

  const handleNoteChange = (event) => {
    setSelectedItemEdit((prevItem) => ({
      ...prevItem,
      note: event.target.value,
    }));
  };
  const handleNameChange = (event) => {
    setSelectedItemEdit((prevItem) => ({
      ...prevItem,
      name: event.target.value,
    }));
  };
  const handleProxyChange = (event) => {
    setSelectedItemEdit((prevItem) => ({
      ...prevItem,
      proxy: event.target.value,
    }));
  };
  const editDataFb = async () => {
    const newArray = {
      id: selectedItemEdit.id,
      id_proxy: proxy.id,
      note: selectedItemEdit.note,
      password: selectedItemEdit.password,
    };
    try {
      const body = newArray;
      await axios.post(editData, body, headers);
      setShowData(false);
      setRefresh(!refresh);
    } catch (error) {
      toast.error(t("sửa không thành công"));
    }
  };
  return (
    <Dialog
      header={t("Chỉnh sửa thông tin")}
      visible={showData}
      style={{ width: "50vw" }}
      onHide={() => setShowData(false)}
    >
      <div className="w-100" xs={6} md={4}>
        <div className="p-inputgroup flex-1">
          <span
            style={{ width: "9rem" }}
            className="input-group-text"
            id="addon-wrapping"
          >
            {t("Tên tài khoản")}
          </span>
          <InputText
            disabled={true}
            value={selectedItemEdit?.name || ""}
            onChange={handleNameChange}
          />
        </div>
        <div className="p-inputgroup flex-1">
          <span
            style={{ width: "9rem" }}
            className="input-group-text"
            id="addon-wrapping"
          >
            Proxy
          </span>
          <Dropdown
            value={proxy}
            onChange={(e) => setProxy(e.value)}
            options={listProxy?.filter((item) => item.status === true)}
            optionLabel="proxy"
            placeholder="Chọn Proxy"
            className="w-full md:w-14rem"
          />
        </div>
        <div className="p-inputgroup flex-1">
          <span
            style={{ width: "9rem" }}
            className="input-group-text"
            id="addon-wrapping"
          >
            Mật khẩu
          </span>
          <InputText
            value={selectedItemEdit?.password || ""}
            onChange={handleProxyChange}
          />
        </div>
        <div className="p-inputgroup flex-1">
          <span
            style={{ width: "9rem" }}
            className="input-group-text"
            id="addon-wrapping"
          >
            {t("Ghi chú")}
          </span>
          <InputText
            value={selectedItemEdit?.note || ""}
            onChange={handleNoteChange}
          />
        </div>
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            className="button-blue radius"
            label={t("Lưu")}
            onClick={() => editDataFb()}
          />
        </div>
      </div>
    </Dialog>
  );
};
