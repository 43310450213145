import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Dialog } from "primereact/dialog";
import { IoIosLink } from "react-icons/io";
import { MdContentCopy } from "react-icons/md";
import { useHeaders } from "../../../const/headers";
import { API_ROUTES, API_URL } from "../../../api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useUser } from "../../../contexts/UserContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { InputTextarea } from "primereact/inputtextarea";

export const Testimonials = () => {
  const { userInfo } = useUser();
  const [rf, setRf] = useState(false);
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [imageFirst, setImageFirst] = useState("");
  const [content, setContent] = useState("");
  const [content1, setContent1] = useState("");
  const [content2, setContent2] = useState("");
  const [description, setDescription] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [landing, setLanding] = useState([]);
  const [file, setFile] = useState(null);
  const headers = useHeaders();
  const uploadEndpoint = API_URL + API_ROUTES.UPLOAD_FILE_SERVER;
  const createUpdateEndpoint = API_URL + API_ROUTES.EDIT_LANDING;
  const apiGetLanding = API_URL + API_ROUTES.GET_ALL_LANDING;

  const fetchData = async () => {
    try {
      const res = await axios.get(apiGetLanding);
      setLanding(res.data);
    } catch (err) { }
  };

  useEffect(() => {
    fetchData();
  }, [rf]);

  const contentFistMain = landing?.filter((item) => item?.id === 19);
  const contentSecondMain = landing?.filter((item) => item?.id === 20);
  const contentThirdMain = landing?.filter((item) => item?.id === 21);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataURL = reader.result;
      const imgElement = document.getElementById("previewImage");
      if (imgElement) {
        imgElement.src = imageDataURL;
      }
    };
    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };
    reader.readAsDataURL(file);
  };

  const onChangeContent = (e) => {
    setContent(e.target.value);
  };
  const onChangeContent1 = (e) => {
    setContent1(e.target.value);
  };
  const onChangeContent2 = (e) => {
    setContent2(e.target.value);
  };

  const onChangeLink = (e) => {
    setDescription(e.target.value);
  };
  const onChangeLink1 = (e) => {
    setDescription1(e.target.value);
  };
  const onChangeLink2 = (e) => {
    setDescription2(e.target.value);
  };

  const uploadToServer = async () => {
    if (file !== null) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await axios.post(uploadEndpoint, formData, headers);
        setImage(response.data.image);
        setImage1(response.data.image);
        setImage2(response.data.image);
      } catch (error) { }
    }
  };

  useEffect(() => {
    setImage(contentFistMain[0]?.image);
    setDescription(contentFistMain[0]?.content);
    setContent(contentFistMain[0]?.title);
    setImage1(contentSecondMain[0]?.image);
    setDescription1(contentSecondMain[0]?.content);
    setContent1(contentSecondMain[0]?.title);
    setImage2(contentThirdMain[0]?.image);
    setDescription2(contentThirdMain[0]?.content);
    setContent2(contentThirdMain[0]?.title);
  }, [visible, visible1, visible2]);

  useEffect(() => {
    uploadToServer();
  }, [file]);

  const createResource = async () => {
    const data = {
      id: contentFistMain[0]?.id,
      title: content,
      content: description,
      image: image === "" ? contentFistMain[0]?.image : image,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription("");
      setContent("");
      setVisible(false);
    } catch (error) { }
  };
  const createResource1 = async () => {
    const data = {
      id: contentSecondMain[0]?.id,
      title: content1,
      content: description1,
      image: image1 === "" ? contentSecondMain[0]?.image : image1,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription1("");
      setContent1("");
      setVisible1(false);
    } catch (error) { }
  };
  const createResource2 = async () => {
    const data = {
      id: contentThirdMain[0]?.id,
      title: content2,
      content: description2,
      image: image2 === "" ? contentThirdMain[0]?.image : image2,
    };
    try {
      const response = await axios.post(createUpdateEndpoint, data, headers);
      setRf(!rf);
      setDescription2("");
      setContent2("");
      setVisible2(false);
    } catch (error) { }
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="testimonials section">
      <div className="container section-title aos-init aos-animate">
        <h2>Phản Hồi Từ Khách Hàng</h2>
        <hr />
      </div>
      <div
        className="container aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <Slider {...settings}>
          <div className="col-lg-12 col-md-12 flex flex-column justify-content-center align-items-center">
            <img
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                border: "4px solid #ffffff",
              }}
              src={`${API_URL}/${contentFistMain[0]?.image}`}
            />
            <h3
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                padding: "10px 0 5px 0",
              }}
            >
              {contentFistMain[0]?.title}
            </h3>

            <div class="stars mb-2">
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
            </div>
            <p style={{ fontStyle: "italic", textAlign: "center" }}>
              <i
                class="bi bi-quote quote-icon-left bi-qute-right"
                style={{
                  color: "color-mix(in srgb, #47b2e4, transparent 50%)",
                  fontSize: "26px",
                }}
              ></i>
              <span
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{
                  __html: contentFistMain[0]?.content,
                }}
              />
              <i
                style={{
                  color: "color-mix(in srgb, #47b2e4, transparent 50%)",
                  fontSize: "26px",
                }}
                class="bi bi-quote quote-icon-right bi-qute-left"
              ></i>
            </p>
            {userInfo?.is_admin === true ? (
              <button
                style={{
                  width: "100%",
                  border: "none",
                  backgroundColor: "#0866ff",
                  color: "#fff",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  outline: "none",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => setVisible(true)}
              >
                Sửa
              </button>
            ) : (
              <></>
            )}
            <Dialog
              header="Quản lý"
              visible={visible}
              style={{ width: "50vw" }}
              onHide={() => {
                if (!visible) return;
                setVisible(false);
              }}
            >
              <div className="d-flex flex-column gap-2 mr-2 mb-2">
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <MdContentCopy />
                  </span>
                  <InputText
                    onChange={(e) => onChangeContent(e)}
                    placeholder="Tiêu đề"
                    value={content}
                  />
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <IoIosLink />
                  </span>
                  <InputTextarea
                    onChange={(e) => onChangeLink(e)}
                    value={description}
                    placeholder="Mô tả"
                  />
                </div>
                <h3>Chọn Ảnh</h3>
                <label htmlFor="fileInput">
                  <button
                    className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <p className="mb-0">Chọn ảnh</p>
                  </button>
                </label>
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                {image === "" && imageFirst === "" ? (
                  <div></div>
                ) : (
                  <img
                    style={{
                      width: "100px",
                      display: "block",
                    }}
                    alt="Selected Image"
                    src={`${API_URL}/${image === "" ? imageFirst : image}`}
                  />
                )}
              </div>
              <div className="d-flex justify-content-end gap-2 mt-2">
                <Button
                  className="button-blue radius"
                  label="Sửa"
                  onClick={() => createResource()}
                />
                <Button
                  className="button-red radius"
                  label="Hủy"
                  onClick={() => setVisible(false)}
                  severity="danger"
                />
              </div>
            </Dialog>
          </div>
          <div className="col-lg-12 col-md-12 flex flex-column justify-content-center align-items-center">
            <img
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                border: "4px solid #ffffff",
              }}
              src={`${API_URL}/${contentSecondMain[0]?.image}`}
            />
            <h3
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                padding: "10px 0 5px 0",
              }}
            >
              {contentSecondMain[0]?.title}
            </h3>

            <div class="stars mb-2">
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
            </div>
            <p style={{ fontStyle: "italic", textAlign: "center" }}>
              <i
                class="bi bi-quote quote-icon-left bi-qute-right"
                style={{
                  color: "color-mix(in srgb, #47b2e4, transparent 50%)",
                  fontSize: "26px",
                }}
              ></i>
              <span
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{
                  __html: contentSecondMain[0]?.content,
                }}
              />
              <i
                style={{
                  color: "color-mix(in srgb, #47b2e4, transparent 50%)",
                  fontSize: "26px",
                }}
                class="bi bi-quote quote-icon-right bi-qute-left"
              ></i>
            </p>
            {userInfo?.is_admin === true ? (
              <button
                style={{
                  width: "100%",
                  border: "none",
                  backgroundColor: "#0866ff",
                  color: "#fff",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  outline: "none",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => setVisible1(true)}
              >
                Sửa
              </button>
            ) : (
              <></>
            )}

            <Dialog
              header="Quản lý"
              visible={visible1}
              style={{ width: "50vw" }}
              onHide={() => {
                if (!visible1) return;
                setVisible1(false);
              }}
            >
              <div className="d-flex flex-column gap-2 mr-2 mb-2">
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <MdContentCopy />
                  </span>
                  <InputText
                    onChange={(e) => onChangeContent1(e)}
                    placeholder="Tiêu đề"
                    value={content1}
                  />
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <IoIosLink />
                  </span>
                  <InputTextarea
                    onChange={(e) => onChangeLink1(e)}
                    value={description1}
                    placeholder="Mô tả"
                  />
                </div>
              </div>
              <h3>Chọn Ảnh</h3>
              <label htmlFor="fileInput">
                <button
                  className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  <p className="mb-0">Chọn ảnh</p>
                </button>
              </label>
              <input
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              {image1 === "" && imageFirst === "" ? (
                <div></div>
              ) : (
                <img
                  style={{
                    width: "100px",
                    display: "block",
                  }}
                  alt="Selected Image"
                  src={`${API_URL}/${image1 === "" ? imageFirst : image1}`}
                />
              )}
              <div className="d-flex justify-content-end gap-2 mt-2">
                <Button
                  className="button-blue radius"
                  label="Sửa"
                  onClick={() => createResource1()}
                />
                <Button
                  className="button-red radius"
                  label="Hủy"
                  onClick={() => setVisible1(false)}
                  severity="danger"
                />
              </div>
            </Dialog>
          </div>
          <div className="col-lg-12 col-md-12 flex flex-column justify-content-center align-items-center">
            <img
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                border: "4px solid #ffffff",
              }}
              src={`${API_URL}/${contentThirdMain[0]?.image}`}
            />
            <h3
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                padding: "10px 0 5px 0",
              }}
            >
              {contentThirdMain[0]?.title}
            </h3>
            <div class="stars mb-2">
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
            </div>
            <p style={{ fontStyle: "italic", textAlign: "center" }}>
              <i
                class="bi bi-quote quote-icon-left bi-qute-right"
                style={{
                  color: "color-mix(in srgb, #47b2e4, transparent 50%)",
                  fontSize: "26px",
                }}
              ></i>
              <span
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{
                  __html: contentThirdMain[0]?.content,
                }}
              />
              <i
                style={{
                  color: "color-mix(in srgb, #47b2e4, transparent 50%)",
                  fontSize: "26px",
                }}
                class="bi bi-quote quote-icon-right bi-qute-left"
              ></i>
            </p>
            {userInfo?.is_admin === true ? (
              <button
                style={{
                  width: "100%",
                  border: "none",
                  backgroundColor: "#0866ff",
                  color: "#fff",
                  fontSize: "1rem",
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  outline: "none",
                  transition: "all 0.2s ease-in-out",
                }}
                onClick={() => setVisible2(true)}
              >
                Sửa
              </button>
            ) : (
              <></>
            )}
            <Dialog
              header="Quản lý"
              visible={visible2}
              style={{ width: "50vw" }}
              onHide={() => {
                if (!visible2) return;
                setVisible2(false);
              }}
            >
              <div className="d-flex flex-column gap-2 mr-2 mb-2">
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <MdContentCopy />
                  </span>
                  <InputText
                    onChange={(e) => onChangeContent2(e)}
                    placeholder="Tiêu đề"
                    value={content2}
                  />
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <IoIosLink />
                  </span>
                  <InputTextarea
                    onChange={(e) => onChangeLink2(e)}
                    value={description2}
                    placeholder="Mô tả"
                  />
                </div>
              </div>
              <h3>Chọn Ảnh</h3>
              <label htmlFor="fileInput">
                <button
                  className="d-flex flex-column p-2 align-items-center gap-2 border border-secondary justify-content-between rounded"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  <p className="mb-0">Chọn ảnh</p>
                </button>
              </label>
              <input
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              {image2 === "" && imageFirst === "" ? (
                <div></div>
              ) : (
                <img
                  style={{
                    width: "100px",
                    display: "block",
                  }}
                  alt="Selected Image"
                  src={`${API_URL}/${image2 === "" ? imageFirst : image2}`}
                />
              )}
              <div className="d-flex justify-content-end gap-2 mt-2">
                <Button
                  className="button-blue radius"
                  label="Sửa"
                  onClick={() => createResource2()}
                />
                <Button
                  className="button-red radius"
                  label="Hủy"
                  onClick={() => setVisible2(false)}
                  severity="danger"
                />
              </div>
            </Dialog>
          </div>
        </Slider>
      </div>
      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4098.815890214521!2d108.17652497535452!3d16.054323539836375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3142190fdaa1d263%3A0x125be4455d53b54e!2zMTcxIFTDtG4gxJDhuqNuLCBIb8OgIEFuLCBD4bqpbSBM4buHLCDEkMOgIE7hurVuZyA1NTAwMDAsIFZp4buHdCBOYW0!5e1!3m2!1svi!2s!4v1723024409530!5m2!1svi!2s"
        frameBorder="0"
        style={{ border: 0, width: "50%", height: "270px" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe> */}
    </div>
  );
};
