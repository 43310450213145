import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES, API_ROUTES_ZALO } from "../../../api";
import "primeicons/primeicons.css";
import { RiExpandDiagonal2Line } from "react-icons/ri";
import { useAuth } from "../../../AuthContext";
import { TableGroupOfAcc } from "./TableGroupOfAcc";
import { TableAccountGr } from "./TableAccountGr";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import "./group.css";
import { InputText } from "primereact/inputtext";
export const ModalScanGroup = ({
  openCategoryAddFr,
  setOpenCategoryAddFr,
  setRefresh,
  refresh,
  dataZalo,
  setDataZalo,
  listFr,
  setListFr,
  toast,
  setManagerScript,
  renderKey,
}) => {
  const headers = useHeaders();
  const apiAccountZalo = API_URL + API_ROUTES.GET_ACC_FB;
  const zaloAccount = API_URL + API_ROUTES.GET_ACC_FB;
  const apiScanGroup = API_URL + API_ROUTES_ZALO.GET_LIST_GROUP;
  const apiAllGroup = API_URL + API_ROUTES_ZALO.GET_ALL_GROUP;
  const apiQuitGroup = API_URL + API_ROUTES_ZALO.QUIT_GROUP;
  const apiQuitGroupResult = API_URL + API_ROUTES_ZALO.QUIT_GROUP_RESULT;
  const scanFriendRequest = API_URL + API_ROUTES_ZALO.SCAN_LIST_FRIEND_REQUEST;
  const apiResultsListGroup = API_URL + API_ROUTES_ZALO.GET_LIST_GROUP_RESULT;
  const { setUserLoggedIn } = useAuth();
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [rows, setRows] = useState(100);
  const [allAddFriend, setAllAddFriend] = useState([]);
  const [taskId, setTaskId] = useState([]);
  const [dataScanRs, setDataScanRs] = useState("");
  const [statusRun, setStatusRun] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [statusScan, setStatusScan] = useState(false);
  const [allGroup, setAllGroup] = useState([]);
  const [statusQuit, setStatusQuit] = useState(false);
  const [resultQuit, setResultQuit] = useState("");
  const [taskIdOut, setTaskIdOut] = useState([]);
  const [typeDelete, setTypeDelete] = useState("");
  const [rowDataDelete, setRowDataDelete] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [resultScan, setResultScan] = useState("");
  const intervalRef = useRef();
  const getDataAllAddGr = async (item) => {
    if (item?.id !== undefined) {
      try {
        const res = await axios.get(
          `${apiAllGroup}?number_per_page=${rows}&page=${
            totalPage + 1
          }&id_account=${selectedItems[0]?.id}`,
          headers
        );
        setAllGroup(res.data.results);
        setGetTotal(res.data);
      } catch (err) {}
    } else {
      setAllGroup([]);
    }
  };
  const scanListGroups = async () => {
    if (selectedItems?.length === 0) {
      toast.error("Vui lòng chọn tài khoản");
      return;
    }
    const body = { id_accounts: selectedItems?.map((item) => item.id) };
    try {
      const res = await axios.post(apiScanGroup, body, headers);
      setTaskId(res.data.id_task);
      setStatusScan(true);
    } catch (err) {}
  };
  const checkResultScan = async () => {
    if (taskId?.length === 0) {
      return;
    }
    const body = { id_task: taskId };
    try {
      const res = await axios.post(apiResultsListGroup, body, headers);
      setResultScan(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      checkResultScan();
    }, 3000);
    return () => clearInterval(intervalRef.current);
  }, [taskId]);
  useEffect(() => {
    if (resultScan?.status === "SUCCESS" || resultScan?.status === "FAILURE") {
      if (resultScan?.data[0]?.status === true) {
        toast.success("Quét danh sách nhóm thành công");
        getDataAllAddGr(selectedItems[0]);
      }
      if (resultScan?.data[0]?.status === false) {
        toast.error("Quét danh sách nhóm thất bại");
      }
      setStatusScan(false);
      setTaskId([]);
      setResultScan("");
      clearInterval(intervalRef.current);
      setRefresh(!refresh);
    }
  }, [resultScan]);
  useEffect(() => {
    axios
      .get(apiAccountZalo, headers)
      .then(function (response) {
        setDataZalo(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          setUserLoggedIn(false);
        }
      });
  }, []);
  // ========================================================
  // Rời nhóm
  const quitGroup = async (rowData, type) => {
    const groupIds =
      type === "one" ? [rowData.uid] : selectedRows.map((item) => item.uid);
    setTypeDelete(type);
    setRowDataDelete(rowData);
    const body = {
      id_account: selectedItems[0]?.id,
      group_ids: groupIds,
      delay_time: 120,
    };
    try {
      const res = await axios.post(apiQuitGroup, body, headers);
      setTaskIdOut(res.data);
      checkResultQuit(res.data.id_task, rowData, type);
      toast.info("Đang rời nhóm");
      setStatusQuit(true);
    } catch (err) {}
  };
  const checkResultQuit = async (taskId, rowData, type) => {
    const body = { id_task: taskId };
    try {
      const res = await axios.post(apiQuitGroupResult, body, headers);
      if (res.data.status === "SUCCESS") {
        toast.success("Rời nhóm thành công");
        if (type === "one") {
          const spliceGr = allGroup.filter((item) => item.uid !== rowData.uid);
          setAllGroup(spliceGr);
          setTaskIdOut([]);
          setRowDataDelete([]);
        } else if (type === "all") {
          const selectedGrIds = selectedRows.map((item) => item.uid);
          setAllGroup((prevAllGroup) =>
            prevAllGroup.filter((item) => !selectedGrIds.includes(item.uid))
          );
        }
        setSelectedRows([]);
        setStatusQuit(false);
      } else if (res.data.status === "FAILURE") {
        toast.error("Rời nhóm thất bại");
        setStatusQuit(false);
      }

      setResultQuit(res.data ? res.data.status : "UNKNOWN");
    } catch (error) {
      setResultQuit("FAILURE");
      toast.error("Đã xảy ra lỗi khi rời nhóm");
      setStatusQuit(false);
    }
  };
  useEffect(() => {
    if (taskIdOut?.id_task) {
      const interval = setInterval(() => {
        checkResultQuit(taskIdOut.id_task, rowDataDelete, typeDelete);
      }, 3000);
      if (resultQuit === "SUCCESS" || resultQuit === "FAILURE") {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [resultQuit, taskIdOut, rowDataDelete, typeDelete]);

  const handleExit = () => {
    setAllGroup([]);
    setSelectedItems([]);
    setSelectedRows([]);
    setOpenCategoryAddFr(false);
  };
  const filterNameGroup = allGroup.filter((group) =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <Dialog
      header={
        <div
          style={{ position: "relative" }}
          className="d-flex align-items-center gap-2"
        >
          <span>Danh sách nhóm</span>
          <Button
            onClick={scanListGroups}
            disabled={statusScan}
            className="button-blue radius"
            style={{ height: "30px" }}
          >
            <div style={{ fontSize: "12px" }}>
              {statusScan ? (
                <div className="d-flex flex-column h-100 justify-content-center align-items-center gap-2">
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "1.2em" }}
                  ></i>
                </div>
              ) : (
                "quét"
              )}
            </div>
          </Button>
          {selectedRows.length > 0 && (
            <Button
              className=" button-red radius"
              severity="danger"
              style={{
                padding: "4px 6px",
                fontSize: "12px",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: "0",
                right: "0",
              }}
              onClick={() => quitGroup(selectedRows, "all")}
            >
              Rời nhóm đã chọn
            </Button>
          )}
          <div>
            <IconField iconPosition="left">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                className="p-inputtext-sm"
                style={{ fontSize: "12px" }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Tìm kiếm nhóm"
              />
            </IconField>
          </div>
        </div>
      }
      visible={openCategoryAddFr}
      style={{ position: "relative", width: "71vw" }}
      onHide={handleExit}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            onClick={handleExit}
            className="d-flex justify-content-center button-red"
          >
            Thoát
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="d-flex gap-1 w-100 h-100">
          <div style={{ width: "40%" }} className="h-100">
            <TableAccountGr
              getDataAllAddGr={getDataAllAddGr}
              listFr={listFr}
              setListFr={setListFr}
              refresh={refresh}
              dataZalo={dataZalo}
              setAllGroup={setAllGroup}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              allGroup={allGroup}
            />
          </div>
          <div style={{ width: "60%" }} className=" h-100">
            <TableGroupOfAcc
              filterNameGroup={filterNameGroup}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              quitGroup={quitGroup}
              statusQuit={statusQuit}
              setTotalPage={setTotalPage}
              renderKey={renderKey}
              totalPage={totalPage}
              setGetTotal={setGetTotal}
              getTotal={getTotal}
              rows={rows}
              setRows={setRows}
              getDataAllAddGr={getDataAllAddGr}
              refresh={refresh}
              setRefresh={setRefresh}
              toast={toast}
              listFr={listFr}
              setListFr={setListFr}
              statusScan={statusScan}
              allGroup={allGroup}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
              setAllGroup={setAllGroup}
              setManagerScript={setManagerScript}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
