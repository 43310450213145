import { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
import { Paginator } from "primereact/paginator";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_NTD, API_ROUTES_ZALO } from "../../../api";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import { getAxiosResultsGroup } from "./../../../const/getAxios";
import avtGroup from "./avtNew.png";

export const ShowGroupInvine = ({
  accoutDown,
  setAllGroup,
  allGroup,
  setForcusGr,
  forcusGr,
}) => {
  const apiAllGroup = API_URL + API_ROUTES_ZALO.GET_ALL_GROUP;
  const [first, setFirst] = useState(0);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [rows, setRows] = useState(100);
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  useEffect(() => {
    if (!accoutDown?.id) {
      return;
    } else {
      getAxiosResultsGroup(
        apiAllGroup,
        setAllGroup,
        setGetTotal,
        rows,
        totalPage + 1,
        accoutDown?.id || "null"
      );
    }
  }, [totalPage, rows, accoutDown]);
  //   const handleForcusGr = (item) => {
  //     if (forcusGr.includes(item)) {
  //       setForcusGr(forcusGr?.filter((gr) => gr.id !== item.id));
  //     } else {
  //       setForcusGr((pre) => [...pre, item]);
  //     }
  //   };
  return (
    <div
      style={{
        border: "1px solid",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{ overflow: "auto", overflowX: "hidden" }}
        className="d-flex flex-column h-100"
      >
        <div className="d-flex gap-2 flex-column p-2 overflow-auto w-100 h-100">
          {allGroup?.map((item, index) => (
            <nav
              onClick={() => setForcusGr(item)}
              style={{
                background: forcusGr?.id === item.id ? "burlywood" : "",
              }}
              className="cursor-pointer rounded p-2 d-flex gap-2 align-items-center"
              key={index}
            >
              <img
                style={{ borderRadius: "50%", width: "30px", height: "30px" }}
                src={item.avt || avtGroup}
              />

              <p>{item.name}</p>
            </nav>
          ))}
        </div>
        <div style={{ flexShrink: 0, position: "sticky", bottom: 0 }}>
          <Paginator
            style={{ height: "15%" }}
            className="pagination-markerting"
            first={first}
            rows={rows}
            totalRecords={getTotal?.count}
            onPageChange={onPageChange}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </div>
      </div>
    </div>
  );
};
