import React, { useState, useEffect } from "react";
import axios from "axios";
import Navigate from "../container/Navigate";
import { Carousel } from "primereact/carousel";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Footer } from "./Footer";
import { ManageResource } from "../container/resources/ManageResource";
import { API_URL, API_ROUTES } from "../../api";
import { useHeaders } from "../../const/headers";
import { useUser } from "../../contexts/UserContext";
import { ManagePro } from "../container/resources/ManagePro";
import { updateTitle } from "../../const/updateTitle";
import { AddResu } from "../container/resources/AddResu";
import { AddManage } from "../container/resources/AddManage";
import { useTranslation } from "react-i18next";
export const Resources = () => {
  updateTitle("Chốt Care - Tài Nguyên");
  const { userInfo } = useUser();
  const getTaiNguyen = API_URL + API_ROUTES.GET_RESOURCE;
  const deteteContent = API_URL + API_ROUTES.DELETE_RESOURCE;
  const getProduct = API_URL + API_ROUTES.GET_PRODUCT_RESOURCE;
  const deletePro = API_URL + API_ROUTES.DELETE_PRODUCT_RESOURCE;
  const [resources, setResource] = useState([]);
  const [dataRes, setDataRes] = useState([]);
  const [dataPro, setDataPro] = useState([]);
  const [addMana, setAddMana] = useState(false);
  const [addRe, setAddre] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [modalPro, setModalPro] = useState(false);
  const [itemPro, setItemPro] = useState([]);
  const [rf, setRf] = useState(false);
  const headers = useHeaders();
  const { t } = useTranslation();
  const fetchData = async () => {
    try {
      const res = await axios.get(getTaiNguyen, headers);
      setResource(res.data);
    } catch (err) { }
  };
  const fetchDataPro = async () => {
    try {
      const res = await axios.get(getProduct, headers);
      setDataPro(res.data);
    } catch (err) { }
  };
  const deleteRe = async (id) => {
    const confirm = window.confirm("Bạn có chắc chắn muốn xóa không");
    if (confirm) {
      const body = {
        id: id.id,
      };
      try {
        const res = await axios.post(deteteContent, body, headers);
        setRf(!rf);
      } catch (err) { }
    }
  };
  useEffect(() => {
    fetchDataPro();
    fetchData();
  }, [rf]);
  const deletePros = async (id) => {
    const confirm = window.confirm("Bạn có chắc chắn muốn xóa không");
    if (confirm) {
      const body = {
        id: id.id,
      };
      try {
        const res = await axios.post(deletePro, body, headers);
        setRf(!rf);
      } catch (err) { }
    }
  };
  useEffect(() => {
    fetchDataPro();
    fetchData();
  }, [rf]);
  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const productTemplate = (resources) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
        <div className="mb-3"></div>
        <div>
          <h4 className="mb-1">{resources.content}</h4>
          <a
            href={resources.link}
            target="_blank"
            className="p-button text-light button-blue radius"
          >
            {t("XEM NGAY")}
          </a>
        </div>
        {userInfo.is_admin === true ? (
          <div className="mt-2 ">
            <Button
              label={t("Sửa")}
              style={{ width: "66px", height: "26px" }}
              className="button-blue radius"
              onClick={() => {
                setDataRes(resources);
                setModalCreate(true);
              }}
            />
            <Button
              label={t("Xoá")}
              style={{ width: "66px", height: "26px" }}
              className="button-red radius"
              onClick={() => {
                deleteRe(resources);
              }}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="w-100 h-100 d-flex flex-column mgtop-respon-47">
          <div className="card d-flex">
            <AddResu
              setRf={setRf}
              rf={rf}
              dataRes={dataRes}
              setAddre={setAddre}
              addRe={addRe}
            />
            <ManageResource
              setRf={setRf}
              rf={rf}
              dataRes={dataRes}
              setModalCreate={setModalCreate}
              modalCreate={modalCreate}
            />
            <ManagePro
              itemPro={itemPro}
              setModalPro={setModalPro}
              modalPro={modalPro}
              setRf={setRf}
              rf={rf}
            />
            <AddManage
              itemPro={itemPro}
              setAddMana={setAddMana}
              addMana={addMana}
              setRf={setRf}
              rf={rf}
            />
            <Carousel
              value={resources}
              numVisible={3}
              numScroll={1}
              responsiveOptions={responsiveOptions}
              itemTemplate={productTemplate}
            />
            {userInfo.is_admin === true ? (
              <Button
                label={t("Thêm")}
                style={{ padding: "15px 0" }}
                className=" button-blue radius"
                icon="pi pi-external-link"
                onClick={() => {
                  setAddre(true);
                }}
              />
            ) : (
              <div></div>
            )}
          </div>
          <div
            className="d-flex flex-wrap gap-4 justify-content-between p-4"
            style={{ overflow: "auto" }}
          >
            {dataPro?.map((item, index) => (
              <div className="card" style={{ width: "400px" }}>
                {userInfo.is_admin === true ? (
                  <div style={{ textAlign: "center", paddingTop: "5px" }}>
                    <Button
                      label={t("Sửa")}
                      className=" button-blue radius"
                      style={{
                        width: "66px",
                        height: "26px",
                        marginRight: "0.5rem",
                      }}
                      onClick={() => {
                        setItemPro(item);
                        setModalPro(true);
                      }}
                    />
                    <Button
                      label={t("Xoá")}
                      style={{ width: "66px", height: "26px" }}
                      className=" button-red radius"
                      onClick={() => {
                        deletePros(item);
                      }}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
                <Card
                  className="h-100"
                  header={
                    <h3
                      style={{
                        fontSize: "22px",
                        paddingTop: "10px",
                        textAlign: "center",
                        fontWeight: "bold",
                        lineHeight: "1.5",
                      }}
                    >
                      {item?.title}
                    </h3>
                  }
                >
                  <div className="d-flex justify-content-center w-100">
                    <img
                      style={{ height: "200px" }}
                      src={`${API_URL}/${item.image}`}
                    />
                  </div>
                  <p className="m-0 mt-2 mb-2">{item?.content}</p>
                  <div className="d-flex justify-content-center w-100">
                    <a
                      href={item?.link}
                      target="_blank"
                      className="p-button text-light button-blue radius"
                    >
                      {t("XEM NGAY")}
                    </a>
                  </div>
                </Card>
              </div>
            ))}
          </div>
          {userInfo.is_admin === true ? (
            <Button
              label={t("Thêm")}
              style={{ padding: "15px 0" }}
              className=" button-blue radius"
              icon="pi pi-external-link"
              onClick={() => {
                setAddMana(true);
              }}
            />
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
