import { useState, useEffect, useRef } from "react";
import { API_URL, API_ROUTES_ZALO, API_ROUTES } from "../../../api";
import { getAxios } from "../../../const/getAxios";
import { InputTextarea } from "primereact/inputtextarea";
import { IoSend } from "react-icons/io5";
import { IoDocumentAttach } from "react-icons/io5";
import { RiEmojiStickerFill } from "react-icons/ri";
import { InforConversation } from "./InforConversation";
import { FaCaretSquareRight } from "react-icons/fa";
import { FaCaretSquareLeft } from "react-icons/fa";
import { postAxiosMess } from "../../../const/postAxios";
import { useHeaders } from "../../../const/headers";
import { HeaderInChat } from "./HeaderInChat";
import { IoMdCloseCircle } from "react-icons/io";
import axios from "axios";
import { Image } from "primereact/image";
import { FaRegFileVideo } from "react-icons/fa6";
import { TiDelete } from "react-icons/ti";
import EmojiPicker from "emoji-picker-react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FcAddImage } from "react-icons/fc";
import { BiEditAlt } from "react-icons/bi";
import { MdLabel } from "react-icons/md";
import { Checkbox } from "primereact/checkbox";
export const ViewMessenger = ({
  setMessCard,
  setTypeQuote,
  typeQuote,
  idFriend,
  allAccount,
  conversation,
  idLocation,
  setHiddenOrder,
  hiddenOrder,
  newConver,
  messenger,
  setMessenger,
  resetScroll,
  setMessengerText,
  messengerText,
  handleSendMessenger,
  inputImageRef,
  listLinkFile,
  setListLinkFile,
  handleGetLinkFile,
  setOpenShare,
  setItemShare,
  getLinkAttack,
  setAddLabel,
  addLabel,
  categoryClassify,
  listFrLabel,
  setRfFiend,
  rfFriend,
  toast,
  setRfClassify,
  rfClassify,
  setListFrLabel,
  setModalSendStranger,
  setForcusAvt,
  setItemForcusAvt,
  setIsSetting,
  rfGetMember,
  memberGr,
  setMemberGr,
}) => {
  const apiGetMess = API_URL + API_ROUTES_ZALO.GET_MESS_IN_FRIEND;
  const apiGetMemberGr = API_URL + API_ROUTES_ZALO.GET_MEMBER_SHOW;
  const createFastMess = API_URL + API_ROUTES.CREATE_FAST_REPLY;
  const apiGetAllFastMess = API_URL + API_ROUTES_ZALO.GET_FAST_MESS_ZALO;
  const deleteFastMess = API_URL + API_ROUTES.DELETE_FAST_MESS;
  const editFastRely = API_URL + API_ROUTES.EDIT_FAST_REPLY;
  const apiAddLabel = API_URL + API_ROUTES.ADD_LABEL_CONVERSION;
  const apiDeleteLabel = API_URL + API_ROUTES.REMOVE_LABEL_CONVERSION;
  const headers = useHeaders();
  const [hiddenListGr, setHiddenListGr] = useState(false);
  const [itemFriend, setItemFriend] = useState([]);
  const [scrollTop, setScrollTop] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const itemMember = JSON.parse(localStorage.getItem("itemMember"));
  const item = conversation?.results?.filter((item) => item?.id == idFriend);
  const [startText, setStartText] = useState(false);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const fastMessRef = useRef();
  const [allFastMess, setAllFastMess] = useState([]);
  const [imageFastMess, setImageFastMess] = useState(null);
  const [editFastMess, setEditFastMess] = useState([]);
  const [itemChecked, setItemChecked] = useState([]);
  const [states, setStates] = useState([]);
  useEffect(() => {
    getAxios(`${apiGetAllFastMess}?id_account=${idLocation}`, setAllFastMess);
  }, []);
  useEffect(() => {
    messengerText.startsWith("/") ? setStartText(true) : setStartText(false);
  }, [messengerText]);
  useEffect(() => {
    if (Array.isArray(item) && item[0]?.id) {
      newConver?.id === item[0]?.id
        ? setItemFriend(newConver || [])
        : setItemFriend(item[0] || []);
    }
  }, [conversation, newConver, idFriend]);
  const itemAccout = allAccount?.filter((item) => item.id == idLocation) || [];
  useEffect(() => {
    getAxios(`${apiGetMess}?id_conversation=${idFriend}`, setMessenger);
    if (Array.isArray(item) && item[0]?.group?.id) {
      const body = {
        id_group: item[0]?.group?.id || "",
      };
      postAxiosMess(apiGetMemberGr, body, setMemberGr, headers);
    } else {
      setMemberGr([]);
    }
  }, [idFriend, rfGetMember]);
  useEffect(() => {
    const getNextMess = async () => {
      try {
        const res = await axios.get(
          `${apiGetMess}?id_conversation=${idFriend}&page=${
            messenger?.next.split("page=")[1]
          }`,
          headers
        );
        setMessenger((pre) => ({
          next: res?.data?.next,
          results: [...res?.data.results, ...pre?.results],
        }));
      } catch (err) {}
    };
    if (scrollTop && messenger?.next) {
      getNextMess();
    }
  }, [scrollTop]);
  const handleIconClick = () => {
    inputImageRef.current.click();
  };
  const handleFileChange = () => {
    const fileInput = inputImageRef.current;
    const files = fileInput.files;
    if (files.length > 0) {
      const newFiles = Array.from(files).map((file) => ({
        name: file.name,
        file: file,
      }));
      handleGetLinkFile(newFiles);
    } else {
    }
  };
  const handleFastFile = () => {
    const fileInput = fastMessRef.current;
    const files = fileInput.files;
    const getLink = async () => {
      const formData = new FormData();
      formData.append("file", files[0]);
      try {
        const res = await axios.post(getLinkAttack, formData, headers);
        setImageFastMess(res.data.file);
      } catch (err) {}
    };
    getLink();
  };
  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    for (const item of items) {
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        const files = [
          {
            name: "filecopy",
            file: file,
          },
        ];
        handleGetLinkFile(files);
        break;
      }
    }
  };
  const onEmojiClick = (event, emojiObject) => {
    setMessengerText((prevMessage) => prevMessage + event.emoji);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessenger();
    }
  };
  const handleCreaFastMess = async () => {
    const body = {
      id_account: idLocation,
      command: "",
      title: title,
      content: content,
      image: imageFastMess,
    };
    try {
      await axios.post(createFastMess, body, headers);
      getAxios(`${apiGetAllFastMess}?id_account=${idLocation}`, setAllFastMess);
      setContent("");
      setTitle("");
      setImageFastMess([]);
    } catch (err) {}
  };
  const handleEditFastMess = async () => {
    const body = {
      id_fast_reply: editFastMess.id,
      command: "",
      title: title,
      content: content,
      image: imageFastMess,
    };
    try {
      await axios.post(editFastRely, body, headers);
      getAxios(`${apiGetAllFastMess}?id_account=${idLocation}`, setAllFastMess);
      setContent("");
      setTitle("");
      setImageFastMess([]);
    } catch (err) {}
  };
  const handleUseFastMess = (item) => {
    setMessengerText(item.content);
    item?.image
      ? setListLinkFile([{ link: `/${item.image}`, name: "fastmess" }])
      : setListLinkFile([]);
    setStartText(false);
  };
  const handleDeleteFastMess = async (e, item) => {
    e.stopPropagation();
    const body = {
      id_fast_reply: item.id,
    };
    try {
      await axios.post(deleteFastMess, body, headers);
      getAxios(`${apiGetAllFastMess}?id_account=${idLocation}`, setAllFastMess);
    } catch (err) {}
  };
  useEffect(() => {
    if (editFastMess?.id) {
      setTitle(editFastMess?.title);
      setContent(editFastMess?.content);
      editFastMess?.image
        ? setImageFastMess(`/${editFastMess?.image}`)
        : setImageFastMess([]);
    }
  }, [editFastMess]);
  const handleSelectLabel = async (itemCheck) => {
    let bodyAdd = [];
    let bodyRemove = [];
    const idConver = listFrLabel?.map((item) => item.id);
    for (let i = 0; i < idConver?.length; i++) {
      if (idConver?.some((item) => item === itemCheck.conversation[i])) {
        bodyRemove?.push(idConver[i]);
      } else {
        bodyAdd?.push(idConver[i]);
      }
    }
    const bodyAdds = {
      id_category: itemCheck.id,
      id_conversations: bodyAdd,
    };
    const bodyRemoves = {
      id_category: itemCheck.id,
      id_conversations: bodyRemove,
    };
    try {
      if (bodyAdd.length > 0) {
        await axios.post(apiAddLabel, bodyAdds, headers);
      }
      if (bodyRemove.length > 0) {
        await axios.post(apiDeleteLabel, bodyRemoves, headers);
      }
      toast.success("Thành công");
      setRfFiend(!rfFriend);
      setRfClassify(!rfClassify);
      setListFrLabel([]);
    } catch (err) {}
  };
  return (
    <div className="w-100 position-relative d-flex justify-content-between p-0 m-0 flex-column">
      <div
        className="d-flex flex-column w-100 "
        id="top-scroll-overflow"
        style={{ height: "100%", overflow: "auto" }}
      >
        <div style={{ height: "max-content" }}>
          <HeaderInChat
            setIsSetting={setIsSetting}
            rfFriend={rfFriend}
            setRfFiend={setRfFiend}
            toast={toast}
            itemFriend={itemFriend}
            memberGr={memberGr}
            hiddenListGr={hiddenListGr}
            setHiddenListGr={setHiddenListGr}
          />
        </div>
        {hiddenListGr && (
          <nav className="navmenuschat">
            <IoMdCloseCircle onClick={() => setHiddenListGr(false)} size={40} />
            <ul>
              {memberGr?.map((item, index) => (
                <div className="d-flex justify-content-start w-100 mb-2 align-items-center">
                  <nav className="d-flex justify-content-start w-100">
                    <li
                      className="d-flex justify-content-start gap-2 w-100 align-items-center ml-2 "
                      key={index}
                    >
                      <img className="account-avatar" src={item.avt} />
                      <p className="account-name">{item.name}</p>
                    </li>
                  </nav>
                  <p className="account-name w-100">
                    {item.is_creator
                      ? "Trưởng nhóm"
                      : item.is_admin
                      ? "Phó nhóm"
                      : ""}
                  </p>
                </div>
              ))}
            </ul>
          </nav>
        )}
        {addLabel && (
          <nav className="listAddCate">
            <IoMdCloseCircle onClick={() => setAddLabel(false)} size={40} />
            <ul>
              {categoryClassify?.map((item, index) => (
                <li
                  className="d-flex justify-content-start gap-2 w-100 mb-2 align-items-center ml-2 "
                  key={index}
                >
                  <Checkbox
                    onClick={(e) => {
                      handleSelectLabel(item);
                    }}
                    checked={itemChecked.id === item.id}
                  ></Checkbox>
                  <MdLabel size={30} style={{ color: item.color }} />
                  <p className="account-name">{item.name}</p>
                </li>
              ))}
            </ul>
          </nav>
        )}
        <InforConversation
          typeMess={
            conversation?.results?.filter((item) => item?.id == idFriend)[0]
          }
          setForcusAvt={setForcusAvt}
          setItemForcusAvt={setItemForcusAvt}
          setModalSendStranger={setModalSendStranger}
          setMessCard={setMessCard}
          setItemShare={setItemShare}
          setOpenShare={setOpenShare}
          setTypeQuote={setTypeQuote}
          resetScroll={resetScroll}
          setScrollTop={setScrollTop}
          messenger={messenger}
          itemFriend={itemFriend}
          itemAccout={itemAccout}
          memberGr={memberGr}
        />
      </div>
      <div
        className="bg-light flex align-items-center gap-2 position-relative"
        style={{ height: "max-content" }}
      >
        {(listLinkFile?.length > 0 || typeQuote?.id) && (
          <div
            style={{
              position: "absolute",
              bottom: "58px",
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignContent: "flex-end",
            }}
          >
            <nav className="d-flex gap-2 align-items-center">
              <p>
                {typeQuote?.id
                  ? `Trả lời tin nhắn của ${
                      itemFriend?.group
                        ? typeQuote.uidFrom === "0"
                          ? "chính mình"
                          : itemMember?.filter(
                              (i) => i.uid === typeQuote.uidFrom
                            )[0]?.name
                        : typeQuote.uidFrom === "0"
                        ? "chính mình"
                        : itemFriend?.friend?.name
                    }`
                  : ""}
              </p>
              {typeQuote?.id && (
                <TiDelete
                  size={20}
                  cursor="pointer"
                  onClick={() => setTypeQuote([])}
                />
              )}
            </nav>

            {listLinkFile?.map((item, index) => (
              <div
                key={index}
                style={{
                  position: "relative",
                  border: "3px solid",
                }}
              >
                {item.link?.includes("png") || item.link?.includes("jpg") ? (
                  <Image
                    src={`${API_URL}${item.link}`}
                    zoomSrc={`${API_URL}${item.link}`}
                    alt={item.name}
                    width="80"
                    height="60"
                    preview
                  />
                ) : (
                  <FaRegFileVideo size={60} />
                )}
                <TiDelete
                  cursor="pointer"
                  onClick={() =>
                    setListLinkFile(
                      listLinkFile?.filter((i) => i.link !== item.link)
                    )
                  }
                  size={30}
                  style={{
                    position: "absolute",
                    top: "-11px",
                    right: "-9px",
                  }}
                  color="red"
                />
              </div>
            ))}
          </div>
        )}
        {startText && (
          <div
            style={{
              position: "absolute",
              bottom: "58px",
              width: "100%",
              padding: "0.5rem",
              background: "#ffffff",
              height: "250px",
              display: "flex",
            }}
          >
            <div className="w-100 h-100 d-flex flex-column">
              <nav className="w-100 pt-2 pb-2">
                <p className="text-dark" style={{ fontWeight: "bold" }}>
                  Tin nhắn nhanh
                </p>
              </nav>
              <div className="fast-message-container overflow-auto">
                {allFastMess?.map((item, index) => (
                  <nav
                    onClick={() => handleUseFastMess(item)}
                    key={index}
                    className="pt-2 pb-2 d-flex gap-2 align-items-center position-relative"
                  >
                    <div>
                      <p>{`/${item.title}`}</p>
                      <p>{item.content}</p>
                    </div>
                    {item.image && (
                      <Image
                        width="40px"
                        src={`${API_URL}/${item.image}`}
                        preview={`${API_URL}/${item.image}`}
                      />
                    )}
                    <BiEditAlt
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditFastMess(item);
                      }}
                      size={30}
                      color="blue"
                      style={{ position: "absolute", right: "30px" }}
                    />
                    <TiDelete
                      onClick={(e) => handleDeleteFastMess(e, item)}
                      size={30}
                      color="red"
                      style={{ position: "absolute", right: "0" }}
                    />
                  </nav>
                ))}
              </div>
            </div>

            <div style={{ width: "40%", borderLeft: "1px solid" }}>
              <nav
                className="w-100 pt-2 pb-2 pl-2 d-flex justify-content-between align-items-center"
                style={{ borderBottom: "1px solid" }}
              >
                <p className="text-dark">
                  {" "}
                  {editFastMess?.id
                    ? "Chỉnh sửa tin nhắn nhanh"
                    : "Tạo tin nhắn nhanh"}
                </p>
                <TiDelete
                  size={30}
                  cursor="pointer"
                  color="red"
                  onClick={() => setStartText(false)}
                />
              </nav>
              <nav className="w-100 d-flex flex-column gap-2">
                <InputText
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Phím tắt (Ví dụ : Xin chào)"
                />
                <InputTextarea
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  placeholder="Nội dung (Ví dụ : Cảm ơn bạn đã nhắn tin mình có thể giúp gì cho bạn)"
                />
                <nav className="d-flex gap-2 align-items-center">
                  <FcAddImage
                    cursor="pointer"
                    size={30}
                    onClick={() => fastMessRef.current.click()}
                  />
                  {imageFastMess?.length > 0 && (
                    <Image
                      width="30"
                      src={`${API_URL}${imageFastMess}`}
                      preview={`${API_URL}${imageFastMess}`}
                    />
                  )}
                  {imageFastMess?.length > 0 && (
                    <button onClick={() => setImageFastMess([])}>Xoá</button>
                  )}
                </nav>
                <input
                  type="file"
                  onChange={handleFastFile}
                  style={{ display: "none" }}
                  ref={fastMessRef}
                  accept="image/*"
                />
                <nav className="w-100 d-flex justify-content-end">
                  <Button
                    onClick={
                      editFastMess?.id ? handleEditFastMess : handleCreaFastMess
                    }
                    className="button-blue"
                  >
                    {editFastMess?.id ? "Chỉnh sửa" : "Thêm"}
                  </Button>
                  {editFastMess?.id && (
                    <Button
                      onClick={() => {
                        setEditFastMess([]);
                        setContent("");
                        setTitle("");
                        setImageFastMess([]);
                      }}
                      className="button-red"
                    >
                      Huỷ
                    </Button>
                  )}
                </nav>
              </nav>
            </div>
          </div>
        )}

        <InputTextarea
          value={messengerText}
          onChange={(e) => {
            setMessengerText(e.target.value);
            if (e.target.value.includes("@")) {
              setStates(true);
            } else {
              setStates(false);
            }
          }}
          autoResize
          style={{ resize: "none", maxHeight: "58px" }}
          className="w-100"
          onPaste={handlePaste}
          onKeyPress={handleKeyPress}
        />
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }}
          ref={inputImageRef}
          accept="image/*,video/*"
        />
        <IoDocumentAttach
          size={35}
          cursor="pointer"
          onClick={handleIconClick}
        />
        <div style={{ position: "relative" }}>
          <RiEmojiStickerFill
            cursor="pointer"
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            size={35}
          />
          {showEmojiPicker && (
            <div
              style={{
                position: "absolute",
                top: "-450px",
                right: 0,
                zIndex: 9999,
              }}
            >
              <EmojiPicker onEmojiClick={onEmojiClick} />
            </div>
          )}
        </div>
        <IoSend
          cursor="pointer"
          onClick={() => {
            handleSendMessenger();
            // handleButtonClick();
          }}
          size={35}
        />
      </div>
      {hiddenOrder ? (
        <FaCaretSquareRight
          onClick={() => setHiddenOrder(!hiddenOrder)}
          size={30}
          style={{ position: "absolute", top: "-2px", right: "-2px" , zIndex:"1000" }}
        />
      ) : (
        <FaCaretSquareLeft
          onClick={() => setHiddenOrder(!hiddenOrder)}
          size={30}
          style={{ position: "absolute", top: "-2px", right: "-2px" , zIndex:"1000" }}
        />
      )}
    </div>
  );
};
