import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// Thêm các ngôn ngữ bạn muốn hỗ trợ ở đây
// Ví dụ: tiếng Việt
const resources = {
  vi: {
    translation: {
      placeholder: "Nhập mật khẩu",
      "Quản lý tin nhắn": "Quản lý tin nhắn",
      Xoá: "Xoá",
    },

  },
  en: {
    translation: {
      placeholder: "Enter password",
      "Quản Lý Tin Nhắn": "Manage messages",
      "Quản Lý Tài Khoản": "Manage accounts",
      "Quản Lý Đơn Hàng": "Manage orders",
      "Quản Lý Khách Hàng": "Manage customers",
      "Quản Lý Nhóm": "Manage groups",
      "Marketing Bán Hàng": "Marketing and sales",
      "Quản Lý Bài Viết": "Manage articles",
      "Công Cụ Facebook": "Facebook tools",
      "Nhóm": "Group",
      "Bạn Bè": "Friends",
      "Tin Nhắn": "Message",
      "Đơn hàng": "Order",
      "Chiến Dịch": "Campaign",
      "Quản Lý Tên Miền": "Magage Domain",
      "Quản Lý Proxy": "Magage Proxy",
      "Tài Khoản Zalo": "Zalo Accounts",
      "LIÊN HỆ CHỐT NHANH": "CONTACT CHOT NHANH",
      "Tài khoản": "Account",
      "Trạng thái tài khoản": "Account status",
      "Hạn sử dụng": "Expiry date",
      "Sửa": "Edit",
      "Xoá": "Delete",
      "Nhận đơn": "Confirm",
      "Thêm tài khoản Zalo": "Add account Zalo",
      "Kiểm tra tài khoản": "Check account",
      "Kiểm tra proxy": "Check proxy",
      "Thêm Proxy": "Add Proxy",
      "Trạng thái": "Status",
      "Cập nhật": "Update",
      "Cấu hình tên miền riêng": "Configuring a custom domain",
      "Tên miền": "Domain",
      "Thêm bạn": "Add Friend",
      "Lời mời kết bạn đã gửi": "Friend request sent",
      "Lời mời kết bạn": "Friend request",
      "Quét danh sách bạn bè": "Scan friends list",
      "Chạy": "Run",
      "Chạy tiếp": "Continue running",
      "Kết quả": "Result",
      "Dừng": "Stop",
      "Quản lý kịch bản": "Script management",
      "Sao chép kịch bản": "Copy script",
      "Chi tiết": "Details",
      "Tham gia nhóm": "Add group",
      "Quét danh sách nhóm": "Scan list groups",
      "Tên kịch bản": "Scrit name",
      "Tài khoản được gán": "Assigned account",
      "Tuỳ chọn": "Options",
      "Khách hàng được gán": "Assigned customer",
      "Đăng bài": "Post article",
      "Nhắn tin cho bạn bè": "Message to friends",
      "Nhắn tin đến số điện thoại": "Message to phone",
      "Gửi tin nhắn vào nhóm": "Message to group",
      "Gửi tin nhắn cho thành viên nhóm": "Message to member group",
      "Thêm kịch bản": "Add article",
      "Xoá kết quả": "Delete result",
      "Tên Kịch bản": "Scrit name",
      "Người nhận": "Recipient",
      "Ngày đặt": "Order date",
      "Quản lý đơn hàng MiniShop": "MiniShop order management",
      "Chỉnh sửa mật khẩu": "Change password",
      "Quản lý nhân viên": "Employee management",
      "Số lượng nhân viên được phép tạo là:": "The allowed number of employees to create is:",
      "Thêm tài khoản nhân viên": "Add employee account",
      "Mật khẩu hiện tại": "Current password",
      "Mật khẩu mới": "New password",
      "Nhập lại mật khẩu mới": "Confirm new password",
      "Tùy chọn": "Option",
      "Chức năng": "Feature",
      "Tỉ lệ": "Rate",
      "Chiến dịch": "Campaign",
      "Thành công": "Success",
      "Thất bại": "Failure",
      "Nhắn tin cho thành viên nhóm": "Message to member group",
      "Quản lý nhóm": "Group management",
      "Lọc": "Filter",
      "Xuất file Excel": "Export Excel",
      "Thêm tài khoản": "Add account",
      "Họ tên": "Full name",
      "Mật khẩu": "Password",
      "Quyền": "Permission",
      "Ngày tạo": "Date created",
      "Số tài khoản": "Number of accounts",
      "Mail nhận thông báo": "Notification email",
      "Nhập mail nhận thông báo": "Input email for notifications",
      "Tài khoản đang chờ": "Pending account",
      "Thay đổi": "Change",
      "XEM NGAY": "WATCH",
      "Thêm": "Add",
      "Logo hiện tại": "Curent logo",
      "Chọn Logo mới": "Change Logo",
      "Chọn ảnh": "Change image",
      "Lưu": "Save",
      "Trang chủ": "Homepage",
      "Đến Trang chủ": "Go Homepage",
      "Thông báo đăng ký": "Register notification",
      "Thông báo đăng nhập lần đầu tiên": "Firt-time login notification",
      "Nội dung": "Content",
      "Xem Popup": "View Popup",
      "Tắt Popup": "Turn-off Popup",
      "Bật Popup": "Turn-on Popup",
      "Xoá ảnh": "Delete image",
      "Thông báo": "Notification",
      "Hết hạn": "Expired",
      "Lỗi": "Bug",
      "Xoá tất cả": "Delete all",
      "Tiêu đề": "Title",
      "Kỹ thuật viên": "Development",
      "Thời gian": "Time",
      "Xem thêm": "See more",
      "Báo cáo": "Statistics",
      "Tester Ghi chú": "Tester note",
      "Dev Ghi chú": "Dev note",
      "Hình ảnh": "Image",
      "Tester xác nhận": "Tester confirm",
      "Dev xác nhận": "Dev confirm",
      "Hoàn thành": "Done",
      "Tạm dừng": "Pause",
      "Đang chạy": "Running",
      "Chưa có tài khoản nào được gán": "No account assigned yet",
      "tài khoản được gán": "account assigned yet",
      "Có": "Have",
      "Chưa có bạn bè nào được gán": "No friend assigned yet",
      "bạn bè được gán": "friend assigned yet",
      "Chưa có nhóm nào được gán": "No group assigned yet",
      "Đã huỷ": "Canceled",
      "Đã xác nhận": "Confirmed",
      "Chờ xác nhận": "Confirming",
      "STT": "No.",
      "Huỷ": "Cancel",
      "Đã giải quyết": "Fixed",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",
      "": "",

      "Quản Lý Nhân Viên": "Manage employees",
      "Thống Kê": "Statistics",
      "Quản Lý Người Dùng": "Manage users",
      "Tài Khoản Đang Chờ": "Accounts are waiting",
      "Tài Nguyên": "Resources",
      "Hướng Dẫn": "Guide",
      "Cài Đặt": "Settings",
      "Đăng Xuất": "Log out",
      "Debug Thủ Công": "Manual debugging",
      Xoá: "Delete",
      Sửa: "Edit",
      "Hoạt động": "Activity",
      "Không hoạt động": "Inactive",
      "Thêm tài khoản Facebook": "Add Facebook account",
      "Lấy mã đồng bộ": "Get sync code",
      "Kiểm tra tài khoản": "Check Account",
      "Kiểm tra Proxy": "Check Proxy",
      "Tìm kiếm nhanh": "Quick search",
      "Tên tài khoản": "Account name",
      "Ghi chú": "Note",
      "Trạng thái tài khoản": "Account status",
      "Trạng thái Proxy": "Proxy status",
      "Tuỳ chọn": "Optional",
      "Nhập dữ liệu": "Insert information",
      Lưu: "Save",
      Thoát: "Exit",
      "*Lưu ý: Không chia sẻ mã đồng bộ này với người khác":
        "Note: Do not share this sync code with others",
      "Làm mới": "Refresh",
      "Mã đồng bộ": "Sync Code",
      "Bạn phải chọn ít nhất 1 tài khoản để kiểm tra":
        "You must select at least 1 account to check",
      "Xóa không thành công": "Delete failed",
      "Bạn phải chọn ít nhất 1 tài khoản để xóa":
        "You must select at least 1 account to delete",
      "Lưu ý: Khi xoá tài khoản ra khỏi hệ thống. Tất cả các chiến dịch, kịch bản, nhãn khách hàng..., Dữ liệu liên quan đến tài khoản sẽ bị mất":
        "Note: When deleting an account from the system, all campaigns, scripts, customer labels..., data related to the account will be lost",
      "sửa không thành công": "Edit failed",
      "Chỉnh sửa thông tin": "Edit information",
      "Tên đăng nhập": "User name",
      "Tài khoản bị giới hạn tin nhắn, vui lòng kiểm tra tài khoản":
        "Your account is limited to messages, please check your account",
      "Vui lòng chọn tài khoản trước khi thực hiện chức năng này":
        "Please select an account before performing this function",
      "Đã gửi lời mời kết bạn": "Friend request sent",
      "Quản lý nhãn": "Label management",
      "Tất cả": "All",
      " : Nam": " : Male",
      " : Nữ": " : Female",
      "Kết bạn": "Add friend",
      "Tính năng kết bạn thủ công:": "Manual friend making feature:",
      "- Không nên dùng tính năng kết bạn thủ công liên tục trên một tài khoản, sẽ dễ bị facebook hạn chế tính năng do phát hiện hành vi bất thường":
        "- Do not use the manual friend making feature If you continue on one account, you will be susceptible to Facebook restrictions feature suppression due to abnormal behavior detection",
      "Gợi ý: Mỗi ngày chỉ nên kết bạn từ 1 đến 5 người bạn và chỉ nên kết bạn với những người đã phản hồi tin nhắn của bạn":
        "Suggestion: You should only make friends from 1 to 5 people per day friends and should only make friends with people who have betrayed you reply to your message",
      "Đã gửi lời mời kết bạn": "Friend request sent",
      "Xem thêm...": "See more...",
      "Người dùng này hiện không có mặt , bạn không thể nhắn tin với người này":
        "This user is currently unavailable, you cannot message Trust this person",
      Tạo: "Create",
      "Địa chỉ": "Address",
      "TẠO ĐƠN HÀNG": "CREATE ORDER",
      "Tên khách hàng": "Customer name",
      "Số điện thoại": "Phone number",
      "Sản phẩm": "Product",
      "Số lượng": "Quantity",
      "Thành tiền": "Price",
      "Địa chỉ cụ thể": "Specific address",
      Tỉnh: "Province",
      "Thành phố": "City",
      "Tỉnh / Thành Phố": "Province / City",
      "Quận / Huyện": "District",
      "Xã / Phường": "Ward",
      "Bình luận": "Comment",
      "Đang trả lời bình luận": "Replying to comments",
      "Trả lời": "Reply",
      "Nhập nội dung...": "Answer...",
      "Bình luận liên quan": "Comments",
      "Quản lý bình luận:": "Manager comment",
      "Gửi tin nhắn tới người lạ": "Send message to strangers",
      "Tên miền riêng để sử dụng các tính năng như Mini Shop, đăng hình ảnh trên tường, v.v..": "Custom domain to use features like Mini Shop, post pictures on wall, etc.",
      "Sau khi thêm Tên miền riêng vào hệ thống, các sản phẩm trong Mini Shop sẽ có link dạng: https://[tên miền của bạn .com]/showproduct/xxxx": "After adding your own Domain Name to the system, the products in Mini Shop will have a link like: https://[your domain name .com]/showproduct/xxxx",
      "Vui lòng trỏ tên miền của bạn về IP: 116.105.225.194 trước khi thêm vào hệ thống!": "Please point your domain to IP: 116.105.225.194 before adding to the system!",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "vi",
  fallbackLng: "vi",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
