import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { API_URL, API_ROUTES_NTD, API_ROUTES_ZALO } from "../../../api";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { Dialog } from "primereact/dialog";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
import { filterLatestUpdates } from "../../../const/filter";
import { statusLineCheck } from "../../../const/updateTitle";
import { Paginator } from "primereact/paginator";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { sortDataByDate } from "../../../const/sortData";
import { Dropdown } from "primereact/dropdown";
import { getAxiosResultsGroup } from "../../../const/getAxios";
export const ResultsInvineJionGr = ({
  toast,
  setTotalPage,
  totalPage,
  setGetTotal,
  getTotal,
  setRows,
  rows,
  setOpenRsInvine,
  openRsInvine,
  accoutDown,
  dataZalo,
  renderJoin,
}) => {
  const headers = useHeaders();
  const deleteResultInvine =
    API_URL + API_ROUTES_ZALO.DELETE_RESULTS_INVINE_GROUP;
  const allResults = API_URL + API_ROUTES_ZALO.RESULTS_INVITE_JOIN_GROUP;
  const getDataAllFr = () => {};
  const [first, setFirst] = useState(0);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const [seclecCate, setSelecCate] = useState(null);
  const [wsResults, setWsResults] = useState([]);
  const [listResults, setListResults] = useState([]);

  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  useEffect(() => {
    if (accoutDown?.id && openRsInvine) {
      getAxiosResultsGroup(
        allResults,
        setWsResults,
        setGetTotal,
        rows,
        totalPage + 1,
        accoutDown?.id
      );
    }
  }, [totalPage, rows, renderJoin, seclecCate, accoutDown, openRsInvine]);
  const deleteResult = async (rowData) => {
    const body = { id_results: [rowData?.id] };
    try {
      await axios.post(deleteResultInvine, body, headers);
      getAxiosResultsGroup(
        allResults,
        setWsResults,
        setGetTotal,
        rows,
        totalPage + 1,
        accoutDown?.id
      );
      toast.success("Xoá thành công");
    } catch (err) {
      toast.error("Xoá thất bại");
    }
  };
  const getName = (rowData) => {
    const nameAccount = dataZalo?.find((item) => item.id === rowData?.account);
    return <p>{nameAccount?.name}</p>;
  };

  const filteredData = filterLatestUpdates(wsResults || []);

  const date = (rowData) => {
    return (
      <>
        <p>{`${rowData?.created_at?.split("T")[1]?.split(".")[0]} - ${
          rowData?.created_at?.split("T")[0]
        }`}</p>
      </>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{totalPage * rows + rowIndex.rowIndex + 1}</>;
  };
  const uidACc = (rowData) => {
    return (
      <>
        <p>{rowData?.account}</p>
      </>
    );
  };
  const phoneNumberFr = (rowData) => {
    return (
      <>
        <p>{rowData?.phone_number}</p>
      </>
    );
  };
  const optionResults = (rowData) => {
    return (
      <>
        {/* <Button
          className=" button-blue radius"
          onClick={() => {
            setOpenDetails(true);
            setItemResults(rowData);
          }}
          style={{
            padding: "6px 8px",
            marginRight: "0.5rem",
            fontSize: "12px",
          }}
        >
          Chi tiết
        </Button> */}
        <Button
          className=" button-red radius"
          onClick={() => deleteResult(rowData)}
          severity="danger"
          style={{ padding: "6px 8px", fontSize: "12px" }}
        >
          Xóa
        </Button>
      </>
    );
  };
  return (
    <Dialog
      header="Mời bạn bè tham gia nhóm"
      visible={openRsInvine}
      style={{ position: "relative", width: "70vw" }}
      onHide={() => setOpenRsInvine(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          {/* 
          <Button onClick={handleJoinGr} className="button-blue radius">
            Kết quả
          </Button> */}
          <Button
            className="button-red radius"
            onClick={() => setOpenRsInvine(false)}
          >
            Thoát
          </Button>
          {/* <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          /> */}
        </div>
      }
    >
      <div className="d-flex flex-column h-100 w-100 overflow-auto">
        {/* <Dropdown
          value={seclecCate}
          onChange={(e) => setSelecCate(e.value)}
          options={[...allCateClient, { name: "Tất cả", id: null }]}
          optionLabel="name"
          placeholder="Chọn kịch bản"
          className="w-full md:w-14rem"
        />{" "} */}
        <div
          className="d-flex gap-2 flex-column h-100"
          style={{ border: "1px solid", overflow: "auto" }}
        >
          <DataTable
            size="small"
            value={sortDataByDate(filteredData)}
            className="small-font-table dataTableWithFixedHeaderMkt dataTableWithHoverEffect datatableoverflow overflow-auto"
            tableStyle={{ width: "100%", height: "86%" }}
            rowClassName={(rowData) => rowClassName(rowData, listResults)}
            onRowClick={(e) => onRowClick(e.data, setListResults, listResults)}
          >
            <Column
              header={headerCheckbox(
                listResults,
                filteredData,
                toggleSelectAll,
                setListResults
              )}
              body={(rowData) =>
                bodyCheckbox(rowData, listResults, setListResults, getDataAllFr)
              }
            />
            <Column body={indexTemplate} field="UID" header="STT"></Column>
            <Column
              field="created_at"
              body={(rowData) => date(rowData)}
              header="Thời gian"
              sortable
            ></Column>
            <Column
              body={(rowData) => getName(rowData)}
              header="Tên tài khoản"
            ></Column>
            <Column header="Bạn bè" field="friend_name"></Column>
            <Column header="Tham gia nhóm" field="group_name"></Column>
            <Column
              field="status"
              body={(rowData) => statusLineCheck(rowData, "status")}
              header="Kết quả"
              sortable
            ></Column>
            <Column
              field=""
              body={(rowData) => optionResults(rowData)}
              header="Tuỳ chọn"
            ></Column>
          </DataTable>
          <Paginator
            className="pagination-markerting"
            first={first}
            rows={rows}
            totalRecords={getTotal?.count}
            onPageChange={onPageChange}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </div>
      </div>
    </Dialog>
  );
};
