import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
  rowClassName,
  onRowClick,
} from "../../../const/checkboxPrimeReactjs";
import { API_URL, API_ROUTES_NTD, API_ROUTES_ZALO } from "../../../api";
import { useHeaders } from "../../../const/headers";
import axios from "axios";
import { statusRunTools } from "../../../const/updateTitle";
import { EditScriptGroup } from "./EditScriptGroup";
import { useTranslation } from "react-i18next";
export const ScriptAccountGr = ({
  allCate,
  setRefresh,
  refresh,
  setOpenManager,
  setItemCate,
  allCateClient,
  setManagerScript,
  managerScript,
  listScript,
  setListScript,
}) => {
  const deleteCate = API_URL + API_ROUTES_ZALO.JOIN_GROUP.DELETE_CATEGORY;
  const apiCopyCate = API_URL + API_ROUTES_ZALO.JOIN_GROUP.COPY_CATEGORY;
  const headers = useHeaders();
  const [editCate, setEditCate] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const getDataAllFr = () => {};
  const { t } = useTranslation();
  const handleDetleCate = async (item) => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá !");
    if (confirm) {
      const body = { id_category: item.id };
      try {
        const res = await axios.post(deleteCate, body, headers);
        setRefresh(!refresh);
        setListScript([]);
      } catch (err) {}
    }
  };
  const handleCopyCate = async (item) => {
    const userName = prompt("Nhập tên kịch bản mới");
    if (userName === null) {
      return;
    }
    if (userName.trim() === "") {
      alert("Tên kịch bản không được để trống");
      return;
    }
    const body = { id_category: item.id, name: userName };
    try {
      await axios.post(apiCopyCate, body, headers);
      setRefresh(!refresh);
      setListScript([]);
    } catch (err) {}
  };
  const optionAcc = (rowData) => {
    return (
      <div className="d-flex gap-1 btn-option-wrapper">
        <Button
          className="button-blue radius"
          onClick={() => {
            setManagerScript(true);
            setItemCate(rowData);
          }}
          style={{ padding: "6px 8px" }}
        >
          {t("Quản lý kịch bản")}
        </Button>
        <Button
          className="button-blue radius"
          onClick={() => {
            handleCopyCate(rowData);
          }}
          style={{ padding: "6px 8px" }}
        >
          {t("Sao chép kịch bản")}
        </Button>
        <Button
          className="button-blue radius"
          onClick={() => {
            setEditCate(rowData);
            setModalEdit(true);
          }}
          style={{ padding: "6px 8px" }}
        >
          {t("Chi tiết")}
        </Button>
        <Button
          className="button-red radius"
          severity="danger"
          onClick={() => handleDetleCate(rowData)}
          style={{ padding: "6px 8px" }}
        >
          {t("Xoá")}
        </Button>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  const listAccount = (rowData) => {
    return (
      <div className="w-100 d-flex flex-wrap gap-2 align-items-center">
        <p>
          {t(
            rowData?.account?.length === 0
              ? "Chưa có tài khoản nào được gán"
              : `Có ${rowData?.account?.length} tài khoản được gán`
          )}
        </p>
      </div>
    );
  };
  return (
    <div className=" h-100 " style={{ border: "1px solid", width: "100%" }}>
      <DataTable
        size="small"
        className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ width: "100%" }}
        value={allCateClient}
        rowClassName={(rowData) => rowClassName(rowData, listScript)}
        onRowClick={(e) => onRowClick(e.data, setListScript, listScript)}
      >
        <Column
          header={headerCheckbox(
            listScript,
            allCateClient,
            toggleSelectAll,
            setListScript
          )}
          body={(rowData) =>
            bodyCheckbox(rowData, listScript, setListScript, getDataAllFr)
          }
        />
        <Column body={indexTemplate} field="UID" header={t("STT")}></Column>
        <Column field="name" sortable header={t("Tên kịch bản")}></Column>
        <Column
          body={(rowData) => listAccount(rowData)}
          header={t("Tài khoản được gán")}
        ></Column>
        <Column
          field="status"
          body={(rowData) => statusRunTools(rowData, t)}
          sortable
          header={t("Trạng thái")}
        ></Column>
        <Column
          body={(rowData) => optionAcc(rowData)}
          header={t("Tuỳ chọn")}
          style={{ width: "400px" }}
          className="options-category"
          headerClassName="option-header"
        ></Column>
      </DataTable>
      <EditScriptGroup
        setRefresh={setRefresh}
        refresh={refresh}
        editCate={editCate}
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
      />
    </div>
  );
};
