import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { API_ROUTES, API_ROUTES_NTD, API_URL } from "../../../api";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { useHeaders } from "../../../const/headers";
import { InputTextarea } from "primereact/inputtextarea";
import { FaSearch } from "react-icons/fa";
import { Dropdown } from "primereact/dropdown";
import { toast, ToastContainer } from "react-toastify";
import Navigate from "../Navigate";
import { Footer } from "../../../components/layout/Footer";
import { IoIosCloseCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Card } from "primereact/card";
import { MdDelete } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaCopy } from "react-icons/fa";
import { GoLinkExternal } from "react-icons/go";
import { FadeLoader } from "react-spinners";
export const ModalListPostMarket = ({
  setOpenListPost,
  openListPost,
  editQuestion,
  setRf,
  rf,
  idPage,
}) => {
  const apiFilterLoca = API_URL + API_ROUTES_NTD.GET_LOCATION_MARKETPLACE;
  const apiListFilter = API_URL + API_ROUTES_NTD.GET_LIST_FILTER_MARKET;
  const resultsListFilter = API_URL + API_ROUTES_NTD.RESULTS_LIST_FILTER_MARKET;
  const headers = useHeaders();
  const [loading, setLoading] = useState(false);
  const [keyFilter, setKeyfilter] = useState({
    keywords: "",
    total: "",
    location: "",
    locations: "",
    radius: "",
    minPrice: "",
    maxPrice: "",
  });
  const [idTask, setIdTask] = useState([]);
  const [location, setLocation] = useState([]);
  const [status, setStatus] = useState([]);
  const [results, setResults] = useState([]);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setKeyfilter({
      ...keyFilter,
      [name]: value,
    });
  };

  const handleCopy = (item) => {
    localStorage.setItem("dataCopy", JSON.stringify(item));
    navigate("/addmarket");
  };
  const FilterLoca = async () => {
    const body = { location_name: keyFilter.location };
    try {
      const res = await axios.post(apiFilterLoca, body, headers);
      setLocation(res.data);
    } catch (err) {}
  };

  const getIdTaskFilter = async () => {
    if (keyFilter.keywords?.length === 0) {
      return alert("Vui lòng nhập từ khoá");
    } else if (!keyFilter.locations?.name && location.length === 0) {
      return alert("Vui lòng nhập và chọn vị trí");
    }
    const body = {
      keyword: keyFilter.keywords || null,
      quantity: null,
      location: keyFilter.locations?.name
        ? keyFilter.locations?.name
        : location[0]?.name,
      latitude: keyFilter.locations?.latitude
        ? keyFilter.locations?.latitude
        : location[0]?.latitude,
      longitude: keyFilter.locations?.longitude
        ? keyFilter.locations?.longitude
        : location[0]?.longitude,
      radius_km: Number(keyFilter.radius) || null,
      price_min: Number(keyFilter.minPrice) || null,
      price_max: Number(keyFilter.maxPrice) || null,
    };
    try {
      const res = await axios.post(apiListFilter, body, headers);
      setIdTask(res.data.id_task);
      setLoading(true);
      // setKeyfilter([]);
    } catch (err) {}
  };

  const getResultsTask = async () => {
    const body = {
      id_task: idTask,
    };
    try {
      const res = await axios.post(resultsListFilter, body, headers);
      if (res.data.status === "SUCCESS") {
        setResults(res.data.data.list_post);
        localStorage.setItem(
          "dataSearch",
          JSON.stringify(res.data.data.list_post) || []
        );
        toast.success("Thành công");
        setLoading(false);
      }
      setStatus(res.data.status);
    } catch (err) {
      setStatus(err.response.data.status);
      toast.error("Thất bại");
      setLoading(false);
    }
  };
  useEffect(() => {
    setResults(JSON.parse(localStorage.getItem("dataSearch")));
  }, []);
  useEffect(() => {
    if (idTask.length > 0) {
      const interval = setInterval(() => {
        getResultsTask(idTask);
      }, 3000);
      if (status === "SUCCESS" || status === "FAILURE") {
        setStatus([]);
        setIdTask([]);
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [status, idTask]);
  const widthNavi = document.getElementById("navigate-width")?.offsetWidth;
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container" id="width-navigate">
          <Navigate />
        </div>
        <div
          className="d-flex h-100 "
          style={{ width: `calc(100vw - ${widthNavi}px)` }}
        >
          <div className="d-flex flex-column h-100 w-100 p-2">
            <div
              className="d-flex pb-2 gap-2 w-100 position-relative"
              style={{ borderBottom: "1px solid" }}
            >
              <IoIosCloseCircle
                cursor="pointer"
                onClick={() => navigate("/market")}
                size={25}
                style={{ position: "absolute", top: "0", right: "0" }}
              />
              <InputText
                className="input-placeholder-large"
                type="text"
                name="keywords"
                value={keyFilter.keywords}
                onChange={handleChange}
                id="username"
                style={{ width: "100%" }}
                placeholder="Nhập từ khóa tìm kiếm"
              />
              {/* <InputText
                value={keyFilter.total}
                onChange={(e) =>
                  setKeyfilter({
                    total: e.target.value,
                  })
                }
                id="username"
                style={{ width: "100%" }}
                placeholder="Số lượng"
              /> */}
              <nav className="position-relative w-100">
                <form
                  onSubmit={(e) => {
                    e.preventDefault(); // Prevent the default form submit action
                    FilterLoca(); // Call your FilterLoca function
                  }}
                >
                  <InputText
                    className="input-placeholder-large"
                    name="location"
                    value={keyFilter.location}
                    onChange={handleChange}
                    id="username"
                    style={{ width: "100%" }}
                    placeholder="Nhập vị trí tìm kiếm"
                  />
                  <button
                    type="submit"
                    style={{
                      border: "none",
                      background: "none",
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <FaSearch size={20} />
                  </button>
                </form>
              </nav>
              <Dropdown
                value={keyFilter.locations}
                name="locations"
                onChange={handleChange}
                options={location.map((loc, index) => ({
                  name: loc.name,
                  code: index,
                  latitude: loc.latitude,
                  longitude: loc.longitude,
                }))}
                optionLabel="name"
                placeholder={`${location[0]?.name || "Chọn khu vực tìm kiếm"}`}
                className="custom-dropdown"
                style={{ width: "100%" }}
              />
              <InputText
                className="input-placeholder-large"
                value={keyFilter.radius}
                name="radius"
                onChange={handleChange}
                id="username"
                style={{ width: "100%" }}
                placeholder="Bán kính / km - không bắt buộc"
              />
              <InputText
                className="input-placeholder-large"
                value={keyFilter.minPrice}
                name="minPrice"
                onChange={handleChange}
                id="username"
                style={{ width: "100%" }}
                placeholder="Giá nhỏ nhất (VND) - không bắt buộc"
              />
              <InputText
                className="input-placeholder-large"
                value={keyFilter.maxPrice}
                name="maxPrice"
                onChange={handleChange}
                id="username"
                style={{ width: "100%" }}
                placeholder="Giá lớn nhất (VND) - không bắt buộc"
              />
              <Button
                disabled={loading}
                className="custom-button"
                style={{
                  width: "50%",
                }}
                label={loading ? "Đang Lấy danh sách..." : "Lấy danh sách"}
                onClick={getIdTaskFilter}
                text
              />
              <Button
                disabled={loading}
                className="custom-button"
                style={{ width: "50%" }}
                label="Quay lại"
                onClick={() => navigate("/market")}
                text
              />
            </div>
            <nav>
              <h3 className="mt-2">
                Top Sản Phẩm Bán Chạy Theo Từ Khoá Tìm Kiếm:
              </h3>
            </nav>
            {loading && (
              <div className="d-flex w-100 justify-content-center mt-2 mb-2">
                <FadeLoader />
              </div>
            )}
            <div
              class="d-flex flex-wrap w-100 h-100 gap-4 justify-content-between"
              style={{ overflowY: "auto", overflowX: "hidden" }}
            >
              {results?.map((item, index) => (
                <Card
                  className="card-market-trend "
                  // onClick={() => setSelecCate(item)}
                  key={index}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <div>
                      <img
                        style={{ width: "134px", height: "134px" }}
                        src={item.image}
                      />
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <h4 className="mb-0">{item.title}</h4>
                      <p>{item.description}</p>
                      <p>{item.price}</p>
                      <nav className="d-flex gap-2 align-items-center">
                        <FaLocationDot size={25} />
                        <p>{`${item.district} - ${item.city}`}</p>
                      </nav>
                      <nav
                        onClick={() => {
                          handleCopy(item);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "max-content",
                          color: "#0D6EFD",
                        }}
                        className="d-flex gap-2 align-items-center copy-styles-market"
                      >
                        <FaCopy style={{ marginTop: "2px" }} size={25} />
                        <p>Sao chép bài viết</p>
                      </nav>
                      <a
                        href={`https://www.facebook.com/${item.id_post}`}
                        target="blank"
                        style={{ cursor: "pointer" }}
                        className="d-flex gap-2 align-items-center"
                      >
                        <GoLinkExternal
                          style={{ marginTop: "2px" }}
                          size={25}
                        />
                        <p>Xem bài viết</p>
                      </a>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
