import { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Button } from "primereact/button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { getNoheaders } from "../../../const/getAxios";
import { postNoHeaders } from "../../../const/postAxios";
import { IoMdCloseCircle } from "react-icons/io";
import { FaMinus, FaPlus } from "react-icons/fa";
import { AiOutlineProduct } from "react-icons/ai";
import { useUser } from "../../../contexts/UserContext";
function ModalAddProduct({
  modalAdd,
  setModalAdd,
  productEdit,
  setRefes,
  refes,
  toast,
}) {
  const { userInfo } = useUser();
  const headers = useHeaders();
  const apiGetAllCate = API_URL + API_ROUTES_ZALO.GET_CATEGORY_SHOP;
  const apiGetProduct = API_URL + API_ROUTES_ZALO.GET_ALL_PRODUCT_SHOP;
  const apiAddProduct = API_URL + API_ROUTES_ZALO.ADD_PRODUCT_IN_ORDER;
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [listVariant, setListVariant] = useState([]);
  const [listQuantity, setListQuantity] = useState([]);
  useEffect(() => {
    getNoheaders(`${apiGetAllCate}?id_employee=${userInfo.id}`, setCategory);
  }, []);
  useEffect(() => {
    const itemVarian = product
      ?.map((item) => item?.variants?.map((item) => item))
      .flat();
    const price = itemVarian?.map((item) => ({
      product_variant_id: item.id,
      quantity: 0,
      price: item.price,
    }));
    setListQuantity(price);
  }, [product, refes]);
  const handleGetProduct = (item) => {
    getNoheaders(
      `${apiGetProduct}?id_employee=${userInfo.id}&id_category=${item.id}`,
      setProduct
    );
  };
  const handleGetQuantity = (item, type) => {
    const existingVariant = listVariant.find(
      (variant) => variant.product_variant_id === item.id
    );
    if (
      listQuantity?.find((quantity) => quantity.product_variant_id === item.id)
        .quantity === 0 &&
      type === "minus"
    ) {
      alert("Số lượng không được nhỏ hơn 1");
      return;
    }
    const totalQuantity =
      type === "plus"
        ? {
            ...listQuantity?.find(
              (quantity) => quantity.product_variant_id === item.id
            ),
            quantity:
              listQuantity?.find(
                (quantity) => quantity.product_variant_id === item.id
              ).quantity + 1,
          }
        : {
            ...listQuantity?.find(
              (quantity) => quantity.product_variant_id === item.id
            ),
            quantity:
              listQuantity?.find(
                (quantity) => quantity.product_variant_id === item.id
              ).quantity - 1,
          };
    setListQuantity([
      ...listQuantity?.filter((list) => list.product_variant_id !== item.id),
      totalQuantity,
    ]);
    if (existingVariant) {
      type === "plus"
        ? setListVariant((prevVariants) =>
            prevVariants.map((variant) =>
              variant.product_variant_id === item.id
                ? { ...variant, quantity: variant.quantity + 1 }
                : variant
            )
          )
        : setListVariant((prevVariants) =>
            prevVariants.map((variant) =>
              variant.product_variant_id === item.id
                ? { ...variant, quantity: variant.quantity - 1 }
                : variant
            )
          );
    } else {
      setListVariant((prevVariants) => [...prevVariants, totalQuantity]);
    }
  };
  const handleAddProduct = async () => {
    let total = 0;
    for (let i = 0; i < listVariant.length; i++) {
      total = total + listVariant[i].price * listVariant[i].quantity;
    }
    const body = {
      id_order: productEdit?.id,
      total_amount: total,
      additional_items: listVariant?.filter((item) => item.quantity > 0),
    };
    try {
      await axios.post(apiAddProduct, body, headers);
      setRefes(!refes);
      setListVariant([]);
      setModalAdd(false);
    } catch (err) {
      toast.error(err.response.data.error);
    }
  };
  return (
    <Dialog
      header="Thêm sản phẩm"
      className="dialog-shop"
      visible={modalAdd}
      style={{ width: "50%" }}
      onHide={() => {
        if (!modalAdd) return;
        setModalAdd(false);
      }}
    >
      <ToastContainer />
      <div>
        {product?.length === 0 ? (
          <div>
            <p>Chọn thể loại</p>
            {category?.map((cate, index) => (
              <div
                key={index}
                className="d-flex gap-2 modal-box-product"
                onClick={() => handleGetProduct(cate)}
              >
                {cate.avt ? (
                  <img
                    src={`${API_URL}${cate.avt}`}
                    style={{ width: "40px", height: "40px" }}
                  />
                ) : (
                  <AiOutlineProduct size={40} />
                )}
                <p>{cate.name}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className="position-relative p-4 d-flex flex-column gap-2 w-100">
            {product?.map((item, index) => (
              <div key={item.id} className="d-flex gap-2  w-100">
                <nav className="d-flex" style={{ width: "300px" }}>
                  <img
                    src={`${API_URL}${item.images[0]}`}
                    style={{ width: "50px", height: "50px" }}
                  />
                  <p>{item.title}</p>
                </nav>
                <nav className="w-100 d-flex flex-column">
                  {item?.variants?.map((vari, i) => (
                    <div
                      className="d-flex justify-content-between gap-2 w-100"
                      key={vari.id}
                    >
                      <div
                        className="category-container-order cursor-pointer"
                        style={{ width: "200px" }}
                      >
                        <p className="category-shop">{`${vari.classify}:`}</p>
                        <nav className="d-flex gap-2 flex-wrap">
                          {vari.options?.map((option, num) => (
                            <p
                              className="category-shop"
                              key={option.id}
                            >{`${option?.name} - ${option?.value}`}</p>
                          ))}
                        </nav>
                      </div>
                      <div className="d-flex align-items-center">
                        <p>
                          {`${(
                            listQuantity?.filter(
                              (quantity) =>
                                quantity.product_variant_id === vari.id
                            )[0]?.quantity *
                            listQuantity?.filter(
                              (quantity) =>
                                quantity.product_variant_id === vari.id
                            )[0]?.price
                          )?.toLocaleString("vi-VN")} VND`}
                        </p>
                      </div>
                      <div className="d-flex gap-2 align-items-center">
                        <FaMinus
                          cursor="pointer"
                          onClick={() => handleGetQuantity(vari, "minus")}
                        />
                        <input
                          style={{
                            width: `30px`,
                          }}
                          value={
                            listQuantity?.filter(
                              (quantity) =>
                                quantity.product_variant_id === vari.id
                            )[0]?.quantity
                          }
                          //   onChange={(e) =>
                          //     handleNumberOrderChange(item, e.target.value)
                          //   }
                        />
                        <FaPlus
                          cursor="pointer"
                          onClick={() => handleGetQuantity(vari, "plus")}
                        />
                      </div>
                    </div>
                  ))}
                </nav>
              </div>
            ))}
            <IoMdCloseCircle
              cursor="pointer"
              onClick={() => setProduct([])}
              style={{ position: "absolute", top: "0", right: "0" }}
              size={30}
            />
          </div>
        )}
      </div>
      <nav className="w-100 mt-2 d-flex align-items-center justify-content-end gap-2">
        <Button
          style={{
            padding: "8px 12px",
            width: "max-content",
            background: "red",
          }}
        >
          Huỷ
        </Button>
        <Button
          style={{
            padding: "8px 12px",
            width: "max-content",
            background: "#0167f3",
          }}
          onClick={() => {
            handleAddProduct();
          }}
        >
          Thêm
        </Button>
      </nav>
    </Dialog>
  );
}

export default ModalAddProduct;
