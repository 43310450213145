import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_ROUTES, API_URL } from "../api";
import { useHeaders } from "./headers";
import { useAuth } from "../AuthContext";
import { useUser } from "../contexts/UserContext";
const useLogout = () => {
  const headers = useHeaders();
  const { refreshs } = useAuth();
  const { setUserInfo } = useUser();
  const refresh = localStorage.getItem("refresh");
  const navigate = useNavigate();
  const { userLoggedIn, setUserLoggedIn } = useAuth();

  const logout = () => {
    const apiLogout = API_URL + API_ROUTES.LOGIN_OUT;
    axios
      .post(
        apiLogout,
        {
          refresh: refresh,
        },
        headers
      )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          navigate("/");
          // window.location.reload();
          setUserLoggedIn(false);
          setUserInfo([]);
          localStorage.clear();
        }
      })
      .catch((error) => {
        setUserLoggedIn(false);
      });
  };

  return logout;
};

export default useLogout;
