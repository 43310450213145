import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_NTD, API_ROUTES_ZALO } from "../../../api";
import { Checkbox } from "primereact/checkbox";
import { ListCommentsEditClient } from "./editScriptClient/ListCommentsEditClient";
import { ListIMessEditClient } from "./editScriptClient/ListIMessEditClient";
import { InputNumber } from "primereact/inputnumber";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { IoInformationCircle } from "react-icons/io5";
import { RiExpandDiagonal2Line } from "react-icons/ri";
import { InputTextarea } from "primereact/inputtextarea";
import { filter } from "lodash";
export const EditScriptClient = ({
  modalEdit,
  setModalEdit,
  editCate,
  refresh,
  setRefresh,
}) => {
  const headers = useHeaders();
  const editCategoryMkt = API_URL + API_ROUTES_ZALO.CREATE_EDIT_CATEGORY_CLIENT;
  const [name, setName] = useState("");

  const [valueCmt, setValueCmt] = useState("");
  const [valueInbox, setValueInbox] = useState("");
  const [comments, setComments] = useState([]);
  const [inbox, setInbox] = useState([]);
  const [inboxSpilt, setInboxSplit] = useState([]);
  const [delayTime, setDelayTime] = useState("");
  const [numberCount, setNumberCount] = useState(0);
  const [uidTag, setUidTag] = useState("");
  const [waitTime, setWaitTime] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  useEffect(() => {
    if (editCate?.length === 0) {
      return;
    } else {
      setName(editCate?.name || "");
      setInbox(
        editCate?.first_messages?.length === 0 ||
          editCate?.first_messages[0] === null ||
          editCate?.first_messages === null
          ? []
          : editCate?.first_messages
      );
      setPhoneNumber(editCate?.phone_numbers?.join("\n"));
      setDelayTime(editCate?.delay_time);
      setNumberCount(editCate?.number_count);
      setWaitTime(editCate?.wait_time);
      setIsChecked(editCate?.divide || false);
    }
  }, [modalEdit]);
  const editCateMkt = async (e) => {
    e.preventDefault();
    if (!name || !delayTime) {
      alert("Vui lòng nhập đầy đủ thông tin");
    } else {
      const body = {
        id_category: editCate?.id,
        name: name,
        phone_numbers: phoneNumber.split("\n")?.filter((i) => i !== ""),
        first_messages: inbox,
        delay_time: delayTime,
        wait_time: waitTime,
        number_count: numberCount || 1,
        divide: isChecked,
      };
      try {
        const res = await axios.post(editCategoryMkt, body, headers);
        setRefresh(!refresh);
        setModalEdit(false);
      } catch (err) {}
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      editCateMkt(e);
    }
  };
  return (
    <Dialog
      header="Chỉnh sửa kịch bản"
      visible={modalEdit}
      style={{ position: "relative", width: "70vw" }}
      onHide={() => setModalEdit(false)}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button className="button-blue radius" onClick={editCateMkt}>
            Sửa kịch bản
          </Button>
          <Button
            onClick={() => setModalEdit(false)}
            className="button-red radius"
          >
            Thoát không lưu
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon" style={{ width: "9rem" }}>
            Tên kịch bản
          </span>
          <InputText
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder=""
          />
        </div>
        <div className="p-inputgroup flex-1 w-100">
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Thời gian chờ (Giây)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Thời gian chờ giữa mỗi hành động trong một chuỗi nhiều
                      hành động
                      <br />- Ví dụ: Bạn tích chọn 3 chức năng "Gửi tin nhắn +
                      Bình luận + Like bài viết". Thì đây là một chuỗi 3 hành
                      động liên tiếp. Bạn nên để thời gian chờ tối thiểu là 60s
                      và khuyến khích là 180s để an toàn cho tài khoản. Hành
                      động quá nhanh sẽ dễ bị hạn chế tính năng trên tài khoản
                      do cơ chế chống spam của Facebook
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputText
              style={{ width: "100%" }}
              value={delayTime}
              placeholder="Thời gian tính bằng giây (s)"
              onChange={(e) => setDelayTime(e.value)}
              useGrouping={false}
              min={60}
              type="number"
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Số lượt</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Tài khoản đi tương tác với mỗi một khách hàng được tính là
                      1 lượt. Nên để số lượt là 1. Và cài đặt thêm thời gian chờ
                      lặp lại ở ô bên cạnh
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputText
              style={{ width: "100%" }}
              value={numberCount}
              placeholder=""
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 1) {
                  setNumberCount(value);
                } else {
                  setNumberCount(1);
                }
              }}
              useGrouping={false}
              min={1}
              type="number"
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span style={{ width: "" }}>Thời gian chờ lặp lại (Giờ)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Chức năng này có tác dụng khi bạn cài đặt số lượt (số lượt
                      được cài đặt ở ô bên cạnh) Chức năng này giúp chạy tiếp
                      chiến dịch hoàn toàn tự động khi bạn đặt số lượt ở ô bên
                      cạnh. Nên để thời gian là 1 giờ
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputText
              style={{ width: "100%" }}
              value={waitTime}
              placeholder="Thời gian tính bằng giờ (h)"
              onChange={(e) => setWaitTime(e.value)}
              useGrouping={false}
              min={1}
              type="number"
            />
          </div>
        </div>

        <div className="d-flex gap-4">
          <nav className="mt-2" style={{ width: "50%" }}>
            <div className="d-flex">
              <p className="mb-2 mr-2">Số điện thoại muốn kết bạn</p>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      - Chỉ nhập duy nhất 1 UID
                      <br />- Ví dụ: UID Facebook của Fanpage, Group
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </div>
            <InputTextarea
              style={{ width: "100%", height: "80%" }}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder=""
              rows={5}
              cols={30}
            />
          </nav>
          <div style={{ marginTop: "30px" }}>
            <label className="d-flex gap-2">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                style={{ width: "20px", height: "auto" }}
              />
              Chia
            </label>
          </div>
        </div>

        <ListIMessEditClient
          valueInbox={valueInbox}
          setInbox={setInbox}
          inbox={inbox}
          setValueInbox={setValueInbox}
          inboxSpilt={inboxSpilt}
          setInboxSplit={setInboxSplit}
        />
      </div>
    </Dialog>
  );
};
