import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { API_URL, API_ROUTES } from "../../api";
import { Password } from "primereact/password";
import { MdOutlineHome } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { updateTitle } from "../../const/updateTitle";
import ModalRegister from "../container/register/ModalResgister";
import RegisterFail from "../container/register/RegisterFail";
import { useUser } from "../../contexts/UserContext";
import { locale } from "primereact/api";
import "./Registers.css";
export const Register = () => {
  locale("vi");
  updateTitle("Chốt Care - Đăng Ký");
  const { modalRe, setModalRe, newLogo } = useUser();
  const [fbLink, setFbLink] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [passWord, setPassword] = useState("");
  const [error, setError] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [modalReFail, setModalReFail] = useState(false);
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(true);
  const [mail, setMail] = useState("");
  const registerAcc = API_URL + API_ROUTES.CREATE_REGISTER;
  const createRegi = async () => {
    if (passWord !== oldPass) {
      alert("Mật khẩu không khớp");
    } else if (
      !phone ||
      !fullName ||
      !username ||
      !passWord ||
      !mail ||
      !oldPass
    ) {
      alert("Vui lòng nhập đầy đủ thông tin");
    } else if (!/^[a-zA-Z0-9]+$/.test(username)) {
      alert("Tên đăng nhập không được chứa dấu và các ký tự đặc biệt.");
    } else if (!isValid) {
      alert("Nhập đúng định dạng @gmail.com");
    } else {
      const body = {
        facebook_link: fbLink,
        phone_number: phone,
        fullname: fullName,
        username: username,
        password: passWord,
        mail: mail,
      };
      try {
        const res = await axios.post(registerAcc, body);
        setModalRe(true);
        setFbLink("");
        setPhone("");
        setUsername("");
        setFullName("");
        setOldPass("");
        setPassword("");
        setMail("");
      } catch (err) {
        if (
          err.response &&
          err.response.data.error ===
          "Tài khoản đăng nhập đã tồn tại trên hệ thống"
        ) {
          toast.error("Tài khoản đăng nhập đã tồn tại trên hệ thống");
        } else if (
          err.response &&
          err.response.data.error === "Mail đã tồn tại trên hệ thống"
        ) {
          toast.error("Mail đã tồn tại trên hệ thống");
        } else {
          toast.error("Đăng ký thất bại");
        }
      }
    }
  };
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
    const phoneRegex = /^(0[1|3|5|7|8|9])+([0-9]{8})$/;
    if (!phoneRegex.test(value)) {
      setError("Số điện thoại không hợp lệ. Vui lòng nhập 10-11 chữ số.");
    } else {
      setError("");
    }
  };
  const handleMailChange = (e) => {
    const value = e.target.value;
    setMail(value);
    if (value.includes("@gmail.com")) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      createRegi();
    }
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    if (containsDiacritics(value)) {
      alert("Mật khẩu không được chứa ký tự có dấu.");
    } else {
      setPassword(value);
    }
  };
  const containsDiacritics = (str) => {
    return /[^\u0000-\u007E]/.test(str);
  };
  return (
    <div
      style={{ height: "100vh", color: "#912091e0" }}
      className="p-4  w-100 d-flex justify-content-center align-items-center background-register"
    >
      <ToastContainer />
      <ModalRegister setModalRe={setModalRe} modalRe={modalRe} />
      <RegisterFail setModalReFail={setModalReFail} modalReFail={modalReFail} />
      <div className="d-flex h-100 login-register-responsive" style={{}}>
        <div
          className="login-register-border-radius d-flex justify-content-center align-items-center flex-column"
          style={{
            padding: "58px 42px",
            backgroundColor: "transparent",
            color: "purple",
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            position: "relative",
          }}
        >
          <MdOutlineHome
            onClick={() => navigate("/")}
            size={40}
            className="text-light"
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              cursor: "pointer",
            }}
          />
          <nav className="d-flex flex-column gap-2 align-items-center mb-4">
            <img
              style={{ width: "273px", borderRadius: "10%" }}
              src={`${API_URL}/${newLogo}`}
            />
          </nav>
          <nav className="d-flex flex-column gap-2  w-100 align-items-center">
            <h4
              style={{ fontWeight: "700" }}
              className="text-light text-center"
            >
              HỆ THỐNG MARKETING
            </h4>
            <h4
              style={{ fontWeight: "700" }}
              className="text-light text-center"
            >
              BÁN HÀNG TỰ ĐỘNG
            </h4>

            <p style={{ fontSize: "16px" }} className="text-light">
              DÙNG NGAY MIỄN PHÍ
            </p>
          </nav>
        </div>
        <div
          className="login-register-border-radius d-flex justify-content-center align-items-center flex-column"
          style={{
            padding: "58px 42px",
            backgroundColor: "white",
            color: "purple",
            border: "2px solid white",
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
          }}
        >
          <nav
            style={{ alignItems: "end" }}
            className="register-mobile d-flex w-100 gap-4"
          >
            <h3 className="text-dark">Đăng ký miễn phí</h3>
            <p
              onClick={() => navigate("/login")}
              style={{ fontSize: "1rem", cursor: "pointer", color: "blue" }}
              className="mb-0 mb-2"
            >
              {"->"} Bạn đã có tài khoản ?
            </p>
          </nav>
          <div
            style={{ justifyContent: "flex-start" }}
            className="line-register d-flex mb-4 w-100"
          >
            <nav
              style={{
                width: "60px",
                borderRadius: "21px",
                padding: "4px",
                background: "purple",
              }}
            ></nav>
          </div>
          <div className="w-100 d-flex flex-column gap-4 mt-4">
            <FloatLabel>
              <InputText
                id="username"
                className="w-100"
                value={fbLink}
                onChange={(e) => setFbLink(e.target.value)}
                onKeyDown={handleKeyDown}
                style={{ fontSize: "1rem" }}
              />
              <label htmlFor="username" style={{ fontSize: "1rem", fontWeight: "400" }}>
                Link Facebook{" "}
              </label>
            </FloatLabel>
            <FloatLabel>
              <InputText
                id="username"
                className="w-100"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                onKeyDown={handleKeyDown}
                style={{ fontSize: "1rem" }}
              />
              <label htmlFor="username" style={{ fontSize: "1rem", fontWeight: "400" }}>
                Họ tên{" "}
                <span style={{ color: "red" }} class="required">
                  *
                </span>
              </label>
            </FloatLabel>
            <FloatLabel>
              <InputText
                type="number"
                id="username"
                className="w-100 no-arrows"
                value={phone}
                onChange={handlePhoneChange}
                onKeyDown={handleKeyDown}
                style={{ fontSize: "1rem" }}
              />
              <label htmlFor="username" style={{ fontSize: "1rem", fontWeight: "400" }}>
                Số điện thoại{" "}
                <span style={{ color: "red" }} class="required">
                  *
                </span>
              </label>
            </FloatLabel>
            {error && (
              <p style={{ color: "red", marginTop: "-20px" }}>{error}</p>
            )}
            <FloatLabel>
              <InputText
                id="username"
                className="w-100"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={handleKeyDown}
                style={{ fontSize: "1rem" }}
              />
              <label htmlFor="username" style={{ fontSize: "1rem", fontWeight: "400" }}>
                Đặt tên tài khoản đăng nhập{" "}
                <span style={{ color: "red" }} class="required">
                  *
                </span>
              </label>
            </FloatLabel>
            <FloatLabel>
              <label htmlFor="username" style={{ fontSize: "1rem", fontWeight: "400" }}>
                Gmail{" "}
                <span style={{ color: "red" }} class="required">
                  *
                </span>
              </label>
              <InputText
                id="username"
                className="w-100"
                type="gmail"
                value={mail}
                onChange={handleMailChange}
                onKeyDown={handleKeyDown}
                style={{ fontSize: "1rem" }}
              />{" "}
            </FloatLabel>
            {!isValid && (
              <small style={{ color: "red", marginTop: "-20px" }}>
                Mail phải có định dạng @gmail.com
              </small>
            )}
            <div className="password-mobile d-flex gap-4 w-100 justify-content-between">
              <div className="w-100">
                <FloatLabel>
                  <Password
                    toggleMask
                    id="username"
                    className="w-100"
                    value={passWord}
                    onChange={handlePasswordChange}
                    onKeyDown={handleKeyDown}
                    style={{ fontSize: "1rem" }}
                  />
                  <label htmlFor="username" style={{ fontSize: "1rem", fontWeight: "400" }}>
                    Mật khẩu{" "}
                    <span style={{ color: "red" }} class="required">
                      *
                    </span>
                  </label>
                </FloatLabel>
              </div>
              <div className="w-100">
                <FloatLabel>
                  <Password
                    toggleMask
                    id="username"
                    className="w-100"
                    value={oldPass}
                    onChange={(e) => setOldPass(e.target.value)}
                    onKeyDown={handleKeyDown}
                    style={{ fontSize: "1rem" }}
                  />
                  <label htmlFor="username" style={{ fontSize: "1rem", fontWeight: "400" }}>
                    Xác nhận mật khẩu{" "}
                    <span style={{ color: "red" }} class="required">
                      *
                    </span>
                  </label>
                </FloatLabel>
              </div>
            </div>
          </div>
          <div className="w-100 mt-4">
            <Button
              onClick={createRegi}
              className="w-100 button-blue radius"
              label="Đăng ký"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
