import axios from "axios";
export const postAxios = async (api, body, setData, headers) => {
  try {
    const res = await axios.post(api, body, headers);
    setData(res.data.data);
  } catch (err) {
    console.log(err);
  }
};
export const postNoHeaders = async (api, body, setData) => {
  try {
    const res = await axios.post(api, body);
    setData(res.data);
  } catch (err) {
    console.log(err);
  }
};
export const postAxiosMess = async (api, body, setData, headers) => {
  try {
    const res = await axios.post(api, body, headers);
    setData(res.data.data);
    localStorage.setItem("itemMember", JSON.stringify(res.data.data));
  } catch (err) {
    console.log(err);
  }
};
