import React, { useEffect, useRef } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES, API_ROUTES_NTD } from "../../api";
import { Footer } from "../layout/Footer";
import { Button } from "primereact/button";
import { useHeaders } from "../../const/headers";
import { updateTitle } from "../../const/updateTitle";
import { useAuth } from "../../AuthContext";
import { locale } from "primereact/api";
import { getAxiosMarket } from "../../const/getAxios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Card } from "primereact/card";
import { FiExternalLink } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { Paginator } from "primereact/paginator";
import { IoIosCloseCircle } from "react-icons/io";
import { PulseLoader } from "react-spinners";
import { Dropdown } from "primereact/dropdown";
import { getAxios } from "../../const/getAxios";
import { MdError } from "react-icons/md";
import { truncateMarket } from "../../const/splittext";
import { FaCalendarCheck } from "react-icons/fa";
import Map from "./Map";
import { IoChatboxEllipses } from "react-icons/io5";
import { FaEye } from "react-icons/fa";
import { Sidebar } from "primereact/sidebar";

export const ListPostMarketplace = () => {
  locale("vi");
  const location = useLocation();
  const headers = useHeaders();
  const pathId = location.pathname;
  updateTitle("Chốt Care - Quản Lý Khách Hàng");
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const apiAllPost = API_URL + API_ROUTES_NTD.SHOW_LIST_POST_MARKET;
  const apiDeletePost = API_URL + API_ROUTES_NTD.DELETE_POST_MARKET;
  const resultsDeletePost = API_URL + API_ROUTES_NTD.RESULTS_DELETE_POST_MARKET;
  const apiGetPost = API_URL + API_ROUTES_NTD.GET_POST_MARKET;
  const reusltsGetPost = API_URL + API_ROUTES_NTD.RESULTS_GET_POST_MARKET;
  const navigate = useNavigate();
  const { setUserLoggedIn } = useAuth();
  const [idTask, setIdTask] = useState([]);
  const [idTaskPost, setIdTaskPost] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [statusDelete, setStatusDelete] = useState([]);
  const [statusPost, setStatusPost] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [getTotal, setGetTotal] = useState([]);
  const [rows, setRows] = useState(100);
  const [listPost, setListPost] = useState([]);
  const [first, setFirst] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idPost, setIdPost] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  const [hidden, setHidden] = useState({ status: false, id: "" });
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  const [itemPost, setItemPost] = useState([]);
  const [dataFb, setDataFb] = useState([]);
  useEffect(() => {
    if (selectedAccount.length !== 0) {
      getAxiosMarket(
        apiAllPost,
        setListPost,
        setGetTotal,
        rows,
        totalPage + 1,
        selectedAccount === "list" ? "list" : selectedAccount.id
      );
    }
  }, [refresh, selectedAccount, totalPage, rows]);
  const handleSelectAccount = (item) => {
    setSelectedAccount(item);
  };
  const handleSelectedAll = () => {
    setSelectedAccount("list");
  };
  const dataFbWithOutCheckPoint = dataFb?.filter(
    (item) => item?.checkpoint === false && item?.status_proxy === true
  );
  useEffect(() => {
    const accountId = pathId.split("/")[2];
    setSelectedAccount(accountId);
    getAxios(getDataFb, setDataFb);
  }, []);
  const getIdTaskPost = async () => {
    const body = {
      id_accounts: selectedAccount.id ? [selectedAccount.id] : [],
    };
    try {
      const res = await axios.post(apiGetPost, body, headers);
      setIdTaskPost(res.data.id_task);
      setLoading(true);
    } catch (err) {}
  };
  const getResultsPost = async () => {
    const body = {
      id_task: idTaskPost,
    };
    try {
      const res = await axios.post(reusltsGetPost, body, headers);
      if (res.data.status === "SUCCESS") {
        setLoading(false);

        // toast.success("Xoá thành công");
      }
      setStatusPost(res.data.status);
    } catch (err) {
      setStatusPost(err.response.data.status);
      setLoading(false);
      //   toast.error("Xoá thất bại");
    }
  };

  const getIdTaskDelete = async (item) => {
    const body = {
      id_account: item.account,
      id_post: item.id,
    };
    try {
      const res = await axios.post(apiDeletePost, body, headers);
      setIdTask(res.data.id_task);
      toast.info("Đang xoá , vui lòng chờ");
      setHidden({
        status: true,
        id: item.id,
      });
    } catch (err) {}
  };

  const getResultsTask = async () => {
    const body = {
      id_task: idTask,
    };
    try {
      const res = await axios.post(resultsDeletePost, body, headers);
      if (res.data.status === "SUCCESS") {
        setHidden({
          status: false,
          id: "",
        });
        res.data.data
          ? toast.success("Xoá thành công")
          : toast.error("Xoá thất bại");
      }
      setStatusDelete(res.data.status);
    } catch (err) {
      setStatusDelete(err.response.data.status);
      setHidden({
        status: false,
        id: "",
      });
      toast.error("Xoá thất bại");
    }
  };
  useEffect(() => {
    if (idTask.length > 0) {
      const interval = setInterval(() => {
        getResultsTask(idTask);
      }, 3000);
      if (statusDelete === "SUCCESS" || statusDelete === "FAILURE") {
        setStatusDelete([]);
        setIdTask([]);
        clearInterval(interval);
        setRefresh(!refresh);
      }
      return () => clearInterval(interval);
    }
  }, [statusDelete, idTask]);
  useEffect(() => {
    if (idTaskPost.length > 0) {
      const interval = setInterval(() => {
        getResultsPost(idTaskPost);
      }, 3000);
      if (statusPost === "SUCCESS" || statusPost === "FAILURE") {
        setStatusPost([]);
        setIdTaskPost([]);
        clearInterval(interval);
        setRefresh(!refresh);
      }
      return () => clearInterval(interval);
    }
  }, [statusPost, idTaskPost]);
  const formatPrice = (price) => {
    if (typeof price !== "number") {
      return "";
    }
    return new Intl.NumberFormat("vi-VN").format(price) + " đ";
  };
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container" id="width-navigate">
          <Navigate />
        </div>
        <div
          style={{ width: "calc(100vw - 230px)" }}
          className=" h-100 p-4 d-flex flex-column align-items-between justify-content-between"
        >
          <div className="d-flex flex-column " style={{ height: "90%" }}>
            <nav className="d-flex justify-content-between">
              <nav className="d-flex flex-column gap-4 w-100">
                <div className="d-flex gap-3 flex-column">
                  <div className="d-flex gap-3">
                    <Button
                      className="custom-button"
                      onClick={() => window.history.back()}
                    >
                      Quay lại
                    </Button>
                    {loading ? (
                      <PulseLoader size={15} />
                    ) : (
                      <Button className="custom-button" onClick={getIdTaskPost}>
                        Làm mới
                      </Button>
                    )}
                    <Button
                      className="custom-button"
                      onClick={handleSelectedAll}
                    >
                      Chọn tất cả
                    </Button>
                  </div>
                  <div style={{ overflow: "auto", width: "100%" }}>
                    <ul className="d-flex gap-4">
                      {dataFbWithOutCheckPoint?.map((item, index) => (
                        <li
                          onClick={() => handleSelectAccount(item)}
                          style={{
                            cursor: "pointer",
                          }}
                          className={`d-flex flex-column gap-2 align-items-center gap-2 w-100 ${
                            selectedAccount?.id === item.id ? "selected" : ""
                          }`}
                          key={index}
                        >
                          <img
                            style={{
                              borderRadius: "50%",
                              width: "50px",
                              border:
                                selectedAccount?.id === item.id
                                  ? "3px solid #007bff"
                                  : "none", // Thêm viền sáng khi được chọn
                            }}
                            src={item?.avatar}
                            alt="ảnh đại diện"
                          />
                          <p style={{ width: "max-content" }}>{item.name}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="d-flex gap-4 align-items-center">
                  <h3>Sản phẩm đã niêm yết của bạn</h3>
                </div>
              </nav>
              <IoIosCloseCircle
                onClick={() => navigate("/market")}
                style={{ cursor: "pointer" }}
                size={30}
              />
            </nav>
            <div className="d-flex flex-wrap gap-2 w-100 styles-card-market  h-100 overflow-auto">
              {listPost?.map((item, index) => (
                <Card className="list-card-market" key={index}>
                  <div className="d-flex gap-4 w-100  h-100 justify-content-between">
                    <div
                      className="d-flex gap-4 align-items-center "
                      style={{ width: "100%" }}
                    >
                      <div>
                        <img
                          style={{ width: "100px", height: "100px" }}
                          src={item.url_image}
                        />
                      </div>
                      <div className="d-flex flex-column gap-2">
                        {item.status === 4 && (
                          <nav className="d-flex gap-2 align-items-center">
                            <MdError color="red" />
                            <p style={{ color: "red", fontWeight: "700" }}>
                              {item.status_indicator}
                            </p>
                            <p style={{ color: "red", fontWeight: "700" }}>
                              (Vui lòng xóa bài viết để không ảnh hưởng đến tài
                              khoản)
                            </p>
                          </nav>
                        )}
                        <h4 style={{ fontSize: "1.3rem" }} className="mb-0">
                          {item.title}
                        </h4>
                        <p>{truncateMarket(item.description, 300)}</p>
                        <nav className="d-flex gap-4 align-items-center">
                          {item.price && <p>{formatPrice(item.price)}</p>}
                          <nav className="d-flex gap-2 align-items-center fw-bold">
                            <img
                              className="rounded-circle"
                              style={{ width: "20px", height: "20px" }}
                              src={
                                dataFb?.filter((i) => i.id === item.account)[0]
                                  ?.avatar
                              }
                            />
                            <p>
                              {
                                dataFb?.filter((i) => i.id === item.account)[0]
                                  ?.name
                              }
                            </p>
                          </nav>
                          <nav>
                            <a
                              href={item.url_post}
                              target="blank"
                              style={{ cursor: "pointer" }}
                            >
                              <FiExternalLink size={30} />
                            </a>

                            <MdDelete
                              onClick={() => getIdTaskDelete(item)}
                              style={{
                                marginTop: "2px",
                                cursor: "pointer",
                                display: hidden.id === item.id ? "none" : "",
                              }}
                              size={30}
                            />
                          </nav>
                        </nav>
                        <nav className="d-flex gap-4 align-items-center">
                          {item.created_at && (
                            <nav className="d-flex gap-2 align-items-center">
                              <FaCalendarCheck />
                              <p>
                                {new Date(item.created_at).toLocaleString()}
                              </p>
                            </nav>
                          )}
                          <nav className="d-flex gap-2 align-items-center">
                            <IoChatboxEllipses />
                            <p>{`Tin nhắn trả lời tự động: ${
                              item.messages_bot?.length > 0 ? "Có" : "Không"
                            }`}</p>
                            {item.messages_bot?.length > 0 && (
                              <FaEye
                                cursor="pointer"
                                onClick={() => setActiveIndex(index)}
                              />
                            )}
                          </nav>
                          <Sidebar
                            visible={activeIndex === index}
                            onHide={() => setActiveIndex(null)}
                          >
                            {item.messages_bot?.map((item) => (
                              <p
                                className="mb-2 p-2 rounded text-light"
                                style={{ background: "#093be5" }}
                              >
                                {item}
                              </p>
                            ))}
                          </Sidebar>
                        </nav>
                      </div>
                    </div>
                    <Map location={item.location} />
                  </div>
                </Card>
              ))}
            </div>
          </div>
          <Paginator
            className="pagination-markerting"
            first={first}
            rows={rows}
            totalRecords={getTotal?.count}
            onPageChange={onPageChange}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};
