import { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Button } from "primereact/button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES_ZALO } from "../../../api";

function DialogSuccess({
  openSuccess,
  setOpenSuccess,
  allCart,
  allProduct,
  dataSuccess,
  setDataSuccess,
  setRfCart,
  rfCart,
}) {
  const handleClose = () => {
    setOpenSuccess(false);
    setDataSuccess([]);
    setRfCart(!rfCart);
  };
  const dataCart = allCart;
  return (
    <Dialog
      header="Đặt hàng thành công"
      className="dialog-shop"
      visible={openSuccess}
      style={{ width: "30rem" }}
      //   onHide={() => {
      //     if (!openSuccess) return;
      //     setOpenSuccess(false);
      //   }}
      closable={false}
    >
      <ToastContainer />
      <div className="d-flex flex-column w-100 gap-2">
        <nav className="d-flex flex-column gap-2">
          <p>Cảm ơn quý khách đã đặt mua sản phẩm</p>
          <p>
            Tin nhắn xác nhận đơn hàng đã được gửi đến số điện thoại của quý
            khách (qua Zalo). Vui lòng kiểm tra tin nhắn, chúng tôi sẽ liên hệ
            với quý khách để xác nhận đơn hàng
          </p>
        </nav>
        <nav className="w-100 d-flex flex-column gap-2">
          <p className="mt-2 mb-2 fw-bold">Thông tin đơn hàng:</p>
          <nav className="d-flex gap-2 w-100">
            <p style={{ width: "max-content" }}>Người nhận:</p>
            <p className="fw-bold">{dataSuccess.full_name}</p>
          </nav>
          <nav className="d-flex gap-2 w-100">
            <p style={{ width: "max-content" }}>SĐT:</p>
            <p className="fw-bold">{dataSuccess.phone_number}</p>
          </nav>
          <nav className="d-flex gap-2 w-100">
            <nav>
              <p style={{ width: "max-content" }}>Địa chỉ:</p>
            </nav>
            <p className="fw-bold">{`${dataSuccess.address} - ${dataSuccess.ward} - ${dataSuccess.district} - ${dataSuccess.city}`}</p>
          </nav>
          <nav>
            <p>Sản phẩm:</p>
            {dataCart?.items?.map((item, index) => (
              <nav className="d-flex gap-2">
                <p
                  style={{
                    fontWeight: "600",
                    marginBottom: "6px",
                  }}
                >{`${item.title} ${item.product_variant.options?.map(
                  (item) => `${item.name} ${item.value}`
                )} [SL]${
                  item.quantity
                } * ${item.product_variant.price.toLocaleString(
                  "vi-VN"
                )} VND = ${(
                  item.quantity * item.product_variant.price
                ).toLocaleString("vi-VN")} VND`}</p>
              </nav>
            ))}
          </nav>
          <p style={{ fontSize: "18px", fontWeight: "700" }}>
            Tổng: {`${allCart?.total_amount?.toLocaleString("vi-VN")} VND`}
          </p>
        </nav>
      </div>
      <nav className="w-100 mt-2 d-flex align-items-center justify-content-end gap-2">
        <Button
          style={{
            padding: "8px 12px",
            width: "max-content",
            background: "#0167f3",
          }}
          onClick={() => {
            handleClose();
          }}
        >
          Đóng
        </Button>
      </nav>
    </Dialog>
  );
}

export default DialogSuccess;
