import axios from "axios";
export const getAxios = async (api, setData) => {
  const rfLocal = localStorage.getItem("access");
  try {
    const res = await axios.get(api, {
      headers: {
        Authorization: `Bearer ${rfLocal}`,
      },
    });
    setData(res.data);
  } catch (err) {}
};
export const getAxiosResults = async (
  api,
  setData,
  setData1,
  perPage,
  index
) => {
  const rfLocal = localStorage.getItem("access");
  try {
    const res = await axios.get(
      `${api}?number_per_page=${perPage}&page=${index}`,
      {
        headers: {
          Authorization: `Bearer ${rfLocal}`,
        },
      }
    );
    setData(res.data.results);
    setData1(res.data);
  } catch (err) {}
};
export const getAxiosResultsGroup = async (
  api,
  setData,
  setData1,
  perPage,
  index,
  id_account
) => {
  const rfLocal = localStorage.getItem("access");
  try {
    const res = await axios.get(
      `${api}?number_per_page=${perPage}&page=${index}&id_account=${id_account}`,
      {
        headers: {
          Authorization: `Bearer ${rfLocal}`,
        },
      }
    );
    setData(res.data.results);
    setData1(res.data);
  } catch (err) {}
};
export const getAxiosResultsCategory = async (
  api,
  setData,
  setData1,
  perPage,
  index,
  id_category
) => {
  const rfLocal = localStorage.getItem("access");
  try {
    const res = await axios.get(
      `${api}?number_per_page=${perPage}&page=${index}&id_category=${id_category}`,
      {
        headers: {
          Authorization: `Bearer ${rfLocal}`,
        },
      }
    );
    setData(res.data.results);
    setData1(res.data);
  } catch (err) {}
};
export const getAxiosCommets = async (api, setData, setData1, index) => {
  const rfLocal = localStorage.getItem("access");
  try {
    const res = await axios.get(`${api}?page=${index}`, {
      headers: {
        Authorization: `Bearer ${rfLocal}`,
      },
    });
    setData(res.data.results);
    setData1(res.data);
  } catch (err) {}
};
export const getAxiosGroup = async (
  api,
  setData,
  setData1,
  perPage,
  index,
  idAcc
) => {
  const rfLocal = localStorage.getItem("access");
  try {
    const res = await axios.get(
      `${api}?id_account=${idAcc}&number_per_page=${perPage}&page=${index}`,
      {
        headers: {
          Authorization: `Bearer ${rfLocal}`,
        },
      }
    );
    setData(res.data.results);
    setData1(res.data);
  } catch (err) {}
};

export const getAxiosDebugManual = async (
  api,
  setData,
  setData1,
  perPage,
  index,
  status
) => {
  const rfLocal = localStorage.getItem("access");
  try {
    const res = await axios.get(
      `${api}?number_per_page=${perPage}&page=${index}&status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${rfLocal}`,
        },
      }
    );
    setData(res.data.results);
    setData1(res.data);
  } catch (err) {}
};

export const getAxiosMarket = async (
  api,
  setData,
  setData1,
  perPage,
  index,
  id_account
) => {
  const rfLocal = localStorage.getItem("access");
  try {
    const res = await axios.get(
      `${api}?number_per_page=${perPage}&page=${index}${
        id_account === "list" ? "" : `&id_account=${id_account}`
      }`,
      {
        headers: {
          Authorization: `Bearer ${rfLocal}`,
        },
      }
    );
    setData(res.data.results);
    setData1(res.data);
  } catch (err) {}
};

export const getAxiosStatis = async (api, setData, startTime, endTime) => {
  const rfLocal = localStorage.getItem("access");
  try {
    const res = await axios.get(
      `${api}?start_date=${startTime}&end_date=${endTime}`,
      {
        headers: {
          Authorization: `Bearer ${rfLocal}`,
        },
      }
    );
    setData(res.data);
  } catch (err) {}
};
export const getNoheaders = async (api, setData) => {
  try {
    const res = await axios.get(api);
    setData(res.data);
  } catch (err) {}
};
export const getAxiosFilter = async (
  api,
  setData,
  setData1,
  perPage,
  index,
  key,
  start,
  end,
  epl,
  status
) => {
  const rfLocal = localStorage.getItem("access");
  try {
    const res = await axios.get(
      `${api}?number_per_page=${perPage}&page=${index}&key=${key}&start_date=${start}&end_date=${end}&id_employee=${epl}&status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${rfLocal}`,
        },
      }
    );
    setData(res.data.results);
    setData1(res.data);
  } catch (err) {}
};
