import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaExclamationTriangle } from "react-icons/fa";

export function updateTitle(newTitle) {
  document.getElementById("dynamicTitle").innerText = newTitle;
}
export function updateFavicon(iconUrl) {
  // Tìm thẻ link có chứa rel="icon"
  var link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/png";
  link.rel = "icon";
  link.href = iconUrl;

  // Nếu thẻ link chưa tồn tại thì thêm nó vào thẻ <head>
  if (!document.querySelector("link[rel*='icon']")) {
    document.getElementsByTagName("head")[0].appendChild(link);
  }
}
export const statusLineCheck = (rowData, status) => {
  return (
    <div className="modal-status-line-check">
      {rowData?.[status] === 2 ? (
        <></>
      ) : rowData?.[status] === 1 ? (
        <AiOutlineCheckCircle
          size={20}
          style={{ color: "green" }}
          title="Thành công"
        />
      ) : rowData?.[status] === 3 ? (
        <FaExclamationTriangle
          size={20}
          style={{ color: "#ff7809" }}
          title="Chờ xét duyệt vào nhóm"
        />
      ) : (
        <AiOutlineCloseCircle
          size={20}
          style={{ color: "red" }}
          title="Thất bại"
        />
      )}
    </div>
  );
};

export const statusMarket = (rowData) => {
  return (
    <div className="modal-status-line-check">
      {rowData.status === 0 ? (
        <AiOutlineCloseCircle
          size={20}
          style={{ color: "red" }}
          title="Thất bại"
        />
      ) : rowData.status === 1 ? (
        <AiOutlineCheckCircle
          size={20}
          style={{ color: "green" }}
          title="Thành công"
        />
      ) : rowData.status === 2 ? (
        <FaExclamationTriangle
          size={20}
          style={{ color: "#ff7809" }}
          title={rowData.status_indicator}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export const statusRunTools = (rowData, t) => {
  return (
    <p
      style={{
        color:
          rowData?.status === 0
            ? "red"
            : rowData?.status === 1
              ? "#573613"
              : rowData?.status === 2
                ? "blue"
                : "",
      }}
    >
      {rowData?.status === null
        ? ""
        : rowData?.status === 0
          ? t("Tạm dừng")
          : rowData?.status === 1
            ? t("Đang chạy")
            : rowData?.status === 2
              ? t("Hoàn thành")
              : ""}
    </p>
  );
};

