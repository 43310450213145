import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES, API_ROUTES_ZALO } from "../../../api";
import { TableAccountClientAddFr } from "./TableAccountClientAddFr";
import { TableAddFrOfAccount } from "./TableAddFrOfAccount";
import "primeicons/primeicons.css";
import { RiExpandDiagonal2Line } from "react-icons/ri";
import { useAuth } from "../../../AuthContext";
import { TableAccountClientSentFr } from "./TableAccountClientSentFr";
import { TableSentFrOfAccount } from "./TableSentFrOfAccount";
import { use } from "i18next";
export const ModalScanSentFr = ({
  setRefresh,
  refresh,
  dataZalo,
  setDataZalo,
  checkboxes,
  setCheckboxes,
  scriptClient,
  setScriptClient,
  listFr,
  setListFr,
  toast,
  openSentFriend,
  setOpenSentFriend,
}) => {
  const headers = useHeaders();
  const zaloAccount = API_URL + API_ROUTES.GET_ACC_FB;
  const scanFriendRequest = API_URL + API_ROUTES_ZALO.SCAN_SENT_FRIEND_REQUEST;
  const resultScan = API_URL + API_ROUTES_ZALO.LIST_SENT_FRIEND_REQUEST_RESULT;
  const deleteAddFriend = API_URL + API_ROUTES_ZALO.RECALL_SENT_FRIEND_REQUEST;
  const resultDeleteAddFriend =
    API_URL + API_ROUTES_ZALO.RECALL_SENT_FRIEND_REQUEST_RESULT;
  const { setUserLoggedIn } = useAuth();
  const [allAddFriend, setAllAddFriend] = useState([]);
  const [taskId, setTaskId] = useState([]);
  const [dataScanRs, setDataScanRs] = useState("");
  const [statusRun, setStatusRun] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [statusScan, setStatusScan] = useState("");
  const [rejectFriend, setRejectFriend] = useState("");
  const [getTaskDele, setGetTaskDele] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [typeDelete, setTypeDelete] = useState("");
  const [rowDataDelete, setRowDataDelete] = useState([]);
  const [userIdDeleted, setUserIdDeleted] = useState([]);

  const intervalRef = useRef();
  const handleExit = () => {
    setDataScanRs([]);
    setSelectedItems([]);
    setSelectedRows([]);
    setOpenSentFriend(false);
  };
  const getScanAddFriend = async () => {
    if (selectedItems?.length === 0) {
      return;
    }
    if (selectedItems[0]?.length === 0) {
      return;
    }
    const body = { id_account: selectedItems?.find((item) => item.id)?.id };
    try {
      const res = await axios.post(scanFriendRequest, body, headers);
      setTaskId(res.data.id_task);
      setStatusRun(true);
      axios
        .get(zaloAccount, headers)
        .then(function (response) {
          setDataZalo(response.data);
        })
        .catch(function (error) {
          if (error?.response?.status === 401) {
            setUserLoggedIn(false);
          }
        });
    } catch (err) {}
  };
  const checkResultScan = async () => {
    if (taskId?.length === 0) {
      return;
    }
    const body = { id_task: taskId };
    try {
      const res = await axios.post(resultScan, body, headers);
      setStatusScan(res.data);
      setDataScanRs(Object.values(res.data.data));
    } catch (err) {
      toast.error("Đã xảy ra lỗi khi quét lời mời kết bạn");
      setTaskId([]);
      setStatusRun(false);
      setStatusScan((pre) => ({ ...pre, status: "" }));
      return;
    }
  };
  useEffect(() => {
    if (statusScan?.status === "SUCCESS" || statusScan?.status === "FAILURE") {
      setTaskId([]);
      setStatusRun(false);
      setStatusScan((pre) => ({ ...pre, status: "" }));
      return;
    }
    intervalRef.current = setInterval(() => {
      checkResultScan();
    }, 3000);
    return () => clearInterval(intervalRef.current);
  }, [taskId]);
  useEffect(() => {
    if (statusScan?.status === "SUCCESS" || statusScan?.status === "FAILURE") {
      setStatusRun(false);
      clearInterval(intervalRef.current);
      setRefresh(!refresh);
    }
  }, [statusScan]);
  const getDeleteAddFriend = async (rowData, type) => {
    const fids =
      type === "one"
        ? [rowData.userId]
        : selectedRows?.map((item) => item.userId);
    setTypeDelete(type);
    setRowDataDelete(rowData);
    const body = {
      id_account: selectedItems[0]?.id,
      fids: fids,
    };

    try {
      const res = await axios.post(deleteAddFriend, body, headers);
      setGetTaskDele(res.data);
      getRejectAddFriend(res.data.id_task, rowData, type);
      toast.info("Đang xoá lời mời kết bạn");
    } catch (err) {}
  };
  const getRejectAddFriend = async (taskidRj, rowData, type) => {
    const body = { id_task: taskidRj };
    try {
      const res = await axios.post(resultDeleteAddFriend, body, headers);
      setUserIdDeleted(res.data.data);
      if (res.data.status === "SUCCESS") {
        toast.success("Xóa lời mời kết bạn thành công");
        if (type === "one") {
          setDataScanRs(
            dataScanRs.filter((item) => item.userId !== rowData.userId)
          );
          setGetTaskDele([]);
          setRowDataDelete([]);
        } else if (type === "all") {
          const selectedUserIds = res.data.data.map((item) => item.uid);
          const filteredDataScanRs = dataScanRs.filter(
            (item) => !selectedUserIds.includes(item.userId)
          );
          setDataScanRs(filteredDataScanRs);
          setGetTaskDele([]);
          setSelectedRows([]);
        }
      } else if (res.data && res.data.status === "FAILURE") {
        toast.error("Xóa lời mời kết bạn thất bại");
      }
      setRejectFriend(res.data ? res.data.status : "UNKNOWN");
    } catch (err) {
      setRejectFriend("FAILURE");
      toast.error("Đã xảy ra lỗi khi xoá lời mời kết bạn");
    }
  };
  useEffect(() => {
    if (getTaskDele.id_task) {
      const interval = setInterval(() => {
        getRejectAddFriend(getTaskDele.id_task, rowDataDelete, typeDelete);
      }, 3000);
      if (rejectFriend === "SUCCESS" || rejectFriend === "FAILURE") {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [rejectFriend, getTaskDele, rowDataDelete, typeDelete]);
  return (
    <Dialog
      header={
        <div
          style={{ position: "relative" }}
          className="d-flex align-items-center gap-2"
        >
          <span>Lời mời Kết bạn đã gửi</span>
          {selectedRows.length > 0 && (
            <Button
              className=" button-red radius"
              severity="danger"
              style={{
                padding: "4px 6px",
                fontSize: "12px",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: "0",
                right: "0",
              }}
              onClick={() => getDeleteAddFriend(selectedRows, "all")}
            >
              Thu hồi lời mời đã chọn
            </Button>
          )}
        </div>
      }
      visible={openSentFriend}
      style={{ position: "relative", width: "71vw" }}
      onHide={handleExit}
      className="overFlowJoinGr"
      footer={
        <div className="d-flex justify-content-end gap-2 mt-2">
          <Button
            onClick={handleExit}
            className="d-flex justify-content-center button-red"
          >
            Thoát
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="d-flex gap-1 w-100 h-100">
          <div style={{ width: "40%" }} className="h-100">
            <TableAccountClientSentFr
              statusRun={statusRun}
              getScanAddFriend={getScanAddFriend}
              taskId={taskId}
              setAllAddFriend={setAllAddFriend}
              allAddFriend={allAddFriend}
              setRefresh={setRefresh}
              refresh={refresh}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              dataZalo={dataZalo}
              checkboxes={checkboxes}
              setCheckboxes={setCheckboxes}
            />
          </div>
          <div style={{ width: "60%" }} className=" h-100">
            <TableSentFrOfAccount
              getDeleteAddFriend={getDeleteAddFriend}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setSelectedItems={setSelectedItems}
              getScanAddFriend={getScanAddFriend}
              statusRun={statusRun}
              dataScanRs={dataScanRs}
              setDataScanRs={setDataScanRs}
              setAllAddFriend={setAllAddFriend}
              allAddFriend={allAddFriend}
              refresh={refresh}
              setRefresh={setRefresh}
              selectedItems={selectedItems}
              scriptClient={scriptClient}
              setScriptClient={setScriptClient}
              listFr={listFr}
              setListFr={setListFr}
              toast={toast}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
