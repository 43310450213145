import React, { useEffect } from "react";
import Navigate from "../container/Navigate";
import { Table } from "react-bootstrap";
import { Password } from "primereact/password";
import { TbPasswordMobilePhone } from "react-icons/tb";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES } from "../../api";
import { Footer } from "./Footer";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { ManageSep } from "../container/managesep/ManagerSep";
import { ModalEditSep } from "../container/managesep/ModalEditSep";
import { useHeaders } from "../../const/headers";
import { InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { updateTitle } from "../../const/updateTitle";
import { ResetPassSep } from "../container/managesep/ResetPassSep";
import { GoDotFill } from "react-icons/go";
import FilterSep from "../container/managesep/FilterSep";
import { ExportCsv } from "../container/managesep/ExportCsv";
import { Dialog } from "primereact/dialog";
export const Sep = () => {
  updateTitle("Chốt Care - Quản Lý Người Dùng");
  const headers = useHeaders();
  const changePassApi = API_URL + API_ROUTES.CHANGE_PASS;
  const getAllAcc = API_URL + API_ROUTES.GET_ALL_ACCOUNT;
  const deleteAcc = API_URL + API_ROUTES.DELETE_ACCOUNT_MANAGER;
  const [passParent, setPassParent] = useState("");
  const [newPass, setNewPass] = useState("");
  const [donePass, setDonePass] = useState("");
  const [heightTop, setHeightTop] = useState("");
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const { t } = useTranslation();
  const [listEmpl, setListEmpl] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [hiddenTable, setHiddenTable] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [getItemEdit, setGetItemEdit] = useState([]);
  const [search, SetSearch] = useState("");
  const [openCsv, setOpenCsv] = useState(false);
  const [statusSort, setStatusSort] = useState(false);
  const [sortSep, setSortSep] = useState(null);
  const searchChange = (event) => {
    const { value } = event.target;
    SetSearch(value);
  };
  function filterByName(listEmpl, search) {
    return listEmpl?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
      )
    );
  }
  const handleOpenCsv = () => {
    setOpenCsv(true);
  };
  function filterElements(inputList) {
    let outputList = [];
    for (let item of inputList) {
      if (
        (item.manager && inputList.some((i) => i.id === item.manager)) ||
        item.manager === null
      ) {
        outputList.push(item);
      }
    }
    return outputList;
  }
  const filteredList = filterElements(listEmpl);

  useEffect(() => {
    if (statusSort) {
      setSortSep(
        filterByName(filteredList, search).sort(
          (a, b) => new Date(a.expiration_date) - new Date(b.expiration_date)
        )
      );
    } else {
      setSortSep(
        filterByName(filteredList, search).sort(
          (a, b) => new Date(b.expiration_date) - new Date(a.expiration_date)
        )
      );
    }
  }, [statusSort, listEmpl]);
  const changeNewPass = (e) => {
    setNewPass(e?.target?.value);
  };
  const changeDonePass = (e) => {
    setDonePass(e?.target?.value);
  };
  const changeParentPass = (e) => {
    setPassParent(e?.target?.value);
  };
  const deleteAccount = async (id) => {
    const confirm = window.confirm(
      "Bạn có chắc chắn muốn xóa tài khoản này không"
    );
    if (confirm) {
      const body = {
        id_manager: id,
      };
      try {
        const res = await axios.post(deleteAcc, body, headers);
        setRefresh(!refresh);
      } catch (err) { }
    }
  };
  const changePass = async () => {
    if (newPass === donePass) {
      const data = {
        old_password: passParent,
        new_password: newPass,
      };
      try {
        const response = await axios.post(changePassApi, data, headers);
        toast.success("Đổi mật khẩu Thành Công");
      } catch (error) {
        alert("Đổi mật khẩu thất bại vui lòng kiểm tra lại thông tin");
      }
    } else {
      alert("Vui lòng nhập chính xác mật khẩu");
    }
  };
  useEffect(() => {
    setHeightTop(document?.getElementById("heigt-top")?.offsetHeight);
    getDataEmpl();
  }, [refresh]);
  const getDataEmpl = async () => {
    try {
      const response = await axios.get(getAllAcc, headers);
      setListEmpl(response.data);
    } catch (err) { }
  };
  const handleExportClick = () => { };
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="d-flex h-100 w-100 mgtop-respon-47">
          <div
            className="overflow-auto w-100 h-100 d-flex flex-column"
            style={{
              borderRight: "0.5px solid",
              borderLeft: "0.5px solid",
            }}
          >
            <div
              id="heigt-top"
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid",
              }}
              className="thead-fixed justify-content-between"
            >
              {/* <h3
                className="manage-domain-respon"
                style={{
                  padding: "20px",
                  margin: "0",
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#545454",
                }}
              >
                Tài khoản
              </h3> */}
              <ExportCsv
                setOpenCsv={setOpenCsv}
                openCsv={openCsv}
                listEmpl={listEmpl}
              />
              <ManageSep
                setVisible={setVisible}
                visible={visible}
                setRefresh={setRefresh}
                refresh={refresh}
                listEmpl={listEmpl}
              />
              <FilterSep
                setVisible2={setVisible2}
                visible2={visible2}
                setRefresh={setRefresh}
                refresh={refresh}
                setSortSep={setSortSep}
                sortSep={sortSep}
              />
              <div
                className="d-flex gap-2 pb-2 pt-2"
                style={{
                  overflow: "auto",
                  margin: "0 5px"
                }}
              >
                <Button
                  className=" button-blue d-flex justify-content-center radius"
                  style={{ width: "180px" }}
                  onClick={() => setRefresh(!refresh)}
                >
                  {t("Làm mới")}
                </Button>
                <Button
                  className=" button-blue d-flex justify-content-center radius"
                  style={{ width: "180px" }}
                  onClick={() => setVisible2(true)}
                >
                  {t("Lọc")}
                </Button>
                <Button
                  className=" button-blue d-flex justify-content-center radius"
                  style={{ width: "180px" }}
                  onClick={() => {
                    handleOpenCsv();
                  }}
                >
                  {t("Xuất file Excel")}
                </Button>
                <InputGroup
                  className="responsive-width-acc"
                  style={{
                    width: "auto",
                    borderRadius: "0",
                  }}
                >
                  <Form.Control
                    placeholder={t("Tìm kiếm nhanh")}
                    aria-describedby="basic-addon2"
                    value={search}
                    style={{
                      height: "100%",
                      borderRadius: "0",
                    }}
                    onChange={searchChange}
                  />
                </InputGroup>
                <Button
                  className="button-blue d-flex justify-content-center radius"
                  style={{ width: "180px" }}
                  onClick={() => setVisible(true)}
                >
                  {t("Thêm tài khoản")}
                </Button>
                <Button
                  className="button-blue radius"
                  onClick={() => setVisible3(true)}
                >
                  {t("Chỉnh sửa mật khẩu")}
                </Button>
                <Dialog
                  header={t("Chỉnh sửa mật khẩu")}
                  visible={visible3}
                  style={{ width: "30vw" }}
                  onHide={() => setVisible3(false)}
                >
                  <div
                    className="container-table-button-mange add-jobs-flex"
                    style={{
                      padding: "30px",
                      background: "#f9f9f9",
                      justifyContent: "center",
                    }}
                  >
                    <div className="contair-add-jobs-left d-flex flex-column gap-4 align-items-center w-100">
                      <div className="d-flex flex-column align-items-start w-100">
                        <p
                          style={{
                            marginBottom: "5px",
                            fontSize: "12px",
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          {t("Mật khẩu hiện tại")}
                        </p>
                        <Password
                          placeholder={t("placeholder")}
                          className="inputPass"
                          style={{ width: "100%", fontSize: "10px" }}
                          value={passParent}
                          onChange={(e) => changeParentPass(e)}
                          toggleMask
                          feedback={t("")}
                          promptLabel={t("")}
                          type="password"
                        />
                      </div>
                      <div className="d-flex flex-column align-items-start w-100">
                        <p
                          style={{
                            marginBottom: "5px",
                            fontSize: "12px",
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          {t("Mật khẩu mới")}
                        </p>
                        <Password
                          className="inputPass"
                          style={{ width: "100%", fontSize: "10px" }}
                          value={newPass}
                          onChange={(e) => changeNewPass(e)}
                          toggleMask
                          showMask={false}
                          feedback={t("")}
                          promptLabel={t("")}
                          placeholder={t("placeholder")}
                          type="password"
                        />
                      </div>
                      <div className="d-flex flex-column align-items-start w-100">
                        <p
                          style={{
                            marginBottom: "5px",
                            fontSize: "12px",
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          {t("Nhập lại mật khẩu mới")}
                        </p>
                        <Password
                          className="inputPass"
                          style={{ width: "100%", fontSize: "10px" }}
                          value={donePass}
                          onChange={(e) => changeDonePass(e)}
                          toggleMask
                          feedback={t("")}
                          promptLabel={t("")}
                          placeholder={t("placeholder")}
                          type="password"
                        />
                      </div>
                      <Button
                        className="button-blue radius"
                        onClick={() => changePass()}
                      >
                        {t("Cập nhật")}
                      </Button>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
            <div className="overflow-auto respon-h40">
              <Table striped bordered hover>
                <thead className="thead-fixed">
                  <tr style={{ borderTop: "0", textAlign: "center" }}>
                    <th>{t("STT")}</th>
                    <th>{t("Tên đăng nhập")} </th>
                    <th>{t("Mật khẩu")}</th>
                    <th>{t("Họ tên")}</th>
                    <th>{t("Số điện thoại")}</th>
                    <th>{t("Mail")}</th>
                    <th>{t("Quyền")}</th>
                    <th>{t("Số tài khoản")}</th>
                    <th>{t("Ngày tạo")}</th>
                    <th>{t("Hạn sử dụng")}</th>
                    <th>{t("Online")}</th>
                    <th>{t("Tùy chọn")}</th>
                  </tr>
                </thead>
                <tbody>
                  {(search.length === 0
                    ? sortSep
                    : filterByName(filteredList, search)
                  )?.map((item, index) => (
                    <tr
                      style={{ cursor: "pointer", verticalAlign: "middle", textAlign: "center" }}
                      onClick={() => setHiddenTable(!hiddenTable)}
                    >
                      <td>{index + 1}</td>
                      <td>{item.username}</td>

                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          wordWrap: "break-word",
                          maxWidth: "100px",
                        }}
                      >
                        {item.raw_password}
                      </td>
                      <td>{item.fullname}</td>
                      <td>{item.phone_number}</td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          wordWrap: "break-word",
                          maxWidth: "100px",
                        }}
                      >
                        {item.mail}
                      </td>
                      <td>
                        {item?.is_admin === true
                          ? "Admin"
                          : item.is_developer === true &&
                            item.is_superuser === false
                            ? "Lập Trình Viên"
                            : item.is_saler === true &&
                              item.is_superuser === false
                              ? "Chăm sóc khách hàng"
                              : item.is_manager === true &&
                                item.is_superuser === false
                                ? "Khách hàng"
                                : item.is_manager === false &&
                                  item.is_superuser === false
                                  ? `Nhân viên - ${item?.manager_name}`
                                  : ""}
                      </td>
                      <td>{item.account_count}</td>
                      <td>
                        {item.created_at?.split("T")[0].split("-")[2]}-
                        {item.created_at?.split("T")[0].split("-")[1]}-
                        {item.created_at?.split("T")[0].split("-")[0]}
                      </td>
                      <td>
                        {item.expiration_date?.split(" ")[0].split("-")[2]}-
                        {item.expiration_date?.split(" ")[0].split("-")[1]}-
                        {item.expiration_date?.split(" ")[0].split("-")[0]}
                      </td>
                      <td className="text-center">
                        {item.is_online === true ? (
                          <GoDotFill size={20} style={{ color: "green" }} />
                        ) : (
                          <GoDotFill size={20} style={{ color: "red" }} />
                        )}
                      </td>
                      <td style={{ display: "flex" }}>
                        <Button
                          onClick={() => {
                            setModalEdit(true);
                            setGetItemEdit(item);
                          }}
                          className="mr-2 mb-2 radius button-blue"
                          severity="info"
                        >
                          {t("Sửa")}
                        </Button>
                        <Button
                          onClick={() => deleteAccount(item?.id)}
                          className="mb-2 radius button-red"
                          severity="danger"
                        >
                          {t("Xoá")}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <ModalEditSep
                setRefresh={setRefresh}
                refresh={refresh}
                getItemEdit={getItemEdit}
                setModalEdit={setModalEdit}
                modalEdit={modalEdit}
              />
            </div>
            <ResetPassSep toast={toast} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
