import React, { useEffect } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Footer } from "./Footer";
import { useHeaders } from "../../const/headers";
import { useNavigate } from "react-router-dom";
import { updateTitle } from "../../const/updateTitle";
import { API_URL, API_ROUTES_NTD } from "../../api";
import { Dropdown } from "primereact/dropdown";
import { ListContentMarket } from "../marketplace/ListContentMarket";
import PreviewMarket from "../marketplace/PreviewMarket";
import { DropdownFilterLocation } from "../marketplace/DropdownFilterLocation";
import { getAxios } from "../../const/getAxios";
import { AccountMarket } from "../marketplace/AccountMarket";
export const Marketplace = () => {
  updateTitle("Chốt Care - Marketplace");
  const navigate = useNavigate();
  const headers = useHeaders();
  const allCateMarket = API_URL + API_ROUTES_NTD.GET_CATE_MARKETPLACE;
  const postIdCateMart = API_URL + API_ROUTES_NTD.POST_ID_GET_CATE_MARKETPLACE;
  const [cateMarket, setCateMarket] = useState([]);
  const [selectCate, setSelectCate] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState(null);
  const [subOptions, setSubOptions] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [filerKey, setFilterKey] = useState("");
  const [title, setTitle] = useState("");
  const [imageURLs, setImageURLs] = useState([]);
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [dataFb, setDataFb] = useState([]);
  const [selecAcc, setSelecAcc] = useState([]);
  const [location, setLocation] = useState([]);
  const [listFile, setListFile] = useState([]);
  const [listImage, setListImage] = useState([]);
  const [messMarket, setMessMarket] = useState([]);
  const [name, setName] = useState("");
  const [dataLink, setDataLink] = useState([]);
  const widthNavi = document.getElementById("navigate-width")?.offsetWidth;
  const widthMarket = document.getElementById("width-show-market")?.offsetWidth;
  const dataCopy = JSON.parse(localStorage.getItem("dataCopy"));
  const postIdMartket = async (item) => {
    const body = { id_category: item };
    try {
      const res = await axios.post(postIdCateMart, body, headers);
      setSubOptions(
        res.data?.map((item) => ({
          name: item.category_detail_name,
          code: item.id,
          category_detail_id: item.category_detail_id,
        }))
      );
    } catch (err) {}
  };
  useEffect(() => {
    if (selectCate?.code === 48) {
      navigate("/vehiclemarket");
      const object = {
        title: title,
        price: price,
        description: description,
        photos: listImage,
      };

      localStorage.setItem("dataFill", JSON.stringify(object));
    }
    if (selectCate?.code === 49) {
      navigate("/housemarket");
      const object = {
        title: title,
        price: price,
        description: description,
        photos: listImage,
      };
      localStorage.setItem("dataFill", JSON.stringify(object));
    }
  }, [selectCate]);
  useEffect(() => {
    getAxios(allCateMarket, setCateMarket);
  }, []);
  const valueDropdown = cateMarket?.map((item) => ({
    name: item.category_name,
    code: item.id,
  }));
  useEffect(() => {
    if (dataLink?.data) {
      setDescription(dataLink?.data?.description?.text);
      setPrice(dataLink?.data?.price);
      setTitle(dataLink?.data?.title);
      setListImage(dataLink?.data?.photos);
    }
  }, [dataLink]);
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container" id="navigate-width">
          <Navigate />
        </div>
        <div
          className="d-flex h-100 gap-2 p-2"
          style={{ width: `calc(100vw - ${widthNavi}px)` }}
        >
          <div
            className="h-100 shadow_market"
            id="width-show-market"
            style={{ width: "20%", overflowY: "auto", overflowX: "hidden" }}
          >
            <AccountMarket
              selecAcc={selecAcc}
              setSelecAcc={setSelecAcc}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
            <ListContentMarket
              dataCopy={dataCopy}
              toast={toast}
              dataLink={dataLink}
              setDataLink={setDataLink}
              name={name}
              setName={setName}
              listImage={listImage}
              setListImage={setListImage}
              setListFile={setListFile}
              listFile={listFile}
              description={description}
              setDescription={setDescription}
              price={price}
              setPrice={setPrice}
              imageURLs={imageURLs}
              setImageURLs={setImageURLs}
              title={title}
              setTitle={setTitle}
            />
            <div className=" mt-2 flex justify-content-center flex-column align-items-center w-100">
              <p className="w-100 mb-2 fs-6">Chọn danh mục</p>
              <Dropdown
                value={selectCate}
                onChange={(e) => {
                  setSelectCate(e.value);
                  setSelectedSubOption(null);
                  postIdMartket(e.value?.code);
                }}
                options={valueDropdown}
                optionLabel="name"
                placeholder="Hạng mục"
                className="w-100 custom-dropdown style_text_dropdown"
              />
              {selectCate && (
                <Dropdown
                  value={selectedSubOption}
                  onChange={(e) => setSelectedSubOption(e.value)}
                  options={subOptions}
                  optionLabel="name"
                  placeholder={`${subOptions[0]?.name}`}
                  className="w-100"
                />
              )}
            </div>
            <DropdownFilterLocation
              messMarket={messMarket}
              setMessMarket={setMessMarket}
              location={location}
              setLocation={setLocation}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              setFilterKey={setFilterKey}
              filerKey={filerKey}
              headers={headers}
            />
          </div>
          <div
            className="h-100 shadow_market d-flex"
            style={{ width: `calc(100% - ${widthMarket}px - 0.5rem)` }}
          >
            <PreviewMarket
              subOptions={subOptions}
              messMarket={messMarket}
              toast={toast}
              name={name}
              listImage={listImage}
              selectedSubOption={selectedSubOption}
              listFile={listFile}
              filerKey={filerKey}
              selecAcc={selecAcc}
              description={description}
              title={title}
              price={price}
              imageURLs={imageURLs}
              location={location}
              setImageURLs={setImageURLs}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
