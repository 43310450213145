import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useAuth } from "../../../AuthContext";
import { useUser } from "../../../contexts/UserContext";
import { API_URL } from "../../../api";

export default function ModalResetPass({
  setOpenResetPass,
  openResetPass,
  getStatus,
}) {
  return (
    <div
      style={{ border: "0" }}
      className="card b-0 flex justify-content-center"
    >
      <Dialog
        header=""
        visible={openResetPass}
        style={{ width: "50vw" }}
        onHide={() => setOpenResetPass(false)}
      >
        <div>
          <h5>{getStatus}</h5>
        </div>
      </Dialog>
    </div>
  );
}
