import React from "react";
import { Dialog } from "primereact/dialog";
import { API_URL } from "../../../../api";
export const ModalDetailsMessToGr = ({
  setModalDetails,
  modalDetails,
  itemResults,
}) => {
  return (
    <Dialog
      header="Trạng thái chi tiết"
      visible={modalDetails}
      style={{ width: "50vw" }}
      onHide={() => setModalDetails(false)}
    >
      <div className="f-flex flex-column gap-2">
        <nav
          style={{
            paddingBottom: "0",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2"
        >
          <h5>Tên nhóm: {itemResults?.name}</h5>
        </nav>
        <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0",
          }}
          className="d-flex gap-2"
        >
          <h6 style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <>
              <p>Trạng thái đăng bài:</p>
              <p>
                {itemResults?.status === 1
                  ? "Thành công"
                  : itemResults?.status === 0
                  ? "Thất bại"
                  : "Tính năng bị chặn"}
              </p>
            </>
          </h6>
        </nav>
        {/* <nav
          style={{
            borderBottom: "1px solid",
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className=""
        >
          <nav
            style={{
              paddingBottom: "0.5rem",
              paddingTop: "0.5rem",
            }}
            className="d-flex gap-2 w-100"
          >
            <p style={{ width: "max-content" }}>Link bài viết:</p>
            <a
              href={itemResults?.id_post}
              target="_blank"
              rel="nofollow noreferrer"
            >
              {itemResults?.id_post
                ? itemResults?.id_post.length > 50
                  ? `${itemResults?.id_post.slice(0, 50)}...`
                  : itemResults.id_post
                : ""}
            </a>
          </nav>
        </nav> */}
        <nav
          style={{
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className=""
        >
          <nav
            style={{
              paddingBottom: "0.5rem",
              paddingTop: "0.5rem",
            }}
            className="d-flex gap-2 w-100"
          >
            <p style={{ width: "max-content" }}>Nội dung tin nhắn:</p>
          </nav>
        </nav>

        <nav
          style={{
            paddingBottom: "0.5rem",
            paddingTop: "0.5rem",
          }}
          className="d-flex gap-2 flex-column"
        >
          <p style={{ width: "fit-content", textAlign: "justify" }}>
            {itemResults?.content}
          </p>

          {itemResults?.images?.length > 0 &&
            itemResults?.images?.map((image, index) => (
              <img
                key={index}
                style={{ width: "100px", height: "100%" }}
                src={`${API_URL}/${image}`}
              />
            ))}
        </nav>
      </div>
    </Dialog>
  );
};
