import React, { useEffect, useRef, memo } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES, WS_URL } from "../../api";
import { Footer } from "./Footer";
import { useHeaders } from "../../const/headers";
import { updateTitle } from "../../const/updateTitle";
import { useAuth } from "../../AuthContext";
import { locale } from "primereact/api";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { ListTablePost } from "../container/article/messageToFriend/ListTablePost";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useUser } from "../../contexts/UserContext";
import { ListTablePostZalo } from "../container/article/postZalo/ListTablePostZalo";
import { ListTableMessToGroup } from "../container/article/messegeToGroup/ListTableMessToGroup";
import { ListTableMessToMember } from "../container/article/messageToMember/ListTableMessToMember";
import { ListTableMessageToPhone } from "../container/article/messageToPhone/ListTableMessageToPhone";
import './style.css'
import { useTranslation } from "react-i18next";

const ManageArticle = () => {
  locale("vi");
  updateTitle("Chốt Care - Chiến dịch tự động");
  const headers = useHeaders();
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const { setUserLoggedIn } = useAuth();
  const [dataFb, setDataFb] = useState();
  const [dataZalo, setDataZalo] = useState();
  const [refresh, setRefresh] = useState(true);
  const stepperRef = useRef(null);
  const isInitialRender = useRef(true);
  const socketRef = useRef(null);
  const { userInfo } = useUser();
  const [renderKey, setRenderKey] = useState(Date.now());
  const access = localStorage.getItem("access");
  const [allCate, setAllCate] = useState([]);
  const [numberRandom, setNumberRandom] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    axios
      .get(getDataFb, headers)
      .then(function (response) {
        setDataFb(response.data);
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          setUserLoggedIn(false);
        }
      });
  }, [refresh]);
  useEffect(() => {
    const client = new W3CWebSocket(
      `wss://${WS_URL}/ws/campaign/${userInfo?.id}/?token=${access}`
    );
    socketRef.current = client;
    client.onerror = function () { };
    client.onopen = function () { };
    client.onmessage = function (message) {
      const parsedData = JSON.parse(message.data);
      if (parsedData.category !== undefined) {
        const newCategory = parsedData.category;
        setAllCate((prevCate) => {
          const index = prevCate.findIndex((cat) => cat.id === newCategory.id);
          if (index !== -1) {
            return prevCate.map((cat) =>
              cat.id === newCategory.id ? newCategory : cat
            );
          } else {
            return [...prevCate, newCategory];
          }
        });
      }
      if (parsedData.result !== undefined) {
        setRenderKey(Date.now());
      }
      if (parsedData.status_category_post_wall !== undefined) {
        setNumberRandom(Math.random());
      }
      if (parsedData.status_category_mess_friend !== undefined) {
        setNumberRandom(Math.random());
      }
      if (parsedData.status_category_mess_phone_number !== undefined) {
        setNumberRandom(Math.random());
      }
      if (parsedData.status_category_mess_group !== undefined) {
        setNumberRandom(Math.random());
      }
      if (parsedData.status_category_mess_mem_group !== undefined) {
        setNumberRandom(Math.random());
      }
    };
    return () => {
      if (client) {
        client.close();
      }
    };
  }, []);
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <Stepper
          ref={stepperRef}
          className="w-100 h-100 flex flex-column steppPannel stepper-wrapper mgtop-respon-47"
        >
          <StepperPanel className="stepper-article h-100 d-flex" header={t("Đăng bài")} style={{ marginleft: "0" }}>
            <ListTablePostZalo
              numberRandom={numberRandom}
              userInfo={userInfo}
              setRenderKey={setRenderKey}
              allCate={allCate}
              setAllCate={setAllCate}
              refresh={refresh}
              renderKey={renderKey}
              isInitialRender={isInitialRender}
              setRefresh={setRefresh}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
          </StepperPanel>
          <StepperPanel className="h-100 d-flex" header={t("Nhắn tin cho bạn bè")}>
            <ListTablePost
              numberRandom={numberRandom}
              setRenderKey={setRenderKey}
              allCate={allCate}
              setAllCate={setAllCate}
              refresh={refresh}
              renderKey={renderKey}
              isInitialRender={isInitialRender}
              setRefresh={setRefresh}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
          </StepperPanel>
          <StepperPanel header={t("Nhắn tin đến số điện thoại")}>
            <ListTableMessageToPhone
              numberRandom={numberRandom}
              userInfo={userInfo}
              setRenderKey={setRenderKey}
              allCate={allCate}
              setAllCate={setAllCate}
              refresh={refresh}
              renderKey={renderKey}
              isInitialRender={isInitialRender}
              setRefresh={setRefresh}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
          </StepperPanel>
          <StepperPanel header={t("Gửi tin nhắn vào nhóm")}>
            <ListTableMessToGroup
              numberRandom={numberRandom}
              userInfo={userInfo}
              setRenderKey={setRenderKey}
              setDataZalo={setDataZalo}
              dataZalo={dataZalo}
              allCate={allCate}
              setAllCate={setAllCate}
              refresh={refresh}
              renderKey={renderKey}
              isInitialRender={isInitialRender}
              setRefresh={setRefresh}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
          </StepperPanel>
          <StepperPanel header={t("Gửi tin nhắn cho thành viên nhóm")}>
            <ListTableMessToMember
              numberRandom={numberRandom}
              userInfo={userInfo}
              setRenderKey={setRenderKey}
              dataZalo={dataZalo}
              setDataZalo={setDataZalo}
              allCate={allCate}
              setAllCate={setAllCate}
              refresh={refresh}
              renderKey={renderKey}
              isInitialRender={isInitialRender}
              setRefresh={setRefresh}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
          </StepperPanel>
        </Stepper>
      </div>
      <Footer />
    </div>
  );
};

export default memo(ManageArticle);
