import { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { SiTicktick } from "react-icons/si";
import axios from "axios";
import { Button } from "primereact/button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { useHeaders } from "../../../const/headers";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
function ModalAddCart({
  rfCart,
  setRfCart,
  openCart,
  setOpenCart,
  forcusProduct,
  session_key,
  iduser,
}) {
  const headers = useHeaders();
  const addToCart = API_URL + API_ROUTES_ZALO.ADD_TO_CART_SHOP;
  const [numberOrders, setNumberOrders] = useState([]);
  const handleNumberOrderChange = (item, value) => {
    const updatedOrders = numberOrders.filter((order) => order.id !== item.id);
    if (value > 0) {
      updatedOrders.push({ id: item.id, value: parseInt(value) });
    }
    setNumberOrders(updatedOrders);
  };
  const handleMinusOrder = (item) => {
    const currentOrder = numberOrders.find((order) => order.id === item.id);
    const currentValue = currentOrder ? currentOrder.value : 0;
    if (currentValue > 0) {
      handleNumberOrderChange(item, currentValue - 1);
    } else {
      toast.error("Số lượng không được nhỏ hơn 0");
    }
  };
  const handlePlusOrder = (item) => {
    const currentOrder = numberOrders.find((order) => order.id === item.id);
    const currentValue = currentOrder ? currentOrder.value : 0;
    if (item.sold_quantity + currentOrder?.value === item.total_quantity) {
      alert("Số lượng bạn chọn đã vượt quá số lượng tồn kho");
    } else {
      handleNumberOrderChange(item, currentValue + 1);
    }
  };
  const handleAddCart = async () => {
    const body = {
      id_employee: Number(iduser),
      session_key: session_key || "",
      options: numberOrders?.map((item) => ({
        id_variant: item.id,
        quantity: item.value,
      })),
    };
    try {
      const res = await axios.post(addToCart, body);
      localStorage.setItem("session_key", res.data.data.session_key);
      setRfCart(!rfCart);
      setOpenCart(false);
      setNumberOrders([]);
    } catch (err) { }
  };
  return (
    <Dialog
      header="Chọn số lượng sản phẩm"
      className="dialog-shop"
      visible={openCart}
      style={{ width: "auto" }}
      onHide={() => {
        if (!openCart) return;
        setOpenCart(false);
      }}
    >
      <ToastContainer />
      <div>
        {forcusProduct[0]?.variants
          ?.filter((item) => item.stock_status !== "Hết hàng")
          ?.map((item, index) => {
            const currentOrder = numberOrders.find(
              (order) => order.id === item.id
            );
            const currentValue = currentOrder ? currentOrder.value : 0;
            return (
              <div className="d-flex justify-content-between gap-4" key={index}>
                <div className="category-container-order cursor-pointer">
                  <p className="category-shop">{`${item.classify}:`}</p>
                  <nav className="d-flex gap-2">
                    {item.options?.map((option, num) => (
                      <p
                        className="category-shop"
                        key={num}
                      >{`${option?.name} - ${option?.value}`}</p>
                    ))}
                  </nav>
                </div>
                <div className="d-flex align-items-center">
                  <p>
                    {`${(currentValue * item.price)?.toLocaleString(
                      "vi-VN"
                    )} VND`}
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <FaMinus
                    cursor="pointer"
                    onClick={() => handleMinusOrder(item)}
                  />
                  <input
                    style={{
                      width: `${currentValue.toString().length * 2}ch`,
                    }}
                    disabled="true"
                    value={currentValue}
                    onChange={(e) =>
                      handleNumberOrderChange(item, e.target.value)
                    }
                  />
                  <FaPlus
                    cursor="pointer"
                    onClick={() => handlePlusOrder(item)}
                  />
                </div>
              </div>
            );
          })}
      </div>
      <nav className="w-100 mt-2 d-flex justify-content-end gap-2">
        <Button
          style={{ padding: "8px 12px", background: "#0167f3" }}
          onClick={handleAddCart}
        >
          Thêm
        </Button>
        <Button
          style={{ padding: "8px 12px", background: "red" }}
          onClick={() => setOpenCart(false)}
        >
          Huỷ
        </Button>
      </nav>
    </Dialog>
  );
}

export default ModalAddCart;
