import React, { useEffect, useRef } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, API_ROUTES, API_ROUTES_ZALO } from "../../api";
import { Footer } from "./Footer";
import { Button } from "primereact/button";
import { useHeaders } from "../../const/headers";
import { InputGroup } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { updateTitle } from "../../const/updateTitle";
import { CreateAccount } from "../container/account/CreateAccount";
import { EditAccount } from "../container/account/EditAccount";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { getAxios } from "../../const/getAxios";
import "./ManageAccount.css";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
  onRowClick,
  rowClassName,
} from "../../const/checkboxPrimeReactjs";
export const ManageAccount = () => {
  updateTitle("Chốt Care - Tài Khoản Zalo");
  const getDataFb = API_URL + API_ROUTES.GET_ACC_FB;
  const deleteFb = API_URL + API_ROUTES.DELETE_ACC_FB;
  const checkAcc = API_URL + API_ROUTES.CHECK_ACC_FB;
  const checkReultsAcc = API_URL + API_ROUTES.CHECK_ACCOUT_RESULTS;
  const checkProxy = API_URL + API_ROUTES_ZALO.CHECK_PROXY;
  const checkReultsProxy = API_URL + API_ROUTES_ZALO.CHECK_RESULTS_PROXY;
  const headers = useHeaders();
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [dataFb, setDataFb] = useState([]);
  const [search, SetSearch] = useState("");
  const [showData, setShowData] = useState(false);
  const [selectedItemEdit, setSelectedItemEdit] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [selectedUIDStatus, setSelectedUIDStatus] = useState([]);
  const [selectedUIDProxy, setSelectedUIDProxy] = useState([]);
  const [rfDataFb, setRfDataFb] = useState(false);
  const [taskId, setTaskId] = useState([]);
  const [taskIdProxy, setTaskIdProxy] = useState([]);
  const [dataRsAcc, setDataRsAcc] = useState("");
  const [dataRsProxy, setDataRsProxy] = useState("");
  const intervalRef = useRef();
  const { t } = useTranslation();
  const changeSearch = (event) => {
    const { value } = event.target;
    SetSearch(value);
  };
  const deleteSecletAccount = async () => {
    if (listUidCheck.length <= 0) {
      alert(t("Bạn phải chọn ít nhất 1 tài khoản để xóa"));
      return;
    }
    const confirm = window.confirm(
      t(
        "Lưu ý: Khi xoá tài khoản ra khỏi hệ thống. Tất cả các chiến dịch, kịch bản, nhãn khách hàng..., Dữ liệu liên quan đến tài khoản sẽ bị mất"
      )
    );
    if (confirm) {
      try {
        const body = { ids: listUidCheck.map(Number) };
        await axios.post(deleteFb, body, headers);
        setRefresh(!refresh);
      } catch (error) {
        toast.error(t("Xóa không thành công"));
      }
    }
  };
  const deleteOneAccount = async (item) => {
    const confirm = window.confirm(
      t(
        "Lưu ý: Khi xoá tài khoản ra khỏi hệ thống. Tất cả các chiến dịch, kịch bản, nhãn khách hàng..., Dữ liệu liên quan đến tài khoản sẽ bị mất"
      )
    );
    if (confirm) {
      try {
        const body = { ids: [item.id] };
        const response = await axios.post(deleteFb, body, headers);
        setRefresh(!refresh);
      } catch (error) {
        toast.error(t("Xóa không thành công"));
      }
    }
  };
  const listUidCheck = selectedItem?.map((item) => item.id);
  const checkAccount = async () => {
    if (listUidCheck.length <= 0) {
      alert(t("Bạn phải chọn ít nhất 1 tài khoản để kiểm tra"));
    } else {
      setSelectedUIDStatus(listUidCheck);
      try {
        const response = await axios.post(
          checkAcc,
          { id_accounts: listUidCheck.map(Number) },
          headers
        );
        setTaskId(response.data.id_task);
        return response.data.status;
      } catch (error) {
        alert(error?.response?.data?.error);
        setRfDataFb(!rfDataFb);
        return null;
      }
    }
  };
  const checkResultsAccount = async () => {
    if (taskId?.length === 0) {
      return;
    }
    const body = { id_task: taskId };
    try {
      const res = await axios.post(checkReultsAcc, body, headers);
      setDataRsAcc(res.data);
      setRfDataFb(!rfDataFb);
    } catch (err) {
      setSelectedUIDStatus([]);
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      checkResultsAccount();
    }, 3000);

    return () => clearInterval(intervalRef.current);
  }, [taskId]);

  useEffect(() => {
    if (dataRsAcc?.status === "SUCCESS") {
      setSelectedUIDStatus([]);
      setRfDataFb(!rfDataFb);
      clearInterval(intervalRef.current);
    }
  }, [dataRsAcc]);
  useEffect(() => {
    getAxios(getDataFb, setDataFb);
  }, [refresh, rfDataFb]);

  // check proxy
  const listUidCheckProxy = selectedItem?.map((item) => item.proxy?.id);
  const checkProxyAccount = async () => {
    if (listUidCheckProxy.length <= 0) {
      alert(t("Bạn phải chọn ít nhất 1 proxy để kiểm tra"));
    } else {
      setSelectedUIDProxy(listUidCheckProxy);
    }
    const body = {
      id_proxies: listUidCheckProxy.map(Number),
    };
    try {
      const response = await axios.post(checkProxy, body, headers);
      setTaskIdProxy(response.data.id_task);
      return response.data.status;
    } catch (error) {
      setSelectedUIDProxy([]);
      return null;
    }
  };
  const checkResultsProxy = async () => {
    if (taskIdProxy?.length === 0) {
      return;
    }
    const body = { id_task: taskIdProxy };
    try {
      const res = await axios.post(checkReultsProxy, body, headers);
      setDataRsProxy(res.data);
      setRfDataFb(!rfDataFb);
    } catch (err) {
      setDataRsProxy(err.response.data);
      setSelectedUIDProxy([]);
    }
  };
  useEffect(() => {
    if (taskIdProxy?.length === 0) return;
    intervalRef.current = setInterval(() => {
      checkResultsProxy();
    }, 3000);

    return () => clearInterval(intervalRef.current);
  }, [taskIdProxy]);
  useEffect(() => {
    if (
      dataRsProxy?.status === "SUCCESS" ||
      dataRsProxy?.status === "FAILURE"
    ) {
      setSelectedUIDProxy([]);
      setDataRsProxy([]);
      setTaskIdProxy([]);
      setRfDataFb(!rfDataFb);
      clearInterval(intervalRef.current);
    }
  }, [dataRsProxy]);

  const handleEdit = (item) => {
    setShowData(true);
    setSelectedItemEdit(item);
  };
  const getDataAllFr = () => { };
  const statusBodyTemplate = (item) => (
    <span
      style={{
        color: selectedUIDStatus.includes(item?.id)
          ? "#000"
          : item?.checkpoint === false && item?.proxy?.status === true
            ? "blue"
            : "red",
      }}
    >
      {t(
        selectedUIDStatus.includes(item?.id)
          ? "Đang kiểm tra"
          : item?.checkpoint === false && item?.proxy?.status === true
            ? "Hoạt động"
            : item?.checkpoint === null
              ? ""
              : "Không hoạt động"
      )}
    </span>
  );
  const statusBodyProxy = (item) => {
    return (
      <span
        style={{
          color: selectedUIDProxy.includes(item?.proxy?.id)
            ? "#000"
            : item?.proxy?.status === true
              ? "blue"
              : "red",
        }}
      >
        {t(
          selectedUIDProxy.includes(item?.proxy?.id)
            ? "Đang kiểm tra"
            : item?.proxy?.status === false
              ? "Không hoạt động"
              : "Hoạt động"
        )}
      </span>
    );
  };
  const statusProxy = (item) => (
    <span
      style={{
        color: item.proxy?.status ? "blue" : "red",
      }}
    >
      {t(item.proxy?.status ? "Hoạt động" : "Không hoạt động")}
    </span>
  );
  const proxy = (item) => {
    return <span>{item.proxy?.proxy}</span>;
  };

  const buttonTemplate = (item) => (
    <td className="btnOption-account">
      <Button
        onClick={() => handleEdit(item)}
        className="button-blue radius"
        style={{
          padding: "0.5rem 0.75rem",
        }}
      >
        {t("Sửa")}
      </Button>

      <Button
        className="button-red radius"
        style={{
          padding: "0.5rem 0.75rem",
        }}
        onClick={() => {
          deleteOneAccount(item);
        }}
      >
        {t("Xoá")}
      </Button>
    </td>
  );
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  const nameAndAvt = (rowData) => {
    return (
      <div className="d-flex gap-2 align-items-center nameAccount">
        <img
          className="rounded-circle"
          style={{ width: "20px", height: "20px" }}
          src={rowData?.avatar}
        />
        <p>{rowData?.name}</p>
      </div>
    );
  };
  function filterByName(filterDataFb, searchKey) {
    return filterDataFb?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchKey.toLowerCase())
      )
    );
  }
  const resultPro = filterByName(dataFb, search);

  return (
    <div className="d-flex flex-column manaaccount-wrapper " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container">
          <Navigate />
        </div>
        <div className="d-flex h-100 w-100 mgtop-respon-47">
          <div
            className="h-100 d-flex flex-column"
            style={{
              width: "100%",
              borderRight: "0.5px solid",
              borderLeft: "0.5px solid",
            }}
          >
            <div className="d-flex gap-2 w-100 justify-content-between p-2">
              <Button
                style={{ width: "100%" }}
                onClick={() => setModalShow(true)}
                className="button-blue d-flex justify-content-center radius btnAct-ManaAccount"
              >
                {t("Thêm tài khoản Zalo")}
              </Button>
              <Button
                style={{
                  width: "100%",
                }}
                className="button-blue d-flex justify-content-center radius btnAct-ManaAccount"
                onClick={() => {
                  checkAccount();
                }}
                disabled={
                  selectedUIDStatus?.length !== 0 ||
                    selectedUIDProxy?.length !== 0
                    ? true
                    : false
                }
              >
                {t("Kiểm tra tài khoản")}
              </Button>
              <Button
                style={{
                  width: "100%",
                }}
                className="button-blue d-flex justify-content-center radius btnAct-ManaAccount"
                onClick={() => {
                  checkProxyAccount();
                }}
                disabled={
                  selectedUIDStatus?.length !== 0 ||
                    selectedUIDProxy?.length !== 0
                    ? true
                    : false
                }
              >
                {t("Kiểm tra proxy")}
              </Button>
              <Button
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  deleteSecletAccount();
                }}
                className="button-red d-flex justify-content-center radius btnAct-ManaAccount"
              >
                {t("Xoá")}
              </Button>
              <InputGroup
                className="radius btnAct-ManaAccount"
                style={{
                  width: "100%",
                }}
              >
                <Form.Control
                  placeholder={t("Tìm kiếm nhanh")}
                  aria-describedby="basic-addon2"
                  value={search}
                  onChange={changeSearch}
                  style={{
                    borderRadius: "0",
                    textAlign: "center",
                  }}
                />
              </InputGroup>
            </div>
            <div
              className="m-2 h-100"
              style={{ border: "1px solid", overflow: "auto" }}
            >
              <DataTable
                size="small"
                value={resultPro || dataFb}
                className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect"
                tableStyle={{ minWidth: "100%" }}
                rowClassName={(rowData) => rowClassName(rowData, selectedItem)}
                onRowClick={(e) =>
                  onRowClick(e.data, setSelectedItem, selectedItem)
                }
              >
                <Column
                  header={headerCheckbox(
                    selectedItem,
                    resultPro || dataFb,
                    toggleSelectAll,
                    setSelectedItem
                  )}
                  body={(rowData) =>
                    bodyCheckbox(
                      rowData,
                      selectedItem,
                      setSelectedItem,
                      getDataAllFr
                    )
                  }
                  className="checkboxRow"
                />
                <Column className="sttAccount" body={indexTemplate} header={t("STT")}></Column>
                {/* <Column field="uid" header="UID" sortable></Column> */}
                <Column
                  body={(rowData) => nameAndAvt(rowData)}
                  field="name"
                  header={t("Tên tài khoản")}
                  sortable
                  className=""
                ></Column>
                <Column
                  body={proxy}
                  header={t("Proxy")}
                  field="proxy"
                  sortable
                  className="rowProxy"
                ></Column>
                <Column field="note" header={t("Ghi chú")} sortable></Column>
                <Column
                  body={statusBodyTemplate}
                  header={t("Trạng thái tài khoản")}
                  field="checkpoint"
                  sortable
                  className="statusAccount"
                ></Column>
                <Column
                  body={statusBodyProxy}
                  header={t("Trạng thái Proxy")}
                  field="proxy"
                  sortable
                  className="statusProxy"
                ></Column>
                <Column body={buttonTemplate} header={t("Tuỳ chọn")}></Column>
              </DataTable>
              <CreateAccount
                setDataFb={setDataFb}
                refresh={refresh}
                t={t}
                headers={headers}
                setRefresh={setRefresh}
                modalShow={modalShow}
                setModalShow={setModalShow}
                toast={toast}
              />
              <EditAccount
                t={t}
                selectedItemEdit={selectedItemEdit}
                setSelectedItemEdit={setSelectedItemEdit}
                headers={headers}
                setRefresh={setRefresh}
                refresh={refresh}
                setShowData={setShowData}
                showData={showData}
                toast={toast}
              />
              {/* <ModalExpProxy dataFb={dataFb} /> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
