import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { Checkbox } from "primereact/checkbox";
import { ListMessClient } from "./script/ListMessClient";
import { ListCommentsClient } from "./script/ListCommentsClient";
import { InputNumber } from "primereact/inputnumber";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import { IoInformationCircle } from "react-icons/io5";
import { RiExpandDiagonal2Line } from "react-icons/ri";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
export const ModalCreateCateClient = ({
  openCategory,
  setOpenCategory,
  setRefresh,
  refresh,
}) => {
  const headers = useHeaders();
  const createCateMkt = API_URL + API_ROUTES_ZALO.CREATE_EDIT_CATEGORY_CLIENT;
  const [name, setName] = useState("");
  const [valueInbox, setValueInbox] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [inbox, setInbox] = useState(["Xin chào! Kết bạn với mình nhé"]);
  const [inboxSpilt, setInboxSplit] = useState([]);
  const [delayTime, setDelayTime] = useState(60);
  const [numberCount, setNumberCount] = useState(1);
  const [waitTime, setWaitTime] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const addCateMkt = async () => {
    if (!name || !delayTime) {
      alert("Vui lòng nhập đầy đủ thông tin");
    } else {
      const body = {
        id_category: null,
        name: name,
        phone_numbers: phoneNumber.split("\n")?.filter((i) => i !== ""),
        first_messages: inbox,
        delay_time: delayTime,
        wait_time: waitTime,
        number_count: numberCount || 1,
        divide: isChecked,
      };
      try {
        const res = await axios.post(createCateMkt, body, headers);
        setInboxSplit([]);
        setInbox([]);
        setName("");
        setPhoneNumber("");
        setOpenCategory(!openCategory);
        setRefresh(!refresh);
      } catch (err) {}
    }
  };

  return (
    <Dialog
      header="Kịch bản kết bạn"
      visible={openCategory}
      style={{ position: "relative", width: "60vw" }}
      onHide={() => setOpenCategory(false)}
      className="overFlowJoinGr"
      resizable={true}
      footer={
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ minHeight: "50px" }}
        >
          <Button className="button-blue radius" onClick={addCateMkt}>
            Lưu kịch bản
          </Button>
          <Button
            onClick={() => setOpenCategory(false)}
            className="button-red radius"
          >
            Thoát không lưu
          </Button>
          <RiExpandDiagonal2Line
            size={20}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
            }}
          />
        </div>
      }
    >
      <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
        <div className="p-inputgroup flex-1 mb-2">
          <span className="p-inputgroup-addon" style={{ width: "9rem" }}>
            Tên kịch bản
          </span>

          <InputText
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nhập tên kịch bản"
          />
        </div>
        <div className="p-inputgroup flex-1 w-100">
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Thời gian chờ (Giây)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Thời gian chờ giữa mỗi hành động trong một chuỗi nhiều
                      hành động
                      <br />- Ví dụ: Bạn tích chọn 3 chức năng "Gửi tin nhắn +
                      Bình luận + Like bài viết". Thì đây là một chuỗi 3 hành
                      động liên tiếp. Bạn nên để thời gian chờ tối thiểu là 60s
                      và khuyến khích là 180s để an toàn cho tài khoản. Hành
                      động quá nhanh sẽ dễ bị hạn chế tính năng trên tài khoản
                      do cơ chế chống spam của Facebook
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>

            <InputText
              style={{ width: "100%" }}
              value={delayTime}
              placeholder="Thời gian tính bằng giây (s)"
              onChange={(e) => setDelayTime(e.value)}
              useGrouping={false}
              min={60}
              type="number"
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span>Số lượt</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Tài khoản đi tương tác với mỗi một khách hàng được tính là
                      1 lượt. Nên để số lượt là 1. Và cài đặt thêm thời gian chờ
                      lặp lại ở ô bên cạnh
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputText
              style={{ width: "100%" }}
              value={numberCount}
              placeholder=""
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 1) {
                  setNumberCount(value);
                } else {
                  setNumberCount(1);
                }
              }}
              useGrouping={false}
              min={1}
              type="number"
            />
          </div>
          <div className="d-flex flex-column w-100 gap-2">
            <nav className="d-flex gap-2">
              <span style={{ width: "" }}>Thời gian chờ lặp lại (Giờ)</span>
              <OverlayTrigger
                key="tooltip"
                placement="top"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">
                      Chức năng này có tác dụng khi bạn cài đặt số lượt (số lượt
                      được cài đặt ở ô bên cạnh) Chức năng này giúp chạy tiếp
                      chiến dịch hoàn toàn tự động khi bạn đặt số lượt ở ô bên
                      cạnh. Nên để thời gian là 1 giờ
                    </div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </nav>
            <InputText
              style={{ width: "100%" }}
              value={waitTime}
              placeholder="Thời gian tính bằng giờ (h)"
              onChange={(e) => setWaitTime(e.value)}
              useGrouping={false}
              min={1}
              type="number"
            />
          </div>
        </div>

        <div className="d-flex gap-4">
          <nav className="mt-2" style={{ width: "50%" }}>
            <div className="d-flex">
              <p className="mb-2 mr-2">Số điện thoại muốn kết bạn</p>
              <OverlayTrigger
                key="tooltip"
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-right" className="custom-tooltip">
                    <div className="text-start">...</div>
                  </Tooltip>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  <IoInformationCircle size={15} />
                </span>
              </OverlayTrigger>
            </div>
            <InputTextarea
              style={{ width: "100%", height: "80%" }}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder=""
              rows={5}
              cols={30}
            />
          </nav>
          <div style={{ marginTop: "30px" }}>
            <label className="d-flex gap-2">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                style={{ width: "20px", height: "auto" }}
              />
              Chia
            </label>
          </div>
        </div>

        <ListMessClient
          valueInbox={valueInbox}
          setInbox={setInbox}
          inbox={inbox}
          setValueInbox={setValueInbox}
          inboxSpilt={inboxSpilt}
          setInboxSplit={setInboxSplit}
        />
      </div>
      {/* <div className="d-flex justify-content-end gap-2 mt-2">
        <Button className="button-blue radius" onClick={addCateMkt}>
          Lưu kịch bản
        </Button>
        <Button
          onClick={() => setOpenCategory(false)}
          className="button-red radius"
        >
          Thoát không lưu
        </Button>
      </div> */}
    </Dialog>
  );
};

// import React, { useState, useEffect, useRef } from "react";
// import { Dialog } from "primereact/dialog";
// import { Button } from "primereact/button";
// import { InputText } from "primereact/inputtext";
// import axios from "axios";
// import { useHeaders } from "../../../const/headers";
// import { API_URL, API_ROUTES_ZALO } from "../../../api";
// import { ListMessClient } from "./script/ListMessClient";
// import { Tooltip, OverlayTrigger } from "react-bootstrap";
// import { IoInformationCircle } from "react-icons/io5";
// import { RiExpandDiagonal2Line } from "react-icons/ri";
// import { InputTextarea } from "primereact/inputtextarea";
// import { RadioButton } from "primereact/radiobutton";
// import { Dropdown } from "primereact/dropdown";
// import { Paginator } from "primereact/paginator";
// import { getAxiosResultsGroup } from "../../../const/getAxios";
// import { useSharedState } from "../../../const/paginator";
// export const ModalCreateCateClient = ({
//   openCategory,
//   setOpenCategory,
//   setRefresh,
//   refresh,
//   dataZalo,
//   toast,
// }) => {
//   const headers = useHeaders();
//   const createCateMkt = API_URL + API_ROUTES_ZALO.CREATE_EDIT_CATEGORY_CLIENT;
//   const apiAllFr = API_URL + API_ROUTES_ZALO.GET_ALL_FRIEND;
//   const apiShowMemberLink = API_URL + API_ROUTES_ZALO.SHOW_MEMBER_IN_LINK;
//   const apiResultsShowMemberLink =
//     API_URL + API_ROUTES_ZALO.RESULTS_SHOW_MEMBER;
//   const [name, setName] = useState("");
//   const [valueInbox, setValueInbox] = useState("");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [inbox, setInbox] = useState(["Xin chào! Kết bạn với mình nhé"]);
//   const [inboxSpilt, setInboxSplit] = useState([]);
//   const [delayTime, setDelayTime] = useState(60);
//   const [numberCount, setNumberCount] = useState(1);
//   const [waitTime, setWaitTime] = useState(0);
//   const [isChecked, setIsChecked] = useState(false);
//   const [typeList, setTypeList] = useState("sdt");
//   const [seclectAccout, setSeclectAccout] = useState(null);
//   const [allFriends, setAllFriends] = useState([]);
//   const [listUid, setListUid] = useState([]);
//   const [taskCre, setTaskCre] = useState([]);
//   const [nameGr, setNameGr] = useState(false);
//   const [link, setLink] = useState("");
//   const intervalRef = useRef(null);
//   const {
//     onPageChange,
//     getTotal,
//     setGetTotal,
//     rows,
//     totalPage,
//     first,
//     rowsPerPageOptions,
//   } = useSharedState();
//   const handleCheckboxChange = (event) => {
//     setIsChecked(event.target.checked);
//   };
//   useEffect(() => {
//     if (!seclectAccout?.id || typeList === "link") {
//       return;
//     } else {
//       getAxiosResultsGroup(
//         apiAllFr,
//         setAllFriends,
//         setGetTotal,
//         rows,
//         totalPage + 1,
//         seclectAccout?.id || "null"
//       );
//     }
//   }, [totalPage, rows, seclectAccout]);
//   const addCateMkt = async () => {
//     if (!name || !delayTime) {
//       alert("Vui lòng nhập đầy đủ thông tin");
//     } else {
//       const body = {
//         id_category: null,
//         name: name,
//         type: typeList === "sdt" ? "phone_numbers" : "uids",
//         ...(typeList === "sdt"
//           ? { phone_numbers: phoneNumber.split("\n")?.filter((i) => i !== "") }
//           : { uids: listUid?.map((item) => (item.uid ? item.uid : item.id)) }),
//         first_messages: inbox,
//         delay_time: delayTime,
//         wait_time: waitTime,
//         number_count: numberCount || 1,
//         divide: isChecked,
//       };
//       try {
//         const res = await axios.post(createCateMkt, body, headers);
//         setInboxSplit([]);
//         setInbox([]);
//         setName("");
//         setPhoneNumber("");
//         setOpenCategory(!openCategory);
//         setRefresh(!refresh);
//       } catch (err) {}
//     }
//   };
//   const handleSeclectUid = (item) => {
//     if (listUid.includes(item)) {
//       setListUid(listUid.filter((list) => list.id !== item.id));
//     } else {
//       setListUid((pre) => [...pre, item]);
//     }
//   };
//   const handleGetLink = async () => {
//     if (!seclectAccout.id || !link) {
//       alert("Chọn tài khoản và nhập link");
//       return;
//     }
//     const body = {
//       id_account: Number(seclectAccout.id),
//       link: link,
//     };
//     try {
//       const res = await axios.post(apiShowMemberLink, body, headers);
//       setTaskCre(res.data.id_task);
//       toast.success("Đang lấy thông tin");
//       resultsGetLink(res.data.id_task);
//     } catch (err) {}
//   };
//   const resultsGetLink = async (idtask) => {
//     const body = {
//       id_task: idtask,
//     };
//     if (idtask.length > 0) {
//       try {
//         const res = await axios.post(apiResultsShowMemberLink, body, headers);
//         if (res.data.status === "SUCCESS") {
//           setNameGr(true);
//           toast.success("Lấy thông tin thành công");
//           setTaskCre([]);
//           if (res.data.data.list_members) {
//             setAllFriends(res.data.data.list_members);
//           }
//         } else if (res.data.status === "FAILURE") {
//           toast.error("Lấy thông tin thất bại");
//           setNameGr(true);
//           setTaskCre([]);
//         }
//       } catch (error) {
//         setNameGr(true);
//         setTaskCre([]);
//       }
//     }
//   };
//   useEffect(() => {
//     intervalRef.current = setInterval(() => {
//       resultsGetLink(taskCre);
//     }, 3000);
//     if (nameGr) {
//       setNameGr(false);
//       clearInterval(intervalRef.current);
//     }
//     return () => clearInterval(intervalRef.current);
//   }, [taskCre]);
//   useEffect(() => {
//     setListUid([]);
//     setAllFriends([]);
//   }, [typeList]);
//   return (
//     <Dialog
//       header="Kịch bản kết bạn"
//       visible={openCategory}
//       style={{ position: "relative", width: "60vw" }}
//       onHide={() => setOpenCategory(false)}
//       className="overFlowJoinGr"
//       resizable={true}
//       footer={
//         <div
//           className="d-flex justify-content-end align-items-center"
//           style={{ minHeight: "50px" }}
//         >
//           <Button className="button-blue radius" onClick={addCateMkt}>
//             Lưu kịch bản
//           </Button>
//           <Button
//             onClick={() => setOpenCategory(false)}
//             className="button-red radius"
//           >
//             Thoát không lưu
//           </Button>
//           <RiExpandDiagonal2Line
//             size={20}
//             style={{
//               position: "absolute",
//               right: "0",
//               bottom: "0",
//             }}
//           />
//         </div>
//       }
//     >
//       <div className="d-flex flex-column h-100" style={{ overflow: "auto" }}>
//         <div className="p-inputgroup flex-1 mb-2">
//           <span className="p-inputgroup-addon" style={{ width: "9rem" }}>
//             Tên kịch bản
//           </span>

//           <InputText
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             placeholder="Nhập tên kịch bản"
//           />
//         </div>
//         <div className="p-inputgroup flex-1 w-100">
//           <div className="d-flex flex-column w-100 gap-2">
//             <nav className="d-flex gap-2">
//               <span>Thời gian chờ (Giây)</span>
//               <OverlayTrigger
//                 key="tooltip"
//                 placement="top"
//                 overlay={
//                   <Tooltip id="tooltip-right" className="custom-tooltip">
//                     <div className="text-start">
//                       Thời gian chờ giữa mỗi hành động trong một chuỗi nhiều
//                       hành động
//                       <br />- Ví dụ: Bạn tích chọn 3 chức năng "Gửi tin nhắn +
//                       Bình luận + Like bài viết". Thì đây là một chuỗi 3 hành
//                       động liên tiếp. Bạn nên để thời gian chờ tối thiểu là 60s
//                       và khuyến khích là 180s để an toàn cho tài khoản. Hành
//                       động quá nhanh sẽ dễ bị hạn chế tính năng trên tài khoản
//                       do cơ chế chống spam của Facebook
//                     </div>
//                   </Tooltip>
//                 }
//               >
//                 <span
//                   style={{
//                     cursor: "pointer",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   <IoInformationCircle size={15} />
//                 </span>
//               </OverlayTrigger>
//             </nav>

//             <InputText
//               style={{ width: "100%" }}
//               value={delayTime}
//               placeholder="Thời gian tính bằng giây (s)"
//               onChange={(e) => setDelayTime(e.value)}
//               useGrouping={false}
//               min={60}
//               type="number"
//             />
//           </div>
//           <div className="d-flex flex-column w-100 gap-2">
//             <nav className="d-flex gap-2">
//               <span>Số lượt</span>
//               <OverlayTrigger
//                 key="tooltip"
//                 placement="top"
//                 overlay={
//                   <Tooltip id="tooltip-right" className="custom-tooltip">
//                     <div className="text-start">
//                       Tài khoản đi tương tác với mỗi một khách hàng được tính là
//                       1 lượt. Nên để số lượt là 1. Và cài đặt thêm thời gian chờ
//                       lặp lại ở ô bên cạnh
//                     </div>
//                   </Tooltip>
//                 }
//               >
//                 <span
//                   style={{
//                     cursor: "pointer",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   <IoInformationCircle size={15} />
//                 </span>
//               </OverlayTrigger>
//             </nav>
//             <InputText
//               style={{ width: "100%" }}
//               value={numberCount}
//               placeholder=""
//               onChange={(e) => {
//                 const value = e.target.value;
//                 if (value >= 1) {
//                   setNumberCount(value);
//                 } else {
//                   setNumberCount(1);
//                 }
//               }}
//               useGrouping={false}
//               min={1}
//               type="number"
//             />
//           </div>
//           <div className="d-flex flex-column w-100 gap-2">
//             <nav className="d-flex gap-2">
//               <span style={{ width: "" }}>Thời gian chờ lặp lại (Giờ)</span>
//               <OverlayTrigger
//                 key="tooltip"
//                 placement="top"
//                 overlay={
//                   <Tooltip id="tooltip-right" className="custom-tooltip">
//                     <div className="text-start">
//                       Chức năng này có tác dụng khi bạn cài đặt số lượt (số lượt
//                       được cài đặt ở ô bên cạnh) Chức năng này giúp chạy tiếp
//                       chiến dịch hoàn toàn tự động khi bạn đặt số lượt ở ô bên
//                       cạnh. Nên để thời gian là 1 giờ
//                     </div>
//                   </Tooltip>
//                 }
//               >
//                 <span
//                   style={{
//                     cursor: "pointer",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   <IoInformationCircle size={15} />
//                 </span>
//               </OverlayTrigger>
//             </nav>
//             <InputText
//               style={{ width: "100%" }}
//               value={waitTime}
//               placeholder="Thời gian tính bằng giờ (h)"
//               onChange={(e) => setWaitTime(e.value)}
//               useGrouping={false}
//               min={1}
//               type="number"
//             />
//           </div>
//         </div>
//         <div className="flex flex-wrap gap-3 mt-2">
//           <div className="flex align-items-center">
//             <RadioButton
//               inputId="ingredient1"
//               name="pizza"
//               value="sdt"
//               onChange={(e) => setTypeList(e.value)}
//               checked={typeList === "sdt"}
//             />
//             <label htmlFor="ingredient1" className="ml-2">
//               Kết bạn bằng số điện thoại
//             </label>
//           </div>
//           <div className="flex align-items-center">
//             <RadioButton
//               inputId="ingredient2"
//               name="pizza"
//               value="fr"
//               onChange={(e) => setTypeList(e.value)}
//               checked={typeList === "fr"}
//             />
//             <label htmlFor="ingredient2" className="ml-2">
//               Kết bạn bằng danh sách bạn bè
//             </label>
//           </div>
//           <div className="flex align-items-center">
//             <RadioButton
//               inputId="ingredient3"
//               name="pizza"
//               value="link"
//               onChange={(e) => setTypeList(e.value)}
//               checked={typeList === "link"}
//             />
//             <label htmlFor="ingredient3" className="ml-2">
//               Kết bạn từ link nhóm
//             </label>
//           </div>
//         </div>
//         <div className="d-flex gap-4">
//           {typeList === "sdt" ? (
//             <nav className="mt-2" style={{ width: "50%" }}>
//               <div className="d-flex">
//                 <p className="mb-2 mr-2">Số điện thoại muốn kết bạn</p>
//                 <OverlayTrigger
//                   key="tooltip"
//                   placement="bottom"
//                   overlay={
//                     <Tooltip id="tooltip-right" className="custom-tooltip">
//                       <div className="text-start">...</div>
//                     </Tooltip>
//                   }
//                 >
//                   <span
//                     style={{
//                       cursor: "pointer",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     <IoInformationCircle size={15} />
//                   </span>
//                 </OverlayTrigger>
//               </div>
//               <InputTextarea
//                 style={{ width: "100%", height: "80%" }}
//                 value={phoneNumber}
//                 onChange={(e) => setPhoneNumber(e.target.value)}
//                 placeholder=""
//                 rows={5}
//                 cols={30}
//               />
//             </nav>
//           ) : typeList === "fr" ? (
//             <nav className="mt-2" style={{ width: "50%" }}>
//               <Dropdown
//                 value={seclectAccout}
//                 onChange={(e) => setSeclectAccout(e.value)}
//                 options={dataZalo}
//                 optionLabel="name"
//                 placeholder="Chọn tài khoản"
//                 className="w-full mb-2"
//               />
//               <nav
//                 className="d-flex flex-column overflow-auto gap-2"
//                 style={{ height: "30vh" }}
//               >
//                 {allFriends?.map((item, index) => (
//                   <nav
//                     onClick={() => handleSeclectUid(item)}
//                     className="d-flex p-2 gap-2 align-items-center cursor-pointer"
//                     style={{
//                       background: listUid.includes(item) ? "#29d1f9" : "",
//                     }}
//                   >
//                     {item.avatar && (
//                       <img
//                         style={{
//                           height: "30px",
//                           width: "30px",
//                           borderRadius: "50%",
//                         }}
//                         src={item.avatar}
//                       />
//                     )}
//                     <p>{item.name}</p>
//                   </nav>
//                 ))}
//               </nav>{" "}
//               {allFriends?.length > 0 && (
//                 <Paginator
//                   style={{ height: "15%" }}
//                   className="pagination-markerting"
//                   first={first}
//                   rows={rows}
//                   totalRecords={getTotal?.count}
//                   onPageChange={onPageChange}
//                   rowsPerPageOptions={rowsPerPageOptions}
//                 />
//               )}
//             </nav>
//           ) : (
//             <nav className="mt-2" style={{ width: "50%" }}>
//               <div className="p-inputgroup  w-100">
//                 <Dropdown
//                   value={seclectAccout}
//                   onChange={(e) => setSeclectAccout(e.value)}
//                   options={dataZalo}
//                   optionLabel="name"
//                   placeholder="Chọn tài khoản"
//                   className=""
//                 />
//                 <InputText
//                   value={link}
//                   onChange={(e) => setLink(e.target.value)}
//                   placeholder="Nhập link nhóm"
//                 />
//                 <Button
//                   onClick={() => handleGetLink()}
//                   icon="pi pi-search"
//                   className="button-blue"
//                 />
//               </div>
//               <nav
//                 className="d-flex flex-column overflow-auto gap-2"
//                 style={{ height: "30vh" }}
//               >
//                 {allFriends?.map((item, index) => (
//                   <nav
//                     onClick={() => handleSeclectUid(item)}
//                     className="d-flex p-2 gap-2 align-items-center cursor-pointer"
//                     style={{
//                       background: listUid.includes(item) ? "#29d1f9" : "",
//                     }}
//                   >
//                     {item.avatar && (
//                       <img
//                         style={{
//                           height: "30px",
//                           width: "30px",
//                           borderRadius: "50%",
//                         }}
//                         src={item.avatar}
//                       />
//                     )}
//                     <p>{item.dName}</p>
//                   </nav>
//                 ))}
//               </nav>{" "}
//             </nav>
//           )}

//           <div style={{ marginTop: "30px" }}>
//             <label className="d-flex gap-2">
//               <input
//                 type="checkbox"
//                 checked={isChecked}
//                 onChange={handleCheckboxChange}
//                 style={{ width: "20px", height: "auto" }}
//               />
//               Chia
//             </label>
//           </div>
//         </div>
//         <ListMessClient
//           valueInbox={valueInbox}
//           setInbox={setInbox}
//           inbox={inbox}
//           setValueInbox={setValueInbox}
//           inboxSpilt={inboxSpilt}
//           setInboxSplit={setInboxSplit}
//         />
//       </div>
//     </Dialog>
//   );
// };
