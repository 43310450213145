import { useState, useEffect, useRef } from "react";
import { FaPeopleGroup } from "react-icons/fa6";
import avt from "./zalologo.webp";
import { CiLogout } from "react-icons/ci";
import { API_URL, API_ROUTES_ZALO } from "../../../api";
import { useHeaders } from "../../../const/headers";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CiSettings } from "react-icons/ci";
export const HeaderInChat = ({
  itemFriend,
  memberGr,
  hiddenListGr,
  setHiddenListGr,
  toast,
  setRfFiend,
  rfFriend,
  setIsSetting,
}) => {
  const navigate = useNavigate();
  const apiQuitGr = API_URL + API_ROUTES_ZALO.QUIT_GROUP;
  const apiRsQuitGr = API_URL + API_ROUTES_ZALO.QUIT_GROUP_RESULT;
  const headers = useHeaders();
  const [avatar, setAvatar] = useState(null);
  const [name, setName] = useState("");
  const [hiddenHeader, setHiddenHeader] = useState(false);
  const [taskGr, setTaskGr] = useState([]);
  const [outGrStatus, setOutGrStatus] = useState(false);
  const intervalRef = useRef();
  const uidAccount = localStorage.getItem("id_account");
  const itemMember = JSON.parse(localStorage.getItem("itemMember"));
  
  useEffect(() => {
    if (itemFriend?.id) {
      if (itemFriend?.friend) {
        setAvatar(itemFriend?.friend?.avatar || avt);
        setName(itemFriend?.friend?.name);
      } else {
        setAvatar(itemFriend?.group?.avt || avt);
        setName(itemFriend?.group?.name);
      }
    }
  }, [itemFriend]);
  const handleQuitGr = async () => {
    const body = {
      id_account: itemFriend.account,
      group_ids: [itemFriend.group.uid],
      delay_time: 1,
    };
    try {
      const res = await axios.post(apiQuitGr, body, headers);
      setTaskGr(res.data.id_task);
      resultsQuitGr(res.data.id_task);
      toast.success("Đang thực hiện rời nhóm");
    } catch (err) {}
  };
  const resultsQuitGr = async (idtask) => {
    const body = {
      id_task: idtask,
    };
    if (idtask.length > 0) {
      try {
        const res = await axios.post(apiRsQuitGr, body, headers);
        if (res.data.status === "SUCCESS") {
          toast.success("Rời nhóm thành công");
          setRfFiend(!rfFriend);
          setOutGrStatus(true);
          navigate(`/homechat/${itemFriend.account}`);
          setTaskGr([]);
        } else if (res.data.status === "FAILURE") {
          toast.error("Rời nhóm thất bại");
          setOutGrStatus(true);
          setTaskGr([]);
        }
      } catch (error) {
        toast.error("Đã xảy ra lỗi khi rời nhóm");
        setOutGrStatus(true);
        setTaskGr([]);
      }
    }
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      resultsQuitGr(taskGr);
    }, 3000);
    if (outGrStatus) {
      setOutGrStatus(false);
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [taskGr]);
  return (
    <div
      onClick={() => setHiddenHeader(!hiddenHeader)}
      className="headers-in-chatmess"
      style={{
        cursor: hiddenHeader ? "zoom-out" : "zoom-in",
        width: hiddenHeader ? "" : "max-content",
        background: hiddenHeader ? " #63a3e7" : "#000000",
      }}
    >
      {hiddenHeader && (
        <nav className="infor-content">
          <img src={avatar} className="account-avatar" alt="" />
          <p className="name-header">{name}</p>
        </nav>
      )}
      {hiddenHeader && (
        <>
          {itemFriend?.group && (
            <div className="d-flex gap-4">
              <nav
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSetting(true);
                }}
                className="d-flex gap-2 align-items-center cursor-pointer"
              >
                <CiSettings size={20} className="color-dark" />
                <p className="color-dark name-header">Cài đặt</p>
              </nav>
              <nav
                onClick={(e) => {
                  e.stopPropagation();
                  handleQuitGr();
                }}
                className="d-flex gap-2 align-items-center cursor-pointer"
              >
                <CiLogout size={20} className="color-dark" />
                <p className="color-dark name-header">Rời nhóm</p>
              </nav>
              <nav
                onClick={(e) => {
                  e.stopPropagation();
                  setHiddenListGr(!hiddenListGr);
                }}
                className="flex gap-2 align-items-center list-member-gr"
              >
                <FaPeopleGroup size={20} className="color-dark" />
                <p className="color-dark name-header">{`${memberGr?.length} Thành viên`}</p>
              </nav>
            </div>
          )}
        </>
      )}
    </div>
  );
};
