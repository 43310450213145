import axios from "axios";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";
import { IoInformationCircle } from "react-icons/io5";
import { RxPinTop } from "react-icons/rx";
import { TiDelete } from "react-icons/ti";
import { API_ROUTES, API_ROUTES_NTD, API_URL } from "../../api";
import { deleteFileOnServer } from "../../const/deleteFileOnServer";
import { useHeaders } from "../../const/headers";
import { Checkbox } from "primereact/checkbox";
import "./MarketPlace.css";
export const ListContentMarket = ({
  title,
  setTitle,
  setImageURLs,
  imageURLs,
  setPrice,
  price,
  setDescription,
  description,
  setListFile,
  listFile,
  setListImage,
  listImage,
  setDataLink,
  dataLink,
  toast,
  dataCopy,
  dataFill,
}) => {
  const getImg = API_URL + API_ROUTES.GET_LINK_FILE;
  const apiGetLink = API_URL + API_ROUTES_NTD.GET_LINK_MARKET;
  const resultsGetLink = API_URL + API_ROUTES_NTD.RESULTS_GET_LINK_MARKET;
  const headers = useHeaders();
  const [idTask, setIdTask] = useState([]);
  const [link, setLink] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState([]);
  const [checked, setChecked] = useState(false);
  const showFileInput = () => {
    document.getElementById(`fileInput`).click();
  };
  useEffect(() => {
    if (checked) {
      setPrice(0);
    }
  }, [checked]);
  useEffect(() => {
    if (dataFill) {
      setTitle(dataFill.title);
      setPrice(dataFill.price);
      setDescription(dataFill.description);
      setListImage(dataFill.photos);
    }
    localStorage.removeItem("dataFill");
  }, [dataFill]);
  const handleGetImg = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await axios.post(getImg, formData, headers);
      setListImage((prevURLs) => {
        if (Array.isArray(prevURLs)) {
          return [...prevURLs, res.data.file];
        } else {
          return [res.data.file];
        }
      });
    } catch (err) {}
  };
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + listImage.length > 10) {
      alert(
        " Tối đa 10 ảnh ( nên thay đổi ảnh để tránh trùng lặp ảnh vi phạm chính sách bán hàng )"
      );
      return;
    }
    for (let i = 0; i < files.length; i++) {
      handleGetImg(files[i]);
    }
    setListFile(files);
    const newImageURLs = files.map((file) => URL.createObjectURL(file));
    setImageURLs((prevURLs) => [...prevURLs, ...newImageURLs]);
  };
  const handleRemoveImage = (index) => {
    const updatedURLs = [...imageURLs];
    const updatedFile = [...listFile];
    const updatedImage = [...listImage];
    deleteFileOnServer(listImage[index]);
    updatedURLs.splice(index, 1);
    updatedFile.splice(index, 1);
    updatedImage.splice(index, 1);
    setImageURLs(updatedURLs);
    setListFile(updatedFile);
    setListImage(updatedImage);
  };
  const moveImageToTop = (index) => {
    setListImage((prevURLs) => {
      if (Array.isArray(prevURLs)) {
        const newList = [...prevURLs];
        const selectedImage = newList.splice(index, 1)[0];
        newList.unshift(selectedImage);
        return newList;
      } else {
        console.error("prevURLs is not an array");
        return prevURLs;
      }
    });
  };

  const getIdTask = async (item) => {
    const body = {
      link: dataCopy?.id_post
        ? `https://www.facebook.com/marketplace/item/${dataCopy?.id_post}`
        : link,
    };
    try {
      const res = await axios.post(apiGetLink, body, headers);
      setIdTask(res.data.id_task);
      setLoading(true);
    } catch (err) {}
  };
  useEffect(() => {
    if (dataCopy?.id_post) {
      getIdTask();
    }
  }, []);
  const getResultsTask = async () => {
    const body = {
      id_task: idTask,
    };
    try {
      const res = await axios.post(resultsGetLink, body, headers);
      if (res.data.status === "SUCCESS") {
        res.data.data
          ? toast.success("Sao chép thành công")
          : toast.error("Sao chép thất bại");
      }
      localStorage.removeItem("dataCopy");
      setDataLink(res.data);
      setStatus(res.data.status);
    } catch (err) {
      setStatus(err.response.data.status);
      toast.error("Sap chép thất bại");
    }
  };
  useEffect(() => {
    if (idTask.length > 0) {
      const interval = setInterval(() => {
        getResultsTask(idTask);
      }, 3000);
      if (status === "SUCCESS" || status === "FAILURE") {
        setStatus([]);
        setIdTask([]);
        setLoading(false);
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [dataLink, idTask]);
  return (
    <div className=" flex justify-content-center flex-column align-items-center">
      <div className="w-100" style={{ position: "relative" }}>
        <InputText
          className="w-100 input-placeholder-large"
          placeholder="Nhập link sao chép"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
        <OverlayTrigger
          key="tooltip"
          placement="bottom"
          overlay={
            <Tooltip id="tooltip-right" className="custom-tooltip">
              <div className="text-start">
                Nhập link sản phẩm cần sao chép vào đây
              </div>
            </Tooltip>
          }
        >
          <span
            style={{
              cursor: "pointer",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
          >
            <IoInformationCircle size={15} />
          </span>
        </OverlayTrigger>
      </div>
      <Button
        className="d-flex justify-content-center mt-2 mb-2 custom-button"
        style={{
          width: "100%",
        }}
        onClick={getIdTask}
        disabled={loading}
      >
        {loading ? "Vui lòng chờ..." : "Sao chép"}
      </Button>
      <div className="d-flex gap-2">
        <button
          className="d-flex flex-column align-items-center gap-4 p-2 border border-secondary justify-content-between rounded mb-3"
          onClick={() => showFileInput()}
        >
          <IoMdAdd />
          <p className="mb-0">Chọn ảnh</p>
        </button>
        <OverlayTrigger
          key="tooltip"
          placement="right"
          overlay={
            <Tooltip id="tooltip-right" className="custom-tooltip ">
              <div className="text-start custom-tooltip-content">
                Bấm dấu dấu ➕ để thêm ảnh
                <br />
                Bấm dấu ✖ để xóa ảnh
                <br />
                Bấm dấu mũi tên ⬆ để chọn ảnh đó làm ảnh đại diện cho bài viết
                (Thumbnail )
                <br />
                -Lưu ý : Tối đa 10 ảnh ( nên thay đổi ảnh để tránh trùng lặp ảnh
                vi phạm chính sách bán hàng )
              </div>
            </Tooltip>
          }
        >
          <span
            style={{
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            <IoInformationCircle size={15} />
          </span>
        </OverlayTrigger>
      </div>
      <div className="flex flex-wrap">
        {listImage?.map((url, index) => (
          <div key={index} className="position-relative m-2">
            <Image
              width="50"
              height="50"
              src={url.includes("https") ? url : `${API_URL}/${url}`}
              alt={`Selected ${index}`}
              preview
            />
            <button
              className="btn-remove"
              style={{ position: "absolute", top: "-6px", right: "-8px" }}
              onClick={() => handleRemoveImage(index)}
            >
              <TiDelete size={15} />
            </button>
            <button
              className="btn-remove"
              style={{
                display: index === 0 ? "none" : "",
                position: "absolute",
                top: "18px",
                right: "-8px",
              }}
              onClick={() => moveImageToTop(index)}
            >
              <RxPinTop size={15} />
            </button>
          </div>
        ))}
      </div>
      <input
        id={`fileInput`}
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={(e) => handleImageChange(e)}
      />
      <div className="d-flex flex-column w-100 gap-2">
        <InputText
          className="w-100 input-placeholder-large"
          placeholder="Tiêu đề"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <div style={{ position: "relative" }}>
          <InputTextarea
            className="w-100 input-placeholder-large"
            placeholder="Mô tả"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <OverlayTrigger
            key="tooltip"
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-right" className="custom-tooltip">
                <div className="text-start">
                  Bạn có thể chèn số điện thoại vào đây với các thủ thuật lách
                  "ẩn thông tin số điện thoại" của Facebook bằng cách dùng vừa
                  số vừa chữ hoặc bằng dấu chấm
                  <br />
                  Ví dụ 1 : 0 chín 8 bảy 6 năm 4 ba 2 một
                  <br />
                  Ví dụ 2 : 0987.654.321
                </div>
              </Tooltip>
            }
          >
            <span
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: "4px",
                right: "4px",
              }}
            >
              <IoInformationCircle size={15} />
            </span>
          </OverlayTrigger>
        </div>
        <nav className="d-flex align-items-center gap-2">
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <p className="input-placeholder-large">Miễn phí</p>
        </nav>

        <div style={{ position: "relative" }}>
          <InputText
            disabled={checked}
            value={checked ? "Miễn phí" : price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-100 input-placeholder-large"
            placeholder="Giá"
          />
          <OverlayTrigger
            key="tooltip"
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-right" className="custom-tooltip">
                <div className="text-start">
                  Bạn có thể nhập giá bán vào đây bằng số
                  <br />
                  Ví dụ : 99000 và không cần nhập dấu chấm phẩy khi nhập số
                </div>
              </Tooltip>
            }
          >
            <span
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: "4px",
                right: "4px",
              }}
            >
              <IoInformationCircle size={15} />
            </span>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};
