import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
  onRowClick,
  rowClassName,
} from "../../../const/checkboxPrimeReactjs";
import { EditCateMarketing } from "./EditCateMarketing";
import { API_URL, API_ROUTES_NTD } from "../../../api";
import { useHeaders } from "../../../const/headers";
import axios from "axios";
import { statusRunTools } from "../../../const/updateTitle";
import { ModalManageCateMkt } from "./ModalManageCateMkt";
import { useTranslation } from "react-i18next";
export const ListCateMkt = ({
  allCate,
  setRefresh,
  refresh,
  setListMktCate,
  listMktCate,
  dataFb,
  toast,
  setOpenManager,
  itemCate,
  setItemCate,
}) => {
  const deleteScript = API_URL + API_ROUTES_NTD.DELETE_CATEGORY_MARKETING;
  const copyCate = API_URL + API_ROUTES_NTD.COPY_CATEGORY_MARKETING;
  const [modalManage, setModalManage] = useState(false);
  const headers = useHeaders();
  const [editCate, setEditCate] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const getDataAllFr = () => { };
  const { t } = useTranslation();
  const handleDeleteScript = async (item) => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá !");
    if (confirm) {
      const body = { id_category: item.id };
      try {
        await axios.post(deleteScript, body, headers);
        setRefresh(!refresh);
      } catch (err) { }
    }
  };
  const handleCopy = async (item) => {
    const userName = prompt("Nhập tên kịch bản mới");
    const body = { id_category: item.id, name: userName };
    try {
      await axios.post(copyCate, body, headers);
      setRefresh(!refresh);
    } catch (err) { }
  };
  useEffect(() => {
    if (allCate.length > 0 && itemCate) {
      const updatedItemCate = allCate.find((item) => item.id === itemCate.id);
      if (updatedItemCate) {
        setItemCate(updatedItemCate);
      }
    }
  }, [allCate]);

  const optionAcc = (rowData) => {
    return (
      <div className="d-flex gap-2" style={{ width: "max-content" }}>
        <Button
          className="button-blue radius"
          onClick={() => {
            setOpenManager(true);
            setItemCate(rowData);
          }}
          style={{ padding: "6px 8px" }}
        >
          Gán kịch bản
        </Button>
        <Button
          className="button-blue radius"
          onClick={() => {
            handleCopy(rowData);
          }}
          style={{ padding: "6px 8px" }}
        >
          Sao chép
        </Button>
        <Button
          className="button-blue radius"
          onClick={() => {
            setEditCate(rowData);
            setModalEdit(true);
          }}
          style={{ padding: "6px 8px" }}
        >
          Chi tiết
        </Button>
        <Button
          className="button-red radius"
          severity="danger"
          onClick={() => handleDeleteScript(rowData)}
          style={{ padding: "6px 8px" }}
        >
          Xoá
        </Button>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  const listAccount = (rowData) => {
    return (
      <div className="w-100 d-flex flex-wrap gap-2 align-items-center">
        <p>
          {rowData?.account?.length === 0
            ? "Chưa có tài khoản nào được gán"
            : `Có ${rowData?.account?.length} tài khoản được gán`}
        </p>
        {/* <a
          onClick={() => {
            setModalManage(true);
            setItemCate(rowData || []);
          }}
          className="button-link"
        >
          Chi tiết
        </a> */}
      </div>
    );
  };
  return (
    <div className=" h-100 " style={{ border: "1px solid", width: "100%" }}>
      <DataTable
        size="small"
        className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ width: "100%" }}
        value={allCate}
        rowClassName={(rowData) => rowClassName(rowData, listMktCate)}
        onRowClick={(e) => onRowClick(e.data, setListMktCate, listMktCate)}
      >
        <Column
          header={headerCheckbox(
            listMktCate,
            allCate,
            toggleSelectAll,
            setListMktCate
          )}
          body={(rowData) =>
            bodyCheckbox(rowData, listMktCate, setListMktCate, getDataAllFr)
          }
        />
        <Column body={indexTemplate} field="UID" header="STT"></Column>
        <Column field="name" sortable header="Tên Kịch bản"></Column>
        <Column
          body={(rowData) => listAccount(rowData)}
          field="name"
          sortable
          header="Tài khoản được gán"
        ></Column>
        <Column
          field=""
          body={(rowData) => statusRunTools(rowData, t)}
          sortable
          header="Trạng thái"
        ></Column>
        <Column
          className="options-category"
          body={(rowData) => optionAcc(rowData)}
          header="Tuỳ chọn"
        ></Column>
      </DataTable>
      <EditCateMarketing
        setRefresh={setRefresh}
        refresh={refresh}
        editCate={editCate}
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
      />
      <ModalManageCateMkt
        allCate={allCate}
        toast={toast}
        dataFb={dataFb}
        itemCate={itemCate}
        refresh={refresh}
        setRefresh={setRefresh}
        modalManage={modalManage}
        setModalManage={setModalManage}
      />
    </div>
  );
};
