import { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
} from "../../../const/checkboxPrimeReactjs";
import { Paginator } from "primereact/paginator";
import axios from "axios";
import { useHeaders } from "../../../const/headers";
import { API_URL, API_ROUTES_NTD, API_ROUTES_ZALO } from "../../../api";
import { rowClassName } from "../../../const/checkboxPrimeReactjs";
import { onRowClick } from "../../../const/checkboxPrimeReactjs";
import { getAxiosResultsGroup } from "./../../../const/getAxios";
export const ListFrOfAccount = ({
  setListFr,
  listFr,
  statusScan,
  selectedItems,
  setAllGroup,
  renderKey,
  setTotalPage,
  getTotal,
  setGetTotal,
  rows,
  setRows,
  totalPage,
  statusQuit,
  deleteFriend,
  selectedRows,
  setSelectedRows,
  filterNameFriend,
}) => {
  const apiAllFr = API_URL + API_ROUTES_ZALO.GET_ALL_FRIEND;

  const [first, setFirst] = useState(0);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  useEffect(() => {
    if (selectedItems === null || selectedItems?.length === 0) {
      return;
    } else {
      getAxiosResultsGroup(
        apiAllFr,
        setAllGroup,
        setGetTotal,
        rows,
        totalPage + 1,
        selectedItems[0]?.id || "null"
      );
    }
  }, [totalPage, rows, renderKey, selectedItems]);

  const buttonOption = (rowData) => {
    return (
      <div style={{ width: "80px" }} className="d-flex gap-2 flex-column">
        <Button
          className=" button-red radius"
          severity="danger"
          style={{
            padding: "4px 6px",
            width: "100%",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => deleteFriend(rowData, "one")}
          disabled={statusQuit}
        >
          Xóa bạn
        </Button>
      </div>
    );
  };
  const nameAndAvt = (rowData) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <img
          className="rounded-circle"
          style={{ width: "20px", height: "20px" }}
          src={rowData?.avatar}
        />
        <p>{rowData?.name}</p>
      </div>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  return (
    <div
      style={{
        border: "1px solid",
        width: "100%",
        height: "400px",
      }}
    >
      {selectedItems[0]?.length === 0 ? (
        <div className="d-flex flex-column h-100 justify-content-center align-items-center gap-2">
          Bạn không có bạn bè
        </div>
      ) : statusScan ? (
        <div className="d-flex flex-column h-100 justify-content-center align-items-center gap-2">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2em" }}></i>
        </div>
      ) : filterNameFriend && filterNameFriend.length > 0 ? (
        <div
          style={{ overflow: "auto", overflowX: "hidden" }}
          className="d-flex flex-column h-100"
        >
          <div style={{ flexGrow: 1, overflowY: "auto" }}>
            <DataTable
              size="small"
              value={filterNameFriend || []}
              className="small-font-table dataTableWithFixedHeader dataTableWithHoverEffect custom-scrollbar"
              tableStyle={{ width: "100%" }}
              rowClassName={(rowData) => rowClassName(rowData, listFr)}
              onRowClick={(e) => onRowClick(e.data, setListFr, listFr)}
              selection={selectedRows}
              onSelectionChange={(e) => setSelectedRows(e.value)}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3em" }}
              ></Column>
              <Column body={indexTemplate} header="STT"></Column>

              <Column
                body={(rowData) => nameAndAvt(rowData)}
                field="name"
                header="Tên bạn bè"
                sortable
              ></Column>
              <Column field="gender" header="Giới tính" sortable></Column>
              <Column field="sdob" header="Ngày sinh" sortable></Column>
              <Column
                body={(rowData) => buttonOption(rowData)}
                header="Tuỳ chọn"
              />
            </DataTable>
          </div>
          <div style={{ flexShrink: 0, position: "sticky", bottom: 0 }}>
            <Paginator
              style={{ height: "15%" }}
              className="pagination-markerting"
              first={first}
              rows={rows}
              totalRecords={getTotal?.count}
              onPageChange={onPageChange}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column h-100 justify-content-center align-items-center gap-2">
          Bạn không có bạn bè
        </div>
      )}
    </div>
  );
};
