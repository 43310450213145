import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { locale } from "primereact/api";
import {
  toggleSelectAll,
  headerCheckbox,
  bodyCheckbox,
  rowClassName,
  onRowClick,
} from "../../const/checkboxPrimeReactjs";
import { API_URL, API_ROUTES_NTD } from "../../api";
import axios from "axios";
import { useHeaders } from "../../const/headers";
import { Paginator } from "primereact/paginator";
import { getAxiosResults } from "../../const/getAxios";
export const ResultsFriend = ({
  refresh,
  setRefresh,
  toast,
  selectedFriend,
  setSelectedFriend,
  resultsFriend,
  setResultsFriend,
  setTotalPage,
  totalPage,
  setGetTotal,
  getTotal,
  setRows,
  rows,
}) => {
  locale("vi");
  const headers = useHeaders();
  const resultFriend = API_URL + API_ROUTES_NTD.TOOL_BOX_RESULTS_FRIEND;
  const removeResultsFriend = API_URL + API_ROUTES_NTD.TOOL_BOX_REMOVE_FRIEND;
  const [first, setFirst] = useState(0);
  const rowsPerPageOptions = [100, 300, 500, 1000, 2000];
  const [renderKey, setRenderKey] = useState(Date.now());
  const onPageChange = (event) => {
    setTotalPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };
  useEffect(() => {
    getAxiosResults(
      resultFriend,
      setResultsFriend,
      setGetTotal,
      rows,
      totalPage + 1
    );
  }, [totalPage, rows, renderKey, refresh]);
  const deleteResults = async (rowData) => {
    const confirm = window.confirm("Bạn chắc chắn muốn xoá không");
    if (confirm) {
      const bodyUser = { id_scan: [rowData?.id] };
      try {
        const res = await axios.post(removeResultsFriend, bodyUser, headers);
        setRefresh(!refresh);
        toast.success("Xoá thành công");
      } catch (err) {
        toast.error("Xoá thất bại");
      }
    }
  };
  const getDataAllFr = () => {};
  const nameAndAvatar = (rowData) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <img
          style={{ borderRadius: "50%", width: "30px" }}
          src={rowData?.avatar}
          alt=""
        />
        <p>{rowData?.name}</p>
      </div>
    );
  };
  const genDer = (rowData) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <p>{rowData?.gender === "FEMALE" ? "Nữ" : "Nam"}</p>
      </div>
    );
  };
  const shortName = (rowData) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <p>{rowData?.short_name}</p>
      </div>
    );
  };

  const deleteButton = (rowData) => {
    return (
      <>
        <Button
          className="button-red radius"
          style={{ padding: "6px 8px" }}
          onClick={() => deleteResults(rowData)}
          severity="danger"
        >
          Xoá
        </Button>
      </>
    );
  };
  const indexTemplate = (rowData, rowIndex) => {
    return <>{rowIndex.rowIndex + 1}</>;
  };
  return (
    <div className="d-flex flex-column h-100 w-100">
      <DataTable
        size="small"
        value={resultsFriend}
        className="small-font-table dataTableWithFixedTool dataTableWithHoverEffect datatableoverflow"
        tableStyle={{ minWidth: "100%" }}
        rowClassName={(rowData) => rowClassName(rowData, selectedFriend)}
        onRowClick={(e) =>
          onRowClick(e.data, setSelectedFriend, selectedFriend)
        }
      >
        <Column
          header={headerCheckbox(
            selectedFriend,
            resultsFriend,
            toggleSelectAll,
            setSelectedFriend
          )}
          body={(rowData) =>
            bodyCheckbox(
              rowData,
              selectedFriend,
              setSelectedFriend,
              getDataAllFr
            )
          }
        />
        <Column body={indexTemplate} field="" header="STT"></Column>
        <Column field="UID" header="Bạn bè của UID" sortable></Column>
        <Column field="UID_scan" header="UID" sortable></Column>
        <Column
          body={(rowData) => nameAndAvatar(rowData)}
          field="nameAndAvatar"
          header="Bạn bè"
          sortable
        ></Column>
        <Column
          body={(rowData) => shortName(rowData)}
          field="short_name"
          header="Tên rút gọn"
          sortable
        ></Column>
        <Column field="gender" header="giới tính" sortable body={genDer} />
        <Column
          field="url"
          header="Link"
          sortable
          body={(rowData) => {
            const linkText =
              rowData?.url?.length > 40
                ? rowData?.url?.substring(0, 40) + "..."
                : rowData?.url;
            return (
              <a href={rowData?.url} target="_blank" rel="noopener noreferrer">
                {linkText}
              </a>
            );
          }}
        ></Column>
        <Column
          body={(rowData) => deleteButton(rowData)}
          header="Tuỳ chọn"
        ></Column>
      </DataTable>
      <Paginator
        className="pagination-markerting"
        first={first}
        rows={rows}
        totalRecords={getTotal?.count}
        onPageChange={onPageChange}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </div>
  );
};
