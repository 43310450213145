import React, { useEffect } from "react";
import Navigate from "../container/Navigate";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Footer } from "./Footer";
import { useHeaders } from "../../const/headers";
import { updateTitle } from "../../const/updateTitle";
import { API_URL, API_ROUTES_NTD } from "../../api";
import { Dropdown } from "primereact/dropdown";
import { ListContentMarket } from "../marketplace/ListContentMarket";
import PreviewMarket from "../marketplace/PreviewMarket";
import { DropdownFilterLocation } from "../marketplace/DropdownFilterLocation";
import { getAxios } from "../../const/getAxios";
import { AccountMarket } from "../marketplace/AccountMarket";
import { useLocation } from "react-router-dom";
export const MarketplaceHouse = () => {
  updateTitle("Chốt Care - Marketplace");
  const locationss = useLocation();
  const headers = useHeaders();
  const allCateMarket = API_URL + API_ROUTES_NTD.GET_CATE_MARKETPLACE;
  const getAllCateMkt = API_URL + API_ROUTES_NTD.GET_CATEGORY_MARKETPLACE;
  const postIdCateMart = API_URL + API_ROUTES_NTD.POST_ID_GET_CATE_MARKETPLACE;
  const [bedRoom, setBedRoom] = useState("");
  const [bathRoom, setBathRoom] = useState("");
  const [acreage, setAcreage] = useState("");
  const [cateMarket, setCateMarket] = useState([]);
  const [selectCate, setSelectCate] = useState(null);
  const [seletedHouse, setSeletedHouse] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState(null);
  const [subOptions, setSubOptions] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [filerKey, setFilterKey] = useState("");
  const [title, setTitle] = useState("");
  const [imageURLs, setImageURLs] = useState([]);
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [dataFb, setDataFb] = useState([]);
  const [selecAcc, setSelecAcc] = useState([]);
  const [location, setLocation] = useState([]);
  const [listFile, setListFile] = useState([]);
  const [listImage, setListImage] = useState([]);
  const [allCate, setAllCate] = useState([]);
  const [messMarket, setMessMarket] = useState([]);
  const [name, setName] = useState("");
  const [dataLink, setDataLink] = useState([]);
  const widthNavi = document.getElementById("navigate-width")?.offsetWidth;
  const widthMarket = document.getElementById("width-show-market")?.offsetWidth;
  const dataCopy = JSON.parse(localStorage.getItem("dataCopy"));
  const dataFill = JSON.parse(localStorage.getItem("dataFill"));
  const valueDropdown = [
    { name: "Căn hộ", code: "apartment" },
    { name: "Chung cư", code: "condo" },
    { name: "Nhà", code: "house" },
    { name: "Nhà liền kề", code: "townhouse" },
  ];
  useEffect(() => {
    getAxios(getAllCateMkt, setAllCate);
  }, []);
  const itemEditHouse = allCate?.filter(
    (item) => item.id === Number(locationss.pathname.split("/")[2])
  );
  useEffect(() => {
    if (itemEditHouse[0]?.id) {
      setListImage(itemEditHouse[0]?.images);
      setTitle(itemEditHouse[0]?.title);
      setDescription(itemEditHouse[0]?.description);
      setPrice(itemEditHouse[0]?.price);
      setSeletedHouse(
        valueDropdown.filter(
          (item) => item.code === itemEditHouse[0]?.propertyType
        )[0]
      );
      setBedRoom(itemEditHouse[0]?.bedroom);
      setBathRoom(itemEditHouse[0]?.bathroom);
      setAcreage(itemEditHouse[0]?.acreage);
      setFilterKey({
        name: itemEditHouse[0]?.location,
        latitude: itemEditHouse[0]?.latitude,
        longitude: itemEditHouse[0]?.longitude,
      });
      setMessMarket(itemEditHouse[0]?.messages_bot);
    }
  }, [allCate]);
  const postIdMartket = async (item) => {
    const body = { id_category: item };
    try {
      const res = await axios.post(postIdCateMart, body, headers);
      setSubOptions(
        res.data?.map((item) => ({
          name: item.category_detail_name,
          code: item.id,
          category_detail_id: item.category_detail_id,
        }))
      );
    } catch (err) {}
  };
  useEffect(() => {
    getAxios(allCateMarket, setCateMarket);
  }, []);

  useEffect(() => {
    if (dataLink?.data) {
      setDescription(dataLink?.data?.description?.text);
      setPrice(dataLink?.data?.price);
      setTitle(dataLink?.data?.title);
      setListImage(dataLink?.data?.photos);
    }
  }, [dataLink]);
  return (
    <div className="d-flex flex-column " style={{ height: "100vh" }}>
      <div className="notifica-container">
        <ToastContainer />
        <div className="navigate-container" id="navigate-width">
          <Navigate />
        </div>
        <div
          className="d-flex h-100 gap-2 p-2"
          style={{ width: `calc(100vw - ${widthNavi}px)` }}
        >
          <div
            className="h-100 shadow_market"
            id="width-show-market"
            style={{ width: "20%", overflowY: "auto", overflowX: "hidden" }}
          >
            <AccountMarket
              selecAcc={selecAcc}
              setSelecAcc={setSelecAcc}
              dataFb={dataFb}
              setDataFb={setDataFb}
            />
            <ListContentMarket
              dataFill={dataFill}
              dataCopy={dataCopy}
              toast={toast}
              dataLink={dataLink}
              setDataLink={setDataLink}
              name={name}
              setName={setName}
              listImage={listImage}
              setListImage={setListImage}
              setListFile={setListFile}
              listFile={listFile}
              description={description}
              setDescription={setDescription}
              price={price}
              setPrice={setPrice}
              imageURLs={imageURLs}
              setImageURLs={setImageURLs}
              title={title}
              setTitle={setTitle}
            />
            <div className=" mt-2 flex justify-content-center flex-column align-items-center w-100">
              <p className="w-100 mb-2 fs-6">Loại tài sản cho thuê</p>
              <Dropdown
                value={seletedHouse}
                onChange={(e) => {
                  setSeletedHouse(e.value);
                }}
                options={valueDropdown}
                optionLabel="name"
                placeholder="Loại tài sản cho thuê"
                className="w-100 custom-dropdown"
              />
            </div>
            <div className="mt-2 flex justify-content-center flex-column align-items-center w-100">
              <p className="w-100 mb-2 fs-6">Số phòng ngủ</p>
              <input
                type="number"
                value={bedRoom}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0) {
                    setBedRoom(value);
                  }
                }}
                placeholder="Số phòng ngủ"
                className="w-100 p-inputtext"
                min="0"
              />
            </div>
            <div className="mt-2 flex justify-content-center flex-column align-items-center w-100">
              <p className="w-100 mb-2 fs-6">Số phòng tắm</p>
              <input
                type="number"
                value={bathRoom}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0) {
                    setBathRoom(value);
                  }
                }}
                placeholder="Số phòng tắm"
                className="w-100 p-inputtext"
                min="0"
              />
            </div>
            <div className="mt-2 flex justify-content-center flex-column align-items-center w-100">
              <p className="w-100 mb-2 fs-6">Nhập diện tích</p>
              <input
                type="number"
                value={acreage}
                onChange={(e) => setAcreage(e.target.value)}
                placeholder="Diện tích (met vuông)"
                className="w-100 p-inputtext"
              />
            </div>
            <DropdownFilterLocation
              messMarket={messMarket}
              setMessMarket={setMessMarket}
              location={location}
              setLocation={setLocation}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              setFilterKey={setFilterKey}
              filerKey={filerKey}
              headers={headers}
            />
          </div>
          <div
            className="h-100 shadow_market d-flex"
            style={{ width: `calc(100% - ${widthMarket}px - 0.5rem)` }}
          >
            <PreviewMarket
              seletedHouse={seletedHouse}
              selectCate={selectCate}
              bedRoom={bedRoom}
              bathRoom={bathRoom}
              acreage={acreage}
              subOptions={subOptions}
              messMarket={messMarket}
              toast={toast}
              name={name}
              listImage={listImage}
              selectedSubOption={selectedSubOption}
              listFile={listFile}
              filerKey={filerKey}
              selecAcc={selecAcc}
              description={description}
              title={title}
              price={price}
              imageURLs={imageURLs}
              location={location}
              setImageURLs={setImageURLs}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
